import m from "mithril";
import { PageHeader, TopicPageHeader } from "./header";
import { ContentCardView } from "./content_card";
import { Footer } from "./footer";
import { idealTextColor, lightenDarkenColor } from "../color";
import { API_URL_BASE } from "../config";
import { fetchJwt, loggedInUser } from "../account";
import * as api from "../api";
import { deleteTopic, getTopic } from "../api";
import { NotFoundPageView } from "./etc";
import { Parser, HtmlRenderer } from "commonmark";
import { TagSummaryView, TopicSummaryView, UserSummaryView } from "./summaries";
import { listWatches } from "../api";
import { listStars } from "../api";
import { listForks } from "../api";
import { analytics } from "../analytics";
import { reloadPage, titleCase } from "../util";
import { CompactAutoTagView } from "./autotag";
import { trainModel } from "../api";
import { addTutorialTaggieToAttrs } from "./tutorials";
import { listTopicMembers } from "../api";
import { UserImageAndName } from "./users";
import { deleteTopicMember } from "../api";
import { postTopicMember } from "../api";
import { getUser } from "../api";
import { inviteTopicMemberByEmail } from "../api";

export class TopicButtonView {
  static from({ topic, owner, size, hasX, hasLink, hasV, onclick, onX, hideOwner }) {
    return m(TopicButtonView, {
      name: topic.name,
      color: topic.color,
      owner: owner.display_name,
      size,
      hasX,
      hasLink,
      hasV,
      onclick,
      onX,
      hideOwner,
    });
  }

  view(vnode) {
    const { name, owner, size, color, hasX, hasLink, hasV, onclick, onX, hideOwner } = vnode.attrs;

    const deleteOpts =
      typeof onX === "string"
        ? { href: onX, oncreate: m.route.link, style: "margin-left: 4px;" }
        : { onclick: onX, style: "margin-left: 4px;" };

    const title = `${owner}/${name}`;
    const textColor = idealTextColor(color);

    const hoverStyle = m.trust(`<style>
.taggit-topic-button .tag.is-color-${(color || "").slice(1, 7)}:hover {
  background-color: ${lightenDarkenColor(color || "#ffffff", -10)} !important;
}
</style>`);

    const ownerButtonAttrs = { style: "margin-bottom: 0;" };
    if (hasLink) {
      ownerButtonAttrs["href"] = `/${owner}`;
      ownerButtonAttrs["oncreate"] = m.route.link;
    }
    const ownerButton = hideOwner
      ? null
      : m(`a.taggit-tag.tag.is-dark.is-${size || "small"}`, ownerButtonAttrs, owner);

    const topicButtonAttrs = {
      style:
        `margin-bottom: 0; min-width: 0px; justify-content: left; background-color: ${color};` +
        (hideOwner ? "border-radius: 4px;" : ""),
    };
    const topicSpanAttrs = { style: `color: ${textColor};` };
    if (hasLink) {
      topicSpanAttrs["href"] = `/${owner}/${name}`;
      topicSpanAttrs["oncreate"] = m.route.link;
    }
    const topicButton = m(
      `div.tag.is-${size || "small"}.is-color-${(color || "").slice(1, 7)}`,
      topicButtonAttrs,
      [
        m("a", topicSpanAttrs, name),
        hasV ? m("span.icon.is-small", m("i.mdi.mdi-chevron-down")) : null,
        hasX ? m("button.delete", deleteOpts) : null,
      ]
    );

    return m(
      "div.tags.has-addons.is-inline-block.taggit-topic-button",
      {
        title,
        style:
          "flex-wrap: nowrap; white-space: nowrap; margin-bottom: 0;" +
          (hasV || onclick ? "cursor: pointer;" : ""),
        onclick: !hasLink && onclick ? onclick : () => {},
      },
      [ownerButton, hoverStyle, topicButton]
    );
  }

  static fromOwnerAndTopic(owner, topic, size) {
    return m(TopicButtonView, {
      name: topic.name,
      owner: owner.display_name,
      color: topic.color,
      size,
      hasLink: true,
    });
  }
}

export class TopicLabelView {
  view(vnode) {
    const { label, hasX, onX } = vnode.attrs;

    return m(
      "div.control",
      m("div.tags.has-addons", [
        m(
          "span.tag",
          // {href: `/search?label=${label.label_name}`, oncreate: m.route.link},
          label.label_name
        ),
        hasX ? m("a.tag.is-delete", { onclick: onX }) : null,
      ])
    );
  }
}

export class TopicLabelAddButton {
  oninit(vnode) {
    vnode.state.inputting = false;
    vnode.state.loading = false;
    vnode.state.error = false;
  }

  view(vnode) {
    const { onLabelCreate } = vnode.attrs;
    const { inputting } = vnode.state;

    const submit = evt => {
      vnode.state.loading = true;
      m.redraw();
      const inputValue = vnode.dom.querySelector("input").value;
      return onLabelCreate(inputValue)
        .then(() => {
          vnode.state.loading = false;
          vnode.state.error = false;
          reloadPage();
        })
        .catch(() => {
          vnode.state.error = true;
          vnode.state.loading = false;
          m.redraw();
        });
    };

    const inputClass = vnode.state.error ? ".is-danger" : "";
    const buttonClass = vnode.state.loading ? ".is-loading" : "";

    const onkeyup = evt => {
      if (evt.keyCode === 13) {
        submit(evt);
      }
    };

    return m(
      "div.field" + (inputting ? ".has-addons" : ""),
      inputting
        ? [
            m("div.control", m("input.input.is-small" + inputClass, { onkeyup })),
            m(
              "div.control",
              m("button.button.is-small" + buttonClass, { onclick: submit }, "Submit")
            ),
          ]
        : m(
            "a.control",
            {
              onclick: () => {
                vnode.state.inputting = true;
                setTimeout(() => {
                  vnode.dom.querySelector("input").focus();
                }, 100);
              },
            },
            m("span.tag", "+")
          )
    );
  }
}

export const topicPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async (args, requestedPath) => {
      document.title = `Taggit · ${args.owner}/${args.name}`;
      const jwt = await fetchJwt();
      const mePromise = loggedInUser(jwt);
      const topicPromise = getTopic(jwt, args.owner, args.name);

      attrs.onRefreshTopic = () =>
        getTopic(jwt, args.owner, args.name).then(topicDetails => {
          attrs.topicDetails = topicDetails;
          m.redraw();
          return topicDetails;
        });

      attrs.me = (await mePromise) || {};
      try {
        attrs.topicDetails = await topicPromise;
      } catch (e) {
        console.error(e);
      }

      attrs.onGenerateTags = api.generateTopicTags

      return addTutorialTaggieToAttrs(attrs, args, requestedPath, jwt, attrs.me);
    },
    render: vnode => [
      m(TopicPageView, Object.assign(attrs, vnode.attrs)),
      window.errorTaggie || attrs.tutorial || null,
    ],
  };
})();

export class TopicPageView {
  view(vnode) {
    const { me, topicDetails, onRefreshTopic, onGenerateTags } = vnode.attrs;
    if (!topicDetails) {
      return m(NotFoundPageView, vnode.attrs);
    }

    let {
      topic,
      labels,
      owner,
      topic_meta,
      training_eligibility,
      scores,
      smoothed_scores,
      entitlements,
      recent_positive_tags,
    } = topicDetails;
    const { watched, starred, cant_fork, forked } = topic_meta;
    const { max_classifier_tier } = entitlements;
    labels = labels || [];
    const analyticsLabel = `/${owner.display_name}/${topic.name}`;

    const onLabelCreate = labelName => {
      return api.labelTopic(owner.display_name, topic.name, labelName).then(reloadPage);
    };

    const onWatchTextClick = evt =>
      (watched
        ? api.unwatchTopic(owner.display_name, topic.name)
        : api.watchTopic(owner.display_name, topic.name)
      ).then(() => {
        if (watched) {
          analytics.track("unwatch-topic", { category: "topic-watch", label: analyticsLabel });
        } else {
          analytics.track("watch-topic", { category: "topic-watch", label: analyticsLabel });
        }
        reloadPage();
      });
    const onWatchNumberClick = `/${owner.display_name}/${topic.name}/watchers`;
    const onStarTextClick = evt =>
      (starred
        ? api.unstarTopic(owner.display_name, topic.name)
        : api.starTopic(owner.display_name, topic.name)
      ).then(async () => {
        if (starred) {
          analytics.track("unstar-topic", { category: "topic-star", label: analyticsLabel });
        } else {
          analytics.track("star-topic", { category: "topic-star", label: analyticsLabel });
        }
        return reloadPage();
      });
    const onStarNumberClick = `/${owner.display_name}/${topic.name}/stars`;
    const onForkTextClick = async evt => {
      if (!cant_fork) {
        await analytics.timeFunction(
          "fork-topic",
          { category: "fork-topic", label: analyticsLabel },
          async () =>
            api.forkTopic(owner.display_name, topic.name).then(() => {
              m.route.set(`/${me.displayName}/${topic.name}/`);
            })
        );
      }
    };
    const onForkNumberClick = `/${owner.display_name}/${topic.name}/copies`;

    const hasX = topic_meta.access === "edit" || topic_meta.access === "admin";
    const content = new HtmlRenderer().render(new Parser().parse(topic.description || ""));

    const topicLabelsView = m("div.columns", { style: "margin-bottom: 1em;" }, [
      m("div.column", [
        m("h2.heading", "Labels"),
        m(
          "div.field.is-grouped",
          labels
            .map(label =>
              m(TopicLabelView, {
                label,
                hasX,
                onX: evt => {
                  api
                    .unlabelTopic(owner.display_name, topic.name, label.label_name)
                    .then(reloadPage);
                },
              })
            )
            .concat([
              topic_meta.access === "read" ? null : m(TopicLabelAddButton, { onLabelCreate }),
            ])
        ),
      ]),
      m("div.column", [
        m("h2.heading", "Keywords"),
        m(
          "div.field.is-grouped",
          topic.keywords.split(/, /g).map(keyword => m("div.control", m("span.tag", keyword)))
        ),
      ]),
    ]);

    const recentTagsView = Array.isArray(recent_positive_tags)
      ? m("div.recent-tags", [
          m("h2.heading", "Recent Tags"),
          m(
            "div.columns",
            recent_positive_tags.map(target_tag =>
              m(
                "div.column.is-half",
                m(TagSummaryView, {
                  target_tag,
                  topic,
                  owner,
                  acting_user: target_tag.acting_user,
                })
              )
            )
          ),
        ])
      : null;

    const tryItOutView = m("div.try-it-out", [
      m("h2.heading", "Try Auto Tagger"),
      m(CompactAutoTagView, { selectedTopics: [topicDetails], me, buttonText: "Try It Out" }),
    ]);

    return [
      m(TopicPageHeader, {
        me,
        topicDetails,
        onWatchTextClick,
        onWatchNumberClick,
        onStarTextClick,
        onStarNumberClick,
        onForkTextClick,
        onForkNumberClick,
        onGenerateTags,
        watched,
        starred,
        forked,
        canFork: !cant_fork,
        onRefreshTopic,
      }),
      m(
        "section.section",
        { style: "padding-top: 1em;" },
        m("div.container", [
          topicLabelsView,
          tryItOutView,
          m(ContentCardView, {
            title: "Description",
            content: m("p", m.trust(content) || ""),
          }),
          recentTagsView,
        ])
      ),
      m(Footer),
    ];
  }
}

export const topicCreatePageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async (args, requestedPath) => {
      const jwt = await fetchJwt();
      attrs.me = await loggedInUser(jwt);

      if (attrs.me === null) {
        localStorage.setItem("pendingRedirect", location.pathname + location.search);
        m.route.set("/login");
      }

      return addTutorialTaggieToAttrs(attrs, args, requestedPath, jwt, attrs.me);
    },
    render: vnode => [
      m(TopicCreateEditPageView, Object.assign(attrs, vnode.attrs)),
      window.errorTaggie || attrs.tutorial || null,
    ],
  };
})();

export class TopicCreateEditPageView {
  oninit(vnode) {
    const topic = vnode.attrs.topic || {};
    vnode.state.nameLoading = false;
    vnode.state.nameInvalid = null;
    vnode.state.nameInvalidReason = "";
    vnode.state.summaryInvalid = null;
    vnode.state.summaryInvalidReason = "";
    vnode.state.descriptionInvalid = null;
    vnode.state.descriptionInvalidReason = "";
    vnode.state.selectedPrivacy = topic.private === true ? "Private" : "Public";
    vnode.state.selectedColor = topic.color || "#23d160";
    vnode.state.textColor = idealTextColor(vnode.state.selectedColor);
    vnode.state.name = topic.name || "";
    vnode.state.summary = topic.summary || "";
    vnode.state.description = topic.description || "";
    vnode.state.keywords = topic.keywords || "";
  }

  view(vnode) {
    const { me, topic, owner } = vnode.attrs;
    vnode.state.mode = topic === undefined ? "create" : "edit";
    if (vnode.state.mode === "create") {
      document.title = `Taggit · Create Topic`;
    } else {
      document.title = `Taggit · Edit Topic ${owner.display_name}/${topic.name}`;
    }
    const { selectedColor, textColor } = vnode.state;
    const buildColorItem = option => {
      const { color, name } = option;
      const onclick = event => setColor(color);
      return m(
        "div.dropdown-item",
        { onclick },
        m(
          "a.button.has-text-weight-bold",
          {
            style: `border-bottom-left-radius: 4px; border-top-left-radius: 4px;
          background-color: ${color}; border-color: ${color}; color: ${idealTextColor(color)};`,
          },
          name
        )
      );
    };

    const setColor = color => {
      vnode.state.selectedColor = color;
      vnode.state.textColor = idealTextColor(vnode.state.selectedColor);
      m.redraw();
    };

    const onCustomColor = evt => setColor(evt.target.value);

    const topicNameCtrlClass = vnode.state.nameLoading ? ".is-loading" : "";
    const topicNameClass =
      vnode.state.nameInvalid === true
        ? ".is-danger"
        : vnode.state.nameInvalid === false
          ? ".is-success"
          : "";

    let nameTimeout, descriptionTimeout, summaryTimeout, keywordsTimeout;

    const onNameInput = evt => {
      clearTimeout(nameTimeout);
      vnode.state.name = evt.target.value;
      nameTimeout = setTimeout(async () => {
        await updateNameValidation();
        m.redraw();
      }, 600);
    };

    const updateNameValidation = async () => {
      let { name } = vnode.state;
      if (name.length === 0) {
        vnode.state.nameInvalid = true;
        vnode.state.nameInvalidReason = `Topic name cannot be empty.`;
      } else if (name.length > 39) {
        vnode.state.nameInvalid = true;
        vnode.state.nameInvalidReason = `Topic name is too long (${
          name.length
        } characters), max allowed is 39 characters.`;
      } else if (!name.match(/^[A-Za-z\d](?:[A-Za-z\d]|-(?=[A-Za-z\d])){0,38}$/)) {
        vnode.state.nameInvalid = true;
        vnode.state.nameInvalidReason = `Topic name is invalid - must contain only alphanumeric characters or hyphen (-).  Can't end or start with hyphen.`;
      } else {
        const jwt = await fetchJwt();
        let nameCollision = vnode.attrs.topic === undefined || name !== vnode.attrs.topic.name;
        await m
          .request({
            url: API_URL_BASE + `/users/${me.displayName}/topics/${name}`,
            headers: { Authorization: "Bearer " + jwt },
          })
          .catch(err => {
            if (err.message.indexOf("404") >= 0) {
              nameCollision = false;
            }
          });

        if (nameCollision) {
          vnode.state.nameInvalid = true;
          vnode.state.nameInvalidReason = "You already own a topic with this name.";
        } else {
          vnode.state.nameInvalid = false;
        }
      }
    };

    const onSummaryInput = evt => {
      clearTimeout(summaryTimeout);
      vnode.state.summary = evt.target.value;
      summaryTimeout = setTimeout(() => {
        updateSummaryValidation();
        m.redraw();
      }, 600);
    };

    const updateSummaryValidation = () => {
      let summary = document.getElementById("summary").value;
      if (summary.length > 72) {
        vnode.state.summaryInvalid = true;
        vnode.state.summaryInvalidReason = `Summary is too long (${
          summary.length
        } characters), max allowed is 72 characters.`;
      } else {
        vnode.state.summaryInvalid = false;
      }
    };

    const onDescriptionInput = evt => {
      clearTimeout(descriptionTimeout);
      vnode.state.description = evt.target.value;
      descriptionTimeout = setTimeout(() => {
        updateDescriptionValidation();
        m.redraw();
      }, 600);
    };

    const updateDescriptionValidation = () => {
      const { description } = vnode.state;
      if (description.length > 1024 * 1024) {
        vnode.state.descriptionInvalid = true;
        vnode.state.descriptionInvalidReason = `Description is too long (${
          description.length
        } characters), max allowed is 1048576 characters.`;
      } else {
        vnode.state.descriptionInvalid = false;
      }
    };

    const onKeywordsInput = evt => {
      clearTimeout(keywordsTimeout);
      vnode.state.keywords = evt.target.value;
      keywordsTimeout = setTimeout(() => {
        updateKeywordsValidation();
        m.redraw();
      }, 600);
    };

    const updateKeywordsValidation = () => {
      const { keywords } = vnode.state;
      const keywordTokens = keywords.split(/[ ]+/g).filter(kw => kw !== "");
      vnode.state.keywordsInvalid = true;
      if (keywordTokens.length > 16) {
        vnode.state.keywordsInvalidReason = "Must use 16 or fewer keywords.";
      } else if (keywordTokens.length === 0) {
        vnode.state.keywordsInvalidReason = "Must provide at least 1 keyword.";
      } else {
        vnode.state.keywordsInvalid = false;
      }
    };

    const maybeSubmit = async () => {
      await updateNameValidation();
      updateSummaryValidation();
      updateDescriptionValidation();
      updateKeywordsValidation();
      const jwt = await fetchJwt();

      const url =
        vnode.state.mode === "create"
          ? API_URL_BASE + "/topics/create"
          : API_URL_BASE + `/users/${owner.display_name}/topics/${topic.name}`;

      if (
        !vnode.state.descriptionInvalid &&
        !vnode.state.summaryInvalid &&
        !vnode.state.nameInvalid &&
        !vnode.state.keywordsInvalid
      ) {
        await analytics.timeFunction(
          vnode.state.mode + "-topic",
          { category: vnode.state.mode + "-topic", label: `${me.displayName}/${vnode.state.name}` },
          async () =>
            m.request({
              headers: { Authorization: "Bearer " + jwt },
              url: url,
              method: "POST",
              data: {
                topic_name: vnode.state.name,
                description: vnode.state.description,
                summary: vnode.state.summary,
                color: vnode.state.selectedColor,
                private: vnode.state.selectedPrivacy === "Private",
                keywords: vnode.state.keywords,
              },
            })
        );
        m.route.set(
          vnode.state.mode === "create"
            ? `/${me.displayName}/${vnode.state.name}`
            : `/${owner.display_name}/${vnode.state.name}`
        );
      } else {
        m.redraw();
      }
    };

    const createForm = [
      m("label.heading", "Topic"),
      m("div.field.has-addons", [
        m("div.control", m("button.button.is-dark", me.displayName)),
        m(
          "div.control.is-expanded" + topicNameCtrlClass,
          m("input.input#topic-name" + topicNameClass, {
            placeholder: "Topic Name",
            oninput: onNameInput,
            value: vnode.state.name,
          })
        ),
        m(
          "div.control",
          m("div.dropdown.is-hoverable", [
            m(
              "div.dropdown-trigger",
              m(
                "div.button",
                { style: `background-color: ${selectedColor}; border-color: ${selectedColor}` },
                [
                  m(
                    "span.pad-r-half-em.has-text-weight-bold",
                    { style: `color: ${textColor}` },
                    "Color"
                  ),
                  m("span.icon", { style: `color: ${textColor}` }, m("i.mdi.mdi-chevron-down")),
                ]
              )
            ),
            m(
              "div.dropdown-menu",
              m(
                "div.dropdown-content",
                [
                  { name: "Gray", color: "#f5f5f5" },
                  { name: "Purple", color: "#bb50f1" },
                  { name: "Orange", color: "#ff6c41" },
                  { name: "Blue", color: "#209cee" },
                  { name: "Green", color: "#23d160" },
                  { name: "Yellow", color: "#ffdd57" },
                  { name: "Red", color: "#ff3860" },
                ]
                  .map(buildColorItem)
                  .concat([
                    m(
                      "div.dropdown-item",
                      m(
                        "table",
                        { style: "width: 0;" },
                        m("tr", [
                          m("td", { style: `height: 30px; border: 0;` }, "Custom:"),
                          m(
                            "td",
                            { style: "padding: 0; border: 0;" },
                            m(
                              "div.color-picker-container",
                              m("input[type=color]", {
                                style: `cursor: pointer; opacity: 0;`,
                                onchange: onCustomColor,
                                value: selectedColor,
                              })
                            )
                          ),
                        ])
                      )
                    ),
                  ])
              )
            ),
          ])
        ),
      ]),
      vnode.state.nameInvalid === true
        ? m("p.help.is-danger", vnode.state.nameInvalidReason)
        : null,
      m("div.field", [
        m("label.heading", "Summary"),
        m(
          "div.control",
          m("input.input#summary", {
            placeholder: "A short summary",
            oninput: onSummaryInput,
            value: vnode.state.summary,
          })
        ),
      ]),
      vnode.state.summaryInvalid === true
        ? m("p.help.is-danger", vnode.state.summaryInvalidReason)
        : null,
      m("div.field", [
        m("label.heading", "Description"),
        m(
          "div.control",
          m("textarea.textarea#description", {
            rows: 8,
            oninput: onDescriptionInput,
            placeholder: `# A Longer Description

Markdown is valid!
  - Provides value
  - Does cool things`,
            value: vnode.state.description,
          })
        ),
      ]),
      vnode.state.descriptionInvalid === true
        ? m("p.help.is-danger", vnode.state.descriptionInvalidReason)
        : null,
      m("div.field", [
        m("label.heading", [
          "Keywords",
          m(
            "span.icon",
            m("i.mdi.mdi-help-circle.mdi-18px", {
              title: "Keywords used to search for relevant examples to tag for this topic.",
              style: "cursor: help;",
            })
          ),
        ]),
        m(
          "div.control",
          m("textarea.textarea#keywords", {
            rows: 1,
            oninput: onKeywordsInput,
            placeholder: "wonderful, great, awesome, love",
            value: vnode.state.keywords,
          })
        ),
      ]),
      vnode.state.keywordsInvalid === true
        ? m("p.help.is-danger", vnode.state.keywordsInvalidReason)
        : null,
      me.maxPrivateTopics > 0
        ? m("div.field", [
            m("label.heading", "Privacy"),
            m("div.control.has-icons-left", [
              m(
                "div.select",
                m(
                  "select#privacy",
                  {
                    onchange: evt => {
                      vnode.state.selectedPrivacy = evt.target.value;
                    },
                  },
                  [m("option", "Public"), m("option", "Private")]
                )
              ),
              m(
                "div.icon.is-small.is-left",
                vnode.state.selectedPrivacy === "Private"
                  ? m("i.mdi.mdi-lock")
                  : m("i.mdi.mdi-account-group")
              ),
            ]),
          ])
        : null,
    ];

    const footerButtons =
      vnode.state.mode === "create"
        ? m("p.buttons", m("button.button.is-primary", { onclick: maybeSubmit }, "Create Topic"))
        : m("div.level", { style: "width: 100%;" }, [
            m(
              "div.level-left",
              m(
                "div.level-item",
                m(
                  "a.button.is-danger.is-outlined",
                  { href: `/${me.displayName}/${vnode.state.name}/delete`, oncreate: m.route.link },
                  "Delete Topic"
                )
              )
            ),
            m(
              "div.level-right",
              m(
                "div.level-item",
                m("button.button.is-primary", { onclick: maybeSubmit }, "Update Topic")
              )
            ),
          ]);

    const content =
      vnode.state.mode === "edit"
        ? m("div.columns.is-desktop", [
            m(
              "div.column",
              { style: "padding: 1em;" },
              m(TopicEditNavMenu, { owner: owner.display_name, topic: topic.name })
            ),
            m(
              "div.column",
              { style: "flex: 2; padding: 1em;" },
              m(ContentCardView, {
                title: vnode.state.mode === "create" ? "Create Topic" : "Topic Settings",
                content: createForm,
                footer: footerButtons,
              })
            ),
          ])
        : m(ContentCardView, {
            title: vnode.state.mode === "create" ? "Create Topic" : "Topic Settings",
            content: createForm,
            footer: footerButtons,
          });

    return [
      m(PageHeader, { me }),
      m(
        "section.section",
        m(
          "div.container",
          { style: vnode.state.mode === "edit" ? null : "max-width: 48em" },
          content
        )
      ),
      m(Footer),
    ];
  }
}

export const topicSettingsPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async args => {
      document.title = "Taggit · Settings";
      const jwt = await fetchJwt();
      const mePromise = loggedInUser(jwt);
      const topicPromise = getTopic(jwt, args.owner, args.name);

      attrs.me = await mePromise;

      if (attrs.me === null) {
        localStorage.setItem("pendingRedirect", location.pathname + location.search);
        m.route.set("/login");
      }

      const { topic, owner, labels } = await topicPromise;
      attrs.topic = topic;
      attrs.owner = owner;
    },
    render: vnode => m(TopicCreateEditPageView, attrs),
  };
})();

export class DeleteTopicPageView {
  oninit(vnode) {
    vnode.state.deleting = false;
    return loggedInUser().then(me => {
      vnode.state.me = me;

      if (vnode.state.me === null) {
        localStorage.setItem("pendingRedirect", location.pathname + location.search);
        m.route.set("/login");
      }

      m.redraw();
    });
  }
  view(vnode) {
    const me = vnode.state.me || {};

    const onclick = async evt => {
      vnode.state.deleting = true;
      await deleteTopic(await fetchJwt(), vnode.attrs.owner, vnode.attrs.name);
      window.location.pathname = "/";
    };

    return [
      m(PageHeader, { me }),
      m(
        "section.section",
        m("div.container", [
          m("h1.title", `Delete ${vnode.attrs.owner}/${vnode.attrs.name}?`),
          m("h2.subtitle", "Are you sure? This cannot be undone!"),
          m("div.buttons", [
            m(
              "a.button",
              {
                href: `/${vnode.attrs.owner}/${vnode.attrs.name}/settings`,
                oncreate: m.route.link,
              },
              "Cancel"
            ),
            m(
              "button.button.is-danger" + (vnode.state.deleting ? ".is-loading" : ""),
              { onclick },
              "Delete Topic"
            ),
          ]),
        ])
      ),
      m(Footer),
    ];
  }
}

export const deleteTopicPageProvider = {
  onmatch: args => {
    document.title = `Taggit · Delete Topic ${args.owner}/${args.name}`;
  },
  render: vnode => m(DeleteTopicPageView, vnode.attrs),
};

export class UserListView {
  view(vnode) {
    const { users } = vnode.attrs;
    return m("div", users.map(({ user, following }) => m(UserSummaryView, { user, following })));
  }
}

export class TopicWatchersPageView {
  view(vnode) {
    const {
      me,
      users,
      thisTopic: { topic, owner },
    } = vnode.attrs;
    return [
      m(PageHeader, {
        me,
        title: [TopicButtonView.from({ topic, owner, size: "large", hasLink: true }), " Watchers"],
      }),
      m("section.section", m("div.container", m(UserListView, { users }))),
      m(Footer),
    ];
  }
}

export const topicWatchersPageProvider = (() => {
  const attrs = {};
  return {
    onmatch: async args => {
      const jwt = await fetchJwt();
      const mePromise = loggedInUser(jwt);
      const usersPromise = listWatches(jwt, args.owner, args.name);
      const topicPromise = getTopic(jwt, args.owner, args.name);

      attrs.me = await mePromise;
      attrs.users = await usersPromise;
      attrs.thisTopic = await topicPromise;
    },
    render: vnode => m(TopicWatchersPageView, Object.assign(attrs, vnode.attrs)),
  };
})();

export class TopicStarsPageView {
  view(vnode) {
    const {
      me,
      users,
      thisTopic: { topic, owner },
    } = vnode.attrs;

    const content =
      users.length > 0
        ? m(UserListView, { users })
        : m("article.message", m("div.message-body", "No one has starred this topic."));

    return [
      m(PageHeader, {
        me,
        title: [TopicButtonView.from({ topic, owner, size: "large", hasLink: true }), " Stars"],
      }),
      m("section.section", m("div.container", content)),
      m(Footer),
    ];
  }
}

export const topicStarsPageProvider = (() => {
  const attrs = {};
  return {
    onmatch: async args => {
      const jwt = await fetchJwt();
      const mePromise = loggedInUser(jwt);
      const usersPromise = listStars(jwt, args.owner, args.name);
      const topicPromise = getTopic(jwt, args.owner, args.name);

      attrs.me = await mePromise;
      attrs.users = await usersPromise;
      attrs.thisTopic = await topicPromise;
    },
    render: vnode => m(TopicStarsPageView, Object.assign(attrs, vnode.attrs)),
  };
})();

export class TopicForksPageView {
  view(vnode) {
    const {
      me,
      topics,
      thisTopic: { topic, owner },
    } = vnode.attrs;

    const content =
      topics.length > 0
        ? topics.map(({ topic, owner, meta, labels }) =>
            m(TopicSummaryView, { topic, owner, meta, labels })
          )
        : m("article.message", m("div.message-body", "No copies of this topic have been made."));

    return [
      m(PageHeader, {
        me,
        title: [TopicButtonView.from({ topic, owner, size: "large", hasLink: true }), " Copies"],
      }),
      m("section.section", m("div.container", m("div", content))),
      m(Footer),
    ];
  }
}

export const topicForksPageProvider = (() => {
  const attrs = {};
  return {
    onmatch: async args => {
      const jwt = await fetchJwt();
      const mePromise = loggedInUser(jwt);
      const topicsPromise = listForks(jwt, args.owner, args.name);
      const topicPromise = getTopic(jwt, args.owner, args.name);

      attrs.me = await mePromise;
      attrs.topics = await topicsPromise;
      attrs.thisTopic = await topicPromise;
    },
    render: vnode => m(TopicForksPageView, Object.assign(attrs, vnode.attrs)),
  };
})();

export class TopicEditNavMenu {
  view(vnode) {
    const { owner, topic } = vnode.attrs;
    const pathParts = window.location.pathname.split("/");
    const selected = pathParts[pathParts.length - 1];
    return m(
      "div.panel",
      ["settings", "members"].map(page => {
        const href = `/${owner}/${topic}/${page}`;
        const active = selected === page ? ".is-active.has-text-weight-bold" : "";
        return m(
          "a.panel-block" + active,
          { href, oncreate: m.route.link },
          `Topic ${titleCase(page)}`
        );
      })
    );
  }
}

class TopicMemberRow {
  oninit(vnode) {
    vnode.state.loading = false;
  }
  view(vnode) {
    const { member, onSetMembership, onDeleteMembership, isOwner, isDisabled } = vnode.attrs;
    const { membership, user } = member;

    let icon;
    if (membership.access === "read") {
      icon = m("div.icon.is-left", m("i.mdi.mdi-eye"));
    } else if (membership.access === "edit") {
      icon = m("span.icon.is-left", m("i.mdi.mdi-pencil"));
    } else if (membership.access === "admin") {
      icon = m("span.icon.is-left", m("i.mdi.mdi-account"));
    }

    const onchange = evt => {
      vnode.state.loading = true;
      m.redraw();
      onSetMembership(user.user_id, evt.target.value.toLowerCase());
      vnode.state.loading = false;
      m.redraw();
      m.route.set(m.route.get());
    };

    const onclick = evt => {
      vnode.state.loading = true;
      m.redraw();
      onDeleteMembership(user.display_name);
      vnode.state.loading = false;
      m.redraw();
      setTimeout(() => {
        m.route.set(m.route.get());
      }, 300);
    };

    const ownerFlair = isOwner ? m("span.tag.is-dark.margin-l-half-em", "Owner") : null;

    const deleteButton =
      isOwner || isDisabled
        ? null
        : m(
            "div.margin-l-1-em",
            m("a.delete", {
              onclick,
              title: "Remove this user from the topic.",
            })
          );

    return m(
      "div.panel-block.is-flex",
      { style: "justify-content: space-between;", key: user.user_id },
      [
        m("div.is-flex", { style: "align-items: center;" }, [
          m(UserImageAndName, { user }),
          ownerFlair,
        ]),
        m("div.is-flex", { style: "align-items: center;" }, [
          m(
            "div.field.is-marginless",
            m(
              "div.control.has-icons-left",
              m("div.select" + (vnode.state.loading ? ".is-loading" : ""), [
                m(
                  "select",
                  { onchange, disabled: isOwner || isDisabled },
                  ["read", "edit", "admin"].map(access => {
                    const selected = membership.access === access;
                    return m("option", { selected }, titleCase(access));
                  })
                ),
                icon,
              ])
            )
          ),
          deleteButton,
        ]),
      ]
    );
  }
}

class TopicMembersListView {
  view(vnode) {
    const {
      members,
      topicDetails: {
        topic: { owner_id },
        topic_meta: { access },
      },
      onSetMembership,
      onDeleteMembership,
    } = vnode.attrs;
    return m(
      "div.panel",
      [m("div.panel-heading", "Topic Members")].concat(
        members.map(member => {
          const isOwner = member.user.user_id === owner_id;
          const isDisabled = access !== "admin";
          return m(TopicMemberRow, {
            member,
            onSetMembership,
            onDeleteMembership,
            isOwner,
            isDisabled,
          });
        })
      )
    );
  }
}

class AddMemberView {
  oninit(vnode) {
    vnode.state.loading = false;
    vnode.state.username = null;
    vnode.state.errorMessage = null;
    vnode.state.successMessage = null;
  }
  view(vnode) {
    const {
      topicDetails: {
        owner: { display_name: owner },
        topic: { name },
      },
    } = vnode.attrs;
    const onclick = async evt => {
      vnode.state.loading = true;
      m.redraw();
      const jwt = await fetchJwt();
      if (vnode.state.username.indexOf("@") < 0) {
        const { user } = await getUser(jwt, vnode.state.username)
          .then(({ user }) => postTopicMember(jwt, owner, name, user.user_id, "read"))
          .catch(reason => {
            vnode.state.loading = false;
            vnode.state.errorMessage = "Could not find a user that matches this username.";
            m.redraw();
          });
      } else {
        // This is an email address since @s are disallowed in usernames
        // await inviteTopicMemberByEmail(jwt, owner, name, vnode.state.username, "read")
        //   .then(_ => {
        //     console.log(_);
        //     vnode.state.successMessage = `Invite sent to ${vnode.state.username}!`;
        //   })
        //   .catch(_reason => {
        //     vnode.state.loading = false;
        //     vnode.state.errorMessage =
        //       "Failed to invite by email, please try again in a few minutes.";
        //     m.redraw();
        //   });
        vnode.state.loading = false;
        vnode.state.errorMessage = "Sorry, you can't invite users by email yet!";
        m.redraw();
      }
      vnode.state.loading = false;
      m.redraw();
      m.route.set(m.route.get());
    };

    const onkeyup = evt => {
      if (evt.key === "Enter") {
        onclick();
      } else {
        vnode.state.username = evt.target.value;
        vnode.state.errorMessage = null;
        vnode.state.successMessage = null;
      }
    };

    return m("div.panel", [
      m("div.panel-heading", "Add Member"),
      m("div.panel-block.is-block", [
        m("p.margin-b-half-em", "Add members by username."),
        m("div.field.has-addons", [
          m(
            "div.control.is-expanded",
            m("input.input#user-add-input", {
              type: "text",
              placeholder: "Username to add",
              onkeyup,
            })
          ),
          m(
            "div.control",
            m(
              "button.button.is-primary#user-add-submit" +
                (vnode.state.loading ? ".is-loading" : ""),
              { onclick },
              "Add"
            )
          ),
        ]),
        vnode.state.errorMessage !== null ? m("p.help.is-danger", vnode.state.errorMessage) : null,
        vnode.state.successMessage !== null ? m("p.help", vnode.state.successMessage) : null,
      ]),
    ]);
  }
}

export class TopicMembersPageView {
  view(vnode) {
    const { topicDetails, members, me } = vnode.attrs;
    const { owner, topic } = topicDetails;

    const onSetMembership = async (user_id, access) => {
      await postTopicMember(await fetchJwt(), owner.display_name, topic.name, user_id, access);
    };

    const onDeleteMembership = async memberName => {
      await deleteTopicMember(await fetchJwt(), owner.display_name, topic.name, memberName);
    };

    const content = m("div.columns.is-desktop", [
      m(
        "div.column",
        { style: "padding: 1em;" },
        m(TopicEditNavMenu, { owner: owner.display_name, topic: topic.name })
      ),
      m("div.column", { style: "flex: 2; padding: 1em;" }, [
        m(TopicMembersListView, { topicDetails, members, onSetMembership, onDeleteMembership }),
        m(AddMemberView, { topicDetails }),
      ]),
    ]);

    return [
      m(PageHeader, {
        me,
        title: [TopicButtonView.from({ topic, owner, size: "large", hasLink: true }), " Members"],
      }),
      m("section.section", m("div.container", m("div", content))),
      m(Footer),
    ];
  }
}

export const topicMembersPageProvider = (() => {
  const attrs = {};
  return {
    onmatch: async args => {
      const jwt = await fetchJwt();
      const mePromise = loggedInUser(jwt);
      const topicPromise = getTopic(jwt, args.owner, args.name);
      const membersPromise = listTopicMembers(jwt, args.owner, args.name);

      attrs.topicDetails = await topicPromise;
      attrs.me = await mePromise;
      attrs.members = await membersPromise;
    },
    render: vnode => m(TopicMembersPageView, Object.assign(vnode.attrs, attrs)),
  };
})();
