import m from "mithril";
import { ContentCardView } from "./content_card";
import { TopicButtonView, TopicLabelView } from "./topics";
import { identiconFor, UserImageAndName } from "./users";
import { deleteTag, follow, unfollow } from "../api";
import { fetchJwt } from "../account";
import formatDistance from "date-fns/formatDistance";
import parseISO from "date-fns/parseISO";
import { reloadPage } from "../util";

export class TopicSummaryView {
  view(vnode) {
    const { topic, owner, labels } = vnode.attrs;

    const header = m("div.level", { style: "width: 100%" }, [
      m(
        "div.level-left",
        m(
          "div.level-item",
          m(TopicButtonView, {
            name: topic.name,
            owner: owner.display_name,
            hasLink: true,
            color: topic.color,
          })
        )
      ),
      m("div.level-right", [
        m(
          "div.level-item",
          m("span", [m("span.icon", m("i.mdi.mdi-star")), m("span", topic.stars)])
        ),
        m(
          "div.level-item",
          m("span", [
            m("span.icon", m("i.mdi.mdi-tag.mdi-flip-h")),
            m("span", topic.positive_tags + topic.negative_tags),
          ])
        ),
      ]),
    ]);

    const content = [
      m("p", topic.summary),
      labels ? m("div.field.is-grouped", labels.map(label => m(TopicLabelView, { label }))) : null,
    ];

    return m(ContentCardView, {
      title: header,
      content,
    });
  }
}

export class TaggedTextView {
  view(vnode) {
    const { target_tag, topic, owner } = vnode.attrs;
    const tag = target_tag;

    // Correct for emoji presence, since JS counts 2 length per emoji, and our backend treats each
    // emoji as 1 length.
    const characters = Array.from(tag.document_text);
    const prefixText = characters.slice(0, tag.start_idx).join("");
    const tagText = characters.slice(tag.start_idx, tag.end_idx).join("");
    const suffixText = characters.slice(tag.end_idx, characters.length).join("");

    const negStripePx = 2;
    const offsetPx = 8;
    const background = tag.present
      ? `${topic.color}60`
      : `linear-gradient(transparent ${offsetPx}px, ${topic.color} ${offsetPx}px, ${
          topic.color
        } ${offsetPx + negStripePx}px, transparent ${offsetPx + negStripePx}px)`;

    return m("div.tag-text", [
      m("span", prefixText),
      m(
        `span.${owner.display_name}-${topic.name}.tagged`,
        { style: `background: ${background}; padding: 0 2px; border-radius: 2px;` },
        tagText
      ),
      m("span", suffixText),
    ]);
  }
}

export class TagSummaryView {
  view(vnode) {
    const { target_tag, topic, owner, acting_user, roundedCorners, hasDelete } = vnode.attrs;
    const tag = target_tag;
    const maybeDeleteTag = tagId => {
      if (confirm("Permanently delete this tag?")) {
        fetchJwt()
          .then(jwt => deleteTag(jwt, tagId))
          .then(_ =>
            m.route.set(`/tags/delete-successful?tagTopic=${owner.display_name}/${topic.name}`)
          );
      }
    };

    const tagCreatedAt =
      typeof tag.created_at === "string" ? parseISO(tag.created_at) : tag.created_at;

    return m(ContentCardView, {
      roundedCorners,
      content: m("div", [
        m("div.margin-b-1-em", m(TaggedTextView, { target_tag, topic, owner })),
        m("div.tag-meta.is-flex", { style: "align-items: center; flex-wrap: wrap;" }, [
          m(UserImageAndName, { user: acting_user }),
          m("span.pad-l-quarter-em.pad-r-quarter-em", "tagged"),
          tag.present
            ? m("span.tag.icon.is-success", m("i.mdi.mdi-thumb-up"))
            : m("span.tag.icon.is-danger", m("i.mdi.mdi-thumb-down")),
          m("span.pad-l-quarter-em.pad-r-quarter-em", "in"),
          m(TopicButtonView, {
            name: topic.name,
            owner: owner.display_name,
            hasLink: true,
            color: topic.color,
          }),
          m(
            "span.pad-l-quarter-em",
            { title: tagCreatedAt },
            formatDistance(tagCreatedAt, new Date(), { addSuffix: true }) + "."
          ),
          tag.tag_id === undefined
            ? null
            : m(
                "a.icon",
                { href: `/tags/${tag.tag_id}`, oncreate: m.route.link },
                m("i.mdi.mdi-link")
              ),
          tag.tag_id !== undefined && hasDelete
            ? m(
                "a.icon.has-text-danger",
                { onclick: evt => maybeDeleteTag(tag.tag_id), title: "Delete tag" },
                m("i.mdi.mdi-delete-outline")
              )
            : null,
        ]),
      ]),
    });
  }
}

export class UserSummaryView {
  view(vnode) {
    const { user, following } = vnode.attrs;

    const onclick = async evt => {
      const action = following ? unfollow : follow;
      await action(await fetchJwt(), user.display_name);
      reloadPage();
    };

    const followButton = following
      ? m("button.button.level-item", { onclick }, "Unfollow")
      : m("button.button.level-item.is-light", { onclick }, "Follow");

    const userImage = vnode.attrs.user.image_url
      ? m("img", {
          src: user.image_url,
          style: "height: 72px; width: 72px; border-radius: 4px; margin-right: 8px;",
        })
      : m(
          "div.svg-2-radius",
          { style: "border-radius: 4px; margin-right: 8px;" },
          m.trust(identiconFor(vnode.attrs.user.display_name, 72))
        );

    const header = m("div.level", { style: "width: 100%" }, [
      m("div.level-left", [
        m("div.level-item", userImage),
        m("div.level-item", { style: "flex-direction: column; align-items: baseline;" }, [
          m(
            "a.is-block.has-text-weight-bold",
            { href: `/${user.display_name}`, oncreate: m.route.link },
            user.display_name
          ),
          m("span.is-block", user.summary),
          m("span", { title: `${user.follower_count} followers.` }, [
            m("span.icon", m("i.mdi.mdi-account-group")),
            m("span", user.follower_count),
          ]),
        ]),
      ]),
      m("div.level-right", [followButton]),
    ]);

    return m(ContentCardView, {
      content: header,
    });
  }
}
