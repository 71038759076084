import m from "mithril";

export class Footer {
  view(vnode) {
    return m(
      "footer.footer",
      m("div.container", [
        m("span", `© ${new Date().getFullYear()} Thought Vector LLC`),
        m(
          "a.has-text-dark.margin-l-1-em",
          { href: "/privacy-policy", oncreate: m.route.link },
          "Privacy Policy"
        ),
        m(
          "a.has-text-dark.margin-l-1-em",
          { href: "/terms", oncreate: m.route.link },
          "Terms & Conditions"
        ),
      ])
    );
  }
}
