import { HeaderNav, TabCountView } from "./header";
import { Footer } from "./footer";
import { fetchJwt, loggedInUser } from "../account";
import { searchCounts, searchTags, searchTopics, searchUsers } from "../api";
import m from "mithril";
import { TagSummaryView, TopicSummaryView, UserSummaryView } from "./summaries";
import { titleCase } from "../util";

class SearchPageHeaderView {
  view(vnode) {
    const { selected, me, search_text, searchCounts } = vnode.attrs;

    const labelsView = null; // TODO
    const tabsView = m(
      "div.tabs",
      m(
        "ul",
        ["topics", "tags", "users"].map(tab =>
          m(
            "li" + (selected === tab ? ".is-active" : ""),
            m(
              "a",
              { href: `/search/${tab}` + location.search, oncreate: m.route.link },
              m("span", [
                titleCase(tab),
                m(TabCountView, { count: searchCounts[tab], inverted: true }),
              ])
            )
          )
        )
      )
    );

    return m("header.hero.is-primary", [
      m("div.hero-title", m(HeaderNav, { me, search_text, searchPath: selected })),
      m(
        "div.hero-body",
        { style: "padding-bottom: 0;" },
        m("div.container", [labelsView, tabsView])
      ),
    ]);
  }
}

export const topicSearchPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async args => {
      document.title = "Taggit · Topic Search";
      const { labels, minimum_access, search_text } = args;
      attrs.search_text = search_text;
      const jwt = await fetchJwt();
      const mePromise = loggedInUser();
      const searchPromise = searchTopics(jwt, { labels, minimum_access, search_text });
      const searchCountsPromise = searchCounts(jwt, { labels, search_text });

      try {
        attrs.searchCounts = await searchCountsPromise;
      } catch (e) {
        attrs.searchCounts = { topics: 0, tags: 0, users: 0 };
      }

      attrs.me = await mePromise;

      try {
        attrs.searchResults = await searchPromise;
      } catch (e) {
        attrs.searchResults = [];
      }
    },
    render: vnode => m(TopicSearchPageView, attrs),
  };
})();

export class TopicSearchPageView {
  view(vnode) {
    const { me, searchResults, search_text, searchCounts } = vnode.attrs;

    const content = m(
      "div",
      searchResults.map(({ topic, owner, labels }) =>
        m("div.margin-b-1-em", m(TopicSummaryView, { topic, owner, labels }))
      )
    );

    return [
      m(SearchPageHeaderView, { me, search_text, selected: "topics", searchCounts }),
      m("section.section", m("div.container", content)),
      m(Footer),
    ];
  }
}

export const tagSearchPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async args => {
      document.title = "Taggit · Tag Search";
      const { labels, minimum_access, search_text } = args;
      attrs.search_text = search_text;
      const jwt = await fetchJwt();
      const mePromise = loggedInUser();
      const searchPromise = searchTags(jwt, { labels, minimum_access, search_text });
      const searchCountsPromise = searchCounts(jwt, { labels, search_text });

      try {
        attrs.searchCounts = await searchCountsPromise;
      } catch (e) {
        attrs.searchCounts = { topics: 0, tags: 0, users: 0 };
      }

      attrs.me = await mePromise;

      try {
        attrs.searchResults = await searchPromise;
      } catch (e) {
        attrs.searchResults = [];
      }
    },
    render: vnode => m(TagSearchPageView, attrs),
  };
})();

export class TagSearchPageView {
  view(vnode) {
    const { me, searchResults, search_text, searchCounts } = vnode.attrs;

    const content = m(
      "div",
      searchResults.map(({ tag, topic, owner, author }) =>
        m(
          "div.margin-b-1-em",
          m(TagSummaryView, { target_tag: tag, topic, owner, acting_user: author })
        )
      )
    );

    return [
      m(SearchPageHeaderView, { me, search_text, selected: "tags", searchCounts }),
      m("section.section", m("div.container", content)),
      m(Footer),
    ];
  }
}

export const userSearchPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async args => {
      document.title = "Taggit · User Search";
      const { labels, search_text } = args;
      attrs.search_text = search_text;
      const jwt = await fetchJwt();
      const mePromise = loggedInUser();
      const searchPromise = searchUsers(jwt, { labels, search_text });
      const searchCountsPromise = searchCounts(jwt, { labels, search_text });

      try {
        attrs.searchCounts = await searchCountsPromise;
      } catch (e) {
        attrs.searchCounts = { topics: 0, tags: 0, users: 0 };
      }

      attrs.me = await mePromise;

      try {
        attrs.searchResults = await searchPromise;
      } catch (e) {
        attrs.searchResults = [];
      }
    },
    render: vnode => m(UserSearchPageView, attrs),
  };
})();

export class UserSearchPageView {
  view(vnode) {
    const { me, searchResults, searchCounts } = vnode.attrs;

    const content = m(
      "div",
      searchResults.map(({ user, following }) =>
        m("div.margin-b-1-em", m(UserSummaryView, { user, following }))
      )
    );

    return [
      m(SearchPageHeaderView, { me, selected: "users", searchCounts }),
      m("section.section", m("div.container", content)),
      m(Footer),
    ];
  }
}
