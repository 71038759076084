import { GA_TRACKING_ID, GTM_BODY, GTM_NOSCRIPT_SRC } from "./config";
import { fetchJwt, loggedInUser } from "./account";
import m from "mithril";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import { createEvents, getMe } from "./api";
import { uuidv4 } from "./util";

const tagManager = document.createElement("script");
tagManager.type = "text/javascript";
// tagManager.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
tagManager.async = true;
tagManager.innerText = GTM_BODY;
document.head.appendChild(tagManager);

const tagManagerNoScript = document.createElement("noscript");
const tagManagerIFrame = document.createElement("iframe");
tagManagerIFrame.src = GTM_NOSCRIPT_SRC;
tagManagerIFrame.height = 0;
tagManagerIFrame.width = 0;
tagManagerIFrame.style.display = "none";
tagManagerIFrame.style.visibility = "hidden";
tagManagerNoScript.appendChild(tagManagerIFrame);
document.body.appendChild(tagManagerNoScript);

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}

window.sessionId = uuidv4();

class AnalyticsBase {
  constructor() {
    this.callbacks = {};
  }

  ready() {
    return Promise.resolve();
  }

  monitor([path, provider]) {
    let newProvider;
    if (typeof provider === "object") {
      // It's actually a provider
      newProvider = {
        onmatch: async function() {
          const result = await provider.onmatch.apply(provider, arguments);
          analytics.page(path, document.title, document.location.toString());
          return result;
        },
        render: provider.render,
      };
    } else if (typeof provider === "function") {
      // It's a view
      newProvider = {
        render: vnode => {
          console.debug("Starting render");
          analytics.page(path, document.title, document.location.toString());
          console.debug("Rendering");
          return m(provider, vnode.attrs);
        },
      };
    } else {
      console.error(`Expected to get object or function in monitor, got ${typeof provider}.`);
      newProvider = provider;
    }

    return [path, newProvider];
  }

  async identify(userId, userProperties) {
    console.debug("identify", userId, userProperties);
  }

  async track(eventName, eventProperties) {
    console.debug("track", eventName, eventProperties);
  }

  /**
   * Registers a callback to execute code when analytics events are triggered.
   * @param callback
   */
  registerCallback(callback) {
    if (typeof callback === "function") {
      const key = uuidv4();
      this.callbacks[key] = callback;
      return key;
    } else {
      throw `Provided callback should be a function, had typeof = ${typeof callback}`;
    }
  }

  clearCallback(key) {
    if (this.callbacks[key] === undefined) {
      throw `No callback found for key ${key}`;
    }

    delete this.callbacks[key];
  }

  async page(path, title, location) {
    console.debug("page", path, title, location);
  }

  async timeFunction(eventNameBase, { category, label, value }, fn) {
    const started = new Date();
    analytics.track(eventNameBase + "-request", { category, label, value });
    try {
      const result = await fn();
      analytics.track(eventNameBase + "-complete", {
        category,
        label,
        value: differenceInMilliseconds(new Date(), started),
      });
      return result;
    } catch (e) {
      analytics.track(eventNameBase + "-error", {
        category,
        label,
        value: differenceInMilliseconds(new Date(), started),
      });
      throw e;
    }
  }
}

class AnalyticsGoogle extends AnalyticsBase {
  ready() {
    if (window.dataLayer !== undefined) {
      return Promise.resolve();
    }
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        if (window.dataLayer !== undefined) {
          clearTimeout(timeout);
          resolve();
        }
      }, 30);
    });
  }

  async makeConfig(path, title, location) {
    const me = (await loggedInUser()) || {};
    return {
      user_id: me.userId,
      page_path: path,
      page_title: title,
      page_location: location,
    };
  }

  async track(eventName, { category: event_category, label: event_label, value }) {
    return new Promise((resolve, reject) => {
      gtag("event", eventName, { event_category, event_label, value, event_callback: resolve });
    });
  }

  async page(path, title, location) {
    location = location || window.location.toString();
    const conf = await this.makeConfig(path, title, location);
    return new Promise((resolve, reject) => {
      conf.event_callback = resolve;
      gtag("config", GA_TRACKING_ID, conf);
    });
  }
}

class AnalyticsTaggit extends AnalyticsBase {
  async track(eventName, eventProperties) {
    const { category: event_category, label: event_label, value: event_value } = eventProperties;
    const jwt = await fetchJwt();
    const me = await loggedInUser();
    return createEvents(jwt, [
      {
        user_id: me.userId,
        session_id: window.sessionId,
        event_category,
        event_action: eventName,
        event_label,
        event_value,
        created_at: new Date().toISOString(),
      },
    ]).then(result => {
      Object.values(this.callbacks).forEach(cb => cb(eventName, eventProperties));
      return result;
    });
  }
}

class Analytics extends AnalyticsBase {
  constructor(providers) {
    super();
    this.providers = providers;
  }

  async mapProviders(fnName, ...args) {
    return await Promise.all(
      this.providers.map(async p => {
        await p.ready();
        return p[fnName](...args);
      })
    );
  }

  ready() {
    return this.mapProviders("ready");
  }

  track(eventName, eventProperties) {
    return this.mapProviders("track", eventName, eventProperties);
  }

  page(path, title, location) {
    return this.mapProviders("page", path, title, location);
  }
}

export const taggitAnalytics = new AnalyticsTaggit();

export const analytics = new Analytics([
  new AnalyticsBase(),
  new AnalyticsGoogle(),
  taggitAnalytics,
]);
