import m from "mithril";
import { NotFoundPageView } from "./etc";
import { HeaderNav } from "./header";
import { Footer } from "./footer";
import { fetchJwt, loggedInUser } from "../account";
import { highlightSearchTerms, inferTextIndexes, titleCase } from "../util";
import { classifyTexts, createTag, getTopic } from "../api";
import { TopicButtonView } from "./topics";
import { TaggableTextView, TopicSelectDropdownView } from "./tags";
import { ContentCardView } from "./content_card";
import { MLAPI_URL_BASE } from "../config";
import parse from "csv-parse";
import stringify from "csv-stringify";
import FileSaver from "file-saver";
import { analytics } from "../analytics";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import { addTutorialTaggieToAttrs } from "./tutorials";

export const autoTagAbility = (me, scores, topic) => {
  let cantAutoTagReason;
  let cantAutoTag = false;
  scores = scores || {};

  if (me === undefined || me === null || me.userId === undefined) {
    cantAutoTag = true;
    cantAutoTagReason = "You must be logged in to auto tag.";
  } else if (!scores[me.classifierTier]) {
    cantAutoTag = true;

    if (topic && topic.negative_tags + topic.positive_tags >= 30) {
      cantAutoTagReason = "No auto tagger has been trained for this topic.";
    } else if (topic) {
      cantAutoTagReason =
        "You must have tagged at least 30 examples before you can auto tag with this topic.";
    } else {
      cantAutoTagReason = "You cannot auto tag with this topic.";
    }
  }

  return { cantAutoTagReason, cantAutoTag };
};

export const autoTagPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async (args, requestedPath) => {
      document.title = "Taggit · Auto Tag";
      const jwt = await fetchJwt();
      const mePromise = loggedInUser(jwt);
      const topicPromise = args.topics
        ? Promise.all(
            args.topics.split(",").map(topicSpec => getTopic(jwt, ...topicSpec.split("/")))
          )
        : Promise.resolve([]);

      attrs.me = await mePromise;

      if (attrs.me === null) {
        localStorage.setItem("pendingRedirect", location.pathname + location.search);
        m.route.set("/login");
      }

      attrs.selectedTopics = await topicPromise;

      return addTutorialTaggieToAttrs(attrs, args, requestedPath, jwt, attrs.me);
    },
    render: vnode => [
      m(AutoTagPageView, Object.assign(attrs, vnode.attrs)),
      window.errorTaggie || attrs.tutorial || null,
    ],
  };
})();

export class AutoTagHeaderView {
  oninit(vnode) {
    vnode.state.topicPickerShown = false;
    vnode.state.topics = vnode.attrs.topics;
  }

  view(vnode) {
    const { me, selected, onTopicsChange } = vnode.attrs;
    const { topics } = vnode.state;

    // TODO fix this - it doesn't seem to add topic_meta? (training_id missing)
    const addTopic = topicDetails => {
      const { topic, owner } = topicDetails;
      topics[owner.display_name + "/" + topic.name] = topicDetails;
      updateSearchParams();
      onTopicsChange(Object.values(topics));
    };

    const removeTopic = ({ owner, topic }) => {
      delete topics[owner.display_name + "/" + topic.name];
      updateSearchParams();
      onTopicsChange(Object.values(topics));
    };

    const updateSearchParams = () => {
      const params = new URLSearchParams();
      params.set("topics", [Object.keys(vnode.state.topics)].join(","));
      const newUrl =
        location.protocol +
        "//" +
        location.host +
        location.port +
        location.pathname +
        "?" +
        params.toString();
      history.replaceState({}, document.title, newUrl);
    };

    const topicButtons = Object.values(topics).map(({ topic, owner }) =>
      m(
        "span.pad-r-half-em",
        TopicButtonView.from({
          topic,
          owner,
          hasX: true,
          size: "medium",
          hasLink: true,
          onX: () => removeTopic({ owner, topic }),
        })
      )
    );

    const addTopicButton = m(TopicSelectDropdownView, {
      button: m(
        "button.button.is-white.is-outlined",
        { style: "height: 32px; padding-top: 4px; margin-top: 4px;" },
        "+ Add Topic"
      ),
      topicDetails: vnode.state.topics[0],
      onTopicChange: addTopic,
      autoselect: false,
      minimumAccess: "read",
      requireClassifier: true,
    });

    const topicsView = m(
      "div",
      {
        style:
          "border-radius: 4px; padding: 0.5em; width: 100%; background-color: #0002; line-height: 2.5;",
      },
      topicButtons.concat([addTopicButton])
    );

    const tabsView = m(
      "div.tabs",
      m(
        "ul",
        ["text", "file", "api"].map(tab =>
          m(
            "li" + (selected === tab ? ".is-active" : ""),
            m(
              "a",
              { href: `/auto-tag/${tab}` + location.search, oncreate: m.route.link },
              m("span", "Auto Tag " + (tab === "api" ? "API" : titleCase(tab)))
            )
          )
        )
      )
    );

    return m("header.hero.is-primary", [
      m("div.hero-title", m(HeaderNav, { me })),
      m(
        "div.hero-body",
        { style: "padding-bottom: 0;" },
        m("div.container", [m("p.heading", "Topics to Auto Tag"), topicsView, tabsView])
      ),
    ]);
  }
}

export class AutoTagTextView {
  oninit(vnode) {
    vnode.state.text = vnode.attrs.text;
    vnode.state.errorView = null;
    const topicSpecs = vnode.attrs.selectedTopics.map(
      ({ topic, owner }) => `${owner.display_name}/${topic.name}`
    );
    const label =
      topicSpecs.length === 1 ? topicSpecs[0] : topicSpecs.length > 0 ? "Multiple" : "None";
    analytics.track("autotag-view-text", { category: "autotag-view", label });
  }
  view(vnode) {
    const { onTextAutoTagRequested, text, onTextChange, selectedTopics } = vnode.attrs;

    const onclick = evt => {
      if (selectedTopics && selectedTopics.length > 0) {
        vnode.state.errorView = null;
        onTextAutoTagRequested(evt);
      } else {
        vnode.state.errorView = m(
          "p.has-text-danger.margin-r-half-em",
          "Please select at least one topic to auto tag."
        );
        m.redraw();
      }
    };

    const content = [
      m("p.heading", "Text to Auto Tag"),
      m("textarea.textarea", { oninput: evt => onTextChange(evt.target.value), value: text }),
    ];

    return m(ContentCardView, {
      title: "Text to Auto Tag",
      content,
      footer: [
        vnode.state.errorView,
        m("button.button.is-primary", { onclick, disabled: !(text && text.length > 0) }, [
          m("span.icon.is-small", m("i.mdi.mdi-memory")),
          m("span", "Auto Tag"),
        ]),
      ],
    });
  }
}

export class AutoTaggedTextMinimalView {
  oninit(vnode) {
    vnode.state.allTopicDetails = vnode.attrs.allTopicDetails;
  }

  view(vnode) {
    const { classification, onAutoTagCorrect, disallowTagging, disallowCorrection, highlightKeywords } = vnode.attrs;
    const { allTopicDetails } = vnode.state;
    const taggedRanges = Array.from(
      Object.entries(
        classification.auto_tags.reduce((agg, tag) => {
          const key = `${tag.start_idx}-${tag.end_idx}`;
          agg[key] = (agg[key] || []).concat([tag]);
          return agg;
        }, {})
      )
    ).sort(
      ([leftRange, leftTags], [rightRange, rightTags]) =>
        parseInt(leftRange.split("-")[0]) - parseInt(rightRange.split("-")[0])
    );

    const colors = allTopicDetails.reduce((agg, { owner, topic }) => {
      agg[`${owner.display_name}/${topic.name}`] = topic.color;
      return agg;
    }, {});

    const backgroundForTags = tags => {
      // TODO add processing of positive and negative tags (not just autotags)
      const autoTags = tags.filter(tag => tag.kind === "AutoTag" && tag.present);
      const positiveTags = tags.filter(tag => tag.kind === "Tag" && tag.present);
      const negativeTags = tags.filter(tag => tag.kind === "Tag" && !tag.present);
      const stripePx = 8;
      const negStripePx = 2;
      const alphaValue = "99";
      let negativePaddingBoxBackground = "linear-gradient(#fff0, #fff0)"; // Default to transparent (no tag)
      let positivePaddingBoxBackground = "linear-gradient(#fff0, #fff0)"; // Default to transparent (no tag)
      let borderBoxBackground = "linear-gradient(#fff, #fff)";

      // Create auto tag border (border-box)
      if (autoTags.length > 0) {
        borderBoxBackground = "repeating-linear-gradient(-45deg";
        let currentPx = 0;

        autoTags.forEach(tag => {
          const color = colors[tag.topic] + alphaValue;
          borderBoxBackground += `, ${color} ${currentPx}px, ${color} ${currentPx + stripePx}px`;
          currentPx += stripePx;
        });

        borderBoxBackground += ")";
      }

      // Create negative tags background (padding-box)
      if (negativeTags.length > 0) {
        const shownTags = negativeTags.slice(0, 3);
        const offsetPx = 9 - shownTags.length;
        negativePaddingBoxBackground = `linear-gradient(transparent ${offsetPx}px`;
        let currentPx = offsetPx;

        shownTags.forEach(tag => {
          const color = colors[tag.topic];
          negativePaddingBoxBackground += `, ${color} ${currentPx}px, ${color} ${currentPx +
            negStripePx}px`;
          currentPx += negStripePx;
        });

        negativePaddingBoxBackground += `, transparent ${currentPx}px)`;
      }

      // Create positive tags background (padding-box)
      if (positiveTags.length > 0) {
        positivePaddingBoxBackground = "repeating-linear-gradient(-45deg";
        let currentPx = -4; // Picked so that auto tag and positive tag stripes line up

        positiveTags.forEach(tag => {
          const color = colors[tag.topic] + alphaValue;
          positivePaddingBoxBackground += `, ${color} ${currentPx}px, ${color} ${currentPx +
            stripePx}px`;
          currentPx += stripePx;
        });

        positivePaddingBoxBackground += ")";
      }

      return `${negativePaddingBoxBackground} padding-box, ${positivePaddingBoxBackground} padding-box, linear-gradient(#fff, #fff) padding-box, ${borderBoxBackground} border-box`;
    };

    // Correct for emoji presence, since JS counts 2 length per emoji, and our backend treats each
    // emoji as 1 length.
    const docText = Array.from(classification.document_text);

    let charCount = taggedRanges.length === 0 ? 0 : taggedRanges[0][1][0].start_idx;

    const textEles =
      taggedRanges.length === 0
        ? [m("span", highlightSearchTerms(highlightKeywords, docText.join("")))]
        : taggedRanges.reduce(
            (spans, [range, tags]) => {
              const presentTags = tags.filter(tag => tag.present);
              if (charCount < tags[0].start_idx) {
                spans.push(
                  m(
                    "span",
                    highlightSearchTerms(
                      highlightKeywords,
                      docText.slice(charCount, tags[0].start_idx).join("")
                    )
                  )
                );
                charCount += tags[0].start_idx;
              }
              const background = backgroundForTags(tags);
              const chunkText = docText.slice(tags[0].start_idx, tags[0].end_idx).join("");

              const autoTagView = m(
                "span.auto-tagged",
                {
                  style: `background: ${background};`,
                  title: presentTags.map(tag => tag.topic).join(", "),
                },
                highlightSearchTerms(highlightKeywords, chunkText)
              );
              if (disallowCorrection) {
                spans.push(autoTagView);
              } else {
                spans.push(
                  m(AutoTagCorrectionDropdownView, {
                    triggerButtonView: autoTagView,
                    chunkText,
                    onAutoTagCorrect,
                    allTopicDetails,
                    tags,
                    isEditor: true,
                    document_text: classification.document_text,
                    key: classification.document_text,
                    tagMetadata: classification.tagMetadata,
                    topicMetadata: classification.topicMetadata,
                  })
                );
              }

              charCount = tags[0].end_idx;

              return spans;
            },
            [m("span", m.trust(docText.slice(0, taggedRanges[0][1][0].start_idx).join("")))]
          );

    if (docText.length > charCount && charCount > 0) {
      textEles.push(m("span", docText.slice(charCount, docText.length).join("")));
    }

    return disallowTagging ? textEles : m(TaggableTextView, { text: textEles });
  }
}

export class AutoTagTextResultView extends AutoTaggedTextMinimalView {
  view(vnode) {
    const { classification, onAutoTagCorrect, disallowTagging, highlightKeywords } = vnode.attrs;
    const { allTopicDetails } = vnode.state;

    const presentTopics = Object.entries(classification.topics_present)
      .filter(([topicSpec, present]) => present)
      .map(([topicSpec, present]) => {
        const matchingTopics = allTopicDetails.filter(({ owner, topic }) => {
          const [ownerName, topicName] = topicSpec.split("/");
          return ownerName === owner.display_name && topicName === topic.name;
        });

        const { topic, owner } = matchingTopics[0];
        return { topic, owner };
      });

    return m("div.auto-tag-result", [
      m("p.heading", "Auto Tagged Text"),
      m("div", { style: "margin-bottom: 1em;" }, super.view(vnode)),
      m("p.heading", "Topics Present"),
      m(
        "div",
        presentTopics.length > 0
          ? m(TopicPresenceView, { presentTopics })
          : m("p.has-text-grey", "None")
      ),
    ]);
  }
}

export class TopicPresenceView {
  view(vnode) {
    const { presentTopics } = vnode.attrs;
    return m(
      "div",
      presentTopics.map(topicDetails => {
        const { topic, owner } = topicDetails;
        return m(
          "span.margin-r-half-em",
          TopicButtonView.from({ topic, owner, hasLink: true, hideOwner: true })
        );
      })
    );
  }
}

export class CompactAutoTagView {
  oninit(vnode) {
    vnode.state.text = vnode.attrs.text;
    vnode.state.classification = null;
    vnode.state.classifying = false;

    const topicSpecs = vnode.attrs.selectedTopics.map(
      ({ topic, owner }) => `${owner.display_name}/${topic.name}`
    );
    const label =
      topicSpecs.length === 1 ? topicSpecs[0] : topicSpecs.length > 0 ? "Multiple" : "None";
    analytics.track("autotag-view-text", { category: "autotag-view", label });
  }

  view(vnode) {
    const { text } = vnode.state;
    const { selectedTopics, me, buttonText } = vnode.attrs;
    const classifierTier = (me || {}).classifierTier || "basic";
    const topicSpecs = selectedTopics.map(
      ({ topic, owner }) => `${owner.display_name}/${topic.name}:Current:${classifierTier}`
    );

    let cantAutoTag = false,
      cantAutoTagReason;
    for (const { topic, scores } of selectedTopics) {
      const able = autoTagAbility(me, scores, topic);
      if (able.cantAutoTag) {
        cantAutoTag = able.cantAutoTag;
        cantAutoTagReason = able.cantAutoTagReason;
        break;
      }
    }

    const onclick = async evt => {
      vnode.state.classifying = true;
      classifyTexts(await fetchJwt(), topicSpecs, [vnode.state.text]).then(
        ({ predictions: classifications, pipeline_config, topic_spec_map }) => {
          vnode.state.classification = classifications[0];
          vnode.state.classification.tagMetadata = {
            tagger: "Correction",
            correcting_model_vectorizer_kind: pipeline_config.vectorizer.kind,
            correcting_model_augmentor_kind: pipeline_config.dataset_augmentors
              .concat(pipeline_config.vector_augmentors)
              .map(aug => aug.kind)
              .join(","),
          };
          let classifiers = {};
          Object.keys(pipeline_config.classifiers).forEach(key => {
            classifiers[topic_spec_map[key]] = pipeline_config.classifiers[key];
          });
          vnode.state.classification.topicMetadata = classifiers;
          vnode.state.classifying = false;
          m.redraw();
        }
      );
    };

    const oninput = evt => {
      vnode.state.text = evt.target.value;
    };

    const content = [
      m("div", { style: "align-items: flex-end; display: flex; margin-bottom: 1em;" }, [
        m(
          "div",
          { style: "flex-grow: 1;" },
          m("textarea.textarea", { oninput, value: vnode.state.text, rows: 1 })
        ),
        m(
          "button.button.is-primary" + (vnode.state.classifying ? ".is-loading" : ""),
          {
            onclick,
            // disabled: !(text && text.length > 0) || cantAutoTag,
            title: cantAutoTagReason,
            style: "margin-left: 1em; height: 2.9em;",
          },
          [m("span.icon.is-small", m("i.mdi.mdi-memory")), m("span", buttonText || "Auto Tag")]
        ),
      ]),
    ];

    const onAutoTagCorrect = newTag => {
      // TODO remove contradicting correction if present
      vnode.state.classification.auto_tags = vnode.state.classification.auto_tags.filter(tag => {
        return (
          tag.topic !== newTag.topic ||
          tag.start_idx !== newTag.start_idx ||
          tag.end_idx !== tag.end_idx
        );
      });
      vnode.state.classification.auto_tags.push(newTag);
      m.redraw();
    };

    if (vnode.state.classification !== null) {
      content.push(
        m(AutoTagTextResultView, {
          classification: vnode.state.classification,
          allTopicDetails: selectedTopics,
          onAutoTagCorrect,
        })
      );
    }

    return m("div.compact-auto-tag", { style: "margin-bottom: 1em;" }, content);
  }
}

export class AutoTagCorrectionDropdownView {
  oninit(vnode) {
    vnode.state.expanded = false;
    vnode.state.assertions = vnode.attrs.allTopicDetails.map(topicMeta => [topicMeta, null]);
    vnode.state.key = vnode.attrs.document_text;
    vnode.state.topicKey = vnode.attrs.allTopicDetails
      .map(topicMeta => topicMeta.topic.topic_id)
      .reduce((agg, topic_id) => agg + topic_id);
    vnode.state.loading = {};
    vnode.state.expandTimeout = null;

    const outsideClickListener = evt => {
      if (vnode.state.expanded) {
        vnode.state.expanded = false;
        m.redraw();
      }
    };

    document.addEventListener("click", outsideClickListener);
  }

  onbeforeupdate(vnode) {
    const newTopicKey = vnode.attrs.allTopicDetails
      .map(topicMeta => topicMeta.topic.topic_id)
      .reduce((agg, topic_id) => agg + topic_id);
    if (vnode.attrs.document_text !== vnode.state.key || newTopicKey !== vnode.state.topicKey) {
      vnode.state.assertions = vnode.attrs.allTopicDetails.map(topic => [topic, null]);
      vnode.state.key = vnode.attrs.document_text;
      vnode.state.topicKey = newTopicKey;
    }
  }

  view(vnode) {
    const {
      triggerButtonView,
      document_text,
      tagMetadata,
      topicMetadata,
      tags,
      chunkText,
      onAutoTagCorrect,
    } = vnode.attrs;
    const topicSpecs = vnode.attrs.allTopicDetails.map(
      ({ owner, topic }) => `${owner.display_name}/${topic.name}`
    );
    const topicPresence = tags.reduce((agg, tag) => {
      agg[tag.topic] = tag.present;
      return agg;
    }, {});
    const label =
      topicSpecs.length === 1 ? topicSpecs[0] : topicSpecs.length > 0 ? "Multiple" : "None";

    const onmouseenter = evt => {
      analytics.track("autotag-correction-show", { category: "autotag-correction", label });
    };

    const onExpand = evt => {
      if (evt.detail === 1) {
        // Check if this was the first click
        vnode.state.expandTimeout = setTimeout(() => {
          vnode.state.expanded = !vnode.state.expanded;
          m.redraw();
        }, 100);
      } else {
        clearTimeout(vnode.state.expandTimeout);
      }
    };

    const presentAssertions = [];
    const absentAssertions = [];

    vnode.state.assertions.forEach(([{ topic, owner }, present], idx) => {
      const topicSpec = `${owner.display_name}/${topic.name}`;
      const { training_id: correcting_model_training_id, kind: correcting_model_classifier_kind } =
        (topicMetadata || {})[topicSpec] || {};
      const posTagTitle = `Tag selected text as relevant to ${topicSpec}`;
      const negTagTitle = `Tag selected text as irrelevant to ${topicSpec}`;

      const start = document_text.indexOf(chunkText);
      const start_idx = Array.from(document_text.slice(0, start)).length;
      const end_idx = start_idx + Array.from(chunkText).length;

      const tag = async present => {
        const jwt = await fetchJwt();
        const tag = Object.assign(
          {
            present,
            document_text,
            start_idx,
            end_idx,
            correcting_model_training_id,
            correcting_model_classifier_kind,
          },
          tagMetadata
        );
        const value = present ? 1 : 0;
        vnode.state.loading[topicSpec] = {};
        vnode.state.loading[topicSpec][present] = true;
        m.redraw();
        await analytics
          .timeFunction(
            "autotag-correction",
            { category: "autotag-correction", label, value },
            () => createTag(jwt, owner.display_name, topic.name, tag)
          )
          .then(() =>
            onAutoTagCorrect(
              Object.assign(tag, { topic: `${owner.display_name}/${topic.name}`, kind: "Tag" })
            )
          );
        vnode.state.loading[topicSpec][present] = false;
        m.redraw();
      };

      const posTag = async evt => {
        vnode.state.assertions[idx][1] = true;
        return tag(true);
      };
      const negTag = async evt => {
        vnode.state.assertions[idx][1] = false;
        return tag(false);
      };

      const posStyleAddon = present === false ? "opacity: 0.5;" : "";
      const negStyleAddon = present === true ? "opacity: 0.5;" : "";

      const assertion = m(
        "div.dropdown-item.is-flex",
        { style: "justify-content: space-between;" },
        [
          TopicButtonView.from({ topic, owner, hasLink: true }),
          m("div.field.has-addons", { style: "margin-left: 0.5em;" }, [
            m(
              "div.control",
              { style: "margin-right: 0px;" },
              m(
                "button.button.is-success.is-paddingless" +
                  ((vnode.state.loading[topicSpec] || {})[true] ? ".is-loading" : ""),
                {
                  onclick: posTag,
                  title: posTagTitle,
                  style: "height: 24px; width: 30px;" + posStyleAddon,
                },
                m("i.mdi.mdi-thumb-up")
              )
            ),
            m(
              "div.control",
              { style: "margin-right: 0px;" },
              m(
                "button.button.is-danger.is-paddingless" +
                  ((vnode.state.loading[topicSpec] || {})[false] ? ".is-loading" : ""),
                {
                  onclick: negTag,
                  title: negTagTitle,
                  style: "height: 24px; width: 30px;" + negStyleAddon,
                },
                m("i.mdi.mdi-thumb-down")
              )
            ),
          ]),
        ]
      );

      if (topicPresence[topicSpec]) {
        if (presentAssertions.length === 0) {
          presentAssertions.push(m("label.heading", "Present"));
        }
        presentAssertions.push(assertion);
      } else {
        if (absentAssertions.length === 0) {
          absentAssertions.push(m("label.heading", "Not Present"));
        }
        absentAssertions.push(assertion);
      }
    });

    const topicAssertions = presentAssertions.concat(absentAssertions);
    if (topicAssertions.length === 0) {
      return triggerButtonView;
    }

    return m(
      "div.dropdown.auto-tag-correction-dropdown.is-hoverable" +
        (vnode.state.expanded ? ".is-active" : ""),
      { style: "display: inline;" },
      [
        m(
          "div.dropdown-trigger",
          { onclick: onExpand, onmouseenter, style: "display: inline;" },
          triggerButtonView
        ),
        m(
          "div.dropdown-menu",
          m("div.dropdown-content", { style: "padding: 0.75em;" }, topicAssertions)
        ),
      ]
    );
  }
}

const BATCH_SIZE = 100;

class AutoTagFileView {
  oninit(vnode) {
    vnode.state.selectedFile = null;
    vnode.state.selectedField = null;
    vnode.state.candidateFields = [];
    vnode.state.progress = null;
    vnode.state.lines = [];
    vnode.state.progressShown = false;
    vnode.state.topicMentions = {};
    vnode.state.classifications = [];
    vnode.state.topicMapping = {};

    const topicSpecs = vnode.attrs.selectedTopics.map(
      ({ topic, owner }) => `${owner.display_name}/${topic.name}`
    );
    const label =
      topicSpecs.length === 1 ? topicSpecs[0] : topicSpecs.length > 0 ? "Multiple" : "None";
    analytics.track("autotag-view-file", { category: "autotag-view", label });
  }

  view(vnode) {
    // TODO - ADD "HAS TIER SELECT" to  topic button
    const { selectedFile, selectedField, candidateFields } = vnode.state;
    const { selectedTopics, me } = vnode.attrs;
    const classifierTier = (me || {}).classifierTier || "basic";
    const topicSpecs = selectedTopics.map(
      ({ owner, topic }) => `${owner.display_name}/${topic.name}:Current:${classifierTier}`
    );
    vnode.state.topicMapping = selectedTopics.reduce((agg, { owner, topic }) => {
      agg[`${owner.display_name}/${topic.name}:Current:${classifierTier}`] = { topic, owner };
      return agg;
    }, vnode.state.topicMapping);

    const onFileSelected = evt => {
      if (selectedTopics.length === 0) {
        vnode.state.fileError = `Please select at least one topic to auto tag before choosing a file!`;
        evt.target.value = evt.target.defaultValue;
        m.redraw();
        return;
      }

      vnode.state.selectedFile = evt.target.files[0];
      const extension = evt.target.files[0].name.split(".").pop();

      if (extension.toLowerCase() !== "csv" && extension !== "") {
        if (extension.toLowerCase() === "xlsx" || extension.toLowerCase() === "xls") {
          vnode.state.fileError = `Oops! This is an ${extension.toUpperCase()} file.  Try exporting it as a CSV, and trying again!`;
        } else {
          vnode.state.fileError = "Oops! This file doesn't look like a CSV.";
        }
        m.redraw();
        return;
      }
      vnode.state.fileError = null;
      const blob = vnode.state.selectedFile.slice(0, 16384);
      const reader = new FileReader();

      reader.addEventListener("loadend", evt => {
        const text = evt.srcElement.result;
        parse(text, { skip_lines_with_error: true, preview: 100 }, (err, output) => {
          vnode.state.candidateFields = output[0];
          if (vnode.state.candidateFields.indexOf(vnode.state.selectedField) === -1) {
            const textProbas = inferTextIndexes(output);
            const [choice, _textProba] = Object.entries(textProbas).reduce(
              ([choiceIdx, choiceProba], [idx, proba]) => {
                if (proba > choiceProba) {
                  return [idx, proba];
                } else {
                  return [parseInt(choiceIdx), choiceProba];
                }
              },
              [-1, -0.1]
            );
            vnode.state.selectedField = vnode.state.candidateFields[choice];
          }
          m.redraw();
        });
      });

      reader.readAsText(blob);
    };

    const onFieldSelected = evt => {
      vnode.state.selectedField = evt.target.value;
    };

    const onFileAutoTagRequested = evt => {
      vnode.state.classifications = [];
      vnode.state.progress = 0;
      vnode.state.progressShown = true;
      const rdr = new FileReader();
      vnode.state.topicMentions = {};
      rdr.addEventListener("loadend", evt => {
        parse(evt.srcElement.result, async (err, output) => {
          const fieldIdx = candidateFields.indexOf(vnode.state.selectedField);
          const jwt = await fetchJwt();
          let results = [];
          vnode.state.lines = output.slice(1);
          vnode.state.classificationStarted = new Date();

          const label =
            topicSpecs.length === 1 ? topicSpecs[0] : topicSpecs.length > 0 ? "Multiple" : "None";
          analytics.track("autotag-request-file", {
            category: "autotag-request",
            label,
            value: vnode.state.lines.length,
          });

          const classifyBatch = () => {
            const startIdx = vnode.state.progress;
            const texts = vnode.state.lines
              .slice(vnode.state.progress, vnode.state.progress + 100)
              .map(row => row[fieldIdx]);
            if (texts.length === 0) {
              stringify([candidateFields.concat(topicSpecs)].concat(results), (err, output) => {
                const value = differenceInMilliseconds(
                  new Date(),
                  vnode.state.classificationStarted
                );
                analytics.track("autotag-finish-file", {
                  category: "autotag-finish",
                  label,
                  value,
                });

                vnode.state.progressShown = false;
                vnode.state.csvData = new Blob([output], { type: "text/csv;charset=utf-8" });
                m.redraw();
              });
              clearTimeout(vnode.state.submitTimeout);
              return;
            }
            classifyTexts(jwt, topicSpecs, texts).then(
              ({ predictions: classifications, pipeline_config, topic_spec_map }) => {
                classifications.forEach((clf, idx) => {
                  clf.tagMetadata = {
                    tagger: "Correction",
                    from_file: vnode.state.selectedFile.name,
                    from_file_type: vnode.state.selectedFile.type,
                    csv_column: vnode.state.selectedField,
                    csv_row: startIdx + idx,
                    correcting_model_vectorizer_kind: pipeline_config.vectorizer.kind,
                    correcting_model_augmentor_kind: pipeline_config.dataset_augmentors
                      .concat(pipeline_config.vector_augmentors)
                      .map(aug => aug.kind)
                      .join(","),
                  };
                  let classifiers = {};
                  Object.keys(pipeline_config.classifiers).forEach(key => {
                    classifiers[topic_spec_map[key]] = pipeline_config.classifiers[key];
                  });
                  clf.topicMetadata = classifiers;
                });
                vnode.state.classifications = vnode.state.classifications.concat(classifications);
                const topicPresence = classifications.map(clf =>
                  [clf.document_text].concat(
                    topicSpecs.map(
                      spec => (clf.topics_present[spec.split(":")[0]] === true ? 1 : 0)
                    )
                  )
                );
                const resultsBatch = classifications.map((clf, idx) =>
                  output[vnode.state.progress - BATCH_SIZE + idx + 1].concat(
                    topicSpecs.map(
                      spec => (clf.topics_present[spec.split(":")[0]] === true ? 1 : 0)
                    )
                  )
                );
                vnode.state.topicMentions["__total__"] =
                  (vnode.state.topicMentions["__total__"] || 0) + topicPresence.length;
                topicPresence.forEach(([_text, ...presence]) => {
                  presence.forEach((present, idx) => {
                    vnode.state.topicMentions[topicSpecs[idx]] =
                      (vnode.state.topicMentions[topicSpecs[idx]] || 0) + present;
                  });
                });
                results = results.concat(resultsBatch);
                vnode.state.submitTimeout = setTimeout(classifyBatch, 100);
              }
            );
            vnode.state.progress += BATCH_SIZE;
            m.redraw();
          };
          vnode.state.submitTimeout = setTimeout(classifyBatch, 100);
        });
      });
      rdr.readAsText(vnode.state.selectedFile);
    };

    const content = [
      m("div.field", [
        m("p.heading", "File to Auto Tag"),
        m(
          "div.control",
          m(
            "div.file" + (selectedFile ? ".has-name" : ""),
            m("label.file-label", [
              m("input.file-input[type=file]#file-input", { onchange: onFileSelected }),
              m("span.file-cta", [
                m("span.file-icon", m("i.mdi.mdi-upload")),
                m("span.file-label", "Choose a File"),
              ]),
              selectedFile === null ? null : m("span.file-name", selectedFile.name),
            ])
          )
        ),
        vnode.state.fileError ? m("p.help.is-danger", vnode.state.fileError) : null,
      ]),
      m("div.field", [
        m("p.heading", "Field to Auto Tag"),
        m(
          "div.control",
          m(
            "div.select" + (vnode.state.fileLoading ? ".is-loading" : ""),
            m(
              "select",
              { disabled: selectedFile === null, onchange: onFieldSelected },
              candidateFields.map(field =>
                m(
                  "option",
                  field === vnode.state.selectedField ? { selected: "selected" } : {},
                  field
                )
              )
            )
          )
        ),
      ]),
      m("div.field", [
        m("p.heading", "Auto Tagging Progress"),
        m("progress.progress.is-primary", {
          value: vnode.state.progress,
          max: vnode.state.lines.length,
        }),
      ]),
      vnode.state.topicMentions.__total__
        ? m("div.field", [
            m("p.heading", "Auto Tag Topic Mentions"),
            m("table.table.is-fullwidth", [
              m(
                "thead",
                m("tr", [
                  m("th", "Topic"),
                  m("th", "Number Mentioning"),
                  m("th", "Percent Mentioning"),
                ])
              ),
              m(
                "tbody",
                [
                  m("tr", [
                    m("td", "Total"),
                    m("td", vnode.state.topicMentions["__total__"]),
                    m("td", (100.0).toPrecision(4) + "%"),
                  ]),
                ].concat(
                  Object.keys(vnode.state.topicMentions)
                    .filter(topicSpec => topicSpec !== "__total__")
                    .sort(
                      (left, right) =>
                        vnode.state.topicMentions[right] - vnode.state.topicMentions[left]
                    )
                    .map(topicSpec =>
                      m("tr", [
                        m(
                          "td",
                          TopicButtonView.from(
                            Object.assign(vnode.state.topicMapping[topicSpec], {
                              hasLink: true,
                              hideOwner: true,
                            })
                          )
                        ),
                        m("td", vnode.state.topicMentions[topicSpec]),
                        m(
                          "td",
                          (
                            (100 * vnode.state.topicMentions[topicSpec]) /
                            vnode.state.topicMentions["__total__"]
                          ).toPrecision(4) + "%"
                        ),
                      ])
                    )
                )
              ),
            ]),
          ])
        : null,
      vnode.state.topicMentions.__total__
        ? m("div.field", [
            m("p.heading", "Auto Tag Preview"),
            m(TaggedCsvPreviewView, {
              autotags: vnode.state.classifications,
              allTopicDetails: selectedTopics,
            }),
          ])
        : null,
    ];

    const downloadCsv = evt => {
      FileSaver.saveAs(vnode.state.csvData, "results.csv");
    };

    const downloadButton = vnode.state.csvData
      ? m("button.button", { onclick: downloadCsv }, "Download Results")
      : null;

    const disabled = selectedFile === null || selectedField === null || vnode.state.progressShown;

    return m(ContentCardView, {
      title: "Auto Tag File (CSV)",
      content,
      footer: m("div.buttons", [
        m(
          "button.button.is-primary" + (vnode.state.progressShown ? ".is-loading" : ""),
          { onclick: onFileAutoTagRequested, disabled },
          [m("span.icon.is-small", m("i.mdi.mdi-memory")), m("span", "Auto Tag")]
        ),
        downloadButton,
      ]),
    });
  }
}

class TaggedCsvPreviewView {
  oninit(vnode) {
    vnode.state.page = 0;
  }

  view(vnode) {
    const { autotags, allTopicDetails } = vnode.attrs;
    const { page } = vnode.state;
    const totalPages = Math.floor(autotags.length / 10);

    const pages = [page - 2, page - 1, page, page + 1, page + 2].filter(
      p => p >= 0 && p <= totalPages
    );

    const onclick = evt => {
      vnode.state.page = parseInt(evt.target.innerText) - 1;
      m.redraw();
    };

    const isStart = pages[0] === 0;
    const isEnd = pages[pages.length - 1] === totalPages;

    const onNext = evt => {
      if (page < totalPages) {
        vnode.state.page += 1;
        m.redraw();
      }
    };

    const onPrevious = evt => {
      if (page > 0) {
        vnode.state.page -= 1;
        m.redraw();
      }
    };

    const paginationStart = isStart
      ? []
      : [
          m("li", { style: "list-style-type: none;" }, m("a.pagination-link", { onclick }, 1)),
          page >= 4
            ? m(
                "li",
                { style: "list-style-type: none;" },
                m("span.pagination-ellipsis", m.trust("&hellip;"))
              )
            : null,
        ];

    const paginationEnd = isEnd
      ? []
      : [
          page <= totalPages - 4
            ? m(
                "li",
                { style: "list-style-type: none;" },
                m("span.pagination-ellipsis", m.trust("&hellip;"))
              )
            : null,
          m(
            "li",
            { style: "list-style-type: none;" },
            m("a.pagination-link", { onclick }, totalPages + 1)
          ),
        ];

    const pagination = m("nav.pagination", [
      m("a.pagination-previous", { disabled: page === 0, onclick: onPrevious }, "Previous"),
      m("a.pagination-next", { disabled: page === totalPages, onclick: onNext }, "Next"),
      m(
        "ul.pagination-list",
        paginationStart
          .concat(
            pages.map(p =>
              m(
                "li",
                { style: "list-style-type: none;" },
                m("a.pagination-link" + (p === page ? ".is-current" : ""), { onclick }, p + 1)
              )
            )
          )
          .concat(paginationEnd)
      ),
    ]);

    return m("div", [
      m("table.table.is-fullwidth", [
        m("thead", m("tr", [m("th", "Row"), m("th", "Text")])),
        m("tbody", [
          autotags.slice(10 * page, 10 * (page + 1)).map((classification, idx) => {
            const onAutoTagCorrect = newTag => {
              // TODO remove contradicting correction if present
              autotags[10 * page + idx].auto_tags = autotags[10 * page + idx].auto_tags.filter(
                tag => {
                  return (
                    tag.topic !== newTag.topic ||
                    tag.start_idx !== newTag.start_idx ||
                    tag.end_idx !== tag.end_idx
                  );
                }
              );
              autotags[10 * page + idx].auto_tags.push(newTag);
              m.redraw();
            };

            return m("tr", [
              m("td", idx),
              m(
                "td",
                m(AutoTagTextResultView, {
                  classification,
                  allTopicDetails,
                  onAutoTagCorrect,
                })
              ),
            ]);
          }),
        ]),
        m("tfoot", [m("tr", m("td", { colspan: 2 }, pagination))]),
      ]),
    ]);
  }
}

class AutoTagApiView {
  oninit(vnode) {
    const topicSpecs = vnode.attrs.selectedTopics.map(
      ({ topic, owner }) => `${owner.display_name}/${topic.name}`
    );
    const label =
      topicSpecs.length === 1 ? topicSpecs[0] : topicSpecs.length > 0 ? "Multiple" : "None";
    analytics.track("autotag-view-api", { category: "autotag-view", label });
    return fetchJwt().then(jwt => {
      vnode.state.jwt = jwt;
      m.redraw();
    });
  }
  view(vnode) {
    const { selectedTopics, me } = vnode.attrs;
    const search = selectedTopics
      .map(
        ({ topic, owner }) =>
          `topic=${owner.display_name}/${topic.name}:Current:${me.classifierTier}`
      )
      .join("&");
    return m(ContentCardView, {
      title: "Auto Tag API",
      content: [
        "Example curl command for auto tagging texts via the API:",
        m(
          "pre.code",
          {
            style:
              "background: #333; color: #eee; word-wrap: break-word; white-space: pre-wrap; border-radius: 4px; margin-top: 1em;",
          },
          `$ curl -XPOST \\
    --data '{"texts": ["This is how we do it."]}' \\
    --header 'Content-Type: application/json' \\
    --header 'Authorization: Bearer ${vnode.state.jwt}' \\
    '${MLAPI_URL_BASE}/model/predict?${search}'`
        ),
      ],
    });
  }
}

export class AutoTagPageView {
  oninit(vnode) {
    if (vnode.attrs.text) {
      vnode.state.text = (new URLSearchParams(window.location.search)).get("text");
    } else {
      vnode.state.text = "";
    }
    vnode.state.selectedTopics = vnode.attrs.selectedTopics;
  }
  view(vnode) {
    if (["text", "file", "api"].indexOf(vnode.attrs.mode) === -1) {
      return m(NotFoundPageView, vnode.attrs);
    }

    const { me, mode } = vnode.attrs;
    const { text, selectedTopics } = vnode.state;

    const onTextChange = text => {
      vnode.state.text = text;
    };

    const onTopicsChange = topics => {
      vnode.state.selectedTopics = topics;
      m.redraw();
    };

    const onTextAutoTagRequested = async evt => {
      const topicSpecs = selectedTopics.map(
        ({ topic, owner }) => `${owner.display_name}/${topic.name}:Current:${me.classifierTier}`
      );
      const label =
        topicSpecs.length === 1 ? topicSpecs[0] : topicSpecs.length > 0 ? "Multiple" : "None";
      const classificationStarted = new Date();
      analytics.track("autotag-request-text", {
        category: "autotag-request",
        label,
        value: text.split(" ").length,
      });
      vnode.state.classification = null;
      m.redraw();
      const { predictions: classifications, pipeline_config, topic_spec_map } = await classifyTexts(
        await fetchJwt(),
        topicSpecs,
        [text]
      );
      classifications.forEach((clf, idx) => {
        clf.tagMetadata = {
          tagger: "Correction",
          correcting_model_vectorizer_kind: pipeline_config.vectorizer.kind,
          correcting_model_augmentor_kind: pipeline_config.dataset_augmentors
            .concat(pipeline_config.vector_augmentors)
            .map(aug => aug.kind)
            .join(","),
        };
        let classifiers = {};
        Object.keys(pipeline_config.classifiers).forEach(key => {
          classifiers[topic_spec_map[key]] = pipeline_config.classifiers[key];
        });
        clf.topicMetadata = classifiers;
      });
      vnode.state.classification = classifications[0];

      const value = differenceInMilliseconds(new Date(), classificationStarted);
      analytics.track("autotag-finish-text", { category: "autotag-finish", label, value });
      m.redraw();
    };

    const onAutoTagCorrect = newTag => {
      // TODO remove contradicting correction if present
      vnode.state.classification.auto_tags = vnode.state.classification.auto_tags.filter(tag => {
        return (
          tag.topic !== newTag.topic ||
          tag.start_idx !== newTag.start_idx ||
          tag.end_idx !== tag.end_idx
        );
      });
      vnode.state.classification.auto_tags.push(newTag);
      m.redraw();
    };

    let content = null;
    if (mode === "text") {
      content = [
        m(AutoTagTextView, { selectedTopics, text, onTextChange, onTextAutoTagRequested }),
        vnode.state.classification
          ? m(ContentCardView, {
              class: ".auto-tag-text-result-view",
              title: "Results",
              content: m(AutoTagTextResultView, {
                classification: vnode.state.classification,
                allTopicDetails: selectedTopics,
                onAutoTagCorrect,
              }),
            })
          : null,
      ];
    } else if (mode === "api") {
      content = m(AutoTagApiView, { selectedTopics, me });
    } else if (mode === "file") {
      content = m(AutoTagFileView, { selectedTopics, me });
    }

    const topics = selectedTopics.reduce((agg, topicDetails) => {
      const { owner, topic } = topicDetails;
      agg[owner.display_name + "/" + topic.name] = topicDetails;
      return agg;
    }, {});

    return [
      m(AutoTagHeaderView, { me, topics, selected: mode, onTopicsChange }),
      m("section.section", m("div.container", content)),
      m(Footer),
    ];
  }
}
