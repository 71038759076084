import idb from "idb";
import { LOWER_CASE } from "./config";
import m from "mithril";
import lunr from "lunr";
import jStat from "jStat";

export const debounce = (fn, time, state) => {
  let timeout;

  return function() {
    const functionCall = () => fn.apply(this, arguments);

    if (typeof state === "object") {
      clearTimeout(state._timeout);
      state._timeout = setTimeout(functionCall, time);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    }
  };
};

export const hashString = str => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const getDbConn = () => {
  return idb.open("app", 6, upgradeDB => {
    const tables = [
      ["documents", { autoIncrement: true }],
      ["tokenCounts", { keyPath: "token" }],
      ["tokenVectors", { keyPath: "token" }],
      ["textVectors", { keyPath: "textHash" }],
      ["classifications", { keyPath: "id" }, [{ name: "clfCreatedIdx", keyPath: "createdAt" }]],
      ["doneClicks", { keyPath: "id" }],
      ["tags", { keyPath: "id" }],
    ];
    tables.forEach(([name, options, indexes]) => {
      if (!upgradeDB.objectStoreNames.contains(name)) {
        console.debug(`Creating ${name} objectstore...`);
        const store = upgradeDB.createObjectStore(name, options);
        if (indexes && indexes.length > 0) {
          indexes.forEach(({ name, keyPath, params }) => {
            if (!store.indexNames.contains(name)) {
              console.debug(`Creating index ${name} with keyPath ${keyPath}...`);
              store.createIndex(name, keyPath, params);
            }
          });
        }
      }
    });
    console.debug("Done with idb upgrade.");
  });
};

export const scanDb = async ({ collection, limit, offset, predicate, index, direction }) => {
  console.debug(`Getting ${collection} with limit=${limit}, offset=${offset}`);
  offset = offset || 0;
  limit = limit || 100;
  const results = [];
  let skipped = 0;
  const db = await getDbConn();
  function buildResults(cursor) {
    if (!cursor || results.length === limit) {
      console.debug(`Finished fetching ${collection}.`);
      return results;
    }
    if (predicate === undefined || predicate(cursor.value)) {
      if (skipped === offset) {
        results.push(cursor.value);
      } else {
        skipped += 1;
      }
    }
    return cursor.continue().then(buildResults);
  }
  const col = db.transaction(collection).objectStore(collection);
  return (index ? col.index(index) : col).openCursor(null, direction).then(buildResults);
};

export const sentTokenize = s => s.split(/\s*[\.!\?]+\s+/).filter(sent => sent.length > 0);

export const wordTokenize = s => s.split(/\W+/).filter(token => token.length > 0);

export const tokenize = s => sentTokenize(LOWER_CASE ? s.toLowerCase() : s).map(wordTokenize);

export const maybeSplitWords = value => {
  if (typeof value !== "string") {
    return null;
  }
  const tokens = (LOWER_CASE ? value.toLowerCase() : value).split(/\W+/);
  if (tokens.length < 4) {
    return null;
  }
  return tokens;
};

export const tokenizeDocSentences = document => {
  const sentences = [];
  for (const key in document) {
    if (maybeSplitWords(document[key])) {
      sentTokenize(document[key]).forEach(sent => {
        sentences.push(wordTokenize(sent));
      });
    }
  }
  return sentences;
};

export class CardItem {
  view(vnode) {
    return m("div.margin-b-1-em", [m("h2.heading", vnode.attrs.heading), vnode.attrs.component]);
  }
}

export class LoadedMetricCardItem {
  view(vnode) {
    const metric =
      vnode.attrs.metric === LOADING ? m("h1.title.loader", "") : m("h1.title", vnode.attrs.metric);

    return m("div.margin-b-1-em", [m("h2.heading", vnode.attrs.heading), metric]);
  }
}

export const LOADING = Symbol("LOADING");

export const deleteEverything = event => {
  {
    localStorage.clear();
    if (event.target.nodeName === "BUTTON") {
      event.target.classList.add("is-loading");
    } else {
      event.target.parentNode.classList.add("is-loading");
    }
    console.debug("Deleting all local data.");
    const req = indexedDB.deleteDatabase("app");
    req.onsuccess = () => {
      console.debug("Finished deleting all data.");
    };
  }
};

export const titleCase = s =>
  s.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export const reloadPage = () => m.route.set(m.route.get(), null, { replace: true });

/**
 * Tokenize and stem provided search input into a set.
 * @param {string} search
 * @returns {Set<string>}
 */
export const tokenizeAndStem = search =>
  new Set(
    lunr
      .tokenizer(search)
      .map(lunr.trimmer)
      .map(lunr.stemmer)
      .map(t => t.str)
  );

/**
 * Highlights search terms in the provided text, returning a list of texts and mithril <strong>
 *   elements.
 *
 * @param {[string] | Set<string> | string} searchTerms
 * @param {string} text
 * @param {string} addClass
 * @returns {Array}
 */
export const highlightSearchTerms = (searchTerms, text, addClass) => {
  if (searchTerms === undefined) {
    return text;
  }
  if (searchTerms.has === undefined) {
    searchTerms = tokenizeAndStem(Array.isArray(searchTerms) ? searchTerms.join(" ") : searchTerms);
  }

  text = text.toString();
  const components = [];

  let lastTextEndIdx = 0;
  lunr
    .tokenizer(text)
    .map(lunr.trimmer)
    .map(lunr.stemmer)
    .forEach(token => {
      if (searchTerms.has(token.str)) {
        const [startIdx, length] = token.metadata.position;
        const endIdx = startIdx + length;
        components.push(text.slice(lastTextEndIdx, startIdx));
        components.push(
          m("strong" + (addClass ? "." + addClass : ""), text.slice(startIdx, endIdx))
        );
        lastTextEndIdx = endIdx;
      }
    });
  if (text.length > lastTextEndIdx) {
    components.push(text.slice(lastTextEndIdx));
  }

  return components;
};

/**
 * Infer the indexes of a CSV-style set of documents that look like free text.  Returns an object of
 * index to float (how much it looks like text).
 *
 * @param {[[]]} documents
 * @returns {Object}
 */
export const inferTextIndexes = documents => {
  if (documents.length === 0) {
    return [];
  }
  return [0]
    .concat(Array.from({ length: documents[0].length - 1 }, (v, k) => k + 1))
    .reduce((taggables, fieldIdx) => {
      // Heuristics:
      // - On average longer than 20 characters
      // - length standard deviation > mean length / 3
      // - mean token length < 10
      // - stdev token length > 2
      // - more than 10 distinct lengths
      const lengths = (documents || []).slice(0, 100).map(line => line[fieldIdx].length || 0);
      const meanLen = jStat.mean(lengths);
      const stdLen = jStat.stdev(lengths);
      const distinctLengths = new Set(lengths);
      const tokenLengths = (documents || []).slice(0, 100).reduce((lengths, line) => {
        return typeof line[fieldIdx] === "string"
          ? lengths.concat(
              line[fieldIdx]
                .split(" ")
                .filter(tok => tok !== "")
                .map(tok => tok.length)
            )
          : lengths;
      }, []);
      const meanTokenLen = jStat.mean(tokenLengths);
      const stdTokenLen = jStat.stdev(tokenLengths);
      taggables[fieldIdx] =
        ((meanLen > 20) +
          (stdLen > meanLen / 5) +
          (meanTokenLen < 1) +
          (stdTokenLen > 2) +
          (distinctLengths.size > 10)) /
        5;
      return taggables;
    }, {});
};

export const COLORS = {
  primary: "#ff6c41",
};

export const sleep = async ms => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), ms);
  });
};
