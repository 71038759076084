import { fetchJwt, loggedInUser } from "../account";
import m from "mithril";

export const provideMe = (component, pageTitle) => {
  let me = null;

  return {
    onmatch: async args => {
      document.title = pageTitle;
      me = await loggedInUser(await fetchJwt());
    },
    render: vnode => m(component, Object.assign({ me }, vnode.attrs)),
  };
};
