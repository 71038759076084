import m from "mithril";
import { Logo } from "./logo";
import {
  applyFirebaseActionCode,
  base_account_name,
  fetchEmailVerificationToken,
  fetchFirebaseUser,
  fetchJwt,
  init,
  invalidateMe,
  isEmailVerified,
  loggedInUser,
  refreshLogin,
  sendVerificationEmail,
} from "../account";
import {
  awaitMe,
  changePlanTo,
  createUser,
  deleteStripeSource,
  getCustomerTrials,
  getAvailableStripeSources,
  getUser,
  saveStripeSource,
  updateDefaultPaymentSource,
  verifyEmail,
  listPlans,
} from "../api";
import { sleep, titleCase } from "../util";
import { PageHeader } from "./header";
import { Footer } from "./footer";
import { STRIPE_KEY } from "../config";

const reservedNames = new Set([
  "users",
  "product",
  "about",
  "docs",
  "documentation",
  "news",
  "business",
  "explore",
  "market",
  "marketplace",
  "popular",
  "pricing",
  "features",
  "integrate",
  "integrations",
  "partner",
  "partners",
  "blog",
  "help",
  "status",
  "jobs",
  "team",
  "apps",
  "developers",
  "terms",
  "learn",
  "partners",
  "customers",
  "company",
  "trial",
  "try",
  "services",
  "login",
  "users",
  "topics",
  "tags",
  "taggit",
  "admin",
  "administrator",
  "dev",
  "test",
  "prod",
  "official",
  "reviews",
  "sentiment",
  "nps",
  "customer-satisfaction",
  "tag-text",
  "tag-csv",
  "search",
  "me",
  "autotag",
  "autotags",
  "autotagger",
]);

export class FirebaseLoginPageView {
  oninit(vnode) {
    invalidateMe();
    vnode.state.loading = true;
    vnode.state.submitButtonWaiter = null;
    let displayName = null;

    if (vnode.state.submitButtonWaiter !== null) {
      clearInterval(vnode.state.submitButtonWaiter);
      vnode.state.submitButtonWaiter = null;
    }

    const updateSubmitButton = () => {
      const root = document.getElementById("firebaseui-auth-container");

      if (root === null) {
        clearInterval(vnode.state.submitButtonWaiter);
        vnode.state.loading = false;
        // m.redraw();
        return;
      }
      root.querySelectorAll("button").forEach(ele => {
        ele.classList.add("button");
      });
      let buttons = root.querySelectorAll('button[type="submit"]');
      if (buttons.length > 0) {
        let inputs = root.querySelectorAll('input[type="text"][name="name"]');
        if (inputs.length > 0) {
          if (displayName !== inputs[0].value) {
            displayName = inputs[0].value;
            const regexMatches = displayName.match(/^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/);
            const errorEle = root.querySelector(".firebaseui-id-name-error");
            const isReservedName =
              reservedNames.has(displayName) ||
              displayName.startsWith("tag-") ||
              displayName.startsWith("autotagger-");

            if (isReservedName) {
              errorEle.innerText = "That name is reserved.";
              buttons[0].disabled = true;
            } else if (regexMatches) {
              getUser(null, displayName).then(user => {
                if (user !== undefined && user !== null) {
                  errorEle.innerText = "That name is already taken.";
                  buttons[0].disabled = true;
                } else {
                  errorEle.innerText = "";
                  buttons[0].disabled = false;
                }
              });
            } else {
              if (displayName !== "") {
                errorEle.innerText =
                  "Display names can only contain lowercase letters, numbers, or hyphens (-).";
              }
              buttons[0].disabled = true;
            }
          }
        }
      }
    };

    const uiShown = () => {
      vnode.state.loading = false;
      updateSubmitButton();
      vnode.state.submitButtonWaiter = setInterval(updateSubmitButton, 250);
      m.redraw();
    };

    setTimeout(() => init({ uiShown }));
  }

  view(vnode) {
    return m(
      "section.hero.is-primary.is-fullheight",
      m(
        "div.hero-body",
        m("div.container", { style: "max-width: 24em;" }, [
          m("div", { style: "text-align: center; padding-bottom: 2em;" }, m(Logo, { width: 256 })),
          m("div.fui-container", m("div#firebaseui-auth-container")),
          vnode.state.loading ? m("div.loader", { height: "40px", width: "40px" }) : null,
        ])
      )
    );
  }
}

export class FinishLoginPageView {
  oninit(vnode) {
    window.localStorage.removeItem("selected-plan");
    invalidateMe();
    let redirect = "/";
    if (localStorage.getItem("pendingRedirect")) {
      redirect = localStorage.getItem("pendingRedirect");
      localStorage.removeItem("pendingRedirect");
    }
    console.debug(`Redirecting to '${redirect}' after login.`);
    fetchFirebaseUser()
      .then(async user => {
        const taggitUser = await getUser(await fetchJwt(), user.displayName);
        if (taggitUser === null) {
          // Set post login path to show plan selection
          if (redirect === "/") {
            redirect = "/settings/select-plan";
          }
          return createUser(user.email, user.displayName, user.photoUrl);
        } else {
          return taggitUser;
        }
      })
      .then(() => fetchJwt().then(awaitMe))
      .then(() => m.route.set(redirect));
  }
  view(vnode) {
    return m(
      "section.hero.is-primary.is-fullheight",
      m(
        "div.hero-body",
        m("div.container", { style: "max-width: 24em;" }, [
          m("div", { style: "text-align: center; padding-bottom: 2em;" }, m(Logo, { width: 256 })),
          m("div", [
            m("span.loader.is-inline-block", {
              height: "40px",
              width: "40px",
              style: "margin-right: 8px;",
            }),
            m("span", "Finishing up login..."),
          ]),
        ])
      )
    );
  }
}

export class VerifyEmailPageView {
  async oninit(vnode) {
    vnode.state.emailVerified = true; // Be optimistic here about user intent
    vnode.state.loading = false;
    vnode.state.errorMessage = null;
    let currentUser = await fetchFirebaseUser();
    if (currentUser === null) {
      await refreshLogin();
    }
    if (vnode.attrs.oobCode) {
      console.debug("Applying action code.");
      try {
        await applyFirebaseActionCode(vnode.attrs.oobCode);
        window.location.search = "";
        return;
        // Wait for email verification to be reflected
      } catch (e) {
        vnode.state.errorMessage = "Verification code check failed.";
      }
    }
    let i = 0;
    const maxTries = 15;
    while (i < maxTries) {
      console.debug(`Waiting for email verification, try #${i + 1}`);
      await sleep(1000);
      if (await isEmailVerified()) {
        vnode.state.emailVerified = true;
        break;
      }
      i++;
    }
    if (i === maxTries) {
      vnode.state.emailVerified = false;
    }
    console.debug("Email verified?", vnode.state.emailVerified);
    m.redraw();
    if (vnode.state.emailVerified) {
      console.debug("Fetching verification token");
      fetchEmailVerificationToken()
        .then(async token => {
          console.debug("Submitting verification token");
          await verifyEmail(token);
          m.route.set("/");
        })
        .catch(e => {
          console.error("Failed to submit verification token due to: ", e);
          vnode.state.errorMessage = e;
          m.redraw();
        });
    }
  }
  view(vnode) {
    const resendEmail = async evt => {
      console.debug("Resending verification email.");
      vnode.state.loading = true;
      m.redraw();
      await sendVerificationEmail();
      console.debug("Verification email resent.");
      vnode.state.loading = false;
      m.redraw();
    };

    let content;
    if (vnode.state.emailVerified) {
      content =
        vnode.state.errorMessage === null
          ? [
              m("h1.title", "Verifying Email"),
              m("h2.subtitle", [
                m("span.loader", {
                  height: "40px",
                  width: "40px",
                  style: "display: inline-block; margin-right: 8px;",
                }),
                "Confirming email verification...",
              ]),
            ]
          : [
              m("h1.title", "Error Verifying Email"),
              m("h2.subtitle", [
                "Please contact ",
                m("a", { href: "mailto:contact@taggit.io" }, "contact@taggit.io"),
                " and send them the following error:",
              ]),
              m("code", vnode.state.errorMessage),
            ];
    } else {
      content = [
        m("h1.title", "Email Not Verified"),
        m("h2.subtitle", [
          "Your email has not been verified.  Please click the link in the verification email.",
        ]),
        m(
          "button.button.is-primary.is-inverted.is-outlined" +
            (vnode.state.loading ? ".is-loading" : ""),
          { onclick: resendEmail },
          "Resend Verification Email"
        ),
      ];
    }

    return m(
      "section.hero.is-primary.is-fullheight",
      m(
        "div.hero-body",
        m("div.container", { style: "max-width: 24em;" }, [
          m("div", { style: "text-align: center; padding-bottom: 2em;" }, m(Logo, { width: 256 })),
          m("div", content),
        ])
      )
    );
  }
}

class FeaturesList {
  view({ attrs: { features } }) {
    return m(
      "div.plan-items",
      features.map(([feat, comment, isBold]) =>
        m(
          "div.plan-item.is-flex",
          {
            style:
              "justify-content: space-between; background: white; border-bottom-color: whitesmoke; color: #363636;",
          },
          [m("span", feat), isBold ? m("strong", comment) : m("span", comment)]
        )
      )
    );
  }
}

class PlanOptionView {
  view({ attrs: { onSelect, selected, planSpec } }) {
    const { plan_id, title, price, cta, features, ctaIsPrimary } = planSpec;

    const icon = planIcons[plan_id] || "account";

    const ctaButton = selected
      ? m("button.button.is-fullwidth.is-primary", cta)
      : m(
          "button.button.is-fullwidth" + (ctaIsPrimary ? ".is-primary" : ""),
          { onclick: onSelect },
          cta
        );

    return m(`div.pricing-plan.${plan_id}` + (selected ? ".is-active.is-primary" : ""), [
      m("div.plan-header", [
        m("span.icon.plan-price", m(`i.plan-price-amount.mdi.mdi-${icon}`)),
        m("h2.is-size-5", title),
      ]),
      m(
        "div.plan-price",
        { style: "border-bottom: 1px solid whitesmoke;" },
        typeof price === "number"
          ? [m("span.plan-price-amount", [m("span.plan-price-currency", "$"), price]), "/month"]
          : m("span.plan-price-amount", price)
      ),
      m(FeaturesList, { features }),
      m("div.plan-footer", ctaButton),
    ]);
  }
}

export const planIcons = {
  community: "account",
  business: "account-multiple",
  enterprise: "domain",
};

export class PlanSelectView {
  view(vnode) {
    let { onPlanSelect, selectedPlanId, customerTrials, myPlan, ctaIsPrimary } = vnode.attrs;
    let businessCta = "Start 30-Day Trial";
    if (customerTrials && customerTrials.business) {
      if (new Date(customerTrials.business.trial_end) > new Date()) {
        businessCta = "Resume Trial";
      } else {
        businessCta = "Get Started Now";
      }
    }

    const ctaLens = (planId, cta) => (planId === myPlan ? `Stay on ${titleCase(planId)}` : cta);

    const availablePlans = [
      {
        plan_id: "community",
        title: "Community",
        price: 0,
        cta: ctaLens("community", "Get Started Now"),
        ctaIsPrimary,
        features: [
          ["Auto Tag Quality", "Basic", false],
          ["Auto Tagging API", "Yes", false],
          ["Auto Tagging Rate", "Slow", false],
          ["Topic Privacy", "Public", false],
          ["Team Tools", "Public", false],
        ],
      },
      {
        plan_id: "business",
        title: "Business",
        price: 300,
        cta: ctaLens("business", businessCta),
        ctaIsPrimary,
        features: [
          ["Auto Tag Quality", "Neural", true],
          ["Auto Tagging API", "Yes", false],
          ["Auto Tagging Rate", "Fast", true],
          ["Topic Privacy", "Private", true],
          ["Team Tools", "Full", true],
          ["Private Topics", "Unlimited", true],
          ["Support", "Business Hours", true],
        ],
      },
      {
        plan_id: "enterprise",
        title: "Enterprise",
        price: "Contact Us",
        cta: ctaLens("enterprise", "Schedule Demo"),
        onclick: e => {
          window.open("https://calendly.com/thoughtvector/taggit-demo");
          return false;
        },
        ctaIsPrimary,
        features: [
          ["Auto Tag Quality", "Neural", true],
          ["Auto Tagging API", "Yes", false],
          ["Auto Tagging Rate", "Turbo", true],
          ["Topic Privacy", "Private", true],
          ["Team Tools", "Full", true],
          ["Private Topics", "Unlimited", true],
          ["Support", "24/7", true],
          ["On Premise", "Yes", true],
          ["Tagging Services", "Yes", true],
          ["Data Generation", "Yes", true],
        ],
      },
    ];

    let plans = availablePlans.map(planSpec =>
      m(PlanOptionView, {
        planSpec,
        selected: planSpec.plan_id === selectedPlanId,
        onSelect: planSpec.onclick || (() => onPlanSelect(planSpec.plan_id)),
      })
    );
    return m("div.pricing-table", plans);
  }
}

class StripeCardForm {
  async oninit(vnode) {
    vnode.state.addNewPayment = false;
    vnode.state.scriptLoading = true;
    vnode.state.selectedPaymentSource = null;
    vnode.state.availableStripeSources = null;
    vnode.state.emailValid = true;

    return this.maybeUpdateStripe(vnode);
  }

  async maybeUpdateStripe(vnode) {
    const scriptLoadedHandler = resolve => () => {
      vnode.state.scriptLoading = false;
      vnode.state.stripe = window.Stripe(STRIPE_KEY);
      const elements = vnode.state.stripe.elements();
      vnode.state.card = elements.create("card", {
        style: {
          base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        },
      });
      vnode.state.card.addEventListener("change", event => {
        const displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });
      m.redraw();
      resolve();
    };

    return this.getStripeSources(vnode).then(() => {
      if (vnode.state.selectedPaymentSource === null) {
        return new Promise((resolve, reject) => {
          const resolver = scriptLoadedHandler(resolve);
          if (document.getElementById("stripe-js") == null) {
            const script = document.createElement("script");
            script.id = "stripe-js";
            script.onload = resolver;
            script.src = "https://js.stripe.com/v3/";
            document.head.appendChild(script);
          } else {
            resolver();
          }
        });
      } else {
        m.redraw();
      }
    });
  }

  async getStripeSources(vnode) {
    const { stripe_sources } = await getAvailableStripeSources(
      await fetchJwt(),
      vnode.attrs.me.userId
    );
    if (stripe_sources && stripe_sources.length) {
      vnode.state.selectedPaymentSource = stripe_sources[0];
      vnode.attrs.onPaymentSelected(true);
    } else {
      vnode.state.selectedPaymentSource = null;
    }
    vnode.state.availableStripeSources = stripe_sources;
  }

  async deleteStripeSource(vnode, sourceId) {
    vnode.attrs.onPaymentSelected(false);
    m.redraw();
    await deleteStripeSource(await fetchJwt(), base_account_name(vnode.attrs.me), sourceId);
    await this.maybeUpdateStripe(vnode);
    m.redraw();
  }

  onupdate(vnode) {
    if (
      vnode.state.card &&
      document.getElementById("card-element") &&
      !document.getElementById("card-element").hasChildNodes()
    ) {
      vnode.state.card.mount("#card-element");
    }
  }

  view(vnode) {
    const { availableStripeSources, selectedPaymentSource } = vnode.state;

    let content;
    if (vnode.state.scriptLoading && availableStripeSources === null) {
      content = m("div.box", [
        m("span.loader", { style: { display: "inline-block", marginRight: "1em" } }),
        m("span", "Loading..."),
      ]);
    } else if (availableStripeSources.length === 0 || vnode.state.addNewPayment) {
      // No stripe sources are available or they're adding a new one
      const billingNameField = m("div.field.is-horizontal", [
        m("div.field-label.is-normal", "Billing Name"),
        m(
          "div.field-body",
          m(
            "div.field",
            m(
              "div.control.is-expanded",
              m("input.input#billing-name", { placeholder: "Your Name" })
            )
          )
        ),
      ]);

      const validateEmail = e => {
        vnode.state.emailValid = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          e.target.value
        );
      };

      const billingEmailField = m("div.field.is-horizontal", [
        m("div.field-label.is-normal", "Billing Email"),
        m(
          "div.field-body",
          m("div.field", [
            m(
              "div.control.is-expanded",
              m("input.input#billing-email" + (vnode.state.emailValid ? "" : ".is-danger"), {
                placeholder: "you@example.com",
                type: "email",
                onblur: validateEmail,
              })
            ),
            vnode.state.emailValid ? null : m("p.help.is-danger", "This email is invalid"),
          ])
        ),
      ]);

      const billingAddressField = m("div.field.is-horizontal", [
        m("div.field-label.is-normal", "Billing Address"),
        m(
          "div.field-body",
          m(
            "div.field",
            m(
              "div.control.is-expanded",
              m("input.input#billing-address", { placeholder: "123 Street Ave" })
            )
          )
        ),
      ]);

      const billingCityField = m("div.field.is-horizontal", [
        m("div.field-label.is-normal", "Billing City"),
        m(
          "div.field-body",
          m(
            "div.field",
            m("div.control.is-expanded", m("input.input#billing-city", { placeholder: "Tagville" }))
          )
        ),
      ]);

      const billingStateField = m("div.field.is-horizontal", [
        m("div.field-label.is-normal", "Billing State"),
        m("div.field-body", [
          m(
            "div.field",
            m("div.control.is-expanded", m("input.input#billing-state", { placeholder: "WA" }))
          ),
          m(
            "div.field-label",
            { style: { marginTop: "0.375em", whiteSpace: "nowrap", marginLeft: "1.5em" } },
            "Billing ZIP"
          ),
          m(
            "div.field",
            m("div.control.is-expanded", m("input.input#billing-zip", { placeholder: "98118" }))
          ),
        ]),
      ]);

      const cardField = m("div.field.is-horizontal", [
        m("div.field-label.is-normal", "Credit Card"),
        m(
          "div.field-body",
          m("div.field", [
            m(
              "form#payment-form",
              {
                action: "/charge",
                method: "post",
                // Prevent "enter" from submitting form
                onkeypress: e => (e.keyCode || e.which || e.charCode || 0) !== 13,
              },
              [m("div.form-row", [m("div#card-element"), m("div#card-errors", { role: "alert" })])]
            ),
          ])
        ),
      ]);

      const saveCard = async evt => {
        const saveButton = document.getElementById("save-card-button");
        const name = document.getElementById("billing-name").value;
        const email = document.getElementById("billing-email").value;
        const line1 = document.getElementById("billing-address").value;
        const city = document.getElementById("billing-city").value;
        const state = document.getElementById("billing-state").value;
        const postal_code = document.getElementById("billing-zip").value;

        saveButton.disabled = true;
        saveButton.textContent = "Saving Card...";

        try {
          const { source } = await vnode.state.stripe.createSource(vnode.state.card, {
            type: "card",
            currency: "USD",
            owner: {
              address: {
                line1,
                city,
                postal_code,
                state,
                country: "US",
              },
              email,
              name,
            },
          });

          await saveStripeSource(await fetchJwt(), base_account_name(vnode.attrs.me), source);
          vnode.state.addNewPayment = false;
          await this.getStripeSources(vnode);
          vnode.state.card.destroy();
          m.redraw();
        } catch (e) {
          saveButton.disabled = false;
          saveButton.textContent = "Save Card";
          throw e;
        }
      };

      const cancelAddCard = e => {
        vnode.state.addNewPayment = false;
        m.redraw();
      };

      const payButton = m("div.field.is-horizontal", [
        m("div.field-label.is-normal", ""),
        m("div.field-body.buttons", [
          m("button.button.is-primary#save-card-button", { onclick: saveCard }, "Save Card"),
          vnode.state.addNewPayment
            ? m("button.button.is-light", { onclick: cancelAddCard }, "Cancel")
            : null,
        ]),
      ]);

      content = m("div", [
        m(
          "p",
          { style: { marginBottom: "3em" } },
          "Please complete the payment information below to start your trial:"
        ),
        m("div.billing-form", { style: { maxWidth: "44em", margin: "auto" } }, [
          billingNameField,
          billingEmailField,
          billingAddressField,
          billingCityField,
          billingStateField,
          cardField,
          payButton,
        ]),
      ]);
    } else {
      // Stripe sources exist, let them pick from those
      const onAddNewCard = e => {
        vnode.state.addNewPayment = true;
        m.redraw();
      };

      content = m(StripeSourceSelectView, {
        availableStripeSources,
        selectedPaymentSource,
        onSourceSelect: source => {
          vnode.state.selectedPaymentSource = source;
          m.redraw();
        },
        onAddNewCard,
        onDeleteCard: async source => {
          await this.deleteStripeSource(vnode, source.id);
          m.redraw();
        },
      });
    }
    return m("div.section.container", [m("h1.title", "Payment Information"), content]);
  }
}

class CardView {
  view(vnode) {
    const { card, isSummary, onclick } = vnode.attrs;
    return m("div", { style: { cursor: "pointer" }, onclick }, [
      m("div", [
        isSummary ? m("span.icon", m("i.mdi.mdi-credit-card")) : null,
        m("b", card.brand),
        m(
          "span",
          { style: { marginLeft: "0.5em", fontFamily: '"Lucida Console", Monaco, monospace' } },
          card.last4
        ),
      ]),
      isSummary ? null : m("div", `Expires ${card.exp_month}/${card.exp_year}`),
    ]);
  }
}

class StripeSourceSelectView {
  view(vnode) {
    const {
      availableStripeSources,
      selectedPaymentSource,
      onSourceSelect,
      onAddNewCard,
      onDeleteCard,
    } = vnode.attrs;
    const selectedCardView = m(CardView, {
      card: selectedPaymentSource.stripe_data.card,
      isSummary: true,
    });
    return m("div.stripe-source-select", [
      m("p", "Please select the payment source to use:"),
      m("div.dropdown.is-hoverable", { style: { paddingTop: "1em" } }, [
        m("div.dropdown-trigger", [
          m("button.button.is-medium", [
            selectedCardView,
            m("span.icon.is-small", m("i.mdi.mdi-chevron-down")),
          ]),
        ]),
        m(
          "div.dropdown-menu",
          m(
            "div.dropdown-content",
            availableStripeSources
              .map(source =>
                m(
                  "a.dropdown-item.is-flex",
                  {
                    style: {
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "15em",
                      paddingRight: "1em",
                    },
                  },
                  [
                    m(CardView, {
                      onclick: e => onSourceSelect(source),
                      card: source.stripe_data.card,
                    }),
                    m("a.delete.is-medium", {
                      onclick: e => onDeleteCard(source.stripe_data),
                      title: "Delete this card.",
                    }),
                  ]
                )
              )
              .concat([m("a.dropdown-item", { onclick: onAddNewCard }, "+ Add new card")])
          )
        ),
      ]),
    ]);
  }
}

class PlanSelectPageView {
  oninit(vnode) {
    vnode.state.paymentSelected = false;
    vnode.state.selectedPlanId = window.localStorage.getItem("selected-plan") || "business";
    vnode.state.submitReady = false;
    vnode.state.submitted = false;
  }

  updateSubmitReady(vnode) {
    const { selectedPlanId, submitReady, paymentSelected } = vnode.state;
    const newSubmitReady =
      selectedPlanId !== "enterprise" &&
      (selectedPlanId === "community" || (selectedPlanId === "business" && paymentSelected));
    if (submitReady !== newSubmitReady) {
      vnode.state.submitReady = newSubmitReady;
      m.redraw();
    }
  }

  oncreate(vnode) {
    this.updateSubmitReady(vnode);
  }

  onupdate(vnode) {
    this.updateSubmitReady(vnode);
  }

  view(vnode) {
    // const { me, availableStripeSources, customerTrials, myPlan } = vnode.attrs;
    const { me, customerTrials, myPlan } = vnode.attrs;
    const { selectedPlanId, submitReady, submitted } = vnode.state;

    const onPlanSelect = plan_id => {
      // TODO launch calendly if enterprise
      vnode.state.submitMessage = null;
      vnode.state.selectedPlanId = plan_id;
      this.updateSubmitReady(vnode);
    };

    const onPlanSubmit = async evt => {
      if (selectedPlanId === "enterprise") {
        vnode.state.submitMessage =
          "An account manager will help you set up your enterprise " +
          "account after the demo. To try things out in the mean time, select a Community plan!";
        m.redraw();
      } else {
        vnode.state.submitted = true;
        if (selectedPlanId === myPlan) {
          // Don't need to change anything
          return m.route.set("/");
        }
        const jwt = await fetchJwt();
        if (vnode.state.selectedPaymentSource && vnode.state.selectedPaymentSource.id) {
          await updateDefaultPaymentSource(
            jwt,
            base_account_name(me),
            vnode.state.selectedPaymentSource.id
          );
        }
        await changePlanTo(jwt, base_account_name(me), selectedPlanId);
        // This is important so that their new classifier tier is reflected
        invalidateMe();
        m.route.set("/");
      }
    };

    const submitButton = m("div.section.is-flex", { style: "justify-content: flex-end;" }, [
      m(
        "button.button.is-large" +
          (submitReady ? ".is-primary" : "") +
          (submitted ? ".is-loading" : ""),
        { disabled: submitReady !== true, onclick: e => onPlanSubmit(selectedPlanId) },
        "Select Plan"
      ),
    ]);

    const onPaymentSelected = paymentSelected => {
      vnode.state.paymentSelected = paymentSelected;
      this.updateSubmitReady(vnode);
    };

    const stripeForm =
      selectedPlanId !== "community" ? m(StripeCardForm, { onPaymentSelected, me }) : null;

    return [
      m(PageHeader, { me }),
      m("section", [
        m("div.section.container", [
          m("h1.title", "Select A Plan"),
          m(PlanSelectView, { selectedPlanId, onPlanSelect, customerTrials, myPlan }),
        ]),
        stripeForm,
        m("div.container", submitButton),
      ]),
      m(Footer),
    ];
  }
}

export const planSelectPageProvider = (() => {
  const attrs = {};
  return {
    onmatch: async (args, requestedPath) => {
      document.title = "Taggit · Plan Selection";
      const jwt = await fetchJwt();
      const mePromise = loggedInUser(jwt);
      attrs.me = await mePromise;
      const trialsPromise = getCustomerTrials(jwt, base_account_name(attrs.me));
      const currentPlansPromise = listPlans(jwt);
      attrs.customerTrials = await trialsPromise;
      const planDetails = await currentPlansPromise;
      attrs.myPlan = planDetails.my_entitlement.plan.plan_id;
    },
    render: vnode => {
      return m(PlanSelectPageView, Object.assign(attrs, vnode.attrs));
    },
  };
})();
