import m from "mithril";
import {
  singleTagPageResolver,
  tagDeleteSuccessfulPageProvider,
  tagTextPageProvider,
  topicTagsResolver,
} from "./components/tags";
import { homePageProvider } from "./components/home";
import {
  FinishLoginPageView,
  FirebaseLoginPageView,
  planSelectPageProvider,
  VerifyEmailPageView,
} from "./components/account";
import firebase from "firebase/app";
import "firebase/storage";
import { FIREBASE_CONFIG } from "./config";
import {
  deleteTopicPageProvider,
  topicCreatePageProvider,
  topicForksPageProvider,
  topicMembersPageProvider,
  topicPageProvider,
  topicSettingsPageProvider,
  topicStarsPageProvider,
  topicWatchersPageProvider,
} from "./components/topics";
import { profilePageProvider } from "./components/users";
import { Logo } from "./components/logo";
import { deleteAccountPageProvider, settingsPageProvider } from "./components/settings";
import {
  tagSearchPageProvider,
  topicSearchPageProvider,
  userSearchPageProvider,
} from "./components/search";
import { autoTagPageProvider } from "./components/autotag";
import { privacyPageProvider, termsPageProvider } from "./components/terms";
import { analytics } from "./analytics";
import { documentExplorerPageProvider } from "./components/document_explorer";
import { taggingTopicPageProvider } from "./components/tagging_topic";
import { tagRequestsPageProver, topicTagRequestsPageFactory } from "./components/tag_requests";
import { appBenchmarkPageProvider, emptyAppBenchmarkPageView } from "./components/app_benchmark";

firebase.initializeApp(FIREBASE_CONFIG);

class NotImplementedPageView {
  view(vnode) {
    return m(
      "section.hero.is-primary.is-fullheight",
      m(
        "div.hero-body",
        m("div.container", { style: "max-width: 24em;" }, [
          m("div", { style: "text-align: center; padding-bottom: 2em;" }, m(Logo, { width: 256 })),
          m("div", [m("h1.title", "Not Implemented")]),
        ])
      )
    );
  }
}

m.route.prefix("");

const routes = {
  "/": homePageProvider,
  "/uh-oh": NotImplementedPageView,
  "/privacy-policy": privacyPageProvider,
  "/terms": termsPageProvider,
  "/topics/create": topicCreatePageProvider,
  "/settings": settingsPageProvider, // requires login
  "/delete-account": deleteAccountPageProvider, // requires login
  "/login": FirebaseLoginPageView,
  "/settings/select-plan": planSelectPageProvider,
  "/finish-login": FinishLoginPageView,
  "/tag-text": tagTextPageProvider,
  "/explorer": documentExplorerPageProvider,
  "/search/topics": topicSearchPageProvider,
  "/search/tags": tagSearchPageProvider,
  "/search/users": userSearchPageProvider,
  "/tags/delete-successful": tagDeleteSuccessfulPageProvider,
  "/tags/:tagId": singleTagPageResolver,
  "/auto-tag/:mode": autoTagPageProvider, // requires login
  "/app-benchmark/": emptyAppBenchmarkPageView,
  "/app-benchmark/:appId": appBenchmarkPageProvider,
  "/:owner/:name": topicPageProvider,
  "/:owner/:name/settings": topicSettingsPageProvider, // requires login
  "/:owner/:name/delete": deleteTopicPageProvider, // requires login
  "/:owner/:name/tags": topicTagsResolver,
  "/:owner/:name/tagging": taggingTopicPageProvider,
  "/:owner/:name/stars": topicStarsPageProvider,
  "/:owner/:name/watchers": topicWatchersPageProvider,
  "/:owner/:name/copies": topicForksPageProvider,
  "/:owner/:name/members": topicMembersPageProvider,
  "/:owner/:name/tag-requests/pending": topicTagRequestsPageFactory("pending"),
  "/:owner/:name/tag-requests/approved": topicTagRequestsPageFactory("approved"),
  "/:owner/:name/tag-requests/rejected": topicTagRequestsPageFactory("rejected"),
  "/:requestor/tag-requests/pending": topicTagRequestsPageFactory("pending"),
  "/:requestor/tag-requests/approved": topicTagRequestsPageFactory("approved"),
  "/:requestor/tag-requests/rejected": topicTagRequestsPageFactory("rejected"),
  "/tag-requests": tagRequestsPageProver,
  "/verify-email": VerifyEmailPageView,
  "/:displayName": profilePageProvider,
};

window.doABarrelRoll = () =>
  document.querySelectorAll("i.mdi").forEach(ele => ele.classList.add("mdi-spin"));
window.whatTheHeck = () =>
  document.querySelectorAll("i.mdi").forEach(ele => ele.classList.remove("mdi-spin"));

const monitoredRoutes = Object.entries(routes)
  .map(analytics.monitor)
  .reduce((agg, [k, v]) => Object.assign(agg, { [k]: v }), {});

m.route(document.getElementById("root"), "/", monitoredRoutes);
