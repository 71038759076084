import m from "mithril";
import { PageHeader } from "./header";
import { Footer } from "./footer";
import { fetchJwt, invalidateMe, loggedInUser, refreshLogin } from "../account";
import { identiconFor } from "./users";
import Croppie from "croppie";
import firebase from "firebase/app";
import { deleteAccount, getUser, listPlans, updateUser, updateUserImageUrl } from "../api";
import { deleteEverything, reloadPage } from "../util";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import { analytics } from "../analytics";
import { planIcons } from "./account";

export const settingsPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async args => {
      document.title = "Taggit · Settings";
      const jwt = await fetchJwt();
      const plansPromise = listPlans(jwt);
      attrs.me = await loggedInUser(jwt);
      const { my_entitlement, member_entitlements } = await plansPromise;
      attrs.myEntitlement = my_entitlement;
      attrs.memberEntitlements = member_entitlements;

      if (attrs.me === null) {
        localStorage.setItem("pendingRedirect", location.pathname + location.search);
        m.route.set("/login");
      }
    },
    render: vnode => m(SettingsPageView, attrs),
  };
})();

class ProfileImageUpdateView {
  oninit(vnode) {
    vnode.state.cropping = false;
    vnode.state.loadingImage = false;
  }

  oncreate(vnode) {
    vnode.state.croppie = new Croppie(document.getElementById("croppie"), {
      enableExif: true,
      viewport: {
        width: 170,
        height: 170,
        type: "square",
      },
      boundary: {
        width: 200,
        height: 200,
      },
    });
  }

  view(vnode) {
    const { me } = vnode.attrs;

    const uploadProfileImageBlob = async blob => {
      const storage = firebase.storage();
      const ref = storage.ref(`user-assets/profile-images/${me.userId}/${new Date().getTime()}`);
      const result = await ref.put(blob);
      const downloadUrl = await ref.getDownloadURL();
      const jwt = await fetchJwt();
      await updateUserImageUrl(jwt, downloadUrl);
    };

    const handleUpload = async evt => {
      vnode.state.uploading = true;
      const started = new Date();
      analytics.track("photo-upload-start", { category: "photo-upload", label: me.userId });
      m.redraw();
      try {
        const blob = await vnode.state.croppie.result({
          type: "blob",
          size: { width: 500, height: 500 },
          format: "png",
        });
        await uploadProfileImageBlob(blob);
        analytics.track("photo-upload-finish", {
          category: "photo-upload",
          label: me.userId,
          value: differenceInMilliseconds(new Date(), started),
        });
        vnode.state.uploading = false;
        invalidateMe();
        vnode.state.cropping = false;
        reloadPage();
      } catch (e) {
        analytics.track("photo-upload-fail", {
          category: "photo-upload",
          label: me.userId,
          value: differenceInMilliseconds(new Date(), started),
        });
        vnode.state.failedUpload = true;
        vnode.state.uploadFailureReason = e.toString();
        console.error(e);
      }
      m.redraw();
    };

    const handleNewImage = input => {
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = evt => {
          vnode.state.cropping = true;
          vnode.state.loadingImage = false;
          m.redraw();
          return new Promise((accept, reject) => {
            setTimeout(() => {
              vnode.state.croppie.bind({ url: evt.target.result });
              accept();
            }, 200);
          });
        };

        reader.readAsDataURL(input.files[0]);
        vnode.state.loadingImage = true;
        m.redraw();
      }
    };

    const image = me.imageUrl
      ? m("img.is-block", {
          style: "border-radius: 4px; width: 200px; height: 200px; margin-bottom: 0.5em;",
          src: me.imageUrl,
        })
      : m(
          "div.svg-4-radius",
          { style: "border-radius: 4px; margin-bottom: 0.5em;" },
          m.trust(identiconFor(me.displayName, 100))
        );

    const uploadButtons = m("div.buttons.is-fullwidth", { style: "justify-content: center;" }, [
      m(
        "button.button",
        {
          onclick: evt => {
            vnode.state.cropping = false;
            m.redraw();
          },
        },
        "Cancel"
      ),
      m(
        "button.button.is-primary" + (vnode.state.uploading ? ".is-loading" : ""),
        { onclick: handleUpload },
        "Upload"
      ),
    ]);

    return m("div.croppie-wrapper", { style: "max-width: 200px;" }, [
      vnode.state.cropping ? null : image,
      vnode.state.cropping
        ? null
        : m(
            "div.file",
            { style: "justify-content: center;" },
            m("label.file-label", [
              m("input.file-input#upload[type=file]", {
                accept: "image/*",
                onchange: function() {
                  handleNewImage(this);
                },
              }),
              m("span.file-cta", [
                m("span.file-icon", m("i.mdi.mdi-upload")),
                m(
                  "span.file-label" + (vnode.state.loadingImage ? ".is-loading" : ""),
                  "Choose an image"
                ),
              ]),
            ])
          ),
      m("div#croppie", { style: vnode.state.cropping ? "height: 250px;" : "display: none;" }),
      vnode.state.cropping ? uploadButtons : null,
      vnode.state.failedUpload
        ? m("m.is-grey", `Failed to upload image because of: ${vnode.state.uploadFailureReason}`)
        : null,
    ]);
  }
}

export class SettingsPageView {
  oninit(vnode) {
    const { me } = vnode.attrs;
    vnode.state.changingPicture = false;
    vnode.state.displayName = me.displayName;
    vnode.state.summary = me.summary;
    vnode.state.firstAndLastName = me.firstAndLastName;
  }

  view(vnode) {
    const { me } = vnode.attrs;

    const displayNameAvailable = async name => {
      try {
        await getUser(await fetchJwt(), name);
        return false;
      } catch (e) {
        if (e.message.indexOf("404") >= 0) {
          return true;
        } else {
          console.error(e);
          return false;
        }
      }
    };

    const displayNameChanged = async evt => {
      vnode.state.nameAvailable = await displayNameAvailable(vnode.state.displayName);
      m.redraw();
    };

    const onDisplayNameInput = evt => {
      clearTimeout(vnode.state.inputTimeout);
      vnode.state.inputTimeout = setTimeout(() => displayNameChanged(evt), 300);
      const newName = evt.target.value;
      vnode.state.displayName = newName;
    };

    const save = async evt => {
      await analytics.timeFunction(
        "profile-update",
        { category: "profile-update", label: me.userId },
        () =>
          fetchJwt().then(jwt =>
            updateUser(
              jwt,
              vnode.state.firstAndLastName,
              vnode.state.displayName,
              vnode.state.summary
            )
          )
      );
      invalidateMe();
      m.route.set(`/${vnode.state.displayName}`);
    };

    const userDisplayNameView = m("div.field", [
      m("label.heading", "First and Last Name"),
      m("input.input[type=text]", {
        value: vnode.state.firstAndLastName,
        placeholder: "Mick Tagger",
        oninput: evt => {
          vnode.state.firstAndLastName = evt.target.value;
        },
      }),
    ]);

    const displayNameView = m("div.field", [
      m("label.heading", "Display Name"),
      m(
        "input.input[type=text]" +
          (vnode.state.nameAvailable
            ? ".is-success"
            : vnode.state.nameAvailable === false
              ? ".is-danger"
              : ""),
        {
          value: vnode.state.displayName,
          placeholder: "mc_tagger",
          oninput: onDisplayNameInput,
        }
      ),
      vnode.state.nameAvailable === false
        ? m("label.help.is-danger", "This name is already taken!")
        : null,
    ]);

    const userBioView = m("div.field", [
      m("label.heading", "Summary"),
      m("input.input[type=text]", {
        value: vnode.state.summary,
        placeholder: "Enjoys long walks on the beach",
        oninput: evt => {
          vnode.state.summary = evt.target.value;
        },
      }),
    ]);

    const planIcon = planIcons[vnode.attrs.myEntitlement.plan.plan_id] || "account";
    const planButtonView = m("div.field", [
      m("label.heading", "Current Plan"),
      m(
        "a.button",
        { oncreate: m.route.link, href: "/settings/select-plan", title: "Change Plan" },
        [
          m("span.icon.is-small", m(`i.mdi.mdi-${planIcon}`)),
          m("span", vnode.attrs.myEntitlement.plan.title),
        ]
      ),
    ]);

    const deleteAccountButton = m("div.field", [
      m("label.heading", "Delete Account"),
      m(
        "a.button.is-danger.is-outlined",
        {
          onclick: () => {
            alert("To continue to account deletion, please log in again.");
            return refreshLogin("/delete-account");
          },
        },
        "Delete Account"
      ),
    ]);

    const saveButton = m("div.is-right.buttons", [
      m("a.button", { oncreate: m.route.link, href: `/${me.displayName}` }, "Cancel"),
      m(
        "button.button.is-primary",
        { disabled: vnode.state.nameAvailable === false, onclick: save },
        "Save"
      ),
    ]);

    const content = [
      m("div.is-flex", [
        m("div", { style: "margin-right: 1em;" }, [m(ProfileImageUpdateView, { me })]),
        m("div", { style: "flex: 1" }, [
          userDisplayNameView,
          displayNameView,
          userBioView,
          planButtonView,
          deleteAccountButton,
          saveButton,
        ]),
      ]),
    ];

    return [m(PageHeader, { me }), m("section.section", m("div.container", content)), m(Footer)];
  }
}

export class DeleteAccountPageView {
  oninit(vnode) {
    vnode.state.deleting = false;
    return loggedInUser().then(async me => {
      vnode.state.me = me;

      if (vnode.state.me === null) {
        await refreshLogin();
      }

      m.redraw();
    });
  }
  view(vnode) {
    const me = vnode.state.me || {};

    const onclick = async evt => {
      vnode.state.deleting = true;

      await deleteAccount(await fetchJwt());
      await me.fbu.delete();
      deleteEverything(evt);
      window.location.pathname = "/";
    };

    return [
      m(PageHeader, { me }),
      m(
        "section.section",
        m("div.container", [
          m("h1.title", "Delete Account"),
          m("h2.subtitle", "Are you sure? This cannot be undone!"),
          m("div.buttons", [
            m("a.button", { href: `/${me.displayName}`, oncreate: m.route.link }, "Cancel"),
            m(
              "button.button.is-danger" + (vnode.state.deleting ? ".is-loading" : ""),
              { onclick },
              "Delete Account"
            ),
          ]),
        ])
      ),
      m(Footer),
    ];
  }
}

export const deleteAccountPageProvider = {
  onmatch: args => {
    document.title = "Taggit · Delete Account";
  },
  render: vnode => m(DeleteAccountPageView, vnode.attrs),
};
