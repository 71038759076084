import m from "mithril";
import { request } from "../api";
import { fetchJwt, loggedInUser } from "../account";
import { HeaderNav } from "./header";
import { Footer } from "./footer";
import { AutoTaggedTextMinimalView, TopicPresenceView } from "./autotag";
import { TopicButtonView } from "./topics";
import { COLORS, debounce } from "../util";
import embed from "vega-embed";
import { SearchDropdown } from "./etc";
import { MKTGAPI_URL_BASE } from "../config";

class HeaderDiagram {
  oninit(vnode) {
    vnode.state.width = 640;
    vnode.state.height = 480;
  }

  static buildSvg(height, width) {
    return `
<svg width="${width}" height="${height}" viewBox="0 0 640 480" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<g filter="url(#filter0_d)">
<rect x="10" width="260" height="475" rx="8" fill="#F2F2F2"/>
</g>
<g filter="url(#filter1_d)">
<rect x="280" width="355" height="475" rx="8" fill="#F2F2F2"/>
</g>
<rect x="20" y="103" width="240" height="80" rx="8" fill="white"/>
<circle cx="45" cy="128" r="18" fill="#E0E0E0"/>
<rect x="72" y="112" width="179" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="127" width="69" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="182" y="141" width="69" height="9" rx="4.5" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="72" y="156" width="57" height="9" rx="4.5" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="146" y="127" width="105" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="141" width="105" height="9" rx="4.5" fill="#E0E0E0"/>
<path d="M192 165L193.347 169.146H197.706L194.18 171.708L195.527 175.854L192 173.292L188.473 175.854L189.82 171.708L186.294 169.146H190.653L192 165Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M205 165L206.347 169.146H210.706L207.18 171.708L208.527 175.854L205 173.292L201.473 175.854L202.82 171.708L199.294 169.146H203.653L205 165Z" fill="#E0E0E0"/>
<path d="M218 165L219.347 169.146H223.706L220.18 171.708L221.527 175.854L218 173.292L214.473 175.854L215.82 171.708L212.294 169.146H216.653L218 165Z" fill="#E0E0E0"/>
<path d="M232 165L233.347 169.146H237.706L234.18 171.708L235.527 175.854L232 173.292L228.473 175.854L229.82 171.708L226.294 169.146H230.653L232 165Z" fill="#E0E0E0"/>
<path d="M245 165L246.347 169.146H250.706L247.18 171.708L248.527 175.854L245 173.292L241.473 175.854L242.82 171.708L239.294 169.146H243.653L245 165Z" fill="#E0E0E0"/>
<rect x="20" y="197" width="240" height="80" rx="8" fill="white"/>
<circle cx="45" cy="222" r="18" fill="#E0E0E0"/>
<rect x="72" y="206" width="179" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="221" width="69" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="182" y="235" width="69" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="250" width="57" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="146" y="221" width="105" height="9" rx="4.5" fill="#BB50F1" fill-opacity="0.6"/>
<rect x="72" y="235" width="105" height="9" rx="4.5" fill="#BB50F1" fill-opacity="0.6"/>
<path d="M192 259L193.347 263.146H197.706L194.18 265.708L195.527 269.854L192 267.292L188.473 269.854L189.82 265.708L186.294 263.146H190.653L192 259Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M205 259L206.347 263.146H210.706L207.18 265.708L208.527 269.854L205 267.292L201.473 269.854L202.82 265.708L199.294 263.146H203.653L205 259Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M218 259L219.347 263.146H223.706L220.18 265.708L221.527 269.854L218 267.292L214.473 269.854L215.82 265.708L212.294 263.146H216.653L218 259Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M232 259L233.347 263.146H237.706L234.18 265.708L235.527 269.854L232 267.292L228.473 269.854L229.82 265.708L226.294 263.146H230.653L232 259Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M245 259L246.347 263.146H250.706L247.18 265.708L248.527 269.854L245 267.292L241.473 269.854L242.82 265.708L239.294 263.146H243.653L245 259Z" fill="#E0E0E0"/>
<rect x="20" y="290" width="240" height="80" rx="8" fill="white"/>
<circle cx="45" cy="315" r="18" fill="#E0E0E0"/>
<rect x="72" y="299" width="179" height="9" rx="4.5" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="72" y="314" width="69" height="9" rx="4.5" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="182" y="328" width="69" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="343" width="57" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="146" y="314" width="105" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="328" width="105" height="9" rx="4.5" fill="#E0E0E0"/>
<path d="M192 352L193.347 356.146H197.706L194.18 358.708L195.527 362.854L192 360.292L188.473 362.854L189.82 358.708L186.294 356.146H190.653L192 352Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M205 352L206.347 356.146H210.706L207.18 358.708L208.527 362.854L205 360.292L201.473 362.854L202.82 358.708L199.294 356.146H203.653L205 352Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M218 352L219.347 356.146H223.706L220.18 358.708L221.527 362.854L218 360.292L214.473 362.854L215.82 358.708L212.294 356.146H216.653L218 352Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M232 352L233.347 356.146H237.706L234.18 358.708L235.527 362.854L232 360.292L228.473 362.854L229.82 358.708L226.294 356.146H230.653L232 352Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M245 352L246.347 356.146H250.706L247.18 358.708L248.527 362.854L245 360.292L241.473 362.854L242.82 358.708L239.294 356.146H243.653L245 352Z" fill="#E0E0E0"/>
<rect x="20" y="383" width="240" height="80" rx="8" fill="white"/>
<circle cx="45" cy="408" r="18" fill="#E0E0E0"/>
<rect x="72" y="392" width="179" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="407" width="69" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="182" y="421" width="69" height="9" rx="4.5" fill="#27AE60" fill-opacity="0.6"/>
<rect x="72" y="436" width="57" height="9" rx="4.5" fill="#27AE60" fill-opacity="0.6"/>
<rect x="146" y="407" width="105" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="421" width="105" height="9" rx="4.5" fill="#E0E0E0"/>
<path d="M192 445L193.347 449.146H197.706L194.18 451.708L195.527 455.854L192 453.292L188.473 455.854L189.82 451.708L186.294 449.146H190.653L192 445Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M205 445L206.347 449.146H210.706L207.18 451.708L208.527 455.854L205 453.292L201.473 455.854L202.82 451.708L199.294 449.146H203.653L205 445Z" fill="#E0E0E0"/>
<path d="M218 445L219.347 449.146H223.706L220.18 451.708L221.527 455.854L218 453.292L214.473 455.854L215.82 451.708L212.294 449.146H216.653L218 445Z" fill="#E0E0E0"/>
<path d="M232 445L233.347 449.146H237.706L234.18 451.708L235.527 455.854L232 453.292L228.473 455.854L229.82 451.708L226.294 449.146H230.653L232 445Z" fill="#E0E0E0"/>
<path d="M245 445L246.347 449.146H250.706L247.18 451.708L248.527 455.854L245 453.292L241.473 455.854L242.82 451.708L239.294 449.146H243.653L245 445Z" fill="#E0E0E0"/>
<rect x="20" y="10" width="240" height="80" rx="8" fill="white"/>
<circle cx="45" cy="35" r="18" fill="#E0E0E0"/>
<rect x="72" y="19" width="179" height="9" rx="4.5" fill="#27AE60" fill-opacity="0.6"/>
<rect x="72" y="34" width="69" height="9" rx="4.5" fill="#27AE60" fill-opacity="0.6"/>
<rect x="182" y="48" width="69" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="63" width="57" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="146" y="34" width="105" height="9" rx="4.5" fill="#E0E0E0"/>
<rect x="72" y="48" width="105" height="9" rx="4.5" fill="#E0E0E0"/>
<path d="M192 72L193.347 76.1459H197.706L194.18 78.7082L195.527 82.8541L192 80.2918L188.473 82.8541L189.82 78.7082L186.294 76.1459H190.653L192 72Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M205 72L206.347 76.1459H210.706L207.18 78.7082L208.527 82.8541L205 80.2918L201.473 82.8541L202.82 78.7082L199.294 76.1459H203.653L205 72Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M218 72L219.347 76.1459H223.706L220.18 78.7082L221.527 82.8541L218 80.2918L214.473 82.8541L215.82 78.7082L212.294 76.1459H216.653L218 72Z" fill="#F2C94C" fill-opacity="0.5"/>
<path d="M232 72L233.347 76.1459H237.706L234.18 78.7082L235.527 82.8541L232 80.2918L228.473 82.8541L229.82 78.7082L226.294 76.1459H230.653L232 72Z" fill="#E0E0E0"/>
<path d="M245 72L246.347 76.1459H250.706L247.18 78.7082L248.527 82.8541L245 80.2918L241.473 82.8541L242.82 78.7082L239.294 76.1459H243.653L245 72Z" fill="#E0E0E0"/>
<rect x="290" y="165" width="335" height="145" rx="8" fill="white"/>
<path d="M615 300H300C359.786 300 367.316 293.719 376.5 287.437C385.684 281.156 399 262.94 399 262.94C399 262.94 416.4 232.789 420.857 224.623L421.179 224.034C426.435 214.402 436.837 195.344 442.714 186.935C442.714 186.935 451.329 175 460.071 175C468.814 175 477.429 185.678 487.071 201.382C502.455 226.435 513.429 257.286 534.643 279.271C553.447 298.758 577.714 300 615 300Z" fill="#E0E0E0"/>
<rect x="380" y="175" width="4" height="125" fill="#BB50F1" fill-opacity="0.6"/>
<rect x="300" y="175" width="36" height="36" rx="8" fill="#BB50F1" fill-opacity="0.6"/>
<path d="M316.264 197.395C316.282 196.31 316.405 195.453 316.633 194.824C316.861 194.195 317.326 193.498 318.027 192.732L319.818 190.887C320.584 190.021 320.967 189.091 320.967 188.098C320.967 187.141 320.716 186.393 320.215 185.855C319.714 185.309 318.984 185.035 318.027 185.035C317.098 185.035 316.35 185.281 315.785 185.773C315.22 186.266 314.938 186.926 314.938 187.756H312.408C312.426 186.279 312.951 185.09 313.98 184.188C315.02 183.276 316.368 182.82 318.027 182.82C319.75 182.82 321.09 183.285 322.047 184.215C323.013 185.135 323.496 186.402 323.496 188.016C323.496 189.611 322.758 191.183 321.281 192.732L319.791 194.209C319.126 194.947 318.793 196.009 318.793 197.395H316.264ZM316.154 201.729C316.154 201.318 316.277 200.977 316.523 200.703C316.779 200.421 317.152 200.279 317.645 200.279C318.137 200.279 318.51 200.421 318.766 200.703C319.021 200.977 319.148 201.318 319.148 201.729C319.148 202.139 319.021 202.48 318.766 202.754C318.51 203.018 318.137 203.15 317.645 203.15C317.152 203.15 316.779 203.018 316.523 202.754C316.277 202.48 316.154 202.139 316.154 201.729Z" fill="white"/>
<rect x="290" y="318" width="335" height="145" rx="8" fill="white"/>
<path d="M615 453H300C359.786 453 367.316 446.719 376.5 440.437C385.684 434.156 399 415.94 399 415.94C399 415.94 416.4 385.789 420.857 377.623L421.179 377.034C426.435 367.402 436.837 348.344 442.714 339.935C442.714 339.935 451.329 328 460.071 328C468.814 328 477.429 338.678 487.071 354.382C502.455 379.435 513.429 410.286 534.643 432.271C553.447 451.758 577.714 453 615 453Z" fill="#E0E0E0"/>
<rect x="515" y="328" width="4" height="125" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="300" y="328" width="36" height="36" rx="8" fill="#FF6C41" fill-opacity="0.6"/>
<path d="M319.135 350.381H316.852L316.674 336.094H319.326L319.135 350.381ZM316.578 354.729C316.578 354.318 316.701 353.977 316.947 353.703C317.202 353.421 317.576 353.279 318.068 353.279C318.561 353.279 318.934 353.421 319.189 353.703C319.445 353.977 319.572 354.318 319.572 354.729C319.572 355.139 319.445 355.48 319.189 355.754C318.934 356.018 318.561 356.15 318.068 356.15C317.576 356.15 317.202 356.018 316.947 355.754C316.701 355.48 316.578 355.139 316.578 354.729Z" fill="white"/>
<rect x="290" y="10" width="335" height="145" rx="8" fill="white"/>
<path d="M615 145H300C359.786 145 367.316 138.719 376.5 132.437C385.684 126.156 399 107.94 399 107.94C399 107.94 416.4 77.7889 420.857 69.6231L421.179 69.0336C426.435 59.4023 436.837 40.3441 442.714 31.9347C442.714 31.9347 451.329 20 460.071 20C468.814 20 477.429 30.6784 487.071 46.3819C502.455 71.4349 513.429 102.286 534.643 124.271C553.447 143.758 577.714 145 615 145Z" fill="#E0E0E0"/>
<rect x="426" y="20" width="4" height="125" fill="#27AE60" fill-opacity="0.6"/>
<rect x="300" y="20" width="36" height="36" rx="8" fill="#27AE60" fill-opacity="0.6"/>
<path d="M317.325 49C317.082 48.384 316.83 47.8147 316.569 47.292C316.326 46.7507 316.018 46.172 315.645 45.556C315.271 44.94 314.805 44.2213 314.245 43.4C313.685 42.5787 312.975 41.58 312.117 40.404C311.015 38.892 310.231 37.5947 309.765 36.512C309.298 35.4293 309.065 34.3747 309.065 33.348C309.065 32.5267 309.27 31.78 309.681 31.108C310.11 30.4173 310.67 29.876 311.361 29.484C312.07 29.0733 312.835 28.868 313.657 28.868C314.534 28.868 315.346 29.092 316.093 29.54C316.858 29.988 317.483 30.6693 317.969 31.584C318.473 30.6693 319.107 29.988 319.873 29.54C320.638 29.092 321.459 28.868 322.337 28.868C323.139 28.868 323.886 29.0733 324.577 29.484C325.286 29.876 325.855 30.4173 326.285 31.108C326.714 31.78 326.929 32.5267 326.929 33.348C326.929 34.0013 326.835 34.6733 326.649 35.364C326.481 36.036 326.173 36.7827 325.725 37.604C325.277 38.4067 324.661 39.3493 323.877 40.432C323.018 41.6267 322.299 42.644 321.721 43.484C321.142 44.3053 320.666 45.024 320.293 45.64C319.938 46.256 319.63 46.8253 319.369 47.348C319.107 47.8707 318.865 48.4213 318.641 49H317.325ZM317.969 46.564C318.323 45.8547 318.799 45.0333 319.397 44.1C320.013 43.1667 320.731 42.1307 321.553 40.992C322.374 39.8533 323.046 38.8733 323.569 38.052C324.091 37.2307 324.465 36.5773 324.689 36.092C325.137 35.1027 325.361 34.188 325.361 33.348C325.361 32.8067 325.221 32.3213 324.941 31.892C324.661 31.444 324.287 31.0893 323.821 30.828C323.373 30.5667 322.878 30.436 322.337 30.436C321.534 30.436 320.806 30.772 320.153 31.444C319.499 32.0973 319.014 33.0027 318.697 34.16H317.269C316.914 32.8907 316.41 31.9573 315.757 31.36C315.122 30.744 314.422 30.436 313.657 30.436C313.134 30.436 312.639 30.5667 312.173 30.828C311.706 31.0893 311.333 31.444 311.053 31.892C310.773 32.3213 310.633 32.8067 310.633 33.348C310.633 34.132 310.847 35.028 311.277 36.036C311.687 36.988 312.714 38.584 314.357 40.824C315.178 41.9067 315.887 42.9333 316.485 43.904C317.082 44.856 317.577 45.7427 317.969 46.564Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d" x="6" y="0" width="268" height="483" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter1_d" x="276" y="0" width="363" height="483" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<clipPath id="clip0">
<rect width="640" height="480" fill="white"/>
</clipPath>
</defs>
</svg>
`;
  }

  onupdate(vnode) {
    vnode.state.width = vnode.dom.offsetWidth;
    vnode.state.height = vnode.dom.offsetHeight;
  }

  oncreate(vnode) {
    vnode.state.width = vnode.dom.offsetWidth;
    vnode.state.height = vnode.dom.offsetHeight;
  }

  view(vnode) {
    const { height, width } = vnode.state;
    return m.trust(HeaderDiagram.buildSvg(height, width));
  }
}

const categoryToDisplayText = category => {
  if (category === "store") {
    return "iTunes Store";
  }
  return category
    .replace(/-\S/g, txt => " " + txt.charAt(1).toUpperCase())
    .replace("ios ", "")
    .replace(/^\S/g, txt => txt.charAt(0).toUpperCase());
};

class AppBenchmarkHeaderView {
  oninit(vnode) {
    vnode.state.appSearchOptions = [];
    vnode.state.timeout = undefined;
  }
  view(vnode) {
    const {
      me,
      selectedCategory,
      onCategorySelect,
      onSearchSelect,
      availableCategories,
    } = vnode.attrs;

    const searchApps = async newSearch => {
      const searchResults = await request(`/itunes-app-search/${newSearch}`, {
        urlBase: MKTGAPI_URL_BASE,
      });
      vnode.state.appSearchOptions = [];
      const seen = {};
      for (const details of searchResults) {
        if (seen[details.trackId] === undefined) {
          vnode.state.appSearchOptions.push(details);
          seen[details.trackId] = true;
        }
        if (vnode.state.appSearchOptions.length === 15) {
          break;
        }
      }
      m.redraw();
    };

    const itemView = details =>
      m("div", [
        m("b", { style: { display: "block" } }, details.trackName),
        m("span.has-text-grey", [
          m("span", details.artistName),
          m("span.margin-r-half-em.margin-l-half-em", "-"),
          m("span", details.trackId),
        ]),
      ]);

    const appSearchView = m("div", { style: "margin-right: 1.5em; margin-bottom: 1em;" }, [
      m("h2.heading", "iTunes App"),
      m(SearchDropdown, {
        inputClasses: ".inverted-input",
        onSearchChange: debounce(searchApps, 300, vnode.state),
        onItemSelect: details => onSearchSelect(details.trackId),
        itemView,
        options: vnode.state.appSearchOptions,
      }),
    ]);

    const categorySelectView = m("div", [
      m("h2.heading", "Benchmark Against"),
      m("div.select.is-primary", [
        m(
          "select",
          { onchange: evt => onCategorySelect(evt.target.value) },
          availableCategories.map(cat =>
            m(
              "option",
              { selected: selectedCategory === cat, value: cat },
              categoryToDisplayText(cat)
            )
          )
        ),
      ]),
    ]);

    return m("header.hero.is-primary.is-bold", [
      m("div.hero-title", m(HeaderNav, { me })),
      m("div.hero-body", [
        m("div.container", [
          m("div.columns", { style: { flexDirection: "row-reverse" } }, [
            m(
              "div.column.is-flex",
              { style: { flexDirection: "column", justifyContent: "center" } },
              m(HeaderDiagram)
            ),
            m(
              "div.column.is-flex",
              { style: { flexDirection: "column", justifyContent: "center" } },
              [
                m("h1.title", "Taggit iTunes Benchmark"),
                m(
                  "p.margin-b-1-em",
                  { style: { maxWidth: "40em" } },
                  "This benchmark uses 15 app-related Taggit topics to " +
                    "establish baselines for apps in each iTunes category. Using this, app owners can " +
                    "see how they stack up to their competition, and discover what changes will " +
                    "improve customer experience the most."
                ),
                m(
                  "p.margin-b-1-em",
                  { style: { maxWidth: "40em" } },
                  "The benchmark analyzes the most recent 100 non-5-star reviews for each app, " +
                    "identifying mentions of each topic key to app success. We choose non-5-star reviews," +
                    " as many 5-star reviews contain little information about areas that an app has to " +
                    "improve."
                ),
                m(
                  "p.margin-b-1-em",
                  { style: { maxWidth: "40em" } },
                  "Try typing an app name below to get started!"
                ),
                m("div.is-flex", { style: { flexWrap: "wrap" } }, [
                  appSearchView,
                  categorySelectView,
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]);
  }
}

class TopicBenchmarkView {
  onbeforeupdate(vnode, old) {
    if (
      vnode.attrs.selectedCategory !== old.attrs.selectedCategory ||
      vnode.attrs.app_id !== old.attrs.app_id ||
      vnode.attrs.filterActive !== old.attrs.filterActive
    ) {
      return true;
    }
    return false;
  }

  view(vnode) {
    const {
      benchmark,
      selectedTopics,
      autotagRatio,
      autotagPositives,
      autotagTotal,
      filterActive,
      onFilter,
    } = vnode.attrs;
    const { category, topic: topicSpec, ratio: benchmarkRatio, app_histogram } = benchmark;
    const { topic, owner } = selectedTopics[topicSpec];

    const style = { fontFamily: "monospace" };

    const topicView = m("div", [
      m("p.heading", "Topic"),
      TopicButtonView.from({ topic, owner, hideOwner: true, hasLink: true }),
    ]);

    const countView = m("div", [
      m("p.heading", "Count"),
      m("span", { style }, [
        autotagPositives,
        m("span.is-size-7.has-text-grey", "/" + autotagTotal),
      ]),
    ]);

    const delta = autotagRatio / benchmarkRatio - 1;
    const deltaText = m(
      "span",
      (100 * delta)
        .toPrecision(3)
        .replace("-", "")
        .replace("NaN", "--") + "%"
    );
    const deltaIcon =
      delta === 0 || isNaN(delta)
        ? null
        : m("span.icon.is-small", delta > 0 ? m("i.mdi.mdi-arrow-up") : m("i.mdi.mdi-arrow-down"));

    const vsBenchmarkView = m("div", [
      m("p.heading", "VS Benchmark"),
      m("span.is-flex", { style: Object.assign({ alignItems: "center" }, style) }, [
        deltaIcon,
        deltaText,
        m("span", { style: { paddingRight: "1em", flexGrow: "1" } }),
        m(AppHistogramView, { autotagRatio, benchmarkRatio, app_histogram, topicName: topicSpec }),
      ]),
    ]);

    const filterView = m("div", { title: "Filter reviews to those that mention this topic" }, [
      m("p.heading", "Filter"),
      m(
        "span.icon",
        { style: { cursor: "pointer" }, onclick: onFilter },
        m(
          filterActive
            ? "i.mdi.mdi-checkbox-marked-outline.has-text-primary"
            : "i.mdi.mdi-checkbox-blank-outline"
        )
      ),
    ]);

    return [
      filterView,
      topicView,
      countView,
      // ratioView,
      vsBenchmarkView,
      // histogramView,
    ];
  }
}

class BenchmarkSectionView {
  static createBenchmarkBarChartVegaSpec(
    selectedBenchmarks,
    selectedTopics,
    autotag_ratios,
    width,
    appTitle,
    selectedCategory
  ) {
    const benchmarkBarData = selectedBenchmarks.reduce(
      (agg, benchmark) =>
        agg.concat([
          {
            Topic: categoryToDisplayText(benchmark.topic.split("/")[1]),
            Ratio: autotag_ratios[benchmark.topic] / benchmark.ratio - 1,
            Group: "This App",
            color: selectedTopics[benchmark.topic].topic.color,
          },
        ]),
      []
    );

    return {
      $schema: "https://vega.github.io/schema/vega-lite/v4.json",
      data: {
        values: benchmarkBarData,
      },
      config: {
        style: {
          cell: {
            stroke: "transparent",
          },
        },
      },
      title:
        "Topic Mentions: " +
        appTitle +
        " vs " +
        categoryToDisplayText(selectedCategory) +
        " Benchmark",
      encoding: {
        color: { field: "color", type: "nominal", scale: null },
        y: {
          field: "Topic",
          type: "nominal",
          title: null,
          scale: { paddingInner: 0.85 },
          axis: {
            labelFontSize: 16,
            domainColor: "transparent",
            grid: true,
            gridDash: [2, 25],
            gridWidth: 2,
            labelPadding: 8,
          },
        },
        x: {
          field: "Ratio",
          type: "quantitative",
          axis: { title: null, format: "+%", domainColor: "transparent", grid: false },
        },
      },
      layer: [
        {
          mark: { type: "bar" },
        },
        {
          mark: { type: "point", size: 150, filled: true, opacity: 1 },
        },
      ],
      width,
      height: 400,
    };
  }

  static drawBenchmarkVegaChart(vnode) {
    const width = vnode.dom.parentElement.offsetWidth - 216;
    const { benchmarks, selectedCategory, selectedTopics, autotag_ratios, appTitle } = vnode.attrs;
    const selectedBenchmarks = benchmarks.filter(({ category }) => category === selectedCategory)[0]
      .benchmark;
    const spec = BenchmarkSectionView.createBenchmarkBarChartVegaSpec(
      selectedBenchmarks,
      selectedTopics,
      autotag_ratios,
      width,
      appTitle,
      selectedCategory
    );
    return embed("#vega-benchmark-chart", spec, { mode: "vega-lite", renderer: "svg" });
  }

  // oncreate(vnode) {
  //   BenchmarkSectionView.drawBenchmarkVegaChart(vnode);
  // }

  // onupdate(vnode) {
  //   BenchmarkSectionView.drawBenchmarkVegaChart(vnode);
  // }

  view(vnode) {
    const {
      autotag_positives,
      autotag_totals,
      autotag_ratios,
      benchmarks,
      selectedTopics,
      selectedCategory,
      topicFilters,
      onFilterToggle,
      app_id,
      appTitle,
    } = vnode.attrs;

    const selectedBenchmarks = benchmarks.filter(({ category }) => category === selectedCategory)[0]
      .benchmark;
    const halfWayPoint = (selectedBenchmarks.length - selectedBenchmarks.length / 2).toFixed(0);
    const firstColBenchmarks = selectedBenchmarks.slice(0, halfWayPoint);
    const secondColBenchmarks = selectedBenchmarks.slice(halfWayPoint, selectedBenchmarks.length);
    const autotagTotal = Object.values(autotag_totals)[0];

    const benchmarkView = benchmark => {
      const { topic } = benchmark;
      const autotagRatio = autotag_ratios[topic];
      const autotagPositives = autotag_positives[topic];
      const filterActive = topicFilters.indexOf(topic) >= 0;
      const onFilter = e => onFilterToggle(topic);
      return m(TopicBenchmarkView, {
        benchmark,
        selectedTopics,
        autotagRatio,
        autotagPositives,
        filterActive,
        onFilter,
        app_id,
        selectedCategory,
        autotagTotal,
      });
    };

    const style = {
      display: "grid",
      gridColumnGap: "1em",
      gridRowGap: "0.5em",
      gridTemplateColumns: "2em 7em 3em 350px",
      alignContent: "start",
    };

    return m("div", { style: { marginTop: "2em" } }, [
      m("h1.title", "iTunes Benchmark Details"),
      // m("div#vega-benchmark-chart", {style: {marginBottom: "2em"}}),
      m("div.fullhd-columns", [
        m("div.column", { style }, firstColBenchmarks.map(benchmarkView)),
        m("div.column", { style }, secondColBenchmarks.map(benchmarkView)),
      ]),
    ]);
  }
}

class AppHistogramView {
  static buildVegaLiteSpec(countValues, ratio, benchmarkRatio, width) {
    const dataValues = countValues.map((count, idx) => ({
      count,
      ratio: 100 * ratio,
      benchmarkRatio: 100 * benchmarkRatio,
      idx: idx * 2,
    }));
    return {
      $schema: "https://vega.github.io/schema/vega-lite/v4.json",
      data: {
        values: dataValues,
      },
      config: {
        axis: {
          labels: false,
          ticks: false,
          grid: false,
          domainColor: "transparent",
          gridColor: "#0000",
        },
        style: {
          cell: {
            stroke: "transparent",
          },
        },
        padding: 0,
      },
      layer: [
        {
          mark: {
            type: "area",
            interpolate: "step-after",
          },
          encoding: {
            x: {
              field: "idx",
              type: "quantitative",
              title: null,
              axis: { domainColor: "#ccc" },
            },
            y: {
              field: "count",
              type: "quantitative",
              title: null,
            },
            color: { value: "#ccc" },
          },
        },
        {
          mark: "rule",
          encoding: {
            x: {
              field: "benchmarkRatio",
              aggregate: "mean",
              type: "quantitative",
            },
            color: { value: "#333333" },
            size: { value: 2 },
          },
        },
        {
          mark: "rule",
          encoding: {
            x: { field: "ratio", aggregate: "mean", type: "quantitative" },
            color: { value: COLORS.primary },
            size: { value: 2 },
          },
        },
      ],
      height: 15,
      width: width || 250,
    };
  }

  oncreate(vnode) {
    return AppHistogramView.drawVegaLiteChart(vnode);
  }

  onupdate(vnode) {
    return AppHistogramView.drawVegaLiteChart(vnode);
  }

  static drawVegaLiteChart(vnode) {
    const { autotagRatio, benchmarkRatio, app_histogram, topicName } = vnode.attrs;
    // const width = vnode.dom.offsetWidth;
    const spec = AppHistogramView.buildVegaLiteSpec(app_histogram, autotagRatio, benchmarkRatio);
    return embed(`#vega-lite-chart-${topicName.replace("/", "-")}`, spec, {
      mode: "vega-lite",
      renderer: "svg",
    });
  }

  view(vnode) {
    const { topicName } = vnode.attrs;
    return m(`div#vega-lite-chart-${topicName.replace("/", "-")}`, {
      style: { verticalAlign: "text-bottom" },
    });
  }
}

class ReviewSectionView {
  oninit(vnode) {
    vnode.state.page = 0;
  }
  view(vnode) {
    const pageSize = 12;
    const { predictions, selectedTopics, topicFilters } = vnode.attrs;
    const filteredPredictions = predictions.filter(({ title_predictions, body_predictions }) => {
      if (topicFilters.length === 0) {
        return true;
      }
      for (const topic of topicFilters) {
        if (title_predictions.topics_present[topic] || body_predictions.topics_present[topic]) {
          return true;
        }
      }
      return false;
    });
    const hasMore = filteredPredictions.length > (vnode.state.page + 1) * pageSize;

    const onPrevious = e => {
      vnode.state.page -= 1;
      m.redraw();
    };
    const onNext = e => {
      vnode.state.page += 1;
      m.redraw();
    };

    return m("div.app-reviews", [
      m("h1.title", { style: { marginTop: "2em" } }, "Reviews"),
      m(
        "div.reviews.is-flex",
        { style: { flexWrap: "wrap", justifyContent: "center" } },
        filteredPredictions
          .slice(vnode.state.page * pageSize, vnode.state.page * pageSize + pageSize)
          .map(prediction => m(ReviewView, { prediction, selectedTopics }))
      ),
      m("div.buttons", [
        m("button.button", { onclick: onPrevious, disabled: vnode.state.page === 0 }, "Previous"),
        m("button.button", { onclick: onNext, disabled: !hasMore }, "Next"),
      ]),
    ]);
  }
}

class ReviewView {
  oninit(vnode) {
    const { body_predictions, title_predictions, review_id } = vnode.attrs.prediction;
    vnode.state.currentReviewId = review_id;
    vnode.state.bodyClassification = body_predictions;
    vnode.state.titleClassification = title_predictions;
  }

  onupdate(vnode) {
    const { body_predictions, title_predictions, review_id } = vnode.attrs.prediction;
    if (review_id !== vnode.state.currentReviewId) {
      vnode.state.currentReviewId = review_id;
      vnode.state.bodyClassification = body_predictions;
      vnode.state.titleClassification = title_predictions;
    }
  }

  view(vnode) {
    const { selectedTopics } = vnode.attrs;

    const onTitleAutoTagCorrect = newTag => {
      // TODO remove contradicting correction if present
      vnode.state.titleClassification.auto_tags = vnode.state.titleClassification.auto_tags.filter(
        tag => {
          return (
            tag.topic !== newTag.topic ||
            tag.start_idx !== newTag.start_idx ||
            tag.end_idx !== tag.end_idx
          );
        }
      );
      vnode.state.titleClassification.auto_tags.push(newTag);
      m.redraw();
    };

    const onBodyAutoTagCorrect = newTag => {
      // TODO remove contradicting correction if present
      vnode.state.bodyClassification.auto_tags = vnode.state.bodyClassification.auto_tags.filter(
        tag => {
          return (
            tag.topic !== newTag.topic ||
            tag.start_idx !== newTag.start_idx ||
            tag.end_idx !== tag.end_idx
          );
        }
      );
      vnode.state.bodyClassification.auto_tags.push(newTag);
      m.redraw();
    };

    const style = {
      width: "400px",
      margin: "1em",
      flexDirection: "column",
    };

    const presentTopics = [];
    for (const topic in vnode.state.bodyClassification.topics_present) {
      if (
        vnode.state.bodyClassification.topics_present[topic] ||
        vnode.state.titleClassification.topics_present[topic]
      ) {
        presentTopics.push(selectedTopics[topic]);
      }
    }

    return m("div.review.box.is-flex", { style }, [
      m("p.heading", "Title"),
      m(
        "b",
        m(AutoTaggedTextMinimalView, {
          classification: vnode.state.titleClassification,
          allTopicDetails: Object.values(selectedTopics),
          onAutoTagCorrect: onTitleAutoTagCorrect,
        })
      ),
      m("p.heading", { style: { marginTop: "0.5em" } }, "Body"),
      m(AutoTaggedTextMinimalView, {
        classification: vnode.state.bodyClassification,
        allTopicDetails: Object.values(selectedTopics),
        onAutoTagCorrect: onBodyAutoTagCorrect,
      }),
      m("div", { style: { height: "1em" } }),
      m("p.heading", { style: { marginTop: "auto" } }, "Topics Mentioned"),
      presentTopics && presentTopics.length
        ? m(TopicPresenceView, { presentTopics })
        : m("span.has-text-grey", "None"),
      m("div.is-flex", [
        m("div", { style: { marginRight: "1em" } }, [
          m("p.heading", { style: { marginTop: "0.5em" } }, "Created At"),
          m("span", vnode.attrs.prediction.created_at.split("T")[0]),
        ]),
        m("div", [
          m("p.heading", { style: { marginTop: "0.5em" } }, "Author"),
          m("span", vnode.attrs.prediction.author_name),
        ]),
      ]),
    ]);
  }
}

class AppRatingView {
  view(vnode) {
    const { avgRating, ratingCount } = vnode.attrs;
    return m("span", `${avgRating} stars, with ${ratingCount} user ratings`);
  }
}

class TopicsDescriptionView {
  view(vnode) {
    const { topics, title } = vnode.attrs;

    const topicDescriptions = {
      "soaxelbrooke/app-ads":
        "Mentions of ads, pop ups, and other promotions. Mentions of this" +
        " topic can be an indicator of a user-base growing fatigued by monetization efforts or " +
        "callouts of specific offensive ads that are turning them off the app.",
      "soaxelbrooke/app-annoying":
        "A high level indicator of annoyance with an app. This can " +
        "span subjects from app errors, to usability issues, or problems with downstream " +
        "services provided in tandem with the app.",
      "soaxelbrooke/app-battery":
        "People talking about battery drain problems or high power " +
        "consumption by the app.  High battery usage annoys users, and causes phones to put the " +
        "app to sleep frequently.",
      "soaxelbrooke/app-churn":
        "Users talking about uninstalling the app, or ending their " +
        "subscription to a service.  A strong indicator that some segment of users is very " +
        "unhappy, and indicates declining user loyalty.",
      "soaxelbrooke/app-connectivity":
        "Mentions of the app not working with user internet, wifi," +
        " or other similar issues.  Can indicate that the app was not built with graceful " +
        "degradation of network and internet in mind, which most users expect today.",
      "soaxelbrooke/app-crash":
        "Direct mentions of an app crashing, freezing, or force-closing." +
        " App crashes are a leading indicator of app churn, and most users have very little " +
        "patience for an app that crashes on them.",
      "soaxelbrooke/app-error":
        "Broader mentions of problems with the app, like error messages " +
        "or some feature not working as the user expects. Catches a broader scope of problems" +
        " than app-crash.",
      "soaxelbrooke/app-location":
        "Users talking about location related features, like GPS " +
        "and navigation. Can indicate dissatisfaction with location accuracy, or user surprise " +
        "about GPS or location data usage.",
      "soaxelbrooke/app-login":
        "Mentions of login issues with the app, like problems creating" +
        " an account, logging in, or being logged out of the app unexpectedly.  Useful for " +
        "diagnosing authentication service problems.",
      "soaxelbrooke/app-slow":
        "Users complaining about app unresponsiveness or slowness. " +
        "Unresponsiveness is a well known user experience detractor, and users perceive sluggish" +
        " apps as being low quality.",
      "soaxelbrooke/app-update":
        "People talking about an update to the app. Generally this is " +
        "users complaining about a release that introduces bugs, removes loved features, or " +
        "changes the UI in ways that are confusing to the user.",
      "soaxelbrooke/app-usability":
        "Mentions of usability problems in the app. Users may " +
        "mention elements of the app they find confusing, or that don't work in ways they " +
        "expect. A great topic to monitor for ironing out usability issues.",
      "soaxelbrooke/checkout":
        "Users talking about payment and checkout problems. This can " +
        "include subjects like coupon usage, billing errors, and anything related to checkout. " +
        "Payment problems are rare, but relate to a very important aspect of the app to customers.",
      "soaxelbrooke/feature-requests":
        "Mentions of features that users would like to be " +
        "implemented, or changes they would like made to the app. Feature requests can be an " +
        "indicator of an engaged userbase, or of an app that does not yet have a mature " +
        "feature-set.",
      "soaxelbrooke/support-problems":
        "Problems with support or moderation staff. A useful " +
        "indicator of whether users are able to reach support, and if they feel their issues " +
        "are getting resolved.",
    };

    return m("div.column", [
      m("h2.subtitle", title),
      topics && topics.length > 0
        ? topics.map(({ owner, topic, delta }) => {
            const deltaText = m("span", (100 * delta).toPrecision(3).replace("-", "") + "%");
            const deltaIcon =
              delta === 0
                ? null
                : m(
                    "span.icon.is-small",
                    delta > 0 ? m("i.mdi.mdi-arrow-up") : m("i.mdi.mdi-arrow-down")
                  );

            return m("div.topic-description", { style: { marginBottom: "0.5em" } }, [
              TopicButtonView.from({ owner, topic, hideOwner: true, hasLink: true }),
              m(
                "b",
                { style: { fontFamily: "monospace", marginLeft: "1em", marginRight: "1em" } },
                [deltaIcon, deltaText]
              ),
              m("em", topicDescriptions[`${owner.display_name}/${topic.name}`]),
            ]);
          })
        : m("span.has-text-grey", "None"),
    ]);
  }
}

class AppDetailSection {
  view(vnode) {
    // popularTopics is a TopicDetails, with the field `delta` added, which is the percent change
    // from the benchmark
    const {
      title,
      author,
      avgRating,
      ratingCount,
      app_id,
      popularTopics,
      iconUrl,
      categories,
    } = vnode.attrs;

    const appSummaryHeader = m("div.is-flex", { style: { marginBottom: "2em" } }, [
      m("img", {
        src: iconUrl,
        style: { borderRadius: "30px", marginRight: "2em", height: "131px" },
      }),
      m("div", [
        m("h1.title", title),
        m("h2.subtitle", { style: { marginBottom: "0.5em" } }, author),
        m("div.tags", { style: { marginBottom: 0 } }, categories.map(cat => m("span.tag", cat))),
        m(AppRatingView, { avgRating, ratingCount }),
      ]),
    ]);

    return m("div", [
      appSummaryHeader,
      m("div.columns", [
        m(TopicsDescriptionView, { topics: popularTopics.slice(0, 2), title: "Popular Topics" }),
        m(TopicsDescriptionView, { topics: popularTopics.slice(2, 4), title: "Popular Topics" }),
      ]),
    ]);
  }
}

class AppBenchmarkPageView {
  async oninit(vnode) {
    vnode.state.filters = {};
    vnode.state.topicFilters = [];

    request(`/itunes-app-benchmarks/${vnode.attrs.appId}`, { urlBase: MKTGAPI_URL_BASE }).then(
      async benchmarkData => {
        vnode.state.benchmarkData = benchmarkData;
        m.redraw();

        vnode.state.selectedTopics = {};
        const topicSpecs = Object.keys(benchmarkData.autotag_positives).join(",");
        vnode.state.selectedTopics = (await request(
          `/topics-bulk?topics=${topicSpecs}`,
          {}
        )).topics;
        m.redraw();
      }
    );

    request(`/itunes-app-reviews/${vnode.attrs.appId}`, { urlBase: MKTGAPI_URL_BASE }).then(
      reviewData => {
        vnode.state.reviewData = reviewData;
        m.redraw();
      }
    );

    // TODO replace this with bulk topic API
    const topicPromises = {};
  }

  view(vnode) {
    const { appDetails, me, vsCategory } = vnode.attrs;
    const { selectedTopics } = vnode.state;
    const {
      trackName: title,
      trackId: app_id,
      averageUserRating: avgRating,
      userRatingCount: ratingCount,
      artistName: author,
      artworkUrl512: iconUrl,
      genres: categories,
    } = appDetails;

    let availableCategories = ["store"];
    const topicBenchmarks = {};
    const topicDeltas = {};
    const selectedTopicsLoaded = selectedTopics && Object.entries(selectedTopics).length > 0;

    let benchmarkView;
    if (vnode.state.benchmarkData && selectedTopicsLoaded) {
      const {
        autotag_positives,
        autotag_ratios,
        autotag_totals,
        benchmarks,
      } = vnode.state.benchmarkData;
      availableCategories = benchmarks.map(({ category }) => category);

      benchmarks
        .filter(({ category }) => category === vsCategory)[0]
        .benchmark.forEach(({ topic, ratio }) => {
          topicBenchmarks[topic] = ratio;
        });
      for (const topicSpec in autotag_ratios) {
        topicDeltas[topicSpec] = autotag_ratios[topicSpec] / topicBenchmarks[topicSpec] - 1;
      }

      const onFilterToggle = topic => {
        if (vnode.state.topicFilters.indexOf(topic) >= 0) {
          vnode.state.topicFilters = vnode.state.topicFilters.filter(t => t !== topic);
        } else {
          vnode.state.topicFilters.push(topic);
        }
        m.redraw();
      };

      benchmarkView = m(BenchmarkSectionView, {
        autotag_totals,
        autotag_positives,
        autotag_ratios,
        benchmarks,
        selectedTopics,
        selectedCategory: vsCategory,
        topicFilters: vnode.state.topicFilters,
        onFilterToggle,
        app_id,
        appTitle: title,
      });
    } else {
      benchmarkView = m(
        "div.message",
        m("div.message-body", [
          m("span.loader", { style: { display: "inline-block" } }),
          m("span.margin-l-half-em", "Loading benchmark data..."),
        ])
      );
    }

    const onCategorySelect = vsCategory => {
      m.route.set(m.route.get(), { vsCategory });
    };

    const onSearchSelect = appId => {
      window.location.pathname = "/app-benchmark/" + appId;
    };

    const headerView = m(AppBenchmarkHeaderView, {
      me,
      availableCategories,
      selectedCategory: vsCategory,
      onCategorySelect,
      onSearchSelect,
    });

    const popularTopics = selectedTopics
      ? Object.entries(topicDeltas)
          .filter(([topic, ratio]) => ratio >= 0.5)
          .sort(([_topicL, ratioL], [_topicR, ratioR]) => ratioR - ratioL)
          .map(([topic, ratio]) =>
            Object.assign(selectedTopics[topic], { delta: topicDeltas[topic] })
          )
      : [];

    const appSummaryView = m(AppDetailSection, {
      title,
      author,
      avgRating,
      ratingCount,
      app_id,
      popularTopics,
      iconUrl,
      categories,
    });

    let reviewsView;
    if (vnode.state.reviewData && selectedTopicsLoaded) {
      const { predictions } = vnode.state.reviewData;
      reviewsView = m(ReviewSectionView, {
        predictions,
        selectedTopics,
        topicFilters: vnode.state.topicFilters,
        title,
      });
    } else {
      reviewsView = m(
        "div.message",
        m("div.message-body", [
          m("span.loader", { style: { display: "inline-block" } }),
          m("span.margin-l-half-em", "Loading review data..."),
        ])
      );
    }

    return [
      headerView,
      m("section.section", m("div.container", [appSummaryView, benchmarkView, reviewsView])),
      m(Footer),
    ];
  }
}

export const appBenchmarkPageProvider = (() => {
  const attrs = {
    me: null,
    data: null,
  };
  return {
    onmatch: async (args, requestedPath) => {
      document.title = "Taggit · iTunes App Benchmark";
      attrs.vsCategory = args.vsCategory || "store";
      const jwt = await fetchJwt();
      const mePromise = loggedInUser();
      const detailsPromise = request(`/itunes-app-details/${args.appId}`, {
        jwt,
        urlBase: MKTGAPI_URL_BASE,
      });
      attrs.me = await mePromise;
      attrs.appDetails = await detailsPromise;
    },
    render: vnode => {
      return m(AppBenchmarkPageView, Object.assign(attrs, vnode.attrs));
    },
  };
})();

class EmptyAppBenchmarkPageView {
  view(vnode) {
    const { me } = vnode.attrs;

    const onSearchSelect = appId => {
      window.location.pathname = "/app-benchmark/" + appId;
    };

    const headerView = m(AppBenchmarkHeaderView, {
      me,
      availableCategories: ["store"],
      selectedCategory: "store",
      appSearchText: "",
      onCategorySelect: () => undefined,
      onSearchSelect,
    });

    const content = m("div", [
      m("h1.title", "iTunes Benchmarks"),
      m(
        "div.message",
        m(
          "div.message-body",
          "Please select an app to view benchmark statistics and auto-tagged reviews!"
        )
      ),
    ]);

    return [headerView, m("section.section", m("div.container", content)), m(Footer)];
  }
}

export const emptyAppBenchmarkPageView = (() => {
  const attrs = {};
  return {
    onmatch: async () => {
      attrs.me = await loggedInUser();
    },
    render: vnode => m(EmptyAppBenchmarkPageView, Object.assign(attrs, vnode.attrs)),
  };
})();
