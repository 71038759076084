import m from "mithril";
import { PageHeader } from "./header";
import { Footer } from "./footer";
import { TopicSummaryView } from "./summaries";
import Identicon from "identicon.js";
import md5 from "md5";
import { fetchJwt, loggedInUser } from "../account";
import { follow, getUser, listUserActivity, listUserTopics, unfollow } from "../api";
import { activityViewFor } from "./activity";
import { NotFoundPageView } from "./etc";
import { reloadPage } from "../util";

export class UserImageAndName {
  view(vnode) {
    const { user, inverted } = vnode.attrs;
    const oncreate = m.route.link;
    const textColor = inverted ? "white" : "dark";

    const userImage = vnode.attrs.user.image_url
      ? m("img", {
          src: user.image_url,
          style: "height: 30px; width: 30px; border-radius: 2px; margin-right: 8px;",
        })
      : m(
          "div.svg-2-radius",
          { style: "border-radius: 2px; margin-right: 8px;" },
          m.trust(identiconFor(user.display_name, 30))
        );

    return m(
      "a",
      { style: "display: flex; align-items: center;", href: `/${user.display_name}`, oncreate },
      [userImage, m(`span.has-text-weight-bold.has-text-${textColor}`, user.display_name)]
    );
  }
}

export const identiconFor = (displayName, size) => {
  return new Identicon(md5(displayName), { size, format: "svg" }).toString(true);
};

export const profilePageProvider = (() => {
  const attrs = {};
  return {
    onmatch: async args => {
      document.title = `Taggit · ${args.displayName}`;
      const jwt = await fetchJwt();
      const userPromise = getUser(jwt, args.displayName);
      const mePromise = loggedInUser(jwt);
      const userTopicsPromise = listUserTopics(jwt, args.displayName);
      const activityPromise = listUserActivity(jwt, args.displayName);

      attrs.me = (await mePromise) || {};

      try {
        const { user, following } = await userPromise;
        attrs.user = user;
        attrs.following = following;
        attrs.topics = await userTopicsPromise;
        attrs.activity = await activityPromise;
      } catch (e) {
        console.error(e);
      }
    },
    render: vnode => m(ProfilePageView, Object.assign(attrs, vnode.attrs)),
  };
})();

export class ProfilePageView {
  view(vnode) {
    const { user, topics, me, following, activity } = vnode.attrs;

    if (!user || !topics || !me || !activity) {
      return m(NotFoundPageView, vnode.attrs);
    }

    const followButton = following
      ? m(
          "button.button.is-fullwidth",
          {
            onclick: async () => {
              await unfollow(await fetchJwt(), user.display_name);
              reloadPage();
            },
          },
          "Unfollow"
        )
      : m(
          "button.button.is-primary.is-fullwidth",
          {
            onclick: async () => {
              await follow(await fetchJwt(), user.display_name);
              reloadPage();
            },
            disabled: user.display_name === me.displayName,
          },
          "Follow"
        );

    const userImage = user.image_url
      ? m("img.is-block", {
          style: "border-radius: 4px; width: 100px; height: 100px; margin-bottom: 0.5em;",
          src: user.image_url,
        })
      : m(
          "div.svg-4-radius",
          { style: "border-radius: 4px; margin-bottom: 0.5em;" },
          m.trust(identiconFor(user.display_name, 100))
        );

    return [
      m(PageHeader, { me }),
      m("section.section", [
        m("div.row.container", [
          m("div.is-flex", [
            m("div", { style: "margin-right: 1em;" }, [userImage, followButton]),
            m("div", { style: "flex: 1" }, [
              m("p.is-size-4.has-text-weight-bold", user.first_and_last_name),
              m("p.is-size-4", user.display_name),
              m("p", user.summary),
              m("p.has-text-grey", [
                m("i.mdi.mdi-account-group.pad-r-quarter-em"),
                user.follower_count + " follower" + (user.follower_count === 1 ? "" : "s"),
              ]),
            ]),
          ]),
        ]),
        m("div.row.container", { style: "margin-top: 2em;" }, [
          m("h1.subtitle.has-text-weight-bold", "Topics"),
          m(
            "div.columns.is-multiline",
            topics.map(({ topic, owner, labels }) =>
              m("div.column.is-half", m(TopicSummaryView, { topic, owner, labels }))
            )
          ),
        ]),
        m("div.row.container", { style: "margin-top: 2em;" }, [
          m("h1.subtitle.has-text-weight-bold", "Activity"),
          m("div", activity.map(activityViewFor)),
        ]),
      ]),
      m(Footer),
    ];
  }
}
