import m from "mithril";
import { Footer } from "./footer";
import { TaggableTextView, TopicSelectDropdownView } from "./tags";
import { fetchJwt, loggedInUser } from "../account";
import { HeaderInfoButtonView, PageHeader } from "./header";
import parse from "csv-parse";
import lunr from "lunr";
import { PhraseExtractor } from "../phrases";
import { TopicButtonView } from "./topics";
import { classifyTexts } from "../api";
import { AutoTagTextResultView } from "./autotag";
import { highlightSearchTerms, inferTextIndexes, tokenizeAndStem } from "../util";

class ShowMoreDropdownAttrs {
  constructor(onSelect, onDeselect, onRefresh, selected, classifying) {
    this.onSelect = onSelect;
    this.onDeselect = onDeselect;
    this.onRefresh = onRefresh;
    this.selected = selected;
    this.classifying = classifying;
  }
}

class ShowMoreDropdown {
  view(vnode) {
    const { onSelect, selected, onDeselect, onRefresh, classifying } = vnode.attrs;

    let content;
    if (selected !== undefined) {
      const { topic, owner } = selected;
      content = [
        TopicButtonView.from({
          topic,
          owner,
          hasX: true,
          hasLink: true,
          size: "medium",
          onX: onDeselect,
        }),
        m(
          "button.button.button.is-primary.is-outlined.margin-l-half-em.is-inverted" +
            (vnode.attrs.classifying ? ".is-loading" : ""),
          { onclick: onRefresh, style: "height: 32px;" },
          m("span.icon.is-small", m("i.mdi.mdi-refresh"))
        ),
      ];
    } else {
      content = m(TopicSelectDropdownView, {
        button: m("button.button.is-inverted.is-primary.is-outlined", [
          m("span", "Select Topic"),
          m("span.icon", m("i.mdi.mdi-chevron-down")),
        ]),
        onTopicChange: onSelect,
        autoselect: false,
        minimumAccess: "read",
        requireClassifier: true,
      });
    }

    return m("div.field", [m("div.control", content)]);
  }
}

class DocumentExplorerHeader {
  view(vnode) {
    const {
      me,
      onSearchUpdated,
      onImport,
      onClearWorkspace,
      onSearchSubmit,
      currentSearch,
      selectedFile,
      candidateFields,
      selectedFields,
      onUnHideColumns,
      loading,
      matchingDocuments,
      totalDocuments,
      showMoreDropdownAttrs,
    } = vnode.attrs;

    const onkeyup = evt => {
      vnode.state.currentSearch = evt.target.value;
      if (evt.key === "Enter") {
        onSearchSubmit(evt.target.value);
      } else {
        onSearchUpdated(evt.target.value);
      }
    };

    const extras = [];

    if (candidateFields !== null && candidateFields.length > 0 && !loading) {
      extras.push({
        subtitle: "Clear Documents",
        component: m(HeaderInfoButtonView, {
          icon: "delete",
          text: "Clear Documents",
          onTextClick: onClearWorkspace,
        }),
      });
    } else {
      extras.push({
        subtitle: "Import to Workspace",
        component: m("div.field", [
          m(
            "div.control",
            m(
              "div.file" + (selectedFile ? ".has-name" : ""),
              m("label.file-label", [
                m("input.file-input[type=file]#file-input", { onchange: onImport }),
                m("span.file-cta", [
                  m("span.file-icon", m("i.mdi.mdi-upload")),
                  m("span.file-label", "Import CSV"),
                  loading
                    ? m("div.loader", {
                        style: `
                      margin-left: 1em;
                      border: 2px #ff6c41 solid;
                      border-right-color: transparent;
                      border-top-color: transparent;`,
                      })
                    : null,
                ]),
                selectedFile === undefined ? null : m("span.file-name", selectedFile.name),
              ])
            )
          ),
          vnode.attrs.fileError ? m("p.help.has-text-white", vnode.attrs.fileError) : null,
        ]),
      });
    }

    if (selectedFields && candidateFields && selectedFields.length !== candidateFields.length) {
      extras.push({
        subtitle: "Un-Hide Columns",
        component: m(
          "button.button.is-primary.is-inverted.is-outlined",
          { onclick: onUnHideColumns },
          [m("span.icon", m("i.mdi.mdi-eye")), m("span", "Un-Hide Columns")]
        ),
      });
    }

    if (selectedFields && !loading) {
      extras.push({
        subtitle: "Search Documents",
        component: m("div.field.is-grouped", [
          m("div.control.has-icons-left.inverted-input", [
            m("input.input", { placeholder: "", onkeyup, value: currentSearch }),
            m("span.icon.is-small.is-left", m("i.mdi.mdi-magnify")),
          ]),
          m(
            "div.control.is-flex",
            { style: "align-items: center;" },
            m("a.delete", { onclick: () => onSearchSubmit("") })
          ),
        ]),
      });

      extras.push({
        subtitle: "Matching Documents",
        title: `${matchingDocuments} / ${totalDocuments}`,
      });

      if (me) {
        extras.push({
          subtitle: "Auto Tag",
          component: m(ShowMoreDropdown, showMoreDropdownAttrs),
        });
      }
    }

    return m(PageHeader, {
      me,
      extras,
    });
  }
}

class PhraseListView {
  oninit(vnode) {
    vnode.state.page = 0;
  }

  onbeforeupdate(vnode, old) {
    if (vnode.attrs.currentPhrases.length !== old.attrs.currentPhrases.length) {
      vnode.state.page = 0;
    }
    return true;
  }

  view(vnode) {
    const { currentPhrases, onPhraseClick } = vnode.attrs;

    const docToRow = ([phrase, count]) =>
      m("tr", { onclick: evt => onPhraseClick(phrase), style: "cursor: pointer;" }, [
        m("td", phrase),
        m("td", count),
      ]);

    const pageSize = vnode.attrs.pageSize || 50;
    const { page } = vnode.state;
    const totalPages = Math.floor(currentPhrases.length / pageSize);

    const pages = [page - 2, page - 1, page, page + 1, page + 2].filter(
      p => p >= 0 && p <= totalPages
    );

    const onclick = evt => {
      vnode.state.page = parseInt(evt.target.innerText) - 1;
      m.redraw();
    };

    const onNext = evt => {
      if (page < totalPages) {
        vnode.state.page += 1;
        m.redraw();
      }
    };

    const onPrevious = evt => {
      if (page > 0) {
        vnode.state.page -= 1;
        m.redraw();
      }
    };

    const pagination = m("nav.pagination", [
      m("a.pagination-previous", { disabled: page === 0, onclick: onPrevious }, "Previous"),
      m("a.pagination-next", { disabled: page === totalPages, onclick: onNext }, "Next"),
    ]);

    return m(
      "div",
      m("div.table.is-fullwidth.is-hoverable", [
        m("thead", m("tr", [m("th", "Term"), m("th", "Volume")])),
        m("tbody", currentPhrases.slice(page * pageSize, (page + 1) * pageSize).map(docToRow)),
        m("tfoot", [m("tr", m("td", { colspan: 2 }, pagination))]),
      ])
    );
  }
}

class FieldDropdownView {
  view(vnode) {
    const { field, onSortAsc, onSortDesc, onHide, showRight } = vnode.attrs;

    return m(
      "div.dropdown.is-hoverable" + (showRight ? ".is-right" : ""),
      { style: "cursor: pointer;" },
      [
        m(
          "div.dropdown-trigger",
          { style: "display: flex; align-items: center; justify-content: space-between;" },
          [
            m("span", { style: "white-space: nowrap;" }, field),
            m("span.icon.is-small", m("i.mdi.mdi-dots-vertical.has-text-grey")),
          ]
        ),
        m(
          "div.dropdown-menu",
          m("div.dropdown-content", [
            m("a.dropdown-item", { onclick: () => onHide(field) }, "Hide Column"),
            m("a.dropdown-item", { onclick: () => onSortAsc(field) }, "Sort Ascending"),
            m("a.dropdown-item", { onclick: () => onSortDesc(field) }, "Sort Descending"),
          ])
        ),
      ]
    );
  }
}

class DocumentListView {
  oninit(vnode) {
    vnode.state.page = 0;
  }

  onbeforeupdate(vnode, old) {
    if (vnode.attrs.sortedDocuments.length !== old.attrs.sortedDocuments.length) {
      vnode.state.page = 0;
    }
    return true;
  }

  view(vnode) {
    let {
      sortedDocuments,
      selectedFields,
      candidateFields,
      onSortAsc,
      onSortDesc,
      onHide,
      pageSize,
      searchString,
      fieldTextProbs,
      classifications,
      allTopicDetails,
    } = vnode.attrs;
    if (selectedFields === null) {
      if (sortedDocuments && sortedDocuments.length > 0) {
        selectedFields = [0].concat(
          Array.from({ length: sortedDocuments[0].length - 1 }, (v, k) => k + 1)
        );
      } else {
        selectedFields = [];
      }
    }

    pageSize = pageSize || 10;

    const searchTokens = tokenizeAndStem(searchString);
    const { page } = vnode.state;
    const totalPages = Math.floor(sortedDocuments.length / pageSize);

    const docToRow = (document, idx) =>
      m(
        "tr",
        selectedFields.map(field => {
          const onAutoTagCorrect = newTag => {
            // TODO fix contradicting tag corrections
            classifications[page * pageSize + idx][field].auto_tags = classifications[
              page * pageSize + idx
            ][field].auto_tags.filter(tag => {
              return (
                tag.topic !== newTag.topic ||
                tag.start_idx !== newTag.start_idx ||
                tag.end_idx !== tag.end_idx
              );
            });
            classifications[page * pageSize + idx][field].auto_tags.push(newTag);
            m.redraw();
          };
          return m(
            "td",
            // classifications - put autotagged text here
            fieldTextProbs[field] >= 0.6
              ? classifications[page * pageSize + idx] &&
                classifications[page * pageSize + idx][field]
                ? m(AutoTagTextResultView, {
                    classification: classifications[page * pageSize + idx][field],
                    allTopicDetails,
                    onAutoTagCorrect,
                  })
                : m(TaggableTextView, {
                    text: highlightSearchTerms(searchTokens, document[field]),
                    tagging: false,
                  })
              : highlightSearchTerms(searchTokens, document[field])
          );
        })
      );

    const pages = [
      page - 4,
      page - 3,
      page - 2,
      page - 1,
      page,
      page + 1,
      page + 2,
      page + 3,
      page + 4,
    ].filter(p => p >= 0 && p <= totalPages);

    const onclick = evt => {
      vnode.state.page = parseInt(evt.target.innerText) - 1;
      m.redraw();
    };

    const isStart = pages[0] === 0;
    const isEnd = pages[pages.length - 1] === totalPages;

    const onNext = evt => {
      if (page < totalPages) {
        vnode.state.page += 1;
        m.redraw();
      }
    };

    const onPrevious = evt => {
      if (page > 0) {
        vnode.state.page -= 1;
        m.redraw();
      }
    };

    const paginationStart = isStart
      ? []
      : [
          m("li", { style: "list-style-type: none;" }, m("a.pagination-link", { onclick }, 1)),
          page >= 4
            ? m(
                "li",
                { style: "list-style-type: none;" },
                m("span.pagination-ellipsis", m.trust("&hellip;"))
              )
            : null,
        ];

    const paginationEnd = isEnd
      ? []
      : [
          page <= totalPages - 4
            ? m(
                "li",
                { style: "list-style-type: none;" },
                m("span.pagination-ellipsis", m.trust("&hellip;"))
              )
            : null,
          m(
            "li",
            { style: "list-style-type: none;" },
            m("a.pagination-link", { onclick }, totalPages + 1)
          ),
        ];

    const pagination = m("nav.pagination", [
      m("a.pagination-previous", { disabled: page === 0, onclick: onPrevious }, "Previous"),
      m("a.pagination-next", { disabled: page === totalPages, onclick: onNext }, "Next"),
      m(
        "ul.pagination-list",
        paginationStart
          .concat(
            pages.map(p =>
              m(
                "li",
                { style: "list-style-type: none;" },
                m("a.pagination-link" + (p === page ? ".is-current" : ""), { onclick }, p + 1)
              )
            )
          )
          .concat(paginationEnd)
      ),
    ]);

    return m(
      "div",
      { style: "flex: 1; margin-left: 3em; overflow-x: scroll;" },
      m("div.table.is-fullwidth", [
        m(
          "thead",
          m(
            "tr",
            selectedFields.map((field, idx) =>
              m(
                "th",
                m(FieldDropdownView, {
                  field: candidateFields[field],
                  onSortAsc,
                  onSortDesc,
                  onHide,
                  showRight: idx > 2,
                })
              )
            )
          )
        ),
        m("tbody", sortedDocuments.slice(page * pageSize, (page + 1) * pageSize).map(docToRow)),
        m("tfoot", [m("tr", m("td", { colspan: selectedFields.length }, pagination))]),
      ])
    );
  }
}

export class DocumentExplorerPageView {
  oninit(vnode) {
    vnode.state.allDocuments = [];
    vnode.state.classifications = [];
    vnode.state.documentsAutoTagged = [];
    vnode.state.documentsSearchMatch = [];
    vnode.state.documentsSearchScore = [];
    vnode.state.documentIndex = [];
    vnode.state.searchString = "";
    vnode.state.sortBy = null;
    vnode.state.loading = false;
    vnode.state.candidateFields = null;
    vnode.state.selectedFields = null;
    vnode.state.documentProcessingProgress = null;
    vnode.state.phraseModel = new PhraseExtractor({ alpha: 3 });
    vnode.state.currentPhrases = [];
    vnode.state.sortOpts = { sortIdx: "__original__", sortDirection: "asc" };
    vnode.state.showMoreSelected = undefined;
    vnode.state.classifying = false;
    vnode.state.autotags = {};
  }

  rebuildIndex(vnode) {
    const { sortIdx, sortDirection } = vnode.state.sortOpts;
    const matches = [];
    vnode.state.allDocuments.forEach((document, idx) => {
      const inIndex =
        (!vnode.state.showMoreSelected || vnode.state.documentsAutoTagged[idx]) &&
        (vnode.state.searchString === "" || vnode.state.documentsSearchMatch[idx]);
      if (inIndex) {
        matches.push(idx);
      }
    });

    function shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    }

    if (sortIdx === "__original__") {
      vnode.state.documentIndex = matches;
    } else if (sortIdx === "__search__") {
      vnode.state.documentIndex = matches.sort((leftIdx, rightIdx) => {
        const left = vnode.state.documentsSearchScore[leftIdx];
        const right = vnode.state.documentsSearchScore[rightIdx];
        return left < right ? 1 : left > right ? -1 : 0;
      });
    } else if (sortIdx === "__random__") {
      vnode.state.documentIndex = shuffle(matches);
    } else {
      vnode.state.documentIndex = matches.sort((leftIdx, rightIdx) => {
        const left = vnode.state.allDocuments[leftIdx][sortIdx];
        const right = vnode.state.allDocuments[rightIdx][sortIdx];
        if (sortDirection === "asc") {
          return left > right ? 1 : left < right ? -1 : 0;
        } else {
          return left < right ? 1 : left > right ? -1 : 0;
        }
      });
    }
  }

  getMatchingDocuments(vnode, offset, limit) {
    offset |= 0;
    limit |= vnode.state.documentIndex.length;
    return vnode.state.documentIndex.slice(offset, limit).map(idx => vnode.state.allDocuments[idx]);
  }

  view(vnode) {
    const { sortIdx, sortDirection } = vnode.state.sortOpts;
    const { me } = vnode.attrs;
    const { currentPhrases, selectedFields, selectedFile, candidateFields, loading } = vnode.state;
    const classifierTier = (me || {}).classifierTier || "basic";

    const onSearchUpdated = newSearch => {
      vnode.state.searchString = newSearch;
    };

    const getAllTexts = documents => {
      const textIdxs = inferTextIndexes(documents);
      const texts = [];
      documents.forEach(document => {
        for (const [field, textProb] of Object.entries(textIdxs)) {
          if (textProb >= 0.6) {
            texts.push(document[field].toString());
          }
        }
      });
      return texts;
    };

    const onSearchSubmit = newSearch => {
      vnode.state.searchString = newSearch;
      vnode.state.documentsSearchMatch = [];
      vnode.state.documentsSearchScore = [];

      if (newSearch !== "") {
        vnode.state.sortOpts = { sortIdx: "__search__", sortDirection: "asc" };
        const searchScores = {};
        vnode.state.searchIndex.search(newSearch).forEach(({ ref, score }) => {
          searchScores[ref] = score;
        });

        vnode.state.documentsSearchMatch = [];
        vnode.state.documentsSearchScore = [];
        for (let idx = 0; idx < vnode.state.allDocuments.length; idx++) {
          vnode.state.documentsSearchMatch.push(searchScores[idx] !== undefined);
          vnode.state.documentsSearchScore.push(searchScores[idx]);
        }
      } else {
        for (let idx = 0; idx < vnode.state.allDocuments.length; idx++) {
          vnode.state.documentsSearchMatch.push(idx);
          vnode.state.documentsSearchScore.push(idx);
        }
      }
      this.rebuildIndex(vnode);

      const phraseCounts =
        newSearch.length > 0
          ? vnode.state.phraseModel.countPhrases(getAllTexts(this.getMatchingDocuments(vnode)))
          : vnode.state.phraseModel.allPhrases();
      vnode.state.currentPhrases = Array.from(phraseCounts)
        .filter(([phrase, count]) => count > 2)
        .sort((left, right) => right[1] - left[1]);
      m.redraw();
    };

    const readDocuments = () => {
      const reader = new FileReader();

      reader.addEventListener("loadend", evt => {
        parse(evt.srcElement.result, { auto_parse: true }, async (err, output) => {
          const [header, ...documents] = output;
          vnode.state.candidateFields = header;
          vnode.state.selectedFields = [0].concat(
            Array.from({ length: header.length - 1 }, (v, k) => k + 1)
          );
          vnode.state.allDocuments = documents;
          vnode.state.fieldTextProbs = inferTextIndexes(documents);
          this.rebuildIndex(vnode);
          m.redraw();
          setTimeout(processImportedDocuments, 50);
        });
      });

      vnode.state.loading = true;
      reader.readAsText(vnode.state.selectedFile);
      m.redraw();
    };

    const processImportedDocuments = (startIdx, batchSize) => {
      startIdx = startIdx || 0;
      batchSize = batchSize || 1000;
      console.debug(`${new Date()} - Processing document batch starting at ${startIdx}...`);

      if (startIdx >= vnode.state.allDocuments.length) {
        console.debug(`${new Date()} - Indexing...`);
        indexDocuments();
        console.debug(`${new Date()} - Done!`);
        return;
      }

      const documents = vnode.state.allDocuments.slice(startIdx, startIdx + batchSize);
      vnode.state.phraseModel.fit(getAllTexts(documents));
      vnode.state.currentPhrases = Array.from(vnode.state.phraseModel.allPhrases()).sort(
        (left, right) => right[1] - left[1]
      );

      m.redraw();
      setTimeout(() => processImportedDocuments(startIdx + batchSize, batchSize));
    };

    const indexDocuments = () => {
      vnode.state.searchIndex = lunr(function() {
        this.ref("__id__");
        vnode.state.selectedFields.forEach(fieldIdx => {
          this.field(fieldIdx.toString());
        });

        vnode.state.allDocuments.forEach(function(line, idx) {
          this.add(
            line.reduce(
              (agg, item, fieldIdx) => {
                agg[fieldIdx.toString()] = item;
                return agg;
              },
              { __id__: idx }
            )
          );
        }, this);
      });
      vnode.state.loading = false;
      m.redraw();
    };

    const onImport = evt => {
      vnode.state.selectedFile = evt.target.files[0];
      const extension = evt.target.files[0].name.split(".").pop();

      if (extension.toLowerCase() !== "csv" && extension !== "") {
        if (extension.toLowerCase() === "xlsx" || extension.toLowerCase() === "xls") {
          vnode.state.fileError =
            `Oops! This is an ${extension.toUpperCase()} file.` +
            "  Try exporting it as a CSV, and trying again!";
        } else {
          vnode.state.fileError = "Oops! This file doesn't look like a CSV.";
        }
        m.redraw();
        return;
      }

      vnode.state.fileError = null;
      readDocuments();
    };

    const onSortAsc = field => {
      if (candidateFields[sortIdx] !== field || sortDirection !== "asc") {
        vnode.state.sortOpts = { sortDirection: "asc", sortIdx: candidateFields.indexOf(field) };
        this.rebuildIndex(vnode);
        m.redraw();
      }
    };

    const onSortDesc = field => {
      if (candidateFields[sortIdx] !== field || sortDirection !== "desc") {
        vnode.state.sortOpts = { sortDirection: "desc", sortIdx: candidateFields.indexOf(field) };
        this.rebuildIndex(vnode);
        m.redraw();
      }
    };

    const onHide = field => {
      vnode.state.selectedFields = vnode.state.selectedFields.filter(
        f => vnode.state.candidateFields[f] !== field
      );
      m.redraw();
    };

    const onUnHideColumns = () => {
      vnode.state.selectedFields = [0].concat(
        Array.from({ length: candidateFields.length - 1 }, (v, k) => k + 1)
      );
      m.redraw();
    };

    const onClearWorkspace = evt => {
      vnode.state.allDocuments = [];
      vnode.state.classifications = [];
      vnode.state.documentsAutoTagged = [];
      vnode.state.documentsSearchMatch = [];
      vnode.state.documentsSearchScore = [];
      vnode.state.documentIndex = [];
      vnode.state.selectedFields = null;
      vnode.state.candidateFields = null;
      vnode.state.selectedFile = undefined;
      vnode.state.searchIndex = undefined;
      vnode.state.showMoreSelected = undefined;
      vnode.state.phraseModel = new PhraseExtractor({ alpha: 3 });
      m.redraw();
    };

    const onPhraseClick = onSearchSubmit;

    const clfTopics = vnode.state.showMoreSelected ? [vnode.state.showMoreSelected] : [];

    let content;
    if (vnode.state.allDocuments.length === 0) {
      content = m(EmptyContentView);
    } else {
      const phraseList = m(PhraseListView, { currentPhrases, onPhraseClick });
      const documentList = m(DocumentListView, {
        sortedDocuments: this.getMatchingDocuments(vnode),
        autotags: vnode.state.autotags,
        selectedFields,
        candidateFields,
        onSortAsc,
        onSortDesc,
        onHide,
        allTopicDetails: clfTopics,
        classifications: vnode.state.documentIndex.map(idx => vnode.state.classifications[idx]),
        fieldTextProbs: vnode.state.fieldTextProbs,
        searchString: vnode.state.searchString,
      });

      content = m("div", { style: "display: flex;" }, [phraseList, documentList]);
    }

    const totalDocuments = vnode.state.allDocuments.length;
    const matchingDocuments = vnode.state.documentIndex.length;

    const onShowMoreSelect = selected => {
      onSearchSubmit("");
      vnode.state.showMoreSelected = selected;
      const { owner, topic } = selected;
      const topicSpec = `${owner.display_name}/${topic.name}:Current:${classifierTier}`;
      vnode.state.classifications = [];
      vnode.state.documentsAutoTagged = [];
      vnode.state.classifying = true;
      const batchSize = 1000;
      const taggableIndexes = Object.entries(vnode.state.fieldTextProbs)
        .filter(([idx, textProb]) => textProb >= 0.8)
        .map(([idx, _textProb]) => idx);

      const classifyMore = startIdx => {
        const endIdx = startIdx + batchSize;
        const batchDocuments = vnode.state.allDocuments.slice(startIdx, endIdx);
        Promise.all(
          taggableIndexes.map(idx =>
            classifyTexts(vnode.attrs.jwt, topicSpec, batchDocuments.map(row => row[idx]))
          )
        ).then(fieldClassifications => {
          if (vnode.state.showMoreSelected === undefined) {
            return;
          }

          for (let docIdx = 0; docIdx < batchDocuments.length; docIdx++) {
            const docClassifications = {};
            let autoTagged = false;
            fieldClassifications.forEach(
              ({ predictions: classifications, pipeline_config, topic_spec_map }, clfIdx) => {
                classifications[docIdx].tagMetadata = {
                  tagger: "Correction",
                  from_file: vnode.state.selectedFile.name,
                  from_file_type: vnode.state.selectedFile.type,
                  csv_column: vnode.state.candidateFields[parseInt(taggableIndexes[clfIdx])],
                  csv_row: startIdx + docIdx,
                  correcting_model_vectorizer_kind: pipeline_config.vectorizer.kind,
                  correcting_model_augmentor_kind: pipeline_config.dataset_augmentors
                    .concat(pipeline_config.vector_augmentors)
                    .map(aug => aug.kind)
                    .join(","),
                };

                let classifiers = {};
                Object.keys(pipeline_config.classifiers).forEach(key => {
                  classifiers[topic_spec_map[key]] = pipeline_config.classifiers[key];
                });
                classifications[docIdx].topicMetadata = classifiers;

                docClassifications[taggableIndexes[clfIdx]] = classifications[docIdx];
                if (!autoTagged && classifications[docIdx].auto_tags.length > 0) {
                  autoTagged = Object.values(classifications[docIdx].topics_present).reduce(
                    (item, agg) => item || agg
                  );
                }
              }
            );

            vnode.state.classifications.push(docClassifications);
            vnode.state.documentsAutoTagged.push(autoTagged);
          }

          const presentTexts = vnode.state.classifications.reduce((texts, colClfs) => {
            for (const clf of Object.values(colClfs)) {
              for (const autotag of clf.auto_tags) {
                if (autotag.present) {
                  // The first option casts a wider net.
                  // texts.push(clf.document_text);
                  // return texts;

                  // The second option is more local to the classification
                  texts.push(clf.document_text.slice(autotag.start_idx, autotag.end_idx));
                }
              }
            }
            return texts;
          }, []);
          const phraseCounts = vnode.state.phraseModel.countPhrases(presentTexts);
          vnode.state.currentPhrases = Array.from(phraseCounts)
            .filter(([phrase, count]) => count > 2)
            .sort((left, right) => right[1] - left[1]);

          if (vnode.state.allDocuments.length > endIdx) {
            classifyMore(endIdx);
          } else {
            vnode.state.classifying = false;
          }

          this.rebuildIndex(vnode);
          m.redraw();
        });
      };

      classifyMore(0);
    };

    const onShowMoreDeselect = evt => {
      vnode.state.showMoreSelected = undefined;
      vnode.state.classifications = [];
      const phraseCounts = vnode.state.phraseModel.allPhrases();
      vnode.state.currentPhrases = Array.from(phraseCounts)
        .filter(([phrase, count]) => count > 2)
        .sort((left, right) => right[1] - left[1]);
      this.rebuildIndex(vnode);
      m.redraw();
    };

    const onShowMoreRefresh = evt => onShowMoreSelect(vnode.state.showMoreSelected);

    return [
      m(DocumentExplorerHeader, {
        me,
        onSearchUpdated,
        onImport,
        onClearWorkspace,
        onSearchSubmit,
        onUnHideColumns,
        selectedFile,
        loading,
        candidateFields,
        selectedFields,
        matchingDocuments,
        totalDocuments,
        fileError: vnode.state.fileError,
        currentSearch: vnode.state.searchString,
        showMoreDropdownAttrs: new ShowMoreDropdownAttrs(
          onShowMoreSelect,
          onShowMoreDeselect,
          onShowMoreRefresh,
          vnode.state.showMoreSelected,
          vnode.state.classifying
        ),
      }),
      m("section.section", m("div.container", content)),
      m(Footer),
    ];
  }
}

class EmptyContentView {
  view(vnode) {
    return m(
      "article.message",
      m("div.message-body", [
        "Click ",
        m("strong", "Import CSV"),
        " above and select a CSV to get started in " +
          "exploring your documents!  Only document tags are saved.",
      ])
    );
  }
}

export const documentExplorerPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async args => {
      document.title = "Taggit · Document Explorer";
      const jwt = await fetchJwt();
      attrs.jwt = jwt;
      const mePromise = loggedInUser(jwt);
      attrs.me = await mePromise;
    },
    render: vnode => {
      return m(DocumentExplorerPageView, Object.assign(attrs, vnode.attrs));
    },
  };
})();
