import m from "mithril";
import { HeaderNav, NavDropdown, PageHeader, PageHeaderExtra } from "./header";
import { Footer } from "./footer";
import { fetchJwt, loggedInUser } from "../account";
import { createTag, getTag, getTopic, getTopicTags, searchTopics, trainModel } from "../api";
import { TagSummaryView } from "./summaries";
import { TopicButtonView } from "./topics";
import { ContentCardView } from "./content_card";
import { idealTextColor } from "../color";
import { analytics } from "../analytics";
import { NotFoundPageView } from "./etc";
import formatDistance from "date-fns/formatDistance";
import differenceInMilliseconds from "date-fns";
import { reloadPage } from "../util";

export const topicTagsResolver = (() => {
  const attrs = {};

  return {
    onmatch: (args, requestedPath) => {
      document.title = `Taggit · ${args.owner}/${args.name} Tags`;
      attrs.present = args.present;
      return loggedInUser().then(me => {
        attrs.me = me || {};
        return fetchJwt().then(jwt =>
          getTopicTags(jwt, args.owner, args.name, args.present).then(result => {
            if (result.length > 0) {
              attrs.topic = result[0].topic;
              attrs.owner = result[0].owner;
            }
            attrs.tags = result;
          })
        );
      });
    },

    render: vnode => {
      return m(TopicTagsPageView, attrs);
    },
  };
})();

export class TagsPresentDropdownView {
  view(vnode) {
    const oncreate = m.route.link;
    const currentValue =
      vnode.attrs.present === undefined
        ? "All"
        : vnode.attrs.present === false
          ? "Negative"
          : "Positive";
    return m(NavDropdown, {
      bordered: true,
      id: vnode.attrs.id,
      buttonContent: vnode => m("span", currentValue),
      dropdownContent: vnode => [
        m("a.dropdown-item", { oncreate, href: window.location.pathname }, "All"),
        m(
          "a.dropdown-item",
          { oncreate, href: window.location.pathname + "?present=true" },
          "Positive"
        ),
        m(
          "a.dropdown-item",
          { oncreate, href: window.location.pathname + "?present=false" },
          "Negative"
        ),
      ],
    });
  }
}

export class TopicTagsPageView {
  view(vnode) {
    const { me, tags, present, topic, owner } = vnode.attrs;

    const href = `/tag-text?topic=${owner.display_name}/${topic.name}`;
    const nonEmptyTags = tags.filter(({ tag }) => tag);

    const content =
      nonEmptyTags.length > 0
        ? m(
            "div",
            nonEmptyTags.map(({ tag, author, topic, owner }) =>
              m(TagSummaryView, { target_tag: tag, topic, owner, acting_user: author })
            )
          )
        : m("div.notification", [
            m("h2.subtitle", "No Tags Found"),
            m(
              "p",
              owner.display_name === me.displayName
                ? [
                    m("span", "No tags found, maybe you should consider "),
                    m("a", { href, oncreate: m.route.link }, "tagging some text"),
                    m("span", "?"),
                  ]
                : [m("span", "No tags found.  Maybe you should fork the topic and tag some text?")]
            ),
          ]);

    return [
      m(PageHeader, {
        me,
        title: [
          TopicButtonView.fromOwnerAndTopic(owner, topic, "medium"),
          m("h1.title.is-inline-block.margin-l-half-em", "Tags"),
        ],
        extras: [
          {
            subtitle: "Type",
            component: m(TagsPresentDropdownView, {
              present,
              id: "tag-present",
              direction: "right",
            }),
          },
        ],
      }),
      m("section.section", m("div.container", content)),
      m(Footer),
    ];
  }
}

export class SingleTagPageView {
  view(vnode) {
    const { target_tag, me } = vnode.attrs;
    const { tag, topic, owner, author } = target_tag;

    return [
      m(PageHeader, { me }),
      m(
        "section.section.container",
        m(TagSummaryView, { target_tag: tag, topic, owner, acting_user: author, hasDelete: true })
      ),
      m(Footer),
    ];
  }
}

export const singleTagPageResolver = (() => {
  const attrs = {};

  return {
    onmatch: async (args, requestedPath) => {
      document.title = `Taggit · Tag ${args.tagId}`;
      const jwt = await fetchJwt();
      const mePromise = loggedInUser();
      const tagPromise = getTag(jwt, args.tagId);

      attrs.me = await mePromise;

      try {
        attrs.target_tag = await tagPromise;
      } catch (e) {
        attrs.target_tag = null;
      }
    },
    render: vnode => {
      if (attrs.target_tag) {
        return m(SingleTagPageView, attrs);
      } else {
        return m(NotFoundPageView, attrs);
      }
    },
  };
})();

export class TopicSelectDropdownView {
  oninit(vnode) {
    vnode.state.expanded = false;
    vnode.state.topicDetails = vnode.attrs.topicDetails;
    vnode.state.searchText = null;
    vnode.state.loading = false;
    vnode.state.searchResults = [];
    vnode.state.autoselect = vnode.attrs.autoselect === undefined ? true : vnode.attrs.autoselect;
    const minimum_access = vnode.attrs.minimumAccess || "read";

    const outsideClickListener = evt => {
      if (!vnode.dom.contains(evt.target)) {
        if (vnode.state.expanded) {
          vnode.state.expanded = false;
          m.redraw();
        }
      }
    };

    document.addEventListener("click", outsideClickListener);

    let promise;
    if (vnode.attrs.topicDetails === undefined) {
      console.warn("No topicDetails provided, fetching...");
      if (vnode.attrs.owner && vnode.attrs.topic) {
        promise = fetchJwt()
          .then(jwt => getTopic(jwt, vnode.attrs.owner, vnode.attrs.topic))
          .then(result => (vnode.state.topicDetails = result));
      } else if (localStorage.getItem("lastTaggedTopic")) {
        const { owner, color, topic } = JSON.parse(localStorage.getItem("lastTaggedTopic"));
        promise = promise = fetchJwt()
          .then(jwt => getTopic(jwt, owner, topic))
          .then(result => (vnode.state.topicDetails = result));
      } else {
        const require_classifier = vnode.attrs.requireClassifier;
        promise = fetchJwt()
          .then(jwt => searchTopics(jwt, { minimum_access, require_classifier }))
          .then(results => (vnode.state.topicDetails = results[0]));
      }
      return promise.then(() => {
        if (vnode.state.autoselect) {
          vnode.attrs.onTopicChange(vnode.state.topicDetails);
          m.redraw();
        }
      });
    } else {
      return Promise.resolve();
    }
  }

  view(vnode) {
    const size = vnode.attrs.size || "small";
    let topic, owner, color, name;
    if (vnode.state.topicDetails) {
      owner = vnode.state.topicDetails.owner.display_name;
      color = vnode.state.topicDetails.topic.color;
      name = vnode.state.topicDetails.topic.name;
    } else if (localStorage.getItem("lastTaggedTopic")) {
      const topicData = JSON.parse(localStorage.getItem("lastTaggedTopic"));
      color = topicData.color;
      name = topicData.topic;
      owner = topicData.owner;
    }
    const minimum_access = vnode.attrs.minimumAccess || "read";
    const require_classifier = vnode.attrs.requireClassifier;

    const onExpand = evt => {
      if (vnode.state.expanded) {
        vnode.state.expanded = false;
        return m.redraw();
      } else {
        vnode.state.loading = true;
        return fetchJwt()
          .then(jwt =>
            searchTopics(jwt, {
              minimum_access,
              searchText: vnode.state.searchText,
              require_classifier,
            })
          )
          .then(results => {
            vnode.state.searchResults = results;
            vnode.state.expanded = !vnode.state.expanded;
            vnode.state.loading = false;
            m.redraw();
          });
      }
    };

    const makeOnTopicSelect = topicDetails => evt => {
      const { topic, owner, topic_meta } = topicDetails;
      localStorage.setItem(
        "lastTaggedTopic",
        JSON.stringify({
          topic: topic.name,
          owner: owner.display_name,
          color: topic.color,
        })
      );
      vnode.state.topicDetails = topicDetails;
      if (typeof vnode.attrs.onTopicChange === "function") {
        vnode.attrs.onTopicChange(topicDetails);
      }
      vnode.state.expanded = false;
      m.redraw();
    };

    const onInput = evt => {
      vnode.state.searchLoading = true;
      return fetchJwt()
        .then(jwt =>
          searchTopics(jwt, { minimum_access, search_text: evt.target.value, require_classifier })
        )
        .then(results => {
          vnode.state.searchResults = results;
          vnode.state.searchLoading = false;
          m.redraw();
        });
    };

    const oninput = evt => {
      clearTimeout(vnode.state.inputTimeout);
      vnode.state.inputTimeout = setTimeout(() => onInput(evt), 300);
    };

    const triggerButtonView = vnode.attrs.button
      ? vnode.attrs.button
      : m(TopicButtonView, {
          name,
          owner,
          color,
          size,
          hasV: true,
          isLoading: vnode.state.loading,
        });

    return m("div.dropdown" + (vnode.state.expanded ? ".is-active" : ""), [
      m("div.dropdown-trigger", { onclick: onExpand }, triggerButtonView),
      m(
        "div.dropdown-menu#topic-select-dropdown",
        m(
          "div.dropdown-content.box",
          { style: "padding: 0.75em;" },
          [
            m(
              "div.field",
              m("div.control.has-icons-left", [
                m(
                  "input.input.is-small.topic-select-input" +
                    (vnode.state.searchLoading ? ".is-loading" : ""),
                  {
                    placeholder: "Search",
                    style: "border-radius: 4px;",
                    oninput,
                  }
                ),
                m("span.icon.is-small.is-left", m("i.mdi.mdi-magnify")),
              ])
            ),
          ]
            .concat(
              vnode.state.searchResults.map(topicDetails => {
                const { topic, owner } = topicDetails;
                return m(
                  "div.field",
                  m(
                    "div.control",
                    m(TopicButtonView, {
                      name: topic.name,
                      owner: owner.display_name,
                      color: topic.color,
                      size: "small",
                      onclick: makeOnTopicSelect(topicDetails),
                    })
                  )
                );
              })
            )
            .concat([
              m("hr.dropdown-divider"),
              m(
                "a.button.is-small.is-primary",
                { href: "/topics/create", target: "_blank" },
                "+ Create Topic"
              ),
            ])
        )
      ),
    ]);
  }
}

export class TaggableTextView {
  oninit(vnode) {
    vnode.state.tagging = vnode.attrs.tagging || false;
    if (vnode.attrs.topicDetails) {
      vnode.state.color = vnode.attrs.topicDetails.topic.color.slice(1);
    } else {
      vnode.state.color = "aa443380";
    }
    const { topicSpec } = vnode.attrs;
    if (topicSpec) {
      vnode.state.owner = topicSpec.split("/")[0];
      vnode.state.topic = topicSpec.split("/")[1];
    }
  }

  doneTagging(vnode) {
    document.getSelection().removeAllRanges();
    vnode.state.tagging = false;
    m.redraw();
  }

  getInnerText(text) {
    if (typeof text !== "string") {
      if (Array.isArray(text)) {
        return text.reduce((agg, s) => agg + this.getInnerText(s), "");
      } else if (text.text) {
        return text.text;
      } else if (text.children) {
        return this.getInnerText(text.children);
      } else if (text.dom) {
        return text.dom.innerText;
      } else {
        return text.innerText;
      }
    } else {
      return text;
    }
  }

  // Here be dragons
  getSelection(vnode) {
    let sel, range;
    try {
      sel = window.getSelection();
      range = sel.getRangeAt(0).cloneRange();
    } catch (e) {
      vnode.state.noSelect = true;
      m.redraw();
      throw "Failed to get selection.";
    }

    const root = vnode.dom.querySelector(".can-select-text");
    if (!root.contains(range.startContainer) && !root.contains(range.endContainer)) {
      vnode.state.noSelect = true;
      m.redraw();
      throw "No selection in tagging area, aborting classification.";
    }

    const tagText = document.getSelection().toString();
    const [prefix, suffix] = root.innerText.split(tagText);

    const text = prefix + tagText + suffix;
    let start = prefix.length;
    let end = start + tagText.length;

    if (start === end) {
      vnode.state.noSelect = true;
      m.redraw();
      throw "Detected selection start and end both at 0, aborting.";
    } else if (start === 0 && end === 1) {
      end = sel.toString().length;
    }

    // Correct for emoji presence, since the selection object counts 2 length per emoji, and our
    // backend treats each emoji as 1 length.
    start = Array.from(text.slice(0, start)).length;
    end = Array.from(text.slice(0, end)).length;

    vnode.state.noSelect = false;
    m.redraw();
    return {
      start_idx: start,
      end_idx: end,
      document_text: text,
    };
  }

  buildTagger(vnode) {
    const { topicDetails } = vnode.attrs;
    const color = vnode.state.color;
    const hasDone = vnode.attrs.hasDone === undefined ? true : vnode.attrs.hasDone;
    const textColor = idealTextColor("#" + vnode.state.color);
    const { topic, owner } = vnode.state;
    const onTag = vnode.attrs.onTag || (() => {});

    const posTag = async event => {
      vnode.state.posTagging = true;
      m.redraw();
      const result = await tag(true);
      vnode.state.posTagging = false;
      m.redraw();
      return result;
    };

    const negTag = async event => {
      vnode.state.negTagging = true;
      m.redraw();
      const result = await tag(false);
      vnode.state.negTagging = false;
      m.redraw();
      return result;
    };

    const tag = async present => {
      const jwt = await fetchJwt();
      const label = `${owner}/${topic}`;
      const value = present ? 1 : 0;
      try {
        const tag = Object.assign(
          { present, tagger: "JavascriptTaggerV2" },
          this.getSelection(vnode)
        );
        const result = await analytics.timeFunction(
          "tag-text",
          { category: "tag-text", label, value },
          () => createTag(jwt, owner, topic, tag)
        );
        document.getSelection().removeAllRanges();
        onTag(tag);
        return result;
      } catch (e) {
        console.warn(e);
        if (present) {
          vnode.state.posTagging = false;
        } else {
          vnode.state.negTagging = false;
        }
        m.redraw();
      }
    };

    const onTopicChange = topicDetails => {
      const { topic, owner } = topicDetails;
      vnode.state.topic = topic.name;
      vnode.state.owner = owner.display_name;
      vnode.state.color = topic.color.slice(1, 7);

      if (typeof vnode.attrs.onTopicChange === "function") {
        vnode.attrs.onTopicChange(topicDetails);
      }
      m.redraw();
    };

    const posTagTitle = "Tag selected text as relevant to this topic.";
    const negTagTitle = "Tag selected text as *not* relevant to this topic.";

    const notLoggedIn = localStorage.getItem("jwt") === null;
    let errorView;
    if (vnode.state.noSelect) {
      errorView = m(
        "p.help.is-danger.is-full-width.margin-b-half-em",
        { style: "width: 100%" },
        "Select the chunk of text relevant to your tagging!"
      );
    }

    const doneButton = m(
      "div.control",
      m(
        "button.button" + (notLoggedIn ? "" : ".is-size-7"),
        {
          onmouseup: () => this.doneTagging(vnode),
          title: "Done tagging.",
          style: notLoggedIn ? "" : "height: 24px;",
        },
        m("i.mdi.mdi-close")
      )
    );

    let taggingBar;
    if (notLoggedIn) {
      taggingBar = m("div.is-flex.margin-b-half-em", [
        m("div.field.has-addons", [
          m(
            "div.control",
            m("button.button.is-static", [
              "You must be logged in to tag.",
              m(
                "a.margin-l-half-em",
                { href: "/login", oncreate: m.route.link, style: "pointer-events: initial;" },
                "Log In"
              ),
            ])
          ),
          doneButton,
        ]),
      ]);
    } else {
      taggingBar = m("div.is-flex", [
        m("div.field.has-addons" + (vnode.state.noSelect ? ".is-marginless" : ""), [
          m(TopicSelectDropdownView, {
            topicDetails,
            owner,
            topic,
            onTopicChange,
            minimumAccess: "read",
          }),
        ]),
        m("div.field.has-addons", { style: "margin-left: 0.5em;" }, [
          m(
            "div.control",
            { style: "margin-right: 0px;" },
            m(
              "button.button.is-success.is-paddingless" +
                (vnode.state.posTagging ? ".is-loading" : ""),
              {
                onclick: posTag,
                title: posTagTitle,
                style: "height: 24px; width: 30px;",
                disabled: notLoggedIn,
              },
              m("i.mdi.mdi-thumb-up")
            )
          ),
          m(
            "div.control",
            { style: "margin-right: 0px;" },
            m(
              "button.button.is-danger.is-paddingless" +
                (vnode.state.negTagging ? ".is-loading" : ""),
              {
                onclick: negTag,
                title: negTagTitle,
                style: "height: 24px; width: 30px;",
                disabled: notLoggedIn,
              },
              m("i.mdi.mdi-thumb-down")
            )
          ),
          hasDone ? doneButton : null,
        ]),
      ]);
    }

    const taggingHeader = vnode.state.tagging
      ? [
          taggingBar,
          errorView,
          m.trust(`<style>
      .text-${color}::selection {
        background-color: #${color};
        color: ${textColor};
      }
      .text-${color} *::selection {
        background-color: #${color};
        color: ${textColor};
      }
      .text-${color}::-moz-selection {
        background-color: #${color};
        color: ${textColor};
      }
      .text-${color} *::-moz-selection {
        background-color: #${color};
        color: ${textColor};
      }
      </style>`),
        ]
      : [];

    return m("div.select-guard.is-flex", { style: "flex-direction: column;" }, [
      m("div", taggingHeader),
      m(
        "span.can-select-text" + (vnode.state.tagging ? ".text-" + color : ""),
        { style: "flex: 1;" },
        typeof vnode.attrs.text === "string" ? m.trust(vnode.attrs.text) : vnode.attrs.text
      ),
    ]);
  }

  view(vnode) {
    const debouncedStartTagging = () => {
      clearTimeout(vnode.state.taggingTimeout);
      vnode.state.taggingTimeout = setTimeout(startTagging, 500);
    };

    const startTagging = () => {
      vnode.state.tagging = true;
      m.redraw();
    };

    const onmouseup = () => {
      if (
        window.getSelection() &&
        window.getSelection().rangeCount > 0 &&
        !window.getSelection().getRangeAt(0).collapsed
      ) {
        debouncedStartTagging();
      }
    };

    const body = this.buildTagger(vnode);
    const rootClass = vnode.state.tagging ? ".tagging" : ".can-tag";
    return m(
      "div.can-tag-container" + rootClass,
      { style: "position: relative;", ondblclick: debouncedStartTagging, onmouseup },
      [
        body,
        m(
          "button.button.start-tag.is-small",
          { onclick: startTagging, title: "Tag or untag this text." },
          m("i.mdi.mdi-tag")
        ),
      ]
    );
  }
}

export const tagTextPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async args => {
      document.title = "Taggit · Tag Text";
      attrs.me = await loggedInUser(await fetchJwt());

      if (attrs.me === null) {
        localStorage.setItem("pendingRedirect", location.pathname + location.search);
        m.route.set("/login");
      }
    },
    render: vnode => m(TagTextPageView, Object.assign(attrs, vnode.attrs)),
  };
})();

export class TagTextPageView {
  oninit(vnode) {
    vnode.state.tagging = false;
    vnode.state.topicSpec = decodeURIComponent(new URLSearchParams(location.search).get("topic"));
    // TODO just fetch the topic here and provide it to children
  }

  view(vnode) {
    const { me } = vnode.attrs;
    const { topicSpec } = vnode.state;

    const startTagging = evt => {
      vnode.state.tagging = true;
      vnode.state.textToTag = document.getElementById("text-to-tag").value;
      analytics.track("tag-text-start-tagging", {
        category: "tag-text",
        label: topicSpec,
        value: vnode.state.textToTag.split(" ").length,
      });
      m.redraw();
    };

    const tagAnother = evt => {
      vnode.state.tagging = false;
      vnode.state.textToTag = "";
      analytics.track("tag-text-tag-another", { category: "tag-text", label: topicSpec });
      m.redraw();
    };

    const onTopicChange = ({ owner, topic }) => {
      const params = new URLSearchParams(location.search);
      params.set("topic", `${owner.display_name}/${topic.name}`);
      const newUrl =
        location.protocol +
        "//" +
        location.host +
        location.port +
        location.pathname +
        "?" +
        params.toString();
      history.replaceState({}, document.title, newUrl);
    };

    const footerButton = vnode.state.tagging
      ? m("button.button.is-primary", { onclick: tagAnother }, "Tag Another")
      : m("button.button.is-primary", { onclick: startTagging }, "Start Tagging");

    const cardContent = vnode.state.tagging
      ? m(TaggableTextView, {
          tagging: true,
          text: vnode.state.textToTag,
          onTopicChange,
          topicSpec,
          hasDone: false,
        })
      : m("div.field", [
          m("label.heading", "Text to Tag"),
          m("textarea.textarea#text-to-tag", { rows: 10 }),
        ]);

    const content = m(ContentCardView, {
      title: "Tag Text",
      content: cardContent,
      footer: footerButton,
    });

    return [m(PageHeader, { me }), m("section.section", m("div.container", content)), m(Footer)];
  }
}

export const tagDeleteSuccessfulPageProvider = (() => {
  const attrs = {};

  return {
    onmatch: async (args, requestedPath) => {
      document.title = `Taggit · Tag Successfully Deleted`;
      const jwt = await fetchJwt();
      const mePromise = loggedInUser();

      attrs.me = await mePromise;
    },
    render: vnode => {
      return m(TagDeleteSuccessfulPageView, Object.assign(vnode.attrs, attrs));
    },
  };
})();

class TagDeleteSuccessfulPageView {
  view(vnode) {
    const { me, tagTopic } = vnode.attrs;
    const content = [
      m(
        "article.message",
        m(
          "div.message-body",
          m("span", [
            "Tag successfully deleted.",
            tagTopic
              ? m(
                  "a",
                  { href: `/${tagTopic}`, style: "padding-left: 1em;", oncreate: m.route.link },
                  `Return to ${tagTopic}.`
                )
              : null,
          ])
        )
      ),
    ];
    return [m(PageHeader, { me }), m("section.section", m("div.container", content)), m(Footer)];
  }
}

// export const taggingTopicPageProvider = (() => {
//   const attrs = {};
//
//   return {
//     onmatch: async (args, requestedPath) => {
//       document.title = `Taggit · Tagging ${args.owner}/${args.name}`;
//       const jwt = await fetchJwt();
//       const mePromise = loggedInUser();
//       const topicPromise = getTopic(jwt, args.owner, args.name);
//
//       attrs.me = await mePromise;
//
//       if (attrs.me === null) {
//         localStorage.setItem("pendingRedirect", location.pathname + location.search);
//         m.route.set("/login");
//       } else {
//         attrs.topicDetails = await topicPromise;
//       }
//     },
//     render: vnode => {
//       return m(TaggingTopicPageView, Object.assign(vnode.attrs, attrs));
//     },
//   };
// })();

class TaggingTopicHeaderView {
  view(vnode) {
    const { topic, owner, me, topic_meta, scores, entitlements } = vnode.attrs;
    const { color, name, positive_tags, negative_tags } = topic;
    const { max_classifier_tier } = entitlements;

    let basic_trained_at = ((scores || {}).basic || {}).created_at;
    if (basic_trained_at !== undefined) {
      basic_trained_at = formatDistance(new Date(basic_trained_at), new Date(), {
        addSuffix: true,
      });
    } else {
      basic_trained_at = "Never";
    }

    let neural_trained_at = ((scores || {}).neural || {}).created_at;
    if (neural_trained_at !== undefined) {
      neural_trained_at = formatDistance(new Date(neural_trained_at), new Date(), {
        addSuffix: true,
      });
    } else {
      neural_trained_at = "Never";
    }

    let basic_fp = ((scores || {}).basic || {}).validation_false_positives;
    let basic_neg_n = ((scores || {}).basic || {}).validation_neg_n;
    let basic_fp_rate = null;
    if (basic_neg_n > 0) {
      basic_fp_rate = ((100 * basic_fp) / basic_neg_n).toPrecision(3) + "%";
    }

    let neural_fp = ((scores || {}).neural || {}).validation_false_positives;
    let neural_neg_n = ((scores || {}).neural || {}).validation_neg_n;
    let neural_fp_rate = null;
    if (neural_neg_n > 0) {
      neural_fp_rate = ((100 * neural_fp) / neural_neg_n).toPrecision(3) + "%";
    }

    let basic_f1 = ((scores || {}).basic || {}).validation_f1;
    if (basic_f1 !== undefined) {
      basic_f1 = (basic_f1 * 100).toPrecision(3);
    }
    let basic_precision = ((scores || {}).basic || {}).validation_precision;
    if (basic_precision !== undefined) {
      basic_precision = (basic_precision * 100).toPrecision(3);
    }
    let basic_recall = ((scores || {}).basic || {}).validation_recall;
    if (basic_recall !== undefined) {
      basic_recall = (basic_recall * 100).toPrecision(3);
    }

    let neural_f1 = ((scores || {}).neural || {}).validation_f1;
    if (neural_f1 !== undefined) {
      neural_f1 = (neural_f1 * 100).toPrecision(3);
    }
    let neural_precision = ((scores || {}).neural || {}).validation_precision;
    if (neural_precision !== undefined) {
      neural_precision = (neural_precision * 100).toPrecision(3);
    }
    let neural_recall = ((scores || {}).neural || {}).validation_recall;
    if (neural_recall !== undefined) {
      neural_recall = (neural_recall * 100).toPrecision(3);
    }

    return m("header.hero.is-primary", [
      m("div.hero-title", m(HeaderNav, { me })),
      m(
        "div.hero-body",
        m(
          "div.container",
          m("div.columns.is-desktop", [
            m("div.topic-details.column", [
              m("div.topic-name", [
                m("h2.heading", "Topic"),
                m(TopicButtonView, {
                  name,
                  color,
                  size: "large",
                  owner: owner.display_name,
                  hasLink: true,
                }),
              ]),
            ]),
            m("div.topic-extras.column", { style: "flex: 3" }, [
              m("div.topic-statistics", [
                m(PageHeaderExtra, {
                  subtitle: "Total Tags",
                  component: m(
                    "a.title",
                    {
                      href: `/${owner.display_name}/${topic.name}/tags`,
                      oncreate: m.route.link,
                    },
                    positive_tags + negative_tags
                  ),
                }),
                m(PageHeaderExtra, {
                  subtitle: "Positive Tags",
                  component: m(
                    "a.title",
                    {
                      href: `/${owner.display_name}/${topic.name}/tags?present=true`,
                      oncreate: m.route.link,
                    },
                    positive_tags
                  ),
                }),
                m(PageHeaderExtra, {
                  subtitle: "Negative Tags",
                  component: m(
                    "a.title",
                    {
                      href: `/${owner.display_name}/${topic.name}/tags?present=false`,
                      oncreate: m.route.link,
                    },
                    negative_tags
                  ),
                }),
                m(PageHeaderExtra, {
                  subtitle: "Auto Tagger Score",
                  title: [
                    m(
                      "span",
                      {
                        title:
                          basic_trained_at === "Never"
                            ? "No basic auto tagger has been trained yet."
                            : `Basic Classifier Scores
Precision: ${basic_precision}
Recall: ${basic_recall}
F1: ${basic_f1}
False Positive Rate: ${basic_fp_rate}
Trained at: ${basic_trained_at}`,
                      },
                      [
                        m(
                          "span.icon.is-medium",
                          m("i.mdi.mdi-engine-outline", {
                            style:
                              (max_classifier_tier === "neural" ? "opacity: 0.5;" : "") +
                              "font-size:0.75em;",
                          })
                        ),
                        m(
                          "span",
                          { style: max_classifier_tier === "neural" ? "opacity: 0.5" : "" },
                          basic_f1 || "N/A"
                        ),
                      ]
                    ),
                    m(
                      "span",
                      {
                        title:
                          neural_trained_at === "Never"
                            ? "No neural classifier has been trained yet."
                            : `Neural Classifier Scores
Precision: ${neural_precision}
Recall: ${neural_recall}
F1: ${neural_f1}
False Positive Rate: ${neural_fp_rate}
Trained at: ${neural_trained_at}`,
                        style: "margin-left: 0.5em;",
                      },
                      [
                        m(
                          "span.icon.is-medium",
                          m("i.mdi.mdi-brain", {
                            style:
                              (max_classifier_tier === "basic" ? "opacity: 0.5;" : "") +
                              "font-size:0.75em;",
                          })
                        ),
                        m(
                          "span",
                          { style: max_classifier_tier === "basic" ? "opacity: 0.5" : "" },
                          neural_f1 || "N/A"
                        ),
                      ]
                    ),
                    topic_meta.access === "edit" || topic_meta.access === "admin"
                      ? m(
                          "button.button.is-primary.is-inverted.is-outlined.margin-l-half-em" +
                            (vnode.state.training ? ".is-loading" : ""),
                          {
                            title: vnode.state.training
                              ? vnode.state.trainingMessage
                              : "Retrain classifier",
                            onclick: async evt => {
                              if (!vnode.state.training) {
                                vnode.state.training = true;
                                const classificationStarted = new Date();
                                const label = `${owner.display_name}/${topic.name}`;
                                analytics.track("train-model-request", {
                                  category: "train-model",
                                  label,
                                  value: positive_tags + negative_tags,
                                });
                                await trainModel(
                                  await fetchJwt(),
                                  owner.display_name,
                                  topic.name,
                                  "manual-training-request",
                                  max_classifier_tier,
                                  true
                                );
                                analytics.track("train-model-finish", {
                                  category: "train-model",
                                  label,
                                  value: differenceInMilliseconds(
                                    new Date(),
                                    classificationStarted
                                  ),
                                });
                                vnode.state.training = false;
                                reloadPage();
                              }
                            },
                          },
                          m("span.icon.is-small", m("i.mdi.mdi-refresh"))
                        )
                      : null,
                  ],
                }),
              ]),
            ]),
          ])
        )
      ),
    ]);
  }
}

class TaggingTopicPageView {
  view(vnode) {
    const { me, topicDetails } = vnode.attrs;
    if (!topicDetails) {
      return m(NotFoundPageView, vnode.attrs);
    }
    let { topic, labels, owner, topic_meta, scores, entitlements } = topicDetails;

    return [
      m(TaggingTopicHeaderView, {
        me,
        topic,
        owner,
        topic_meta,
        scores,
        entitlements,
      }),
    ];
  }
}
