import { taggitAnalytics } from "../analytics";
import m from "mithril";
import { getEventCounts } from "../api";
import { TAGGIE_HAPPY_SVG, TAGGIE_NORMAL_SVG, TaggieToast } from "./taggie";
import { TopicButtonView } from "./topics";
import { sendVerificationEmail } from "../account";

/**
 * Returns the highest ranked tutorial step, or null if none are triggered.
 * @param {Map<string, number>} counts
 * @param {Map<string, number>} recentCounts
 * @param {Object} attrs
 * @param {Object} routingArgs
 * @param {string} requestedPath
 * @param {User} me
 * @returns {Tutorial || null}
 */
export const buildNextTutorial = (counts, recentCounts, attrs, routingArgs, requestedPath, me) => {
  let maxScore = 0;
  let nextTutorial = null;
  all.forEach(tutorial => {
    const score = tutorial.criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me);
    console.debug(tutorial.constructor.name, score);
    if (score > 0 && score > maxScore) {
      maxScore = score;
      nextTutorial = tutorial;
    }
  });
  return nextTutorial;
};

export class Tutorial {
  constructor(tutorialName, stepNumber) {
    this.tutorialName = tutorialName;
    this.stepNumber = stepNumber;
    this.taggieSvg = TAGGIE_NORMAL_SVG;
  }

  /**
   * Mithril lifecycle method to track viewing of the tutorial.
   */
  oninit(vnode) {
    // Returned promise is ignored because we don't want analytics tracking to block rendering
    this.trackAction("oninit");
  }

  /**
   * Track a tutorial action for analytics and tutorial progress.
   * @param {string} action
   * @returns {Promise}
   */
  async trackAction(action, stepNumber) {
    const label = this.buildAnalyticsLabel(action);
    const category = this.buildAnalyticsCategory();
    return await taggitAnalytics.track(this.buildAnalyticsAction(action, stepNumber), {
      label,
      category,
    });
  }

  /**
   * Builds an analytics action string for counting in Analytics.track.
   * @param {string} action
   * @param {number || null} stepNumber
   * @returns {string}
   */
  buildAnalyticsAction(action, stepNumber) {
    if (stepNumber === undefined) {
      stepNumber = this.stepNumber;
    }
    return `tutorial-${this.tutorialName}-step-${stepNumber}-${action}`;
  }

  /**
   * Builds an analytics category string for counting in Analytics.track.
   * @returns {string}
   */
  buildAnalyticsCategory() {
    return `tutorial-${this.tutorialName}`;
  }

  /**
   * Builds an analytics label string for counting in Analytics.track.
   * @param {string} action
   * @returns {string}
   */
  buildAnalyticsLabel(action) {
    return `step-${this.stepNumber}-${action}`;
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    throw "criteria is not implemented yet.";
  }

  view(vnode) {
    throw "view is not implemented yet.";
  }
}

export class VerifyEmailTutorialStep0 extends Tutorial {
  constructor() {
    super("email_verification", 0);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (!me.fbu.emailVerified) {
      return 1;
    } else {
      0;
    }
  }

  view(vnode) {
    const resendEmail = async evt => {
      console.debug("Resending verification email.");
      vnode.state.loading = true;
      m.redraw();
      await sendVerificationEmail();
      console.debug("Verification email resent.");
      vnode.state.loading = false;
      m.redraw();
    };

    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("span", "Looks like you still need to "),
        m("b", "verify your email"),
        m("span", "! Once you do that, full access will be enabled!"),
      ]),
      m("div.buttons.tutorial-buttons.is-pulled-right", [
        m("button.button.is-primary", { onclick: resendEmail }, "Resend Email"),
      ]),
    ]);
  }
}

export class AutoTagTutorialStep0 extends Tutorial {
  constructor() {
    super("autotagging", 0);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skipAction = this.buildAnalyticsAction("skip", 0);
    const nextAction = this.buildAnalyticsAction("next");
    if (counts[skipAction] === undefined && counts[nextAction] === undefined) {
      return 10 + this.stepNumber - (counts["autotag-request-text"] || 0);
    } else {
      return 0;
    }
  }

  view(vnode) {
    const { onActionEmit } = vnode.attrs;

    const onSkip = evt => {
      return this.trackAction("skip").then(onActionEmit);
    };

    const onNext = evt => {
      return this.trackAction("next").then(onActionEmit);
    };

    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", [m("span", "Howdy! I'm "), m("b", "Taggie"), m("span", "!")]),
        m("p", "I'll show you the major features so you can start auto tagging ASAP!"),
      ]),
      m("div.buttons.tutorial-buttons.is-pulled-right", [
        m("button.button.is-light.skip-tutorial", { onclick: onSkip }, "Skip Tutorial"),
        m("button.button.is-primary", { onclick: onNext }, "Let's Go"),
      ]),
    ]);
  }
}

export class AutoTagTutorialStep1 extends Tutorial {
  constructor() {
    super("autotagging", 1);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skipAction = this.buildAnalyticsAction("skip", 0);
    const nextAction = this.buildAnalyticsAction("next");
    const prevAction = this.buildAnalyticsAction("next").replace(
      this.stepNumber.toString(),
      (this.stepNumber - 1).toString()
    );
    if (
      counts[skipAction] === undefined &&
      counts[nextAction] === undefined &&
      counts[prevAction] > 0
    ) {
      return 10 + this.stepNumber - (counts["autotag-request-text"] || 0);
    } else {
      return 0;
    }
  }

  view(vnode) {
    const onNext = evt => {
      return this.trackAction("next").then(() => {
        m.route.set("/soaxelbrooke/promoters");
      });
    };

    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", [m("span", "Taggit is all about "), m("b", "auto tagging topics"), m("span", ".")]),
        m("p", [
          m("span", "A "),
          m("b", "topic"),
          m(
            "span",
            " is anything you talk about - for instance, this topic by the user soaxelbrooke is about positive feedback:"
          ),
        ]),
        m(
          "p",
          TopicButtonView.from({
            topic: { name: "promoters", color: "rgb(35, 209, 96)" },
            owner: { display_name: "soaxelbrooke" },
            onclick: onNext,
          })
        ),
        m("p", [
          m("b", "Auto Tagging"),
          m(
            "span",
            " is where I predict if a topic is present in some text - data like reviews, tweets, or news."
          ),
        ]),
        m("p", "With auto tagging, you can identify all the mentions of a topic in an instant!"),
        m("p", "Click on the Promoter topic and we'll continue the tour!"),
      ]),
    ]);
  }
}

export class AutoTagTutorialStep2 extends Tutorial {
  constructor() {
    super("autotagging", 2);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skipAction = this.buildAnalyticsAction("skip", 0);
    const prevShown = this.buildAnalyticsAction("oninit", this.stepNumber - 1);
    const nextShown = this.buildAnalyticsAction("oninit", this.stepNumber + 1);

    if (
      counts[skipAction] === undefined &&
      counts[prevShown] > 0 &&
      counts[nextShown] === undefined &&
      requestedPath === "/soaxelbrooke/promoters"
    ) {
      return 10 + this.stepNumber - (counts["autotag-request-text"] || 0);
    } else {
      return 0;
    }
  }

  view(vnode) {
    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", [
          m("span", "Topics contain "),
          m("b", "Tags"),
          m("span", ": positive tags "),
          m("span.tag.icon.is-success", m("i.mdi.mdi-thumb-up")),
          m("span", " are examples of the topic; negative tags "),
          m("span.tag.icon.is-danger", m("i.mdi.mdi-thumb-down")),
          m("span", " are examples of text that's irrelevant to the Topic."),
        ]),
        m(
          "p",
          "These are different than auto tags - they're texts you've labeled to teach me about the topic."
        ),
        m(
          "p",
          "Using these tags, I learn about the Topic, which is how I auto tag new things later.  The more tags a Topic has, the better I auto tag!"
        ),
        m("p", [
          m("span", "Click on the top "),
          m("span", [m("span.icon", m("i.mdi.mdi-memory")), m("b", "Auto Tag")]),
          m("span", " button to see how to auto tag!"),
        ]),
      ]),
    ]);
  }
}

export class AutoTagTutorialStep3 extends Tutorial {
  constructor() {
    super("autotagging", 3);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skipAction = this.buildAnalyticsAction("skip", 0);
    const prevShown = this.buildAnalyticsAction("oninit", this.stepNumber - 1);
    const nextShown = this.buildAnalyticsAction("oninit", this.stepNumber + 1);

    if (
      counts[skipAction] === undefined &&
      counts[prevShown] > 0 &&
      counts[nextShown] === undefined &&
      requestedPath === "/auto-tag/text?topics=soaxelbrooke%2Fpromoters"
    ) {
      return 10 + this.stepNumber - (counts["autotag-request-text"] || 0);
    } else {
      return 0;
    }
  }

  oninit(vnode) {
    super.oninit(vnode);
    const { onActionEmit } = vnode.attrs;
    const analyticsCallbackKey = taggitAnalytics.registerCallback((eventName, eventProperties) => {
      if (eventName === "autotag-request-text") {
        taggitAnalytics.clearCallback(analyticsCallbackKey);
        return this.trackAction("next").then(onActionEmit);
      }
    });
  }

  view(vnode) {
    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", "You can auto tag text, files, or even use our auto tagging API!"),
        m(
          "p",
          "Try typing something positive about your favorite restaurant and clicking the auto tag button!"
        ),
      ]),
    ]);
  }
}

export class AutoTagTutorialStep4 extends Tutorial {
  constructor() {
    super("autotagging", 4);
    this.taggieSvg = TAGGIE_HAPPY_SVG;
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skipAction = this.buildAnalyticsAction("skip", 0);
    const prevCompleted = this.buildAnalyticsAction("next", this.stepNumber - 1);
    const nextShown = this.buildAnalyticsAction("oninit", this.stepNumber + 1);

    if (
      counts[skipAction] === undefined &&
      counts[prevCompleted] > 0 &&
      counts[nextShown] === undefined &&
      requestedPath === "/auto-tag/text?topics=soaxelbrooke%2Fpromoters"
    ) {
      return 10 + this.stepNumber - (counts["autotag-request-text"] || 0);
    } else {
      return 0;
    }
  }

  view(vnode) {
    const { onActionEmit } = vnode.attrs;
    const onNext = evt => {
      return this.trackAction("next").then(onActionEmit);
    };

    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", m("b", "Nice Job!")),
        m(
          "p",
          "You can also correct the auto tags I predict; it's a convenient way to add more tags to the Topic, and helps me be more accurate! But, you need to have edit access to the auto tag's Topic to correct it."
        ),
        m(
          "p",
          "It's helpful if you correct the things I predicted as well as those I didn't - the more tags the better!"
        ),
        m("div.buttons.tutorial-buttons.is-pulled-right", [
          m("button.button.is-primary", { onclick: onNext }, "Next"),
        ]),
      ]),
    ]);
  }
}

export class AutoTagTutorialStep5 extends Tutorial {
  constructor() {
    super("autotagging", 5);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skipAction = this.buildAnalyticsAction("skip", 0);
    const prevCompleted = this.buildAnalyticsAction("next", this.stepNumber - 1);

    if (counts[skipAction] === undefined && counts[prevCompleted] > 0) {
      return 10 + this.stepNumber - (counts["autotag-request-text"] || 0);
    } else {
      return 0;
    }
  }

  view(vnode) {
    const { onActionEmit } = vnode.attrs;
    const onNext = evt => {
      return this.trackAction("complete", 0).then(onActionEmit);
    };

    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", "That's it for the basic intro!"),
        m("p", [
          m("span", "If you'd like to see how to create and tag your own topic, click on the big "),
          m("b", "+"),
          m("span", " at the top, and click "),
          m("b", " Create Topic"),
          m("span", "!"),
        ]),
        m("div.buttons.tutorial-buttons.is-pulled-right", [
          m("button.button.is-primary", { onclick: onNext }, "Done"),
        ]),
      ]),
    ]);
  }
}

export class TopicCreateTutorialStep0 extends Tutorial {
  constructor() {
    super("topic_create", 0);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    const skipAction = this.buildAnalyticsAction("skip", 0);
    const tutorialCompleted = this.buildAnalyticsAction("complete", 0);
    if (
      counts[tutorialCompleted] === undefined &&
      counts[skipAction] === undefined &&
      (counts["create-topic-request"] || 0) < 1 &&
      requestedPath === "/topics/create"
    ) {
      return 5;
    } else {
      return 0;
    }
  }

  oninit(vnode) {
    super.oninit(vnode);
    const { onActionEmit } = vnode.attrs;
    const analyticsCallbackKey = taggitAnalytics.registerCallback((eventName, eventProperties) => {
      if (eventName === "create-topic-request") {
        onActionEmit();
      }
    });
  }

  view(vnode) {
    const { onActionEmit } = vnode.attrs;

    const onSkip = evt => {
      return this.trackAction("skip").then(onActionEmit);
    };

    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", m("b", "A great topic is a specific topic!")),
        m("p", [
          m("span", "The right "),
          m("b", "Color"),
          m("span", " helps you see the topic at a glance when looking at auto tags."),
        ]),
        m("p", [
          m("span", "The "),
          m("b", "Description"),
          m(
            "span",
            " is where you put the specifics: describing what your topic is really about, and providing some specific examples of what it is and isn't."
          ),
        ]),
        m("p", [
          m("b", "Keywords"),
          m(
            "span",
            " are the words people say when talking about your topic. They help me find good examples to tag before I can auto tag!"
          ),
        ]),
        m("p", [m("b", "Create your topic"), m("span", ", and we'll continue on to tagging it!")]),
      ]),
      m("div.buttons.tutorial-buttons.is-pulled-right", [
        m("button.button.is-light.skip-tutorial", { onclick: onSkip }, "Skip Tutorial"),
      ]),
    ]);
  }
}

export class TopicCreateTutorialStep1 extends Tutorial {
  constructor() {
    super("topic_create", 1);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const { topicDetails } = attrs;
    if (topicDetails === undefined) {
      return 0;
    }
    const {
      topicDetails: {
        topic_meta: { access },
      },
    } = attrs;
    const skipAction = this.buildAnalyticsAction("skip", 0);
    const qualifies =
      counts[skipAction] === undefined && counts["create-topic-request"] !== undefined;
    const isTopicPage =
      requestedPath.split("/").length === 3 &&
      routingArgs.owner !== undefined &&
      routingArgs.name !== undefined;
    const shown = counts[this.buildAnalyticsAction("oninit")] !== undefined;
    if (qualifies && isTopicPage && access === "admin" && !shown) {
      return 5;
    } else {
      return 0;
    }
  }

  view(vnode) {
    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", "Here's your topic!"),
        m(
          "p",
          "This page shows you all the important things to know about your topic, including how well its auto tagger is performing."
        ),
        m("p", [
          m("span", "Let's start tagging some examples of your topic by clicking the "),
          m("span.icon", m("i.mdi.mdi-tag-plus.mdi-flip-h")),
          m("b", "Tag"),
          m("span", " button up above!"),
        ]),
      ]),
    ]);
  }
}

export class TopicTaggingTutorialStep0 extends Tutorial {
  constructor() {
    super("topic_tagging", 0);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skip = this.buildAnalyticsAction("skip", 0);
    const next = this.buildAnalyticsAction("next");
    if (counts[skip] === undefined && counts[next] === undefined && isTaggingTopic(requestedPath)) {
      return 5;
    } else {
      return 0;
    }
  }

  view(vnode) {
    const { onActionEmit } = vnode.attrs;

    const onSkip = evt => {
      return this.trackAction("skip").then(onActionEmit);
    };

    const onNext = evt => {
      return this.trackAction("next").then(onActionEmit);
    };

    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", m("b", "Welcome to Topic Tagging!")),
        m("p", [
          m(
            "span",
            "This is the best place to come for adding new tags to your topic - a topic needs at least 10 positive "
          ),
          m("span.tag.icon.is-success", m("i.mdi.mdi-thumb-up")),
          m("span", " tags and 20 negative "),
          m("span.tag.icon.is-danger", m("i.mdi.mdi-thumb-down")),
          m("span", " tags before it can auto tag."),
        ]),
      ]),
      m("div.buttons.tutorial-buttons.is-pulled-right", [
        m("button.button.is-light.skip-tutorial", { onclick: onSkip }, "Skip Tutorial"),
        m("button.button.is-primary", { onclick: onNext }, "Next"),
      ]),
    ]);
  }
}

export class TopicTaggingTutorialStep1 extends Tutorial {
  constructor() {
    super("topic_tagging", 1);
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skip = this.buildAnalyticsAction("skip", 0);
    const next = this.buildAnalyticsAction("next");
    const prev = this.buildAnalyticsAction("next", this.stepNumber - 1);
    if (
      counts[skip] === undefined &&
      counts[next] === undefined &&
      counts[prev] !== undefined &&
      isTaggingTopic(requestedPath)
    ) {
      return 5;
    } else {
      return 0;
    }
  }

  oninit(vnode) {
    super.oninit(vnode);
    const { onActionEmit } = vnode.attrs;
    const analyticsCallbackKey = taggitAnalytics.registerCallback((eventName, eventProperties) => {
      if (eventName === "tag-text-request") {
        taggitAnalytics.clearCallback(analyticsCallbackKey);
        return this.trackAction("next").then(onActionEmit);
      }
    });
  }

  view(vnode) {
    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", [
          m(
            "span",
            "One of the main ways you can tag is by selecting text and marking it as positive "
          ),
          m("span.tag.icon.is-success", m("i.mdi.mdi-thumb-up")),
          m("span", " or negative "),
          m("span.tag.icon.is-danger", m("i.mdi.mdi-thumb-down")),
          m("span", "."),
        ]),
        m(
          "p",
          "To find relevant examples to tag you can search for keywords related to the topic (by default we'll search using the topic keywords)."
        ),
        m("p", [
          m("span", "Try selecting some text and tagging it by clicking "),
          m("span.tag.icon.is-success", m("i.mdi.mdi-thumb-up")),
          m("span", " or "),
          m("span.tag.icon.is-danger", m("i.mdi.mdi-thumb-down")),
          m("span", "!"),
        ]),
      ]),
    ]);
  }
}

export class TopicTaggingTutorialStep2 extends Tutorial {
  constructor() {
    super("topic_tagging", 2);
    this.taggieSvg = TAGGIE_HAPPY_SVG;
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skip = this.buildAnalyticsAction("skip", 0);
    const next = this.buildAnalyticsAction("next");
    const prev = this.buildAnalyticsAction("next", this.stepNumber - 1);
    if (
      counts[skip] === undefined &&
      counts[next] === undefined &&
      counts[prev] !== undefined &&
      isTaggingTopic(requestedPath)
    ) {
      return 4;
    } else {
      return 0;
    }
  }

  view(vnode) {
    const { onActionEmit } = vnode.attrs;

    const onNext = evt => {
      return this.trackAction("next").then(onActionEmit);
    };

    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", m("b", "Great work!")),
        m(
          "p",
          "The more tags, the better; but also, the more diverse tags, the better!  Make sure you tag all different ways of expressing your topic, so I can auto tag all of them!"
        ),
        m("p", [
          m("b", "Let's see if we can get to 100 tags for this topic"),
          m("span", ", that's usually when it gets pretty good!"),
        ]),
      ]),
      m("div.buttons.tutorial-buttons.is-pulled-right", [
        m("button.button.is-primary", { onclick: onNext }, "Next"),
      ]),
    ]);
  }
}

export class TopicTaggingTutorialStep3 extends Tutorial {
  constructor() {
    super("topic_tagging", 3);
    this.taggieSvg = TAGGIE_HAPPY_SVG;
  }

  criteria(counts, recentCounts, attrs, routingArgs, requestedPath, me) {
    const { topicDetails } = attrs;
    if (topicDetails === undefined) {
      return 0;
    }
    const { scores } = topicDetails;

    if (counts[this.buildAnalyticsAction("complete", 0)] !== undefined) {
      return 0;
    }
    const skipped = counts[this.buildAnalyticsAction("skip", 0)] !== undefined;
    const nexted = counts[this.buildAnalyticsAction("next")] !== undefined;
    const lastStepDone =
      counts[this.buildAnalyticsAction("next", this.stepNumber - 1)] !== undefined;
    const shown = counts[this.buildAnalyticsAction("oninit")] !== undefined;
    if (
      !skipped &&
      !nexted &&
      lastStepDone &&
      isTaggingTopic(requestedPath) &&
      scores !== null &&
      !shown
    ) {
      return 3;
    } else {
      return 0;
    }
  }

  view(vnode) {
    return m("div.tutorial-container", [
      m("div.tutorial-content", [
        m("p", m("b", "Auto tagging enabled!")),
        m("p", "Nice work! You can now auto tag with this topic."),
        m("p", [
          m("span", "This also means you can change the "),
          m("b", "Tagging Mode"),
          m("span", " to "),
          m("b", "Auto Tag"),
          m("span", ", which helps you correct my mistakes, and is a much faster way to add tags!"),
        ]),
      ]),
    ]);
  }
}

const isTaggingTopic = requestedPath =>
  requestedPath.split("/").length === 4 && requestedPath.indexOf("/tagging") >= 0;

export const all = [
  new AutoTagTutorialStep0(),
  new AutoTagTutorialStep1(),
  new AutoTagTutorialStep2(),
  new AutoTagTutorialStep3(),
  new AutoTagTutorialStep4(),
  new AutoTagTutorialStep5(),
  new TopicCreateTutorialStep0(),
  new TopicCreateTutorialStep1(),
  new TopicTaggingTutorialStep0(),
  new TopicTaggingTutorialStep1(),
  new TopicTaggingTutorialStep2(),
  new TopicTaggingTutorialStep3(),
  new VerifyEmailTutorialStep0(),
];

/**
 * Add the next appropriate tutorial to attrs, if any.
 * @param {Object} attrs
 * @param {Object} routingArgs
 * @param {string} requestedPath
 * @param {string} jwt
 * @param {User} me
 */
export const addTutorialTaggieToAttrs = async (attrs, routingArgs, requestedPath, jwt, me) => {
  if ((attrs.me || {}).userId === undefined) {
    // No logged in user.
    return;
  }
  /**
   * Emits an analytics action and re-evaluates the next tutorial, updating it on the attrs and
   * redrawing.
   * @param {string} action
   */
  const onActionEmit = action => {
    return addTutorialTaggieToAttrs(attrs, routingArgs, requestedPath, jwt, me).then(() =>
      m.redraw()
    );
  };

  const onDismiss = evt => {
    attrs.tutorial = null;
    m.redraw();
  };

  const eventCountsPromise = getEventCounts(jwt);
  const { counts, recentCounts } = await eventCountsPromise;
  const nextTutorial = buildNextTutorial(
    counts,
    recentCounts,
    attrs,
    routingArgs,
    requestedPath,
    me
  );

  if (nextTutorial) {
    const taggieSvg = nextTutorial.taggieSvg;
    const content = m(nextTutorial.constructor, { onActionEmit });
    attrs.tutorial = m(TaggieToast, { content, taggieSvg, onDismiss });
  } else {
    attrs.tutorial = null;
  }
};
