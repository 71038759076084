import m from "mithril";
import { PageHeader } from "./header";
import { Footer } from "./footer";
import { titleCase } from "../util";

export class NotFoundPageView {
  view(vnode) {
    const { me } = vnode.attrs;

    return [
      m(PageHeader, { me }),
      m(
        "section.section",
        m("div.section", { style: "text-align: center;" }, [
          m("h1.title.has-text-primary", "404"),
          m("p.subtitle", "Page not found."),
        ])
      ),
      m(Footer),
    ];
  }
}

export class DecisionButton {
  view(vnode) {
    const { onclick, action, greyed, greyedReason, inverted } = vnode.attrs;
    const buttonAttrs = {};
    if (greyed) {
      buttonAttrs.style = "opacity: 0.5;";
      buttonAttrs.disabled = true;
      if (greyedReason) {
        buttonAttrs.title = greyedReason;
      }
    } else {
      buttonAttrs.onclick = onclick;
    }
    let icon;
    if (action === "approve") {
      icon = "thumb-up";
    } else if (action === "reject") {
      icon = "thumb-down";
    }
    const invertedClasses = inverted ? ".is-primary.is-inverted.is-outlined" : "";
    return m("button.button" + invertedClasses, buttonAttrs, [
      m("span.icon", m("i.mdi.mdi-" + icon)),
      m("span", titleCase(action)),
    ]);
  }
}

export class SearchDropdown {
  view(vnode) {
    const { onSearchChange, onItemSelect, itemView, inputClasses, options } = vnode.attrs;
    const renderItem = itemView || (item => item);

    return m("div.dropdown" + (options && options.length ? ".is-active" : ""), [
      // Search box
      m("div.field.is-grouped.search-header", [
        m("div.control.has-icons-left" + inputClasses, [
          m("input.input", {
            placeholder: "",
            onkeyup: evt => onSearchChange(evt.target.value),
          }),
          m("span.icon.is-small.is-left", m("i.mdi.mdi-magnify")),
        ]),
      ]),

      // Dropdown content
      options && options.length
        ? m(
            "div.dropdown-menu",
            m(
              "div.dropdown-content",
              options.map(item => {
                const onclick = evt => onItemSelect(item);
                return m("a.dropdown-item", { onclick }, itemView(item));
              })
            )
          )
        : null,
    ]);
  }
}
