import firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from "firebaseui";
import { FN_URL_BASE } from "./config";
import jwt_decode from "jwt-decode";
import { getMe } from "./api";

class User {
  constructor(fbu, me) {
    this.fbu = fbu;
    this.assignMe = this.assignMe.bind(this);
    this.assignMe(me);
  }

  assignMe(me) {
    this.displayName = me.display_name;
    this.firstAndLastName = me.first_and_last_name;
    this.imageUrl = me.image_url;
    this.summary = me.summary;
    this.userId = me.user_id;
    this.classifierTier = me.max_classifier_tier;
    this.maxPrivateTopics = me.max_private_topics;
  }
}

export const loggedInUser = async () => {
  try {
    const fbu = await fetchFirebaseUser();

    if (fbu === null) {
      return null;
    }

    const accessToken = await fbu.getIdToken();
    const jwt = await fetchJwt(accessToken);
    const me = await fetchMe(jwt);
    if (me === null) {
      return null;
    } else {
      return new User(fbu, me);
    }
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const init = callbacks => {
  try {
    if (window.fui === undefined) {
      window.fui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    window.fui.start("#firebaseui-auth-container", {
      callbacks,
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: true,
        },
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInSuccessUrl: "/finish-login",
    });
  } catch (e) {
    console.error(e);
  }
};

let _firebaseUser = null;

export const fetchFirebaseUser = force => {
  if (force !== true && _firebaseUser !== null) {
    return Promise.resolve(_firebaseUser);
  } else {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(_user => {
        _firebaseUser = _user;
        resolve(_user);
      });
    });
  }
};

export const logout = () => {
  localStorage.removeItem("me");
  localStorage.removeItem("jwt");
  return firebase.auth().signOut();
};

/// Re-logs in - useful for actions that require recent firebase login, or when a user needs to log
// in to do something.
export const refreshLogin = async redirectTo => {
  redirectTo = redirectTo || location.pathname + location.search;
  localStorage.setItem("pendingRedirect", redirectTo);
  await logout();
  window.location.pathname = "/login";
};

const tokenValid = token => {
  try {
    const decoded = jwt_decode(token);
    return new Date(decoded.exp * 1000) > new Date();
  } catch (e) {
    return false;
  }
};

export const fetchJwt = async accessToken => {
  let token = localStorage.getItem("jwt");

  if (tokenValid(token)) {
    return token;
  }

  console.info("No valid JWT found, refreshing...");
  if (accessToken === undefined) {
    let user = await fetchFirebaseUser();
    if (user === null) {
      console.warn("User not logged in - returning null JWT.");
      return null;
    }
    accessToken = await user.getIdToken();
  }
  const tokenFetch = await fetch(FN_URL_BASE + "/fetchJwt", {
    method: "POST",
    body: JSON.stringify({ token: accessToken }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(resp => resp.json());
  token = tokenFetch.token;
  localStorage.setItem("jwt", token);
  return token;
};

const fetchMe = async (jwt, forceRefresh) => {
  if (forceRefresh) {
    invalidateMe();
  }
  let me;

  const cachedMe = localStorage.getItem("me") || undefined;
  try {
    me = JSON.parse(cachedMe);
  } catch (e) {
    me = await getMe(jwt);
    if (me === undefined || me === null || me.user_id === undefined) {
      // console.error(`Could not fetch /users/me, got ${me}`);
      return null;
    } else {
      localStorage.setItem("me", JSON.stringify(me));
    }
  }
  if (window.FS) {
    try {
      window.FS.identify(me.user_id, {
        displayName: me.display_name,
        email: me.email,
      });
      window.errorReporter.setUser(me.user_id);
      window.ga("set", "userId", me.user_id);
    } catch (e) {
      // No me found or tag not found
      if (me !== undefined) {
        console.warn(e);
      }
    }
  } else {
    console.debug("FullStory not loaded, not identifying.");
  }
  return me;
};

export const invalidateMe = () => {
  localStorage.removeItem("me");
  localStorage.removeItem("jwt");
};

export const isEmailVerified = async () => {
  let user = await fetchFirebaseUser(true);
  if (user) {
    return user.emailVerified;
  } else {
    throw "No user is logged in.";
  }
};

export const sendVerificationEmail = async () => {
  if (!(await isEmailVerified())) {
    return (await fetchFirebaseUser()).sendEmailVerification();
  } else {
    console.log("Email is already verified, not sending.");
  }
};

export const applyFirebaseActionCode = actionCode => {
  return firebase.auth().applyActionCode(actionCode);
};

export const fetchEmailVerificationToken = async () => {
  let user = await fetchFirebaseUser();
  if (user === null) {
    console.warn("User not logged in - returning null JWT.");
    return null;
  }
  let accessToken = await user.getIdToken();
  return await fetch(FN_URL_BASE + "/verifyEmail", {
    method: "POST",
    body: JSON.stringify({ token: accessToken }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(resp => resp.text());
};

export const base_account_name = me => `${me.userId}_base_account`;
