import { classifyTexts, getTaggableData, getTopic } from "../api";
import { fetchJwt, loggedInUser } from "../account";
import { TaggingTopicHeader } from "./header";
import m from "mithril";
import { TaggableTextView } from "./tags";
import { Footer } from "./footer";
import { AutoTagTextResultView } from "./autotag";
import { highlightSearchTerms, tokenizeAndStem } from "../util";
import { addTutorialTaggieToAttrs } from "./tutorials";

class TaggingTopicPageBody {
  oninit(vnode) {
    vnode.state.loading = false;
  }
  view(vnode) {
    let {
      taggableData,
      topicSpec,
      onTag,
      onGetMore,
      topicDetails,
      loading,
      keywords,
    } = vnode.attrs;

    const onGetMoreClick = evt => {
      vnode.state.loading = true;
      m.redraw();
      onGetMore().then(() => {
        vnode.state.loading = false;
        m.redraw();
      });
    };

    const kindTitles = {
      app_review: "App Review",
      review: "Review",
      tweet: "Tweet",
      news: "News",
      clothing_review: "Clothing Reviews",
    };

    const searchTokens = tokenizeAndStem(keywords);

    let body,
      buttonClass = "";
    const buttonAttrs = { onclick: onGetMoreClick, style: "text-align: right;" };
    if (!loading && taggableData.length === 0) {
      body = m(
        "article.message",
        m("div.message-body", [
          m("strong", "No Results"),
          m(
            "p",
            "Your search matched no results, or there are no more results to fetch.  Try changing your query and trying again."
          ),
        ])
      );
      buttonAttrs.disabled = true;
      buttonAttrs.title = "No more results to fetch. Try a different search.";
    } else if (!loading && taggableData.length > 0) {
      body = m("table.table.is-fullwidth", [
        m("thead", m("tr", [m("th", "Source"), m("th", "Text")])),
        m(
          "tbody",
          taggableData.map(datum => {
            const { text, kind, classification, topicDetails } = datum;

            const onAutoTagCorrect = newTag => {
              // TODO fix contradicting tag corrections
              classification.auto_tags = classification.auto_tags.filter(tag => {
                return (
                  tag.topic !== newTag.topic ||
                  tag.start_idx !== newTag.start_idx ||
                  tag.end_idx !== tag.end_idx
                );
              });
              classification.auto_tags.push(newTag);
              onTag(newTag);
              m.redraw();
            };

            const textElem = classification
              ? m(AutoTagTextResultView, {
                  classification,
                  allTopicDetails: [topicDetails],
                  onAutoTagCorrect,
                  highlightKeywords: searchTokens,
                })
              : m(TaggableTextView, {
                  topicSpec,
                  topicDetails,
                  text: highlightSearchTerms(searchTokens, text),
                  tagging: true,
                  hasDone: false,
                  onTag,
                });
            return m("tr", [m("td", m("span.tag." + kind, kindTitles[kind])), m("td", textElem)]);
          })
        ),
      ]);

      if (taggableData.length < 10) {
        buttonAttrs.disabled = true;
        buttonAttrs.title = "No more results to fetch. Try a different search.";
      }
    } else if (loading) {
      buttonClass = ".is-loading";
      body = m(
        "article.message",
        m("div.message-body", [
          m("span.loader.is-inline-block", { style: "margin-right: 0.5em;" }),
          m("span", "Loading..."),
        ])
      );
    } else {
      body = m("p.is-danger", "Oops, something went wrong!");
    }

    return m("div.container", [
      body,
      m("div.level", [
        m("div.level-left", [
          // TODO put pagination here when CSV import comes
        ]),
        m(
          "div.level-right",
          m("button.button.is-primary" + buttonClass, buttonAttrs, [
            m("span.icon", m("i.mdi.mdi-refresh")),
            m("span", "Get More"),
          ])
        ),
      ]),
    ]);
  }
}

export const taggingTopicPageProvider = (() => {
  if (window.thisSessionTags === undefined) {
    window.thisSessionTags = {};
  }
  const attrs = {
    sortMode: "relevance",
    taggingMode: "standard",
    dataSources: [],
    searchText: null,
    thisSessionTags: window.thisSessionTags || {},
    taggableData: [],
    loading: true,
    pageSize: 10,
    offset: 0,
    searchInputValue: "",
    autoTaggingEnabled: false,
  };
  return {
    onmatch: async (args, requestedPath) => {
      document.title = `Taggit · Tagging Topic ${args.owner}/${args.name}`;

      const jwt = await fetchJwt();
      const mePromise = loggedInUser();
      const topicDetailsPromise = getTopic(jwt, args.owner, args.name);
      attrs.sortMode = args.sortMode !== undefined ? args.sortMode : attrs.sortMode;
      attrs.dataSources =
        args.dataSources !== undefined ? args.dataSources.split(",") : attrs.dataSources;
      const apiResults = await Promise.all([mePromise, topicDetailsPromise]);
      attrs.me = apiResults[0];
      attrs.topicDetails = apiResults[1];

      attrs.autoTaggingEnabled = attrs.topicDetails.scores !== null;

      if (args.taggingMode === undefined) {
        if (attrs.topicDetails.scores === null) {
          // No classifier trained yet, default to standard mode;
          attrs.taggingMode = "standard";
        } else {
          attrs.taggingMode = "autotag";
        }
      } else {
        attrs.taggingMode = args.taggingMode;
      }

      attrs.searchText = args.searchText;
      attrs.searchInputValue = args.searchText;

      attrs.onRefreshTopic = () =>
        getTopic(jwt, args.owner, args.name).then(topicDetails => {
          attrs.topicDetails = topicDetails;
          m.redraw();
          return topicDetails;
        });

      let searchChangeTimeout;
      attrs.onSearchChange = searchText => {
        clearTimeout(searchChangeTimeout);
        attrs.searchInputValue = searchText;
        searchChangeTimeout = setTimeout(() => {
          m.redraw();
        }, 300);
      };

      attrs.onSearchSubmit = searchText => {
        attrs.searchText = searchText;
        attrs.offset = 0;
      };

      const { owner, topic } = attrs.topicDetails;
      const classifierTier = (attrs.me || {}).classifierTier || "basic";
      const topicSpec = `${owner.display_name}/${topic.name}:Current:${classifierTier}`;

      attrs.onRefreshTopic = () =>
        getTopic(jwt, args.owner, args.name).then(topicDetails => {
          attrs.topicDetails = topicDetails;
          m.redraw();
          return topicDetails;
        });

      const searchText = attrs.searchText ? attrs.searchText : attrs.topicDetails.topic.keywords;
      getTaggableData(
        jwt,
        args.owner,
        args.name,
        searchText,
        10,
        window.sessionId,
        attrs.offset,
        attrs.dataSources
      ).then(async data => {
        if (attrs.taggingMode !== "standard") {
          // If this is auto tag mode, build taggableData from predictions
          const texts = data.map(({ text }) => text);
          if (texts.length === 0) {
            attrs.loading = false;
            attrs.taggableData = [];
            m.redraw();
            return;
          }
          const { pipeline_config, predictions, topic_spec_map } = await classifyTexts(
            jwt,
            [topicSpec],
            texts
          );
          predictions.forEach((classification, idx) => {
            let classifiers = {};
            Object.keys(pipeline_config.classifiers).forEach(key => {
              classifiers[topic_spec_map[key]] = pipeline_config.classifiers[key];
            });
            classification.topicMetadata = classifiers;

            classification.tagMetadata = {
              tagger: "Correction",
              from_file: "TaggingTopic",
              csv_column: attrs.dataSources.join(","),
              correcting_model_vectorizer_kind: pipeline_config.vectorizer.kind,
              correcting_model_augmentor_kind: pipeline_config.dataset_augmentors
                .concat(pipeline_config.vector_augmentors)
                .map(aug => aug.kind)
                .join(","),
            };
            data[idx].classification = classification;
            data[idx].topicDetails = attrs.topicDetails;
          });
        } else {
          // Otherwise, add topicDetails to each taggable data
          data.forEach(datum => {
            datum.topicDetails = attrs.topicDetails;
          });
        }
        attrs.taggableData = data;
        attrs.loading = false;
        await addTutorialTaggieToAttrs(attrs, args, requestedPath, jwt, attrs.me);
        m.redraw();
      });
    },
    render: vnode => {
      const {
        topicDetails,
        taggingMode,
        onRefreshTopic,
        autoTaggingEnabled,
        sortMode,
        dataSources,
        thisSessionTags,
        me,
        searchInputValue,
        loading,
      } = attrs;

      const {
        topic,
        labels,
        owner,
        topic_meta,
        training_eligibility,
        smoothed_scores,
        scores,
        entitlements,
        recent_positive_tags,
      } = topicDetails;

      const onDataSourcesChanged = newDataSources => {
        attrs.dataSources = newDataSources;
        return updateData();
      };

      const onTag = tag => {
        attrs.thisSessionTags[topic.topic_id] = (attrs.thisSessionTags[topic.topic_id] || 0) + 1;
        window.thisSessionTags = attrs.thisSessionTags;
        attrs.onRefreshTopic();
      };

      const updateData = async () => {
        const newUrlParams = {};
        if (attrs.taggingMode) {
          newUrlParams.taggingMode = attrs.taggingMode;
        }
        if (attrs.offset > 0) {
          newUrlParams.offset = attrs.offset;
        }
        if (attrs.searchText) {
          newUrlParams.searchText = attrs.searchText;
        }
        if (Array.isArray(attrs.dataSources) && attrs.dataSources.length > 0) {
          newUrlParams.dataSources = attrs.dataSources.join(",");
        }
        m.route.set(`/${owner.display_name}/${topic.name}/tagging`, newUrlParams, {
          replace: true,
        });
        attrs.taggableData = [];
        attrs.loading = true;
        m.redraw();
      };

      const onSearchSubmit = searchText => {
        attrs.onSearchSubmit(searchText);
        return updateData();
      };

      const onTaggingModeChanged = newMode => {
        attrs.taggingMode = newMode;
        return updateData();
      };

      const onGetMore = () => {
        attrs.offset += attrs.pageSize;
        return updateData();
      };

      const onTopicChange = ({ topic, owner }) => {
        attrs.taggableData = [];
        m.redraw();
        m.route.set(`/${owner.display_name}/${topic.name}/tagging`);
      };

      const keywords = attrs.searchText ? attrs.searchText : attrs.topicDetails.topic.keywords;
      return [
        m("div.is-flex", { style: "flex-direction: column; min-height: 100%;" }, [
          m(
            "div",
            { style: "flex: 0 0 auto;" },
            m(TaggingTopicHeader, {
              me,
              topicDetails,
              thisSessionTags,
              dataSources,
              onRefreshTopic,
              onDataSourcesChanged,
              onTopicChange,
              onSearchSubmit,
              searchInputValue,
              taggingMode,
              onTaggingModeChanged,
              autoTaggingEnabled,
            })
          ),
          m(
            "section.section",
            { style: "flex: 1 1 auto; overflow-y: auto;" },
            m(TaggingTopicPageBody, {
              taggableData: attrs.taggableData,
              topicSpec: `${owner.display_name}/${topic.name}`,
              topicDetails,
              onTag,
              onGetMore,
              loading,
              keywords,
            })
          ),
          m(Footer),
        ]),
        window.errorTaggie || attrs.tutorial || null,
      ];
    },
  };
})();
