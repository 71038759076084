import m from "mithril";

export class Logo {
  view(vnode) {
    const width = vnode.attrs.width || 140;
    const height = (width * 42.75) / 140;
    return m.trust(`
    <svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:svg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    width="137.81639"
    height="40"
    viewBox="0 0 36.463918 10.583333"
    version="1.1"
    id="svg3888"
    inkscape:version="0.92.3 (2405546, 2018-03-11)"
    sodipodi:docname="logo_white_beta.svg">
   <defs
      id="defs3882" />
   <sodipodi:namedview
      id="base"
      pagecolor="#ffffff"
      bordercolor="#666666"
      borderopacity="1.0"
      inkscape:pageopacity="0.0"
      inkscape:pageshadow="2"
      inkscape:zoom="16"
      inkscape:cx="60.521003"
      inkscape:cy="16.750109"
      inkscape:document-units="mm"
      inkscape:current-layer="layer1"
      showgrid="true"
      inkscape:window-width="3840"
      inkscape:window-height="2031"
      inkscape:window-x="0"
      inkscape:window-y="55"
      inkscape:window-maximized="1"
      fit-margin-top="2"
      fit-margin-left="2"
      fit-margin-right="2"
      fit-margin-bottom="2"
      units="px">
     <inkscape:grid
        type="xygrid"
        id="grid4774" />
   </sodipodi:namedview>
   <metadata
      id="metadata3885">
     <rdf:RDF>
       <cc:Work
          rdf:about="">
         <dc:format>image/svg+xml</dc:format>
         <dc:type
            rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
         <dc:title />
       </cc:Work>
     </rdf:RDF>
   </metadata>
   <g
      inkscape:label="Layer 1"
      inkscape:groupmode="layer"
      id="layer1"
      transform="translate(-85.292498,-129.4905)">
     <g
        aria-label="taggit"
        style="font-style:normal;font-weight:normal;font-size:10.58333302px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.26458332"
        id="text832"
        transform="matrix(0.8267507,0,0,0.8267507,18.737315,36.081675)">
       <path
          d="m 94.329903,114.96421 v 1.34409 h -0.973666 v 1.11125 h 0.973666 v 2.25425 c 0,1.30175 0.740834,1.94733 1.87325,1.905 0.391583,-0.0106 0.6985,-0.0741 1.068917,-0.22225 l -0.359834,-1.10067 c -0.1905,0.0952 -0.423333,0.15875 -0.624416,0.15875 -0.402167,0 -0.677333,-0.24342 -0.677333,-0.74083 v -2.25425 h 1.449916 V 116.3083 H 95.62107 v -1.48167 z"
          style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;line-height:2;font-family:'Nexa Bold';-inkscape-font-specification:'Nexa Bold, Bold';baseline-shift:baseline;stroke-width:0.26458332;fill:#ffffff"
          id="path834"
          inkscape:connector-curvature="0" />
       <path
          d="m 102.11328,117.01738 c -0.29633,-0.51858 -0.96308,-0.84667 -1.66158,-0.84667 -1.524,-0.0106 -2.719917,0.93134 -2.719917,2.7305 0,1.83092 1.143,2.78342 2.688167,2.77284 0.58208,-0.0106 1.397,-0.30692 1.69333,-0.92075 l 0.0635,0.762 h 1.21709 v -5.21759 h -1.23825 z m -1.54516,0.30692 c 2.032,0 2.032,3.16441 0,3.16441 -0.857253,0 -1.54517,-0.59266 -1.54517,-1.5875 0,-0.99483 0.687917,-1.57691 1.54517,-1.57691 z"
          style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;line-height:2;font-family:'Nexa Bold';-inkscape-font-specification:'Nexa Bold, Bold';baseline-shift:baseline;stroke-width:0.26458332;fill:#ffffff"
          id="path836"
          inkscape:connector-curvature="0" />
       <path
          d="m 108.06459,116.42471 c -0.35983,-0.24341 -0.78317,-0.29633 -1.19592,-0.29633 -1.4605,0 -2.64583,1.016 -2.64583,2.64583 0,1.62984 1.016,2.62467 2.64583,2.62467 0.64559,0 1.32292,0.32808 1.32292,1.04775 0,0.71967 -0.58208,1.12183 -1.32292,1.12183 -0.74083,0 -1.36525,-0.4445 -1.36525,-1.12183 h -1.28058 c 0,1.40758 1.13242,2.32833 2.64583,2.32833 1.51342,0 2.61409,-0.889 2.61409,-2.32833 0,-0.66675 -0.21167,-1.29117 -1.0795,-1.72508 0.85725,-0.39159 1.10066,-1.30175 1.10066,-1.94734 0,-0.5715 -0.14816,-1.09008 -0.55033,-1.54516 l 0.58208,-0.74084 -0.9525,-0.71966 z m -1.19592,0.87842 c 0.74084,0 1.35467,0.5715 1.35467,1.47108 0,0.89959 -0.61383,1.44992 -1.35467,1.44992 -0.75141,0 -1.36525,-0.53975 -1.36525,-1.44992 0,-0.91016 0.61384,-1.47108 1.36525,-1.47108 z"
          style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;line-height:2;font-family:'Nexa Bold';-inkscape-font-specification:'Nexa Bold, Bold';baseline-shift:baseline;stroke-width:0.26458332;fill:#ffffff"
          id="path838"
          inkscape:connector-curvature="0" />
       <path
          d="m 113.91436,116.42471 c -0.35983,-0.24341 -0.78317,-0.29633 -1.19592,-0.29633 -1.4605,0 -2.64583,1.016 -2.64583,2.64583 0,1.62984 1.016,2.62467 2.64583,2.62467 0.64559,0 1.32292,0.32808 1.32292,1.04775 0,0.71967 -0.58208,1.12183 -1.32292,1.12183 -0.74083,0 -1.36525,-0.4445 -1.36525,-1.12183 h -1.28058 c 0,1.40758 1.13242,2.32833 2.64583,2.32833 1.51342,0 2.61409,-0.889 2.61409,-2.32833 0,-0.66675 -0.21167,-1.29117 -1.0795,-1.72508 0.85725,-0.39159 1.10066,-1.30175 1.10066,-1.94734 0,-0.5715 -0.14816,-1.09008 -0.55033,-1.54516 l 0.58208,-0.74084 -0.9525,-0.71966 z m -1.19592,0.87842 c 0.74084,0 1.35467,0.5715 1.35467,1.47108 0,0.89959 -0.61383,1.44992 -1.35467,1.44992 -0.75141,0 -1.36525,-0.53975 -1.36525,-1.44992 0,-0.91016 0.61384,-1.47108 1.36525,-1.47108 z"
          style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;line-height:2;font-family:'Nexa Bold';-inkscape-font-specification:'Nexa Bold, Bold';baseline-shift:baseline;stroke-width:0.26458332;fill:#ffffff"
          id="path840"
          inkscape:connector-curvature="0" />
       <path
          d="m 117.47813,116.27655 v 5.23875 h -1.29116 v -5.23875 z m -1.40758,-1.44992 c 0,1.00542 1.524,1.00542 1.524,0 0,-1.00542 -1.524,-1.00542 -1.524,0 z"
          style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;line-height:2;font-family:'Nexa Bold';-inkscape-font-specification:'Nexa Bold, Bold';baseline-shift:baseline;stroke-width:0.26458332;fill:#ffffff"
          id="path842"
          inkscape:connector-curvature="0" />
       <path
          d="m 119.17593,114.96421 v 1.34409 h -0.97367 v 1.11125 h 0.97367 v 2.25425 c 0,1.30175 0.74083,1.94733 1.87325,1.905 0.39158,-0.0106 0.6985,-0.0741 1.06892,-0.22225 l -0.35984,-1.10067 c -0.1905,0.0952 -0.42333,0.15875 -0.62441,0.15875 -0.40217,0 -0.67734,-0.24342 -0.67734,-0.74083 v -2.25425 h 1.44992 v -1.11125 h -1.43933 v -1.48167 z"
          style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;line-height:2;font-family:'Nexa Bold';-inkscape-font-specification:'Nexa Bold, Bold';baseline-shift:baseline;stroke-width:0.26458332;fill:#ffffff"
          id="path844"
          inkscape:connector-curvature="0" />
     </g>
     <path
        inkscape:connector-curvature="0"
        style="fill:#ffffff;fill-opacity:1;stroke:#000000;stroke-width:0;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill"
        d="m 91.101412,130.87438 -4.303042,4.30305 c -0.286477,0.28647 -0.268935,0.47941 6e-6,0.74835 l 0.584813,0.58477 0.621865,-0.62017 c -0.01268,-0.0462 -0.02014,-0.0937 -0.0223,-0.14152 -0.01361,-0.35427 0.262564,-0.65289 0.616852,-0.66699 0.0654,-0.002 0.130779,0.005 0.193909,0.0223 l 0.575044,-0.57393 c -0.01401,-0.0487 -0.02226,-0.0988 -0.02449,-0.14936 -0.01329,-0.35426 0.263115,-0.65263 0.617396,-0.66644 0.354326,-0.0139 0.652445,0.26202 0.665828,0.61633 0.01329,0.35426 -0.263112,0.65262 -0.617389,0.66643 -0.06578,0.002 -0.131578,-0.005 -0.195026,-0.0229 l -0.451901,0.45134 1.449882,0.001 c 0.107912,-0.19945 0.312923,-0.32736 0.539384,-0.33655 0.354326,-0.0139 0.652444,0.26202 0.665828,0.61633 0.01361,0.35448 -0.262896,0.65319 -0.617397,0.66699 -0.236477,0.009 -0.458531,-0.11287 -0.577829,-0.31706 l -1.649931,-0.001 c -0.11173,0.18476 -0.308758,0.30112 -0.524338,0.30981 -0.06791,0.003 -0.135831,-0.006 -0.201155,-0.0245 l -0.617395,0.61683 1.961768,1.96161 c 0.285293,0.2853 0.474438,0.27392 0.74835,1e-5 l 4.303042,-4.30304 c 0.187086,-0.18709 0.187086,-0.18709 0.18709,-0.56127 l -4e-6,-1.87088 c 5e-6,-0.37419 5e-6,-0.37419 -0.187083,-0.56127 -0.18709,-0.18709 -0.560372,-0.55896 -0.748355,-0.74836 -0.187089,-0.18709 -0.187089,-0.18709 -0.561271,-0.18708 l -1.870883,-1e-5 c -0.374182,1e-5 -0.374182,1e-5 -0.561267,0.18709 z m 1.864003,1.16169 c 0.355867,-0.0136 0.655405,0.26381 0.66917,0.61968 0.01391,0.35607 -0.263545,0.65597 -0.619623,0.66977 -0.356079,0.0139 -0.655999,-0.26351 -0.669802,-0.6196 -0.0139,-0.35628 0.263876,-0.65628 0.620182,-0.66978 z"
        id="path829-3-5-8-0"
        sodipodi:nodetypes="ccccccccccccccccccccccccccccccccccacccca" />
   </g>
 </svg>
    `);
  }
}
