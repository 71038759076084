import { fetch } from "whatwg-fetch";
import m from "mithril";
import { fetchJwt, sendVerificationEmail } from "./account";
import { API_URL_BASE, MLAPI_URL_BASE } from "./config";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import { titleCase } from "./util";

export const watchTopic = (ownerName, topicName) => {
  return fetchJwt().then(jwt =>
    m.request({
      method: "POST",
      url: `${API_URL_BASE}/users/${ownerName}/topics/${topicName}/watches`,
      headers: { Authorization: "Bearer " + jwt },
    })
  );
};

export const unwatchTopic = (ownerName, topicName) => {
  return fetchJwt().then(jwt =>
    m.request({
      method: "DELETE",
      url: `${API_URL_BASE}/users/${ownerName}/topics/${topicName}/watches`,
      headers: { Authorization: "Bearer " + jwt },
    })
  );
};

export const starTopic = (ownerName, topicName) => {
  return fetchJwt().then(jwt =>
    m.request({
      method: "POST",
      url: `${API_URL_BASE}/users/${ownerName}/topics/${topicName}/stars`,
      headers: { Authorization: "Bearer " + jwt },
    })
  );
};

export const unstarTopic = (ownerName, topicName) => {
  return fetchJwt().then(jwt =>
    m.request({
      method: "DELETE",
      url: `${API_URL_BASE}/users/${ownerName}/topics/${topicName}/stars`,
      headers: { Authorization: "Bearer " + jwt },
    })
  );
};

export const forkTopic = (ownerName, topicName) => {
  return fetchJwt().then(jwt =>
    m.request({
      method: "POST",
      url: `${API_URL_BASE}/users/${ownerName}/topics/${topicName}/forks`,
      headers: { Authorization: "Bearer " + jwt },
    })
  );
};

export const labelTopic = (ownerName, topicName, label) => {
  return fetchJwt().then(jwt =>
    m.request({
      method: "POST",
      url: `${API_URL_BASE}/users/${ownerName}/topics/${topicName}/labels/${label}`,
      headers: { Authorization: "Bearer " + jwt },
    })
  );
};

export const unlabelTopic = (ownerName, topicName, label) => {
  return fetchJwt().then(jwt =>
    m.request({
      method: "DELETE",
      url: `${API_URL_BASE}/users/${ownerName}/topics/${topicName}/labels/${label}`,
      headers: { Authorization: "Bearer " + jwt },
    })
  );
};

export const generateTopicTags = (ownerName, topicName) => {
  return fetchJwt().then(jwt =>
    m.request({
      method: "POST",
      url: `${MLAPI_URL_BASE}/generate/tags?topic=${ownerName}/${topicName}`,
      headers: { Authorization: "Bearer " + jwt },
    })
  );
};

export const createUser = (email, display_name, image_url) => {
  return fetchJwt().then(jwt =>
    fetch(`${API_URL_BASE}/users/create`, {
      method: "POST",
      redirect: "follow",
      mode: "cors",
      headers: { Authorization: "Bearer " + jwt, "content-type": "application/json" },
      body: JSON.stringify({ email, display_name, image_url }),
    }).then(async createResponse => {
      await sendVerificationEmail();
      return createResponse;
    })
  );
};

let requestCounterTimeout = null;
let requestPathBuffer = [];

/**
 * Record the paths that requests were sent to so we can identify over-eager API usage.
 *
 * @param {str} path
 */
const recordRequest = path => {
  requestPathBuffer.push({ path, at: new Date() });
  clearTimeout(requestCounterTimeout);
  requestCounterTimeout = setTimeout(() => {
    const durationMs = differenceInMilliseconds(new Date(), requestPathBuffer[0].at);
    console.debug(
      `Made ${requestPathBuffer.length} requests in last ${durationMs} ms:`,
      requestPathBuffer
    );
    requestPathBuffer = [];
  }, 1000);
};

export const request = async (
  path,
  { method, data, jwt, params, isMlApi, defaultResult, onNotOk, urlBase }
) => {
  recordRequest(path);
  method = method || "GET";
  let url = (urlBase || (isMlApi ? MLAPI_URL_BASE : API_URL_BASE)) + path;
  const reqAttrs = { method: method, headers: { "Content-Type": "application/json" } };
  if (jwt) {
    reqAttrs.headers["Authorization"] = "Bearer " + jwt;
  }
  if (data) {
    reqAttrs.body = JSON.stringify(data);
  }
  if (params) {
    let search = new URLSearchParams();
    Object.keys(params || {}).forEach(key => {
      if (params[key] !== undefined && params[key] !== null) {
        if (Array.isArray(params[key])) {
          params[key].forEach(val => search.append(key, val));
        } else {
          search.append(key, params[key]);
        }
      }
    });
    url += "?" + search.toString();
  }
  const start = new Date();
  const response = await fetch(url, reqAttrs);
  if (!response.ok) {
    if (typeof onNotOk === "function") {
      return onNotOk(response);
    } else if (defaultResult === undefined) {
      throw "Failed request with response body: " + (await response.text());
      // return response.text().then(text => {
      //   console.error("Failed request, triggering taggie :(");
      //   window.onerror(text, url, "api.js:143");
      // });
    }
  }

  if (defaultResult !== undefined && response.status === 404) {
    return defaultResult;
  }
  const result = await response.json();
  const apiElapsed = differenceInMilliseconds(new Date(), start);
  console.debug(`${method} ${path} - Took ${apiElapsed} ms`, data);
  return result;
};

export const getUser = (jwt, displayName) =>
  request(`/users/${displayName}`, { jwt, onNotOk: () => null });

export const listUserTopics = (jwt, displayName) =>
  request(`/users/${displayName}/topics`, { jwt });

export const getMe = jwt => request("/users/me", { jwt, onNotOk: () => null });

export const getHome = jwt => request("/home", { jwt });

// Trailing ? is needed because rocket.rs seems to require it even with params parse failing
export const getTopicTags = (jwt, owner, topic, present) =>
  present === undefined
    ? request(`/users/${owner}/topics/${topic}/tags`, { jwt })
    : request(`/users/${owner}/topics/${topic}/tags?present=${present}`, { jwt });

export const getTag = (jwt, tagId) => request(`/tags/${tagId}`, { jwt });

export const searchTopics = (jwt, { minimum_access, search_text, labels, require_classifier }) =>
  request(`/search/topics`, {
    jwt,
    params: {
      minimum_access,
      search_text: search_text !== "" ? search_text : undefined,
      labels,
      require_classifier,
    },
    defaultResult: [],
  });

export const searchTags = (jwt, { minimum_access, search_text, labels }) =>
  request(`/search/tags`, {
    jwt,
    params: { minimum_access, search_text: search_text !== "" ? search_text : undefined, labels },
    defaultResult: [],
  });

export const searchUsers = (jwt, { search_text, labels }) =>
  request(`/search/users`, {
    jwt,
    params: { search_text: search_text !== "" ? search_text : undefined, labels },
    defaultResult: [],
  });

export const searchCounts = (jwt, { search_text, labels }) =>
  request(`/search/counts`, {
    jwt,
    params: {
      search_text: search_text !== "" ? search_text : undefined,
      labels,
      defaultResult: { tags: 0, topics: 0, users: 0 },
    },
  });

export const getTopic = (jwt, owner, topic) => request(`/users/${owner}/topics/${topic}`, { jwt });

export const listHotTopics = jwt => request("/hot-topics", { jwt });

export const createTag = (jwt, owner, topic, tag) => {
  if (owner === undefined) {
    throw "provided undefined owner name in create tag.";
  }
  if (topic === undefined) {
    throw "provided undefined topic name in create tag.";
  }
  return request(`/users/${owner}/topics/${topic}/tags/create`, {
    method: "POST",
    data: Object.assign(
      {
        browser_location_host: location.host,
        browser_location_pathname: location.pathname,
        browser_location_search: location.search,
      },
      tag
    ),
    jwt,
  });
};

export const deleteTag = (jwt, tagId) => request(`/tags/${tagId}`, { method: "DELETE", jwt });

export const follow = (jwt, displayName) =>
  request(`/users/${displayName}/follow`, { method: "POST", jwt });
export const unfollow = (jwt, displayName) =>
  request(`/users/${displayName}/follow`, { method: "DELETE", jwt });

export const listUserActivity = (jwt, displayName) =>
  request(`/users/${displayName}/activity`, { jwt, defaultResult: [] });

export const updateUserImageUrl = (jwt, url) =>
  request("/users/me/image_url", { jwt, data: { url }, method: "POST" });

export const updateUser = (jwt, first_and_last_name, display_name, summary) =>
  request("/users/me", {
    jwt,
    method: "POST",
    data: { display_name, first_and_last_name, summary },
  });

export const deleteAccount = jwt => request("/users/me", { jwt, method: "DELETE" });

export const deleteTopic = (jwt, owner, topic) =>
  request(`/users/${owner}/topics/${topic}`, { jwt, method: "DELETE" });

export const awaitMe = jwt => {
  const startedAt = new Date();
  const maxChecks = 100;
  return new Promise((resolve, reject) => {
    const maybeFulfill = checkNum => {
      request("/users/me", { jwt, defaultResult: null }).then(resp => {
        if (resp !== null) {
          const awaited = differenceInMilliseconds(new Date(), startedAt) / 1000;
          console.debug(`Awaited /users/me training for ${awaited} seconds.`);
          resolve(resp);
        } else if (checkNum > maxChecks) {
          reject(`Timeout waiting for model training of /users/me after ${maxChecks} checks.`);
        } else {
          const timeout = checkNum <= 3 ? 500 : 1500;
          setTimeout(() => {
            maybeFulfill(checkNum + 1);
          }, timeout);
        }
      });
    };
    maybeFulfill(0);
  });
};

export const getTrainingStatus = (jwt, owner, topic) => {
  const checkPath = `/users/${owner}/topics/${topic}/training_requests`;
  return request(checkPath, { jwt, defaultResult: null, onNotOk: () => null });
};

export const awaitTraining = (jwt, owner, topic) => {
  const startedAt = new Date();
  const maxChecks = 3600;
  return new Promise((resolve, reject) => {
    const maybeFulfill = checkNum => {
      getTrainingStatus(jwt, owner, topic).then(resp => {
        if (resp === null) {
          const awaited = differenceInMilliseconds(new Date(), startedAt) / 1000;
          console.debug(`Awaited ${owner}/${topic} training for ${awaited} seconds.`);
          resolve();
        } else if (checkNum > maxChecks) {
          reject(
            `Timeout waiting for model training of` +
              ` ${owner}/${topic} after ${maxChecks} checks.`
          );
        } else {
          const timeout = checkNum <= 3 ? 500 : 3000;
          setTimeout(() => {
            maybeFulfill(checkNum + 1);
          }, timeout);
        }
      });
    };
    maybeFulfill(0);
  });
};

export const trainModel = (jwt, owner, topic, label, tier, shouldAwaitTraining) =>
  request(`/users/${owner}/topics/${topic}/training_requests`, {
    jwt,
    data: { label, tier },
    method: "POST",
  })
    .catch(resp => console.warn(resp))
    .then(resp => {
      if (shouldAwaitTraining === true) {
        return awaitTraining(jwt, owner, topic);
      } else {
        return resp;
      }
    });

export const classifyTexts = (jwt, topics, texts) =>
  request(`/model/predict`, {
    jwt,
    isMlApi: true,
    params: { topic: topics },
    data: { texts: texts.map(text => text.toString()) },
    method: "POST",
    // TODO: handle untrained classifier.  What if the user isn't allowed to train it?
    // onNotOk: async (response) => {
    //   if (response.status === 423) {
    //     console.info("No classifier available, waiting for it to finish training...");
    //     await Promise.all(topics.map(topicSpec => awaitTraining(jwt, ...topicSpec.split('/'))));
    //     return await classifyTexts(jwt, topics, texts);
    //   } else {
    //     console.error("Unexpected classify response:");
    //     console.error(response);
    //   }
    // }
  });

export const listStars = (jwt, owner, topic) =>
  request(`/users/${owner}/topics/${topic}/stars`, { jwt, defaultResult: [] });

export const listForks = (jwt, owner, topic) =>
  request(`/users/${owner}/topics/${topic}/forks`, { jwt, defaultResult: [] });

export const listWatches = (jwt, owner, topic) =>
  request(`/users/${owner}/topics/${topic}/watches`, { jwt, defaultResult: [] });

export const createEvents = (jwt, events) =>
  request("/events", { jwt, method: "POST", data: events });

export const getTaggableData = (jwt, owner, topic, textSearch, size, sessionId, offset, kinds) => {
  size = size || 10;
  offset = offset || 0;
  const noPunct = textSearch.split(",").map(term => term.replace(/[^a-zA-Z0-9]/g, " "));
  const query = noPunct
    .map(str =>
      str
        .split(/[ ]+/g)
        .filter(tok => tok !== "")
        .join("<->")
    )
    .join("|");
  // const query = noPunct.split(" ").reduce((agg, tok) => (tok === "" ? agg : agg + "|" + tok), "");
  const strKinds = Array.isArray(kinds) && kinds.length > 0 ? "kind=" + kinds.join(",") : "";
  const strSessionId = sessionId !== undefined ? "session_id=" + sessionId : "";
  return request(
    `/taggable_data?size=${size}&${strKinds}&offset=${offset}&${strSessionId}&text_search=${encodeURIComponent(
      query
    )}`,
    { jwt, defaultResult: [] }
  );
};

const EMPTY_COUNTS = { counts: {}, recent_counts: {} };
/**
 * Gets event counts for current user.
 * @param jwt
 * @returns {Promise<{counts, recentCounts} | never>}
 */
export const getEventCounts = jwt =>
  request("/users/me/event_counts", {
    jwt,
    defaultResult: EMPTY_COUNTS,
    onNotOk: () => EMPTY_COUNTS,
  }).then(({ counts, recent_counts }) => ({ counts, recentCounts: recent_counts }));

export const getTopicTagRequestCounts = (jwt, owner, topic) =>
  request(`/users/${owner}/topics/${topic}/tag-request-counts/ruling`, {
    jwt,
    defaultResult: [
      {
        ruling: "Pending",
        count: 0,
      },
      {
        ruling: "Rejected",
        count: 0,
      },
      {
        ruling: "Approved",
        count: 0,
      },
    ],
  });

export const getTopicTagRequestCountsByUser = (jwt, owner, topic, requestor_name) =>
  request(`/users/${owner}/topics/${topic}/tag-request-counts/users/${requestor_name}/ruling`, {
    jwt,
    defaultResult: [
      {
        ruling: "Pending",
        count: 0,
      },
      {
        ruling: "Rejected",
        count: 0,
      },
      {
        ruling: "Approved",
        count: 0,
      },
    ],
  });

export const getTagRequestCountsByUser = (jwt, requestor_name) =>
  request(`/tag-request-counts/users/${requestor_name}/ruling`, {
    jwt,
    defaultResult: [
      {
        ruling: "Pending",
        count: 0,
      },
      {
        ruling: "Rejected",
        count: 0,
      },
      {
        ruling: "Approved",
        count: 0,
      },
    ],
  });

export const getTopicPendingTagRequestCountsByUser = (jwt, owner, topic) =>
  request(`/users/${owner}/topics/${topic}/pending-tag-request-counts/user`, {
    jwt,
    defaultResult: [],
  });

export const getPendingTagRequestCounts = (jwt, access) =>
  request(`/tag-request-counts/ruling/Pending?access=${access || "edit"}`, { jwt, access });

export const getPendingTagRequestCountsByUser = (jwt, access) =>
  request(`/tag-request-counts/user?judgment=Pending&access=${access || "read"}`, {
    jwt,
    defaultResult: [],
  });

export const getPendingTagRequestCountsByTopic = (jwt, access) =>
  request(`/tag-request-counts/topic?judgment=Pending&access=${access || "read"}`, {
    jwt,
    defaultResult: [],
  });

export const getTagRequests = (jwt, ruling) =>
  request(`/tag-requests/${ruling}?access=edit`, { jwt, defaultResult: [] });

export const getTagRequestsByUser = (jwt, ruling, requestor) =>
  request(`/tag-requests/${titleCase(ruling)}?requestor=${requestor}`, { jwt });

export const getTagRequestsByTopic = (jwt, ruling, owner, topic) =>
  request(`/tag-requests/${titleCase(ruling)}?topic=${owner}/${topic}`, { jwt });

export const getTagRequestsByUserAndTopic = (jwt, ruling, requestor, owner, topic) =>
  request(`/tag-requests/${titleCase(ruling)}?requestor=${requestor}&topic=${owner}/${topic}`, {
    jwt,
  });

export const judgeTagRequest = (jwt, tag_id, decision) =>
  request(`/tag-judgments`, {
    jwt,
    method: "POST",
    data: { type: "TagIds", judgments: [{ tag_id, decision }] },
  });

export const judgeTopicTagRequests = (jwt, topic_id, decision) =>
  request(`/tag-judgments`, {
    jwt,
    method: "POST",
    data: { type: "TopicIds", judgments: [{ topic_id, decision }] },
  });

export const judgeUserTagRequests = (jwt, user_id, decision) =>
  request(`/tag-judgments`, {
    jwt,
    method: "POST",
    data: { type: "UserIds", judgments: [{ user_id, decision }] },
  });

export const judgeUserTopicTagRequests = (jwt, user_id, topic_id, decision) =>
  request(`/tag-judgments`, {
    jwt,
    method: "POST",
    data: { type: "UserTopicIds", judgments: [{ user_id, topic_id, decision }] },
  });

export const listTopicMembers = (jwt, owner, name) =>
  request(`/users/${owner}/topics/${name}/members`, { jwt });

export const getTopicMember = (jwt, owner, name, memberName) =>
  request(`/users/${owner}/topics/${name}/members/${memberName}`, { jwt });

export const postTopicMember = (jwt, owner, name, user_id, access) =>
  request(`/users/${owner}/topics/${name}/members`, {
    jwt,
    method: "POST",
    data: { user_id, access },
  });

export const deleteTopicMember = (jwt, owner, name, memberName) =>
  request(`/users/${owner}/topics/${name}/members/${memberName}`, { jwt, method: "DELETE" });

export const inviteTopicMemberByEmail = (jwt, owner, name, email, access) =>
  request(`/users/${owner}/topics/${name}/invite-member-by-email`, {
    jwt,
    method: "POST",
    data: { email, access },
  });

export const verifyEmail = jwt => request("/verify-email", { jwt, method: "POST" });

// TODO - discover other cards that aren't just tied to their $UID_base_account
export const getAvailableStripeSources = (jwt, userId) =>
  request(`/customers/${userId}_base_account/payment_sources`, { jwt });

export const listPlans = jwt => request(`/users/me/plans`, { jwt });

export const changePlanTo = (jwt, account_name, plan_id) =>
  request(`/customers/${account_name}/plans/${plan_id}`, { method: "PUT", jwt });

export const saveStripeSource = (jwt, customerAccountName, stripeSource) =>
  request(`/customers/${customerAccountName}/payment_sources/${stripeSource.id}`, {
    jwt,
    data: stripeSource,
    method: "POST",
  });

export const deleteStripeSource = (jwt, customerAccountName, stripeSourceId) =>
  request(`/customers/${customerAccountName}/payment_sources/${stripeSourceId}`, {
    jwt,
    method: "DELETE",
  });

export const updateDefaultPaymentSource = (jwt, accountName, stripeSourceId) =>
  request(`/customers/${accountName}/payment_sources/${stripeSourceId}`, { jwt, method: "PUT" });

export const getCustomerTrials = (jwt, accountName) =>
  request(`/customers/${accountName}/trials`, { jwt }).then(({ trials }) => trials);
