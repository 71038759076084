export const LOWER_CASE = true;
export const EMBED_DIM = 300;
export const ALPHA = 0.0001;

export const API_URL_BASE = "https://api.taggit.io/api/v1";
export const MLAPI_URL_BASE = "https://mlapi.taggit.io/api/v1";
export const MKTGAPI_URL_BASE = "https://mlapi.taggit.io/mktg-api/v1";
export const FN_URL_BASE = "https://us-central1-taggit-prod.cloudfunctions.net";

export const GA_TRACKING_ID = "UA-122493390-2";
export const GTM_BODY = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=lbD0CRdUStn6v1Yzz1xMIA&gtm_preview=env-8&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WHR4JG5');`;
export const GTM_NOSCRIPT_SRC = `https://www.googletagmanager.com/ns.html?id=GTM-WHR4JG5&gtm_auth=lbD0CRdUStn6v1Yzz1xMIA&gtm_preview=env-8&gtm_cookies_win=x`;

export const STRIPE_KEY = "pk_live_g8FsMozAudpvV5AYUOFE9Lqo00Sf7r7fJG";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCMConxM1GsYwVaw8XU1ynF-GZxfrIcM9Y",
  authDomain: "taggit-prod.firebaseapp.com",
  databaseURL: "https://taggit-prod.firebaseio.com",
  projectId: "taggit-prod",
  storageBucket: "taggit-prod.appspot.com",
  messagingSenderId: "327390198867",
};
