import m from "mithril";
import { COLORS, LOADING, titleCase } from "../util";
import { Logo } from "./logo";
import { TopicButtonView } from "./topics";
import { fetchJwt, logout } from "../account";
import { awaitTraining, getPendingTagRequestCounts, getTrainingStatus, trainModel } from "../api";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import { analytics } from "../analytics";
import formatDistance from "date-fns/formatDistance";
import { TopicSelectDropdownView } from "./tags";
import { ProgressBar, ProgressBarAnnotation, ProgressSpec } from "./progressbar";
import { autoTagAbility } from "./autotag";
import { UserImageAndName } from "./users";
import { DecisionButton } from "./etc";

export class PageHeader {
  view(vnode) {
    const headerComponents = [];
    const { me } = vnode.attrs;
    if (vnode.attrs.title) {
      headerComponents.push(m("h1.title", vnode.attrs.title));
    }
    if (vnode.attrs.subtitle) {
      headerComponents.push(m("h2.subtitle", vnode.attrs.subtitle));
    }
    if (vnode.attrs.extras) {
      vnode.attrs.extras.forEach(extra => headerComponents.push(m(PageHeaderExtra, extra)));
    }
    return m("header.hero.is-primary", [
      m("div.hero-title", m(HeaderNav, { me })),
      headerComponents.length ? m("div.hero-body", m("div.container", headerComponents)) : null,
    ]);
  }
}

export class HeaderInfoButtonView {
  view(vnode) {
    const { icon, text, number, onTextClick, onNumberClick, disabled, isLoading } = vnode.attrs;

    const textAttrs = {
      onclick: typeof onTextClick === "function" ? onTextClick : undefined,
      href: typeof onTextClick === "string" ? onTextClick : undefined,
      disabled: disabled,
      oncreate: typeof onTextClick === "string" ? m.route.link : undefined,
      style: "border-bottom-left-radius: 4px; border-top-left-radius: 4px;",
    };

    const numberAttrs = {
      onclick: typeof onNumberClick === "function" ? onNumberClick : undefined,
      href: typeof onNumberClick === "string" ? onNumberClick : undefined,
      oncreate: typeof onNumberClick === "string" ? m.route.link : undefined,
      style: "border-bottom-right-radius: 4px; border-top-right-radius: 4px;",
    };

    const numberExists = !(number === null || number === undefined);
    if (!numberExists) {
      textAttrs.style += "border-bottom-right-radius: 4px; border-top-right-radius: 4px;";
    }

    const loading = isLoading ? ".is-loading" : "";

    return m(
      "div.field.has-addons", [
      m(
        "div.control",
        m("a.button.is-inverted.is-primary.is-outlined" + loading, textAttrs, [
          icon ? m("span.icon.is-small", m("i.mdi.mdi-" + icon)) : null,
          m("span", text),
        ]),
      ),
      numberExists
        ? m("div.control",
            m("a.button.is-inverted.is-primary.is-outlined", numberAttrs, number))
        : null
    ]);
  }
}

export class TopicPageHeader {
  oninit(vnode) {
    const { topicDetails, onRefreshTopic, onGenerateTags } = vnode.attrs;
    const { owner, topic } = topicDetails;
    setTimeout(async () => {
      const jwt = await fetchJwt();
      const trainingStatus = await getTrainingStatus(jwt, owner.display_name, topic.name);
      vnode.state.training = trainingStatus !== null && trainingStatus !== undefined;
      if (vnode.state.training) {
        const requestedAt = new Date(trainingStatus[0].requested_at);
        const requestedAgo = formatDistance(requestedAt, new Date(), { addSuffix: true });
        vnode.state.trainingMessage = `Training requested ${requestedAgo}.`;
        vnode.state.training = true;
        m.redraw();
        // TODO: show when the current training was requested, and if it has been started - can't see because of disabled button
        await awaitTraining(await fetchJwt(), owner.display_name, topic.name);
        vnode.state.training = false;
        vnode.state.trainingMessage = null;
        m.redraw();
        onRefreshTopic();
      }
    });
  }

  view(vnode) {
    const {
      me,
      topicDetails,
      onWatchTextClick,
      onWatchNumberClick,
      watched,
      starred,
      forked,
      cantFork,
      onStarTextClick,
      onStarNumberClick,
      onForkTextClick,
      onForkNumberClick,
      onRefreshTopic,
      onGenerateTags,
    } = vnode.attrs;

    return m("header.hero.is-primary", [
      m("div.hero-title", m(HeaderNav, { me })),
      m(
        "div.hero-body",
        m("div.container", [
          m(TopicHeaderTopRow, {
            topicDetails,
            me,
            onRefreshTopic,
          }),
          m(TopicHeaderBottomRow, {
            me,
            topicDetails,
            onWatchTextClick,
            onWatchNumberClick,
            watched,
            starred,
            forked,
            cantFork,
            onStarTextClick,
            onStarNumberClick,
            onForkTextClick,
            onForkNumberClick,
            onGenerateTags,
          }),
        ])
      ),
    ]);
  }
}

class TopicHeaderBottomRow {
  oninit(vnode) {
    vnode.state.generating = false;
  }
  
  view(vnode) {
    const {
      me,
      topicDetails,
      onWatchTextClick,
      onWatchNumberClick,
      watched,
      starred,
      forked,
      cantFork,
      onStarTextClick,
      onStarNumberClick,
      onForkTextClick,
      onForkNumberClick,
      onGenerateTags,
    } = vnode.attrs;
    const { topic, topic_meta, owner, scores } = topicDetails;
    const { watchers, stars, forks } = topic;

    const canEdit = topic_meta.access === "edit" || topic_meta.access === "admin";
    const tagButtonAttrs = {
      disabled: !me.userId,
      title: "You don't have edit access to this topic.",
    };
    if (!tagButtonAttrs.disabled) {
      tagButtonAttrs.href = `/${owner.display_name}/${topic.name}/tagging`;
      delete tagButtonAttrs.disabled;
      tagButtonAttrs.oncreate = m.route.link;
    }

    const { cantAutoTag, cantAutoTagReason } = autoTagAbility(me, scores, topic);

    const canGenerateTags = (me.userId === "UUx9wX3hf7a7AgXVvIjzgYPKQ693") || (me.userId === "viZKaUBDJ5etXm6XTTu4eiAwd7F2");
    const generateTags = evt => {
      if (!vnode.state.generating) {
        vnode.state.generating = true;
        m.redraw();
        onGenerateTags(topicDetails.owner.display_name, topicDetails.topic.name).then(() => {
          vnode.state.generating = false;
          // Navigate to pending tags from taggie
          m.route.set(`/${owner.display_name}/${topic.name}/tag-requests/pending?requestor=taggie`);
        });
      }
    }

    return m("div", [
      m(PageHeaderExtra, {
        subtitle: "Summary",
        component: m(
          "p.is-size-7",
          { title: `Topic ${topic.topic_id} summary`, style: "max-width: 36em;" },
          topic.summary
        ),
      }),
      // m(PageHeaderExtra, {
      //   subtitle: "Watch Topic",
      //   component: m(HeaderInfoButtonView, {
      //     icon: "eye",
      //     text: watched ? "Unwatch" : "Watch",
      //     number: watchers,
      //     onTextClick: onWatchTextClick,
      //     onNumberClick: onWatchNumberClick,
      //   }),
      // }),
      // TODO implement generate tags here
      canGenerateTags ?
        m(PageHeaderExtra, {
          title: vnode.state.generating ? LOADING : "Generate Tags",
          subtitle: "Generate Tags",
          component: m(HeaderInfoButtonView, {
            isLoading: vnode.state.generating,
            icon: "star",
            text: "Generate",
            onTextClick: generateTags,
          }),
        }) :
        m(PageHeaderExtra, {
          subtitle: "Star Topic",
          component: m(HeaderInfoButtonView, {
            icon: "star",
            text: starred ? "Unstar" : "Star",
            number: stars,
            onTextClick: onStarTextClick,
            onNumberClick: onStarNumberClick,
          }),
        }),
      // m(PageHeaderExtra, {
      //   subtitle: "Copy Topic",
      //   component: m(HeaderInfoButtonView, {
      //     icon: "source-branch",
      //     text: "Copy",
      //     number: forks,
      //     onTextClick: onForkTextClick,
      //     onNumberClick: onForkNumberClick,
      //     disabled: forked || cantFork,
      //   }),
      // }),
      m(PageHeaderExtra, {
        subtitle: "Tag",
        component: m("a.button.is-primary.is-inverted.is-outlined", tagButtonAttrs, [
          m("span.icon.is-small", m("i.mdi.mdi-tag-plus.mdi-flip-h")),
          m("span", "Tag"),
        ]),
      }),
      m(PageHeaderExtra, {
        subtitle: "Auto Tag",
        component: m(
          "a.button.is-primary.is-inverted.is-outlined",
          {
            href:
              "/auto-tag/text?topics=" + encodeURIComponent(`${owner.display_name}/${topic.name}`),
            oncreate: m.route.link,
            disabled: cantAutoTag,
            title: cantAutoTagReason,
          },
          [m("span.icon.is-small", m("i.mdi.mdi-memory")), m("span", "Auto Tag")]
        ),
      }),
      canEdit
        ? m(PageHeaderExtra, {
            subtitle: "Settings",
            component: m(
              "a.button.is-primary.is-inverted.is-outlined#topic-settings",
              {
                href: `/${owner.display_name}/${topic.name}/settings`,
                oncreate: m.route.link,
              },
              m("span.icon.is-small", m("i.mdi.mdi-settings"))
            ),
          })
        : null,
    ]);
  }
}

export class PageHeaderExtra {
  view(vnode) {
    const isLoading = vnode.attrs.title === LOADING;
    const loadingClass = isLoading ? ".loader" : "";
    const title = isLoading ? "" : vnode.attrs.title;
    const classExtra = typeof vnode.attrs.class === "string" ? "." + vnode.attrs.class : "";
    return m(
      "div.is-inline-block.is-vertical-aligned-top" + classExtra,
      { style: "margin-right: 1.5em; margin-bottom: 1em;" },
      [
        m("h2.heading", vnode.attrs.subtitle),
        vnode.attrs.component || m("h1.title" + loadingClass, title),
      ]
    );
  }
}

export class NavDropdown {
  oninit(vnode) {
    vnode.state.shown = false;
    vnode.state.id = vnode.attrs.id || "rand-id-" + (Math.random() * 1000000).toString(16);

    const outsideClickListener = evt => {
      if (vnode.state.shown) {
        if (!document.getElementById(vnode.state.id).parentNode.contains(evt.target)) {
          vnode.state.shown = false;
          m.redraw();
        }
      }
    };

    document.addEventListener("click", outsideClickListener);
  }

  view(vnode) {
    const onclick = evt => {
      vnode.state.shown = !vnode.state.shown;
    };

    return m(
      "div.dropdown.is-" +
        (vnode.attrs.direction || "left") +
        (vnode.state.shown ? ".is-active" : ""),
      [
        m(
          "div.dropdown-trigger",
          m(
            "button.button.is-inverted.is-primary.is-outlined#" + vnode.state.id + "-button",
            { onclick, style: vnode.attrs.bordered ? "" : "border-color: transparent;" },
            [vnode.attrs.buttonContent(vnode), m("span.icon.is-small", m("i.mdi.mdi-chevron-down"))]
          )
        ),

        m(
          "div.dropdown-menu#" + vnode.state.id,
          { onclick },
          m("div.dropdown-content", vnode.attrs.dropdownContent(vnode))
        ),
      ]
    );
  }
}

class ProfileDropdown {
  view(vnode) {
    const { numPendingTagRequests } = vnode.attrs;
    const dropdownContent = vnode => [
      m("p.dropdown-item.has-text-grey.is-size-7", [
        "Signed in as ",
        m("span.has-text-weight-bold", (vnode.attrs.me || {}).displayName || "..."),
      ]),
      m(
        "a.dropdown-item",
        { href: `/${vnode.attrs.me.displayName}`, oncreate: m.route.link },
        "Your Profile"
      ),
      m("a.dropdown-item", { href: "/tag-requests", oncreate: m.route.link }, [
        "Pending Tag Requests",
        m(TabCountView, { count: numPendingTagRequests, greyed: numPendingTagRequests === 0 }),
      ]),
      m("a.dropdown-item", { href: "/settings", oncreate: m.route.link }, "Settings"),
      m(
        "a.dropdown-item",
        {
          onclick: evt =>
            logout().then(() => {
              window.location.pathname = "/";
            }),
        },
        "Log Out"
      ),
    ];

    const buttonContent = vnode =>
      m("div", { style: "position: relative" }, [
        vnode.attrs.me.imageUrl
          ? m("img.user-img", { src: vnode.attrs.me.imageUrl })
          : m("span.icon", m("i.mdi.mdi-account.is-large")),
        numPendingTagRequests > 0
          ? m("div.menu-notifier", {
              style: `
          width: 0.8em;
          height: 0.8em;
          background-color: white;
          border-radius: 10000000px;
          position: absolute;
          bottom: -0.3em;
          right: -0.3em;
          border: 2px solid ${COLORS.primary};        
        `,
            })
          : null,
      ]);

    return m(NavDropdown, {
      me: vnode.attrs.me || {},
      direction: "right",
      id: "profile-dropdown",
      buttonContent,
      dropdownContent,
    });
  }
}

class ActionDropdown {
  view(vnode) {
    const oncreate = m.route.link;
    let taggingTopic = localStorage.getItem("lastTaggedTopic");
    let taggingTopicPath;
    if (taggingTopic) {
      const { owner, topic } = JSON.parse(taggingTopic);
      taggingTopicPath = `/${owner}/${topic}/tagging`;
    }

    return m(NavDropdown, {
      me: vnode.attrs.me || {},
      direction: "right",
      id: "action-dropdown",
      buttonContent: vnode =>
        m("span", { style: `font-size: 2em; line-height: 0.5; margin-top: -2px;` }, "+"),
      dropdownContent: vnode => [
        m("a.dropdown-item", { oncreate, href: "/auto-tag/file" }, [
          m("span.icon", m("i.mdi.mdi-memory")),
          m("span", "Auto-Tag"),
        ]),
        taggingTopicPath
          ? m("a.dropdown-item", { oncreate, href: taggingTopicPath }, [
              m("span.icon", m("i.mdi.mdi-tag-plus.mdi-flip-h")),
              m("span", "Start Tagging"),
            ])
          : null,
        m("hr.dropdown-divider"),
        m("a.dropdown-item", { oncreate, href: "/topics/create" }, [
          m("span.icon", m("i.mdi.mdi-folder-plus")),
          m("span", "Create Topic"),
        ]),
        m("hr.dropdown-divider"),
        m("a.dropdown-item", { oncreate, href: "/explorer" }, [
          m("span.icon", m("i.mdi.mdi-file-find")),
          m("span", "Document Explorer"),
        ]),
      ],
    });
  }
}

export class HeaderNav {
  oninit(vnode) {
    vnode.state.search_text = vnode.attrs.search_text;
    vnode.state.numPendingTagRequests = 0;
    fetchJwt()
      .then(jwt => getPendingTagRequestCounts(jwt, "edit"))
      .then(({ count }) => {
        vnode.state.numPendingTagRequests = count;
        m.redraw();
      });
  }

  view(vnode) {
    const { me } = vnode.attrs;
    const { numPendingTagRequests } = vnode.state;
    const searchPath = vnode.attrs.searchPath || "topics";

    const onkeyup = evt => {
      if (evt.key === "Enter") {
        m.route.set(`/search/${searchPath}?search_text=${evt.target.value}`);
      } else {
        vnode.state.search_text = evt.target.value;
      }
    };

    return m(
      "nav.navbar",
      m(
        "div.container",
        m("div.navbar-brand", { style: "width: 100%;" }, [
          m(
            "a.navbar-item",
            { oncreate: m.route.link, href: "/" },
            // See ../../../assets/logo_white.svg
            m(Logo)
          ),
          m("div.navbar-start", [
            m(
              "div.navbar-item.field",
              m("div.control.has-icons-left.inverted-input", [
                m("input.input", {
                  placeholder: "Search Topics",
                  onkeyup,
                  value: vnode.state.search_text,
                }),
                m("span.icon.is-small.is-left", m("i.mdi.mdi-magnify")),
              ])
            ),
          ]),
          m(
            "div.navbar-end",
            { style: "margin-left: auto;" },
            me === null || Object.keys(me).length === 0
              ? m("div.field.navbar-item.buttons", [
                  m(
                    "a.button.is-primary",
                    {
                      style: "background-color: transparent;",
                      href: "/login",
                      oncreate: m.route.link,
                    },
                    "Log In"
                  ),
                  m(
                    "a.button.is-primary.is-outlined.is-inverted",
                    { href: "/login", oncreate: m.route.link },
                    "Sign Up"
                  ),
                ])
              : [
                  m("span.navbar-item.is-inline-block", m(ActionDropdown, { me })),
                  m(
                    "span.navbar-item.is-inline-block",
                    m(ProfileDropdown, { me, numPendingTagRequests })
                  ),
                ]
          ),
        ])
      )
    );
  }
}

class TopicHeaderTopRow {
  oninit(vnode) {
    vnode.state.updateTrainingTimeout = setTimeout(async () => {
      const result = await this.updateTrainingStatus(vnode);
      clearTimeout(vnode.state.updateTrainingTimeout);
      vnode.state.updateTrainingTimeout = null;
      return result;
    });
  }

  onupdate(vnode) {
    if (vnode.state.updateTrainingTimeout === null) {
      vnode.state.updateTrainingTimeout = setTimeout(async () => {
        const result = await this.updateTrainingStatus(vnode);
        clearTimeout(vnode.state.updateTrainingTimeout);
        vnode.state.updateTrainingTimeout = null;
        return result;
      });
    }
  }

  async updateTrainingStatus(vnode) {
    const { owner, topic } = vnode.attrs.topicDetails;
    const jwt = await fetchJwt();
    const trainingStatus = await getTrainingStatus(jwt, owner.display_name, topic.name);
    vnode.state.training = trainingStatus !== null && trainingStatus !== undefined;
    if (vnode.state.training) {
      const requestedAt = new Date(trainingStatus[0].requested_at);
      const requestedAgo = formatDistance(requestedAt, new Date(), { addSuffix: true });
      vnode.state.trainingMessage = `Training requested ${requestedAgo}.`;
      vnode.state.training = true;
      m.redraw();
      // TODO: show when the current training was requested, and if it has been started - can't see because of disabled button
      await awaitTraining(await fetchJwt(), owner.display_name, topic.name);
      vnode.state.training = false;
      vnode.state.trainingMessage = null;
      m.redraw();
      // TODO - don't do full refresh.  Add hook telling parent to update?
      vnode.attrs.onRefreshTopic();
    }
  }

  view(vnode) {
    const { topicDetails, isTopicDropdown, onTopicChange } = vnode.attrs;
    const {
      topic,
      owner,
      topic_meta,
      training_eligibility,
      scores,
      entitlements,
      smoothed_scores,
      pending_tags_count,
    } = topicDetails;
    const { color, name, positive_tags, negative_tags } = topic;
    const { max_classifier_tier } = entitlements;

    let basic_trained_at = ((scores || {}).basic || {}).created_at;
    if (basic_trained_at !== undefined) {
      basic_trained_at = formatDistance(new Date(basic_trained_at), new Date(), {
        addSuffix: true,
      });
    } else {
      basic_trained_at = "Never";
    }

    let neural_trained_at = ((scores || {}).neural || {}).created_at;
    if (neural_trained_at !== undefined) {
      neural_trained_at = formatDistance(new Date(neural_trained_at), new Date(), {
        addSuffix: true,
      });
    } else {
      neural_trained_at = "Never";
    }

    let basic_fp = ((scores || {}).basic || {}).validation_false_positives;
    let basic_neg_n = ((scores || {}).basic || {}).validation_neg_n;
    let basic_fp_rate = null;
    if (basic_neg_n > 0) {
      basic_fp_rate = ((100 * basic_fp) / basic_neg_n).toPrecision(3) + "%";
    }

    let neural_fp = ((scores || {}).neural || {}).validation_false_positives;
    let neural_neg_n = ((scores || {}).neural || {}).validation_neg_n;
    let neural_fp_rate = null;
    if (neural_neg_n > 0) {
      neural_fp_rate = ((100 * neural_fp) / neural_neg_n).toPrecision(3) + "%";
    }

    let basic_f1 = ((scores || {}).basic || {}).validation_f1;
    if (basic_f1 !== undefined) {
      basic_f1 = (basic_f1 * 100).toPrecision(3);
    }
    let basic_precision = ((scores || {}).basic || {}).validation_precision;
    if (basic_precision !== undefined) {
      basic_precision = (basic_precision * 100).toPrecision(3);
    }
    let basic_recall = ((scores || {}).basic || {}).validation_recall;
    if (basic_recall !== undefined) {
      basic_recall = (basic_recall * 100).toPrecision(3);
    }
    let smoothed_basic_f1 = (smoothed_scores || {}).basic;
    if (smoothed_basic_f1 !== undefined) {
      smoothed_basic_f1 = (smoothed_basic_f1 * 100).toPrecision(3);
    }

    let neural_f1 = ((scores || {}).neural || {}).validation_f1;
    if (neural_f1 !== undefined) {
      neural_f1 = (neural_f1 * 100).toPrecision(3);
    }
    let neural_precision = ((scores || {}).neural || {}).validation_precision;
    if (neural_precision !== undefined) {
      neural_precision = (neural_precision * 100).toPrecision(3);
    }
    let neural_recall = ((scores || {}).neural || {}).validation_recall;
    if (neural_recall !== undefined) {
      neural_recall = (neural_recall * 100).toPrecision(3);
    }
    let smoothed_neural_f1 = (smoothed_scores || {}).neural;
    if (smoothed_neural_f1 !== undefined) {
      smoothed_neural_f1 = (smoothed_neural_f1 * 100).toPrecision(3);
    }

    const eligibility = training_eligibility[entitlements.max_classifier_tier || "basic"];
    const {
      eligible,
      enough_tags,
      minimum_tags,
      requestor_training_this_topic_id,
      this_topic_being_trained_by,
    } = eligibility;
    const retrainButtonAttrs = {
      title: "Retrain auto tagger",
      onclick: async evt => {
        if (!vnode.state.training) {
          await this.updateTrainingStatus(vnode);
          if (vnode.state.training) {
            return;
          }
          const classificationStarted = new Date();
          const label = `${owner.display_name}/${topic.name}`;
          analytics.track("train-model-request", {
            category: "train-model",
            label,
            value: positive_tags + negative_tags,
          });
          await trainModel(
            await fetchJwt(),
            owner.display_name,
            topic.name,
            "manual-training-request",
            max_classifier_tier,
            false
          );
          await this.updateTrainingStatus(vnode);
          analytics.track("train-model-finish", {
            category: "train-model",
            label,
            value: differenceInMilliseconds(new Date(), classificationStarted),
          });
          vnode.state.training = false;
          await vnode.attrs.onRefreshTopic();
        }
      },
    };
    if (!eligible || Object.entries(vnode.attrs.me).length === 0) {
      if (!vnode.state.training) {
        // Setting this would stop the button from indicating that the model is currently training.
        retrainButtonAttrs.disabled = true;
      }

      if (topic_meta.access === "read") {
        retrainButtonAttrs.title = "You do not have permission to train this topic.";
      } else if (!enough_tags) {
        retrainButtonAttrs.title = `Not enough tags to train a model.  At least ${minimum_tags} tags are required.`;
      } else if (requestor_training_this_topic_id !== null) {
        retrainButtonAttrs.title =
          "You are training another topic auto tagger already.  You can only train 1 auto tagger at a time.";
      } else if (this_topic_being_trained_by !== null) {
        retrainButtonAttrs.title = "Another user is currently training this topic's auto tagger.";
      }
    }

    const topicButton = isTopicDropdown
      ? m(TopicSelectDropdownView, {
          minimumAccess: "edit",
          topicDetails: { topic, owner },
          onTopicChange,
          size: "large",
        })
      : m(TopicButtonView, {
          name,
          color,
          size: "large",
          owner: owner.display_name,
          hasLink: true,
        });

    return m("div", [
      m(PageHeaderExtra, {
        subtitle: "Tagging Topic",
        component: topicButton,
      }),
      m(PageHeaderExtra, {
        subtitle: "Total Tags",
        component: m(
          "a.title",
          {
            href: `/${owner.display_name}/${topic.name}/tags`,
            oncreate: m.route.link,
          },
          positive_tags + negative_tags
        ),
      }),
      m(PageHeaderExtra, {
        subtitle: "Positive Tags",
        component: m(
          "a.title",
          {
            href: `/${owner.display_name}/${topic.name}/tags?present=true`,
            oncreate: m.route.link,
          },
          positive_tags
        ),
      }),
      m(PageHeaderExtra, {
        subtitle: "Negative Tags",
        component: m(
          "a.title",
          {
            href: `/${owner.display_name}/${topic.name}/tags?present=false`,
            oncreate: m.route.link,
          },
          negative_tags
        ),
      }),
      m(PageHeaderExtra, {
        subtitle: "Pending Tags",
        component: m(
          "a.title",
          {
            href: `/${owner.display_name}/${topic.name}/tag-requests/pending`,
            oncreate: m.route.link,
          },
          pending_tags_count
        ),
      }),
      m(PageHeaderExtra, {
        subtitle: "Auto Tagger Score",
        title: [
          m(
            "span.basic.classifier-score" +
              (max_classifier_tier === "neural" ? "" : ".active-classifier"),
            {
              title:
                basic_trained_at === "Never"
                  ? "No basic auto tagger has been trained yet."
                  : `Basic Auto Tagger Scores
Precision: ${basic_precision}
Recall: ${basic_recall}
F1: ${basic_f1}
Smoothed F1: ${smoothed_basic_f1}
False Positive Rate: ${basic_fp_rate}
Trained at: ${basic_trained_at}`,
            },
            [
              m(
                "span.icon.is-medium",
                m("i.mdi.mdi-engine-outline", { style: "font-size:0.75em;" })
              ),
              m("span", smoothed_basic_f1 || "N/A"),
            ]
          ),
          m(
            "span.neural.classifier-score" +
              (max_classifier_tier === "neural" ? ".active-classifier" : ""),
            {
              title:
                neural_trained_at === "Never"
                  ? "No neural auto tagger has been trained yet."
                  : `Neural Auto Tagger Scores
Precision: ${neural_precision}
Recall: ${neural_recall}
F1: ${neural_f1}
Smoothed F1: ${smoothed_neural_f1}
False Positive Rate: ${neural_fp_rate}
Trained at: ${neural_trained_at}`,
              style: "margin-left: 0.5em;",
            },
            [
              m(
                "span.icon.is-medium",
                m("i.mdi.mdi-brain", {
                  style: "font-size:0.75em;",
                })
              ),
              m("span", smoothed_neural_f1 || "N/A"),
            ]
          ),
          m(
            "button.button.is-primary.is-inverted.is-outlined.margin-l-half-em#retrain-button" +
              (vnode.state.training ? ".is-loading" : ""),
            retrainButtonAttrs,
            m("span.icon.is-small", m("i.mdi.mdi-refresh"))
          ),
        ],
      }),
    ]);
  }
}

class DataSourceDropdown {
  oninit(vnode) {
    vnode.state.active = false;
    vnode.state.id =
      vnode.attrs.id || "rand-id-" + (Math.random() * 1000000).toString(16).split(".")[0];

    const outsideClickListener = evt => {
      if (vnode.state.active) {
        if (!document.getElementById(vnode.state.id).parentNode.contains(evt.target)) {
          vnode.state.active = false;
          m.redraw();
        }
      }
    };

    document.addEventListener("click", outsideClickListener);
  }

  view(vnode) {
    const { dataSources, onDataSourcesChanged, availableSources } = vnode.attrs;

    const onclick = evt => {
      vnode.state.active = !vnode.state.active;
    };

    const dataSourceButtonText =
      Array.isArray(dataSources) &&
      dataSources.length > 0 &&
      dataSources.length < availableSources.length
        ? dataSources
            .map(key => availableSources.filter(src => src.key === key)[0].title)
            .join(", ")
        : "All Kinds";

    const activeClass = vnode.state.active ? ".is-active" : "";

    const content = m(
      "div.dropdown.is-hoverable.datasource-select" +
        activeClass +
        "#" +
        vnode.state.id +
        "-button",
      { style: "cursor: pointer;" },
      [
        m(
          "div.dropdown-trigger",
          { onclick },
          m("button.button.is-primary.is-inverted.is-outlined", [
            m("span", dataSourceButtonText),
            m("span.icon.is-small", m("i.mdi.mdi-chevron-down")),
          ])
        ),
        m(
          "div.dropdown-menu#" + vnode.state.id,
          m(
            "div.dropdown-content",
            availableSources.map(({ title, description, key }) => {
              const selected = (dataSources || []).indexOf(key) >= 0;
              const onclick = evt => {
                const dataSourcesSet = new Set(dataSources || []);
                if (dataSourcesSet.has(key)) {
                  dataSourcesSet.delete(key);
                } else {
                  dataSourcesSet.add(key);
                }
                vnode.state.active = false;
                onDataSourcesChanged(Array.from(dataSourcesSet));
              };

              return m("a.dropdown-item", { onclick, style: selected ? "color: red;" : "" }, [
                m("h2.label", title),
                m("p.has-text-grey", description),
              ]);
            })
          )
        ),
      ]
    );

    return m("div.field", [m("div.control", content)]);
  }
}

class TaggingModeDropdown {
  oninit(vnode) {
    vnode.state.active = false;
    vnode.state.id =
      vnode.attrs.id || "rand-id-" + (Math.random() * 1000000).toString(16).split(".")[0];

    const outsideClickListener = evt => {
      if (vnode.state.active) {
        if (!document.getElementById(vnode.state.id).parentNode.contains(evt.target)) {
          vnode.state.active = false;
          m.redraw();
        }
      }
    };

    document.addEventListener("click", outsideClickListener);
  }

  view(vnode) {
    const { taggingMode, onTaggingModeChanged, autoTaggingEnabled } = vnode.attrs;

    const onclick = evt => {
      vnode.state.active = !vnode.state.active;
    };

    const activeClass = vnode.state.active ? ".is-active" : "";

    const options = [
      {
        title: "Standard",
        description: "Finds relevant example data to tag using keyword search.",
        key: "standard",
        disabled: false,
      },
      {
        title: "Auto Tag",
        description: "Data is auto tagged for you to correct.",
        key: "autotag",
        disabled: !autoTaggingEnabled,
      },
    ];

    const currentSelection = options.filter(option => option.key === taggingMode)[0].title;

    const content = m(
      "div.dropdown.is-hoverable.tagging-mode-select" +
        activeClass +
        "#" +
        vnode.state.id +
        "-button",
      { style: "cursor: pointer;" },
      [
        m(
          "div.dropdown-trigger",
          { onclick },
          m("button.button.is-primary.is-inverted.is-outlined", [
            m("span", currentSelection),
            m("span.icon.is-small", m("i.mdi.mdi-chevron-down")),
          ])
        ),
        m(
          "div.dropdown-menu#" + vnode.state.id,
          m(
            "div.dropdown-content",
            options.map(({ title, description, key, disabled }) => {
              const onclick = evt => {
                if (!disabled) {
                  onTaggingModeChanged(key);
                }
              };

              const anchorParams = {
                onclick,
                style: disabled ? "cursor: not-allowed;" : "",
                title: disabled
                  ? "Auto tagging is enabled once your topic has 10 positive and 20 negative tags."
                  : "",
              };

              return m("a.dropdown-item", anchorParams, [
                m("h2.label" + (disabled ? ".has-text-grey-light" : ""), title),
                m("p.has-text-grey" + (disabled ? ".has-text-grey-light" : ""), description),
              ]);
            })
          )
        ),
      ]
    );

    return m("div.field", [m("div.control", content)]);
  }
}

class TopicTaggingHeaderBottomRow {
  oninit(vnode) {
    vnode.state.searchInputValue = vnode.attrs.searchInputValue || "";
  }

  view(vnode) {
    const {
      thisSessionTags,
      dataSources,
      onDataSourcesChanged,
      searchInputValue,
      onSearchSubmit,
      taggingMode,
      onTaggingModeChanged,
      autoTaggingEnabled,
    } = vnode.attrs;

    const onSearchChange = newSearch => {
      vnode.state.searchInputValue = newSearch;
    };

    const availableSources = [
      { title: "Reviews", description: "General business business reviews.", key: "review" },
      { title: "App Reviews", description: "Reviews for mobile apps.", key: "app_review" },
      { title: "Tweets", description: "Customer support tweets.", key: "tweet" },
      { title: "News Headlines", description: "Headlines from news stories.", key: "news" },
      { title: "Clothing reviews", description: "Reviews for clothing from online stores.", key: "clothing_review" },
    ];

    const onkeyup = evt => {
      vnode.state.currentSearch = evt.target.value;
      if (evt.key === "Enter") {
        onSearchSubmit(evt.target.value);
      } else {
        onSearchChange(evt.target.value);
      }
    };

    const searchBox = m(PageHeaderExtra, {
      subtitle: "Search Documents",
      component: m("div.field.is-grouped.search-header", [
        m("div.control.has-icons-left.inverted-input", [
          m("input.input#keyword-search", {
            placeholder: "",
            onkeyup,
            value: vnode.state.searchInputValue,
          }),
          m("span.icon.is-small.is-left", m("i.mdi.mdi-magnify")),
        ]),
        m(
          "div.control.is-flex",
          { style: "align-items: center;" },
          m("a.delete", {
            onclick: () => {
              onSearchChange("");
              onSearchSubmit("");
            },
          })
        ),
      ]),
    });

    const dataSourceSelector = m(PageHeaderExtra, {
      subtitle: "Data Source",
      component: m(DataSourceDropdown, { dataSources, onDataSourcesChanged, availableSources }),
    });

    const tagsThisSessionIndicator = m(PageHeaderExtra, {
      subtitle: "Tags This Session",
      title: thisSessionTags,
      class: "tags-this-session",
    });

    const taggingModeSelector = m(PageHeaderExtra, {
      subtitle: "Tagging Mode",
      component: m(TaggingModeDropdown, { taggingMode, onTaggingModeChanged, autoTaggingEnabled }),
    });

    return [taggingModeSelector, searchBox, dataSourceSelector, tagsThisSessionIndicator];
  }
}

export class TabCountView {
  view(vnode) {
    const { inverted, greyed } = vnode.attrs;
    const borderColor = greyed ? "whitesmoke" : inverted ? "white" : COLORS.primary;
    const backgroundColor = greyed ? "whitesmoke" : inverted ? "transparent" : COLORS.primary;
    const textColor = greyed ? "grey" : inverted ? "inherit" : "white";
    const style = `
      margin-left: 1em;
      border-radius: 12px;
      border: 1px solid ${borderColor};
      padding: 2px 8px;
      color: ${textColor};
      background-color: ${backgroundColor};
    `;
    return m("span.is-size-7", { style }, vnode.attrs.count);
  }
}

export class TagRequestsPageHeader {
  view(vnode) {
    const {
      me,
      topicDetails,
      requestor,
      requestCounts,
      onApproveAll,
      onRejectAll,
      selected,
    } = vnode.attrs;

    let approveAllButton, rejectAllButton;
    let requestorExtra, topicButton;
    let basePath;

    if (topicDetails) {
      const {
        topic,
        owner,
        topic_meta: { access },
      } = topicDetails;

      basePath = `/${owner.display_name}/${topic.name}/tag-requests/`;

      let canJudge = false;
      if (selected === "pending") {
        if (access === "edit" || access === "admin") {
          canJudge = true;
        }

        let greyed = false;
        let greyedReason;
        if (!(me || {}).userId) {
          greyed = true;
          greyedReason = "You must be logged in to approve or reject tags.";
        } else if (!canJudge) {
          greyed = true;
          greyedReason = "You do not have permission to approve or reject tags for this topic.";
        } else if (requestCounts[0].count === 0) {
          greyed = true;
          greyedReason = "There are no tags to approve or reject.";
        }

        approveAllButton = m(PageHeaderExtra, {
          subtitle: "Approve All",
          component: m(DecisionButton, {
            inverted: true,
            onclick: onApproveAll,
            greyed,
            greyedReason,
            action: "approve",
          }),
        });
        rejectAllButton = m(PageHeaderExtra, {
          subtitle: "Reject All",
          component: m(DecisionButton, {
            inverted: true,
            onclick: onRejectAll,
            greyed,
            greyedReason,
            action: "reject",
          }),
        });
      }

      const delButton =
        requestor === undefined
          ? null
          : m("a.delete", {
              style: "margin-left: 4px;",
              href: `/${requestor.display_name}/tag-requests/${selected}`,
              oncreate: m.route.link,
            });
      topicButton = m(PageHeaderExtra, {
        subtitle: "Tag Requests for Topic",
        component: m(
          "div.is-flex",
          { style: "padding-top: 4px; justify-content: space-between; align-items: center;" },
          [TopicButtonView.from({ topic, owner, hasLink: true, size: "large" }), delButton]
        ),
      });
    } else if (requestor) {
      basePath = `/${requestor.display_name}/tag-requests/`;
    }

    if (requestor) {
      const delButton =
        topicDetails === undefined
          ? null
          : m("a.delete", {
              style: "margin-left: 4px;",
              href: window.location.pathname,
              oncreate: m.route.link,
            });
      requestorExtra = m(PageHeaderExtra, {
        subtitle: "Tags Requests By User",
        component: m(
          "div.is-flex",
          { style: "padding-top: 4px; justify-content: space-between; align-items: center;" },
          [m(UserImageAndName, { user: requestor, inverted: true }), delButton]
        ),
      });
    }

    const counts = {};
    requestCounts.forEach(({ ruling, count }) => {
      counts[ruling] = count;
    });
    const tabsView = m(
      "div.tabs",
      m(
        "ul",
        ["Pending", "Approved", "Rejected"].map(tab =>
          m(
            "li" + (selected === tab.toLowerCase() ? ".is-active" : ""),
            m(
              "a",
              {
                href: `${basePath}${tab.toLowerCase()}` + location.search,
                oncreate: m.route.link,
              },
              m("span", [titleCase(tab), m(TabCountView, { count: counts[tab], inverted: true })])
            )
          )
        )
      )
    );

    return m("header.hero.is-primary", [
      m("div.hero-title", m(HeaderNav, { me })),
      m("div.hero-body", { style: "padding-bottom: 0;" }, [
        m("div.container", [topicButton, requestorExtra, approveAllButton, rejectAllButton]),
        m("div.container", [tabsView]),
      ]),
    ]);
  }
}

export class TaggingTopicHeader {
  view(vnode) {
    const {
      me,
      topicDetails,
      thisSessionTags,
      dataSources,
      onDataSourcesChanged,
      onTopicChange,
      searchInputValue,
      onSearchSubmit,
      taggingMode,
      onTaggingModeChanged,
      onRefreshTopic,
      autoTaggingEnabled,
    } = vnode.attrs;

    const { topic } = topicDetails;

    const totalTopicTags = topic.positive_tags + topic.negative_tags;
    const lighten = color => `${color}aa`;
    const gradeSpecs = [
      { value: 30, color: "#F9A825", annotation: "Auto Tagging Enabled" },
      { value: 100, color: "#7cb342", annotation: "Decent Auto Tag Accuracy" },
      { value: 300, color: "#43a047", annotation: "Good Auto Tag Accuracy" },
      { value: 1000, color: "#2E7D32", annotation: "Great Auto Tag Accuracy" },
      { value: 3000, color: "#009688", annotation: "Excellent Auto Tag Accuracy" },
      { value: 10000, color: "#0288D1", annotation: "Amazing Auto Tag Accuracy" },
    ];

    let currentGrade,
      nextGrade,
      currentGradeComplete = true;
    if (totalTopicTags < 30) {
      currentGradeComplete = false;
      currentGrade = gradeSpecs[0];
      nextGrade = gradeSpecs[1];
    } else {
      for (const grade of gradeSpecs) {
        if (grade.value > totalTopicTags) {
          nextGrade = grade;
          break;
        } else {
          currentGrade = grade;
        }
      }
    }

    /**
     * Creates the annotation view based on the given grade
     * @param {{value, color, annotation}} grade
     * @param {boolean} isComplete
     */
    const gradeToAnnotation = (grade, isComplete) =>
      new ProgressBarAnnotation(
        grade.value,
        m("div.progress-bar-annotation", [
          isComplete ? m("span.icon.has-text-primary", m("i.mdi.mdi-check")) : null,
          m("h2.heading", isComplete ? {} : { style: `color: ${grade.color}` }, grade.annotation),
          m("div.progress-bar-annotation-tick"),
        ])
      );

    const barColor =
      totalTopicTags < 30 || totalTopicTags > 100 ? currentGrade.color : nextGrade.color;
    const progressSpecs =
      totalTopicTags === (thisSessionTags[topic.topic_id] || 0)
        ? [new ProgressSpec(thisSessionTags[topic.topic_id] || 0, lighten(barColor))]
        : [
            new ProgressSpec(totalTopicTags - (thisSessionTags[topic.topic_id] || 0), barColor),
            new ProgressSpec(thisSessionTags[topic.topic_id] || 0, lighten(barColor)),
          ];

    const annotations = [
      gradeToAnnotation(currentGrade, currentGradeComplete),
      gradeToAnnotation(nextGrade, false),
    ];

    return [
      m("header.hero.is-primary", [
        m("div.hero-title", m(HeaderNav, { me })),
        m(
          "div.hero-body",
          m("div.container", [
            m(TopicHeaderTopRow, {
              topicDetails,
              me,
              onRefreshTopic,
              onTopicChange,
              isTopicDropdown: true,
            }),
            m(TopicTaggingHeaderBottomRow, {
              thisSessionTags: Object.values(thisSessionTags).reduce((a, b) => a + b, 0),
              dataSources,
              onDataSourcesChanged,
              searchInputValue,
              onSearchSubmit,
              taggingMode,
              onTaggingModeChanged,
              autoTaggingEnabled,
            }),
          ])
        ),
      ]),
      m(
        "div.container",
        { style: "margin-top: 3em;" },
        m(ProgressBar, { max: nextGrade.value, progressSpecs, annotations })
      ),
    ];
  }
}
