import m from "mithril";

export const TAGGIE_NORMAL_SVG = `<svg class="taggie-jump" width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52.8 16C54.073 16 55.2939 15.4943 56.1941 14.5941C57.0943 13.6939 57.6 12.473 57.6 11.2C57.6 9.92696 57.0943 8.70606 56.1941 7.80589C55.2939 6.90571 54.073 6.4 52.8 6.4C51.527 6.4 50.3061 6.90571 49.4059 7.80589C48.5057 8.70606 48 9.92696 48 11.2C48 12.473 48.5057 13.6939 49.4059 14.5941C50.3061 15.4943 51.527 16 52.8 16ZM1.888 30.656L30.688 1.856C31.84 0.704 33.44 0 35.2 0H57.6C61.152 0 64 2.848 64 6.4V28.8C64 30.56 63.296 32.16 62.112 33.312L33.344 62.112C32.16 63.264 30.56 64 28.8 64C27.04 64 25.44 63.264 24.288 62.112L1.888 39.712C0.703999 38.56 0 36.96 0 35.2C0 33.408 0.736 31.808 1.888 30.656Z" fill="#FF6C41"/>
<path d="M28.1905 48C35.7605 48 42.0413 43.584 43.2272 37.7964C43.4489 36.7144 42.5332 35.8095 41.4286 35.8095H14.9524C13.8478 35.8095 12.9321 36.7144 13.1538 37.7964C14.3397 43.584 20.6205 48 28.1905 48Z" fill="white"/>
<ellipse cx="22.0953" cy="26.6667" rx="3.80952" ry="3.80952" fill="white"/>
<ellipse cx="38.0953" cy="26.6667" rx="3.80952" ry="3.80952" fill="white"/>
</svg>`;

export const TAGGIE_HAPPY_SVG = `<svg class="taggie-happy-jump" width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M69.3 21C70.9709 21 72.5733 20.3363 73.7548 19.1548C74.9363 17.9733 75.6 16.3709 75.6 14.7C75.6 13.0291 74.9363 11.4267 73.7548 10.2452C72.5733 9.06375 70.9709 8.4 69.3 8.4C67.6291 8.4 66.0267 9.06375 64.8452 10.2452C63.6637 11.4267 63 13.0291 63 14.7C63 16.3709 63.6637 17.9733 64.8452 19.1548C66.0267 20.3363 67.6291 21 69.3 21ZM2.478 40.236L40.278 2.436C41.79 0.924 43.89 0 46.2 0H75.6C80.262 0 84 3.738 84 8.4V37.8C84 40.11 83.076 42.21 81.522 43.722L43.764 81.522C42.21 83.034 40.11 84 37.8 84C35.49 84 33.39 83.034 31.878 81.522L2.478 52.122C0.924004 50.61 0 48.51 0 46.2C0 43.848 0.966003 41.748 2.478 40.236Z" fill="#FF6C41"/>
<path d="M38 63C48.2021 63 56.62 56.889 57.8464 48.993C58.0159 47.9015 57.1046 47 56 47H20C18.8954 47 17.9841 47.9015 18.1536 48.993C19.38 56.889 27.7979 63 38 63Z" fill="white"/>
<path d="M54.5 38L50 33L46 38" stroke="white" stroke-width="3"/>
<path d="M32.5 38L28 33L24 38" stroke="white" stroke-width="3"/>
</svg>`;

export const TAGGIE_DEAD_SVG = `<svg class="taggie-die" width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M69.3 21C70.9709 21 72.5733 20.3363 73.7548 19.1548C74.9363 17.9733 75.6 16.3709 75.6 14.7C75.6 13.0291 74.9363 11.4267 73.7548 10.2452C72.5733 9.06375 70.9709 8.4 69.3 8.4C67.6291 8.4 66.0267 9.06375 64.8452 10.2452C63.6637 11.4267 63 13.0291 63 14.7C63 16.3709 63.6637 17.9733 64.8452 19.1548C66.0267 20.3363 67.6291 21 69.3 21ZM2.478 40.236L40.278 2.436C41.79 0.924 43.89 0 46.2 0H75.6C80.262 0 84 3.738 84 8.4V37.8C84 40.11 83.076 42.21 81.522 43.722L43.764 81.522C42.21 83.034 40.11 84 37.8 84C35.49 84 33.39 83.034 31.878 81.522L2.478 52.122C0.924004 50.61 0 48.51 0 46.2C0 43.848 0.966003 41.748 2.478 40.236Z" fill="#FF6C41"/>
<path d="M46 32L54.5 40.5M54.5 32L46 40.5" stroke="white" stroke-width="3"/>
<path d="M24 32L32.5 40.5M32.5 32L24 40.5" stroke="white" stroke-width="3"/>
<path d="M39 52C31.0648 52 24.4788 56.3324 23.2178 62.0154C22.9785 63.0938 23.8954 64 25 64L53 64C54.1046 64 55.0215 63.0938 54.7822 62.0154C53.5212 56.3324 46.9352 52 39 52Z" fill="white"/>
</svg>`;

/**
 * A base class for Taggie toasts, for showing tutorials or for surfacing errors.
 */
export class TaggieToast {
  view(vnode) {
    const { onDismiss } = vnode.attrs;

    const hide = evt => {
      vnode.state.hidden = true;
      m.redraw();
    };

    return m("div.taggie-root.is-flex", { style: "align-items: flex-end;" }, [
      m("div.box.taggie-content.is-flex", { style: "align-items: flex-start;" }, [
        vnode.attrs.content,
        m("a.icon.has-text-grey", { onclick: onDismiss }, m("i.mdi.mdi-close")),
      ]),
      vnode.attrs.taggieSvg ? m.trust(vnode.attrs.taggieSvg) : null,
    ]);
  }
}

export const buildErrorTaggie = (error, url, line, onDismiss) => {
  let detailsShown = false;
  const onclick = evt => {
    document.getElementById("taggie-error-oops").style.display = "none";
    document.getElementById("taggie-error-details").style.display = "initial";
  };

  const errorJson = JSON.stringify({ error, url, line }, null, 2);

  const oopsContent = m("div#taggie-error-oops", [
    m("p", m("b", "Oops!")),
    m(
      "p",
      "Something went wrong.  I've recorded this error, but you can also click below for details."
    ),
    m("div.buttons.error-buttons.is-pulled-right", [
      m("button.button.is-primary", { onclick }, "Details"),
    ]),
  ]);

  const detailsContent = m("div#taggie-error-details", { style: "display: none;" }, [
    m("p", m("b", "Error Details")),
    m("pre.code", { style: "overflow-x: scroll; max-width: 19em;" }, errorJson),
  ]);

  const content = m("div.error-container", [detailsContent, oopsContent]);

  return m(TaggieToast, { content, taggieSvg: TAGGIE_DEAD_SVG, onDismiss });
};

// window.onerror = (error, url, line) => {
//   console.error(error);
//   const onDismiss = () => {
//     window.errorTaggie = null;
//     m.redraw();
//   };
//   window.errorTaggie = buildErrorTaggie(error, url, line, onDismiss);
//   m.redraw();
// };
