import m from "mithril";

/**
 * Spec used to specify a component of a progress bar.
 */
export class ProgressSpec {
  /**
   * @param {number} value
   * @param {string} color
   */
  constructor(value, color) {
    this.value = value;
    this.color = color;
  }
}

/**
 * Annotation for a progress bar, including its horizontal location, and the component that should
 * be drawn there.
 */
export class ProgressBarAnnotation {
  /**
   * @param {number} position
   * @param {{}} annotation
   */
  constructor(position, annotation) {
    this.position = position;
    this.annotation = annotation;
  }
}

/**
 * A single component of the progress bar.
 */
class ProgressBarComponent {
  /**
   * @param {ProgressSpec} progressSpec
   */
  view({ attrs: progressSpec }) {
    const { value, color } = progressSpec;
    return m("div.taggit-progress-bar-component", {
      style: `flex: ${value}; background-color: ${color};`,
    });
  }
}

/**
 * Attrs:
 *  -
 */
export class ProgressBar {
  /**
   * @param {[ProgressSpec]} progressSpecs
   * @param {[ProgressBarAnnotation]} annotations
   * @param {number} max
   */
  view({ attrs: { progressSpecs, max, annotations } }) {
    const totalWidth = progressSpecs.reduce((agg, { value }) => value + agg, 0);
    const remaining = max - totalWidth;

    for (const annotation of annotations) {
      if (annotation.position > max) {
        throw "Annotation was out of range.";
      }
    }

    if (typeof max !== "number") {
      throw "Max must be specified and be a number";
    }
    if (totalWidth > max) {
      throw "Total width larger than specified progress bar max";
    }

    const barComponents = progressSpecs
      .map(spec => m(ProgressBarComponent, spec))
      .concat([m(ProgressBarComponent, new ProgressSpec(remaining, "#dbdbdb"))]);

    const barAnnotations = annotations.map(({ position, annotation }) =>
      m("div", { style: `position: absolute; right: ${100 * (1 - position / max)}%;` }, annotation)
    );

    return m("div.taggit-progress-bar-container", [
      m("div.bar-components", barComponents),
      m("div.bar-annotations", barAnnotations),
    ]);
  }
}
