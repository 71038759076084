import { TagSummaryView, TopicSummaryView, UserSummaryView } from "./summaries";
import m from "mithril";
import { UserImageAndName } from "./users";
import formatDistance from "date-fns/formatDistance";
import parseISO from "date-fns/parseISO";

class TopicLinkView {
  view(vnode) {
    const { ownerName, topicName } = vnode.attrs;
    return m(
      "a.pad-r-quarter-em.has-text-weight-bold.has-text-dark",
      { oncreate: m.route.link, href: `/${ownerName}/${topicName}` },
      `${ownerName}/${topicName}`
    );
  }
}

class TopicActionView {
  view(vnode) {
    const { action } = vnode.attrs;
    const { acting_user, target_topic } = action;
    const { topic, owner, labels } = target_topic;

    const actionDescription = {
      star_topic: "starred topic",
      fork_topic: "forked topic",
      watch_topic: "watched topic",
      create_topic: "created topic",
    }[action.action];

    return m("div.action-item", [
      m("div.action-description.is-flex", { style: "align-items: center; padding: 1em 0;" }, [
        m(UserImageAndName, { user: acting_user }),
        m("span.pad-r-quarter-em.pad-l-quarter-em", actionDescription),
        m(TopicLinkView, { ownerName: owner.display_name, topicName: topic.name }),
        m(
          "span",
          formatDistance(parseISO(action.created_at), new Date(), { addSuffix: true }) + "."
        ),
      ]),
      m(
        "div.acted-item",
        { style: "margin-left: 38px;" },
        m(TopicSummaryView, { topic, owner, labels })
      ),
    ]);
  }
}

class TagActivityView {
  view(vnode) {
    const { action } = vnode.attrs;
    const { acting_user, target_tag, target_topic } = action;
    const { topic, owner } = target_topic;

    return m("div.action-item", [
      m("div.action-description.is-flex", { style: "align-items: center; padding: 1em 0;" }, [
        m(UserImageAndName, { user: acting_user }),
        m("span.pad-r-quarter-em.pad-l-quarter-em", "tagged in"),
        m(TopicLinkView, { ownerName: owner.display_name, topicName: topic.name }),
        m(
          "span",
          formatDistance(parseISO(action.created_at), new Date(), { addSuffix: true }) + "."
        ),
      ]),
      m(
        "div.acted-item",
        { style: "margin-left: 38px;" },
        m(TagSummaryView, { target_tag, topic, owner, acting_user })
      ),
    ]);
  }
}

class UserActivityView {
  view(vnode) {
    const { action } = vnode.attrs;
    const { acting_user, target_user, following_target_user } = action;

    return m("div.action-item", [
      m("div.action-description.is-flex", { style: "align-items: center; padding: 1em 0;" }, [
        m(UserImageAndName, { user: acting_user }),
        m("span.pad-r-half-em.pad-l-quarter-em", "followed"),
        m(UserImageAndName, { user: target_user }),
        m(
          "span.pad-l-quarter-em",
          formatDistance(parseISO(action.created_at), new Date(), { addSuffix: true }) + "."
        ),
      ]),
      m(
        "div.acted-item",
        { style: "margin-left: 38px;" },
        m(UserSummaryView, { user: target_user, following: following_target_user })
      ),
    ]);
  }
}

export const activityViewFor = action => {
  if (
    action.action === "fork_topic" ||
    action.action === "star_topic" ||
    action.action === "watch_topic" ||
    action.action === "create_topic"
  ) {
    return m(TopicActionView, { action });
  } else if (action.action === "create_tag") {
    return m(TagActivityView, { action });
  } else if (action.action === "follow_user") {
    return m(UserActivityView, { action });
  } else {
    return null;
  }
};
