const lunr = require("lunr");

class Phrase extends lunr.Token {
  constructor(str, metadata, left, right, level) {
    super(str, metadata);
    this.left = left;
    this.right = right;
    this.level = level;
  }

  original() {
    const [start, length] = this.metadata.position;
    return this.metadata.sourceDocument.slice(start, start + length);
  }
}

class Document {
  constructor(str, tokens) {
    this.str = str;
    this.tokens = tokens;
  }

  static from(text) {
    return new Document(
      text.trim(),
      lunr
        .tokenizer(text)
        .map(lunr.trimmer)
        .map(lunr.stemmer)
    );
  }
}

class DefaultMap {
  constructor(ifMissing) {
    this._map = new Map();
    this.ifMissing = ifMissing;
    this.size = 0;
  }

  get(key) {
    const result = this._map.get(key);
    return result === undefined ? this.ifMissing() : result;
  }

  set(key, value) {
    this._map.set(key, value);
    this.size = this._map.size;
  }

  [Symbol.iterator]() {
    return this._map[Symbol.iterator]();
  }
}

class Counter extends DefaultMap {
  constructor() {
    super(() => 0);
  }

  increment(key) {
    this.set(key, this.get(key) + 1);
  }
}

class PhraseExtractor {
  // TODO: different alpha for each depth?
  constructor() {
    this.depth = 3;
    this.alpha = 5;
    this.minFreq = 5;
    this.delimWrapper = "\t";

    this.ngramCounts = new DefaultMap(() => new Counter());
    this.ngramScores = new Map();
    this.canonicalCounts = new DefaultMap(() => new Counter());
  }

  makeDelim(depth) {
    return `${this.delimWrapper}${depth}G${this.delimWrapper}`;
  }

  fit(texts) {
    let documents = texts.map(Document.from);

    for (let level = 1; level <= this.depth; level++) {
      const delim = this.makeDelim(level);
      const counts = this.ngramCounts.get(level);
      if (level === 1) {
        // Just count single tokens
        documents.forEach(doc => {
          doc.tokens.forEach(t => counts.increment(t.str));
        });
        this.uniqueTokens = counts.size;
      } else {
        // Count token and phrase occurrences
        documents.forEach((document, idx) => {
          if (document.tokens.length >= level) {
            const documentNgrams = new Set();
            document.tokens.reduce((prev, next) => {
              const phrase = prev.str + delim + next.str;
              documentNgrams.add(phrase);
              const canonicalCounts = this.canonicalCounts.get(phrase);
              canonicalCounts.increment(
                document.str
                  .slice(
                    prev.metadata.position[0],
                    next.metadata.position[0] + next.metadata.position[1]
                  )
                  .replace(/^[^\w]/, "")
                  .replace(/[^\w]$/, "")
              );
              this.canonicalCounts.set(phrase, canonicalCounts);
              return next;
            });
            for (const phrase of documentNgrams.values()) {
              counts.increment(phrase);
            }
          }
        });

        // Re-score phrases calculated so far
        const scores = new Map();
        for (const [phrase, count] of counts) {
          const phraseScore = this.scorePhraseTokens(level, delim, phrase, count);
          if (phraseScore > this.alpha) {
            scores.set(phrase, phraseScore);
          }
        }
        this.ngramScores.set(level, scores);

        // Rewrite documents with phrases detected so far (so that they can be used in the next level
        documents = documents.map(document => {
          document.tokens = document.tokens.reduce((rest, next) => {
            if (rest.length > 0) {
              const prev = rest[rest.length - 1];
              const phraseCandidate = prev.str + delim + next.str;
              if (scores.has(phraseCandidate)) {
                const startIdx = prev.metadata.position[0];
                const length = next.metadata.position[0] + next.metadata.position[1] - startIdx;
                const phraseMeta = {
                  position: [startIdx, length],
                  sourceDocument: document.str,
                };
                rest[rest.length - 1] = new Phrase(phraseCandidate, phraseMeta, prev, next, level);
              } else {
                rest.push(next);
              }
            } else {
              rest.push(next);
            }
            return rest;
          }, []);
          return document;
        });

        this.size += counts.size;
      }
      this.ngramCounts.set(level, counts);
    }
  }

  getPhraseLevel(phrase) {
    const re = new RegExp(`${this.delimWrapper}([0-9]+)G${this.delimWrapper}`);
    const matches = re.exec(phrase);
    return matches === null ? 1 : parseInt(matches[1]);
  }

  getNgramCount(phrase) {
    // Interprets ngram level of phrase, and fetches count for that level
    const level = this.getPhraseLevel(phrase);
    return this.ngramCounts.get(level).get(phrase);
  }

  scorePhraseTokens(level, delim, phrase, phraseCount) {
    const [left, right] = phrase.split(delim);
    const leftCount = this.getNgramCount(left);
    const rightCount = this.getNgramCount(right);
    return ((phraseCount - this.minFreq) * this.uniqueTokens) / (leftCount * rightCount);
  }

  mapPhrases(text, fn) {
    fn = fn || (x => x);
    let document = Document.from(text);
    for (let level = 2; level <= this.depth; level++) {
      const scores = this.ngramScores.get(level);
      const delim = this.makeDelim(level);
      document.tokens = document.tokens.reduce((rest, next) => {
        if (rest.length > 0) {
          const prev = rest[rest.length - 1];
          const phraseCandidate = prev.str + delim + next.str;
          if (scores.has(phraseCandidate)) {
            const startIdx = prev.metadata.position[0];
            const length = next.metadata.position[0] + next.metadata.position[1] - startIdx;
            const phraseMeta = {
              position: [startIdx, length],
              sourceDocument: document.str,
            };
            rest[rest.length - 1] = new Phrase(phraseCandidate, phraseMeta, prev, next, level);
          } else {
            rest.push(next);
          }
        } else {
          rest.push(next);
        }
        return rest;
      }, []);
    }
    return document.tokens.filter(token => token instanceof Phrase).map(fn);
  }

  phrasesIn(text) {
    return this.mapPhrases(text);
  }

  normalizePhrase(phrase) {
    const counts = this.canonicalCounts.get(phrase);
    let maxCount = 0;
    let maxPhrase = phrase;
    for (const [phrase, count] of counts) {
      if (count > maxCount) {
        maxPhrase = phrase;
        maxCount = count;
      }
    }
    return maxPhrase;
  }

  transform(texts, replaceFn) {
    return texts.map(text => {
      const chunks = [];
      let currentIdx = 0;
      this.phrasesIn(text).forEach(phrase => {
        chunks.push(text.slice(currentIdx, phrase.metadata.position[0]));
        chunks.push(replaceFn(phrase));
        currentIdx += phrase.metadata.position[0] - currentIdx;
        currentIdx += phrase.metadata.position[1];
      });
      chunks.push(text.slice(currentIdx, text.length));
      return chunks.join("");
    });
  }

  countPhrases(texts, normalize) {
    normalize = normalize === undefined ? true : normalize;
    const counter = new Counter();
    texts.forEach(text =>
      this.mapPhrases(text, phrase => {
        counter.increment(phrase.str);
      })
    );
    if (normalize) {
      const normalized = new Counter();
      for (const [phrase, count] of counter) {
        normalized.set(this.normalizePhrase(phrase), count);
      }
      return normalized;
    } else {
      return counter;
    }
  }

  allPhrases(normalize) {
    normalize = normalize === undefined ? true : normalize;
    const counts = new Map();
    for (let level = 2; level <= this.depth; level++) {
      const ngramCounts = this.ngramCounts.get(level);
      for (const [phrase, score] of this.ngramScores.get(level)) {
        counts.set(this.normalizePhrase(phrase), ngramCounts.get(phrase));
      }
    }
    return counts;
  }
}

module.exports = {
  Phrase,
  Document,
  PhraseExtractor,
};
