import m from "mithril";

export class ContentCardView {
  view(vnode) {
    const { roundedCorners } = vnode.attrs;
    return m(
      "div.card.content-card" + (vnode.attrs.class || ""),
      { style: "margin-bottom: 2em;" + (roundedCorners ? "border-radius: 4px;" : "") },
      [
        vnode.attrs.title
          ? m("header.card-header", m("div.card-header-title", vnode.attrs.title))
          : null,
        m("div.card-content", m("div.content", vnode.attrs.content)),
        vnode.attrs.footer
          ? m(
              "div.card-footer",
              m("div.card-footer-item", { style: "justify-content: flex-end;" }, vnode.attrs.footer)
            )
          : null,
      ]
    );
  }
}
