import m from "mithril";
import { UserImageAndName } from "./users";
import { TopicButtonView } from "./topics";
import { HeaderNav, TagRequestsPageHeader } from "./header";
import { fetchJwt, loggedInUser } from "../account";
import {
  getTopic,
  getTopicTagRequestCounts,
  getTagRequestsByTopic,
  judgeTagRequest,
  judgeUserTopicTagRequests,
  judgeUserTagRequests,
  judgeTopicTagRequests,
  getUser,
  getTopicTagRequestCountsByUser,
  getTagRequestsByUserAndTopic,
  getTagRequestsByUser,
  getTagRequestCountsByUser,
  getPendingTagRequestCountsByTopic,
  getPendingTagRequestCountsByUser,
  getTagRequests,
} from "../api";
import { TaggedTextView } from "./summaries";
import { DecisionButton } from "./etc";

class TagRequestsHeader {
  view(vnode) {
    const {
      topicDetails,
      count,
      onApproveAll,
      onRejectAll,
      onCollapse,
      onExpand,
      expanded,
      requestor,
      ruling,
      myUserId,
    } = vnode.attrs;
    const {
      owner,
      topic,
      topic_meta: { access },
    } = topicDetails;

    const toggleExpand = evt => {
      if (expanded) {
        onCollapse();
      } else {
        onExpand();
      }
    };

    const expandIcon = expanded
      ? m(
          "span.icon.pad-r-quarter-em",
          { onclick: toggleExpand, style: "cursor: pointer;" },
          m("i.mdi.mdi-unfold-less-horizontal")
        )
      : m(
          "span.icon.pad-r-quarter-em",
          { onclick: toggleExpand, style: "cursor: pointer;" },
          m("i.mdi.mdi-unfold-more-horizontal")
        );

    const href = `/${owner.display_name}/${topic.name}/tag-requests/${ruling}?requestor=${
      requestor.display_name
    }`;

    const descriptionView = m("div.is-flex", { style: "align-items: center; flex-wrap: wrap;" }, [
      m(UserImageAndName, { user: requestor }),
      m("span", { style: "padding: 0 0.25em;" }, " requested "),
      m("a.has-text-weight-semibold", { href, oncreate: m.route.link }, `${count} tags`),
      m("span", { style: "padding: 0 0.25em;" }, " for "),
      TopicButtonView.from({ topic, owner, hasLink: true }),
    ]);

    let canJudge = false;
    if (access === "edit" || access === "admin") {
      canJudge = true;
    }
    let approveGreyed = false;
    let rejectGreyed = false;
    let approveGreyedReason, rejectGreyedReason;
    if (!myUserId) {
      approveGreyed = true;
      rejectGreyed = true;
      approveGreyedReason = "You must be logged in to approve or reject tags.";
      rejectGreyedReason = "You must be logged in to approve or reject tags.";
    } else if (!canJudge) {
      approveGreyed = true;
      rejectGreyed = true;
      approveGreyedReason = "You do not have permission to approve or reject tags for this topic.";
      rejectGreyedReason = "You do not have permission to approve or reject tags for this topic.";
    }

    return m(
      "div.panel-heading.is-flex",
      { style: "justify-content: flex-end; flex-wrap: wrap; " },
      [
        m("div.is-flex", { style: "flex: 1 1 50%; align-items: center;" }, [
          expandIcon,
          descriptionView,
        ]),
        m("div.buttons.tag-judge-buttons", [
          m(DecisionButton, {
            onclick: onApproveAll,
            greyed: approveGreyed,
            greyedReason: approveGreyedReason,
            action: "approve",
          }),
          m(DecisionButton, {
            onclick: onRejectAll,
            greyed: rejectGreyed,
            greyedReason: rejectGreyedReason,
            action: "reject",
          }),
        ]),
      ]
    );
  }
}

class TagRequestRow {
  view(vnode) {
    const {
      topicDetails: {
        owner,
        topic,
        topic_meta: { access },
      },
      tagRequest,
      onApprove,
      onReject,
      myUserId,
    } = vnode.attrs;
    const { tag, judgments } = tagRequest;

    let ruling = null;
    let myJudgments = (judgments || [])
      .filter(jgmt => jgmt.judge_id === myUserId)
      .sort((left, right) => {
        // Want to get the most recent judgment
        return left.created_at < right.created_at;
      });
    if (myJudgments.length > 0) {
      ruling = myJudgments[0].tag_approval.decision;
    }

    const href = `/tags/${tag.tag_id}`;

    let canJudge = false;
    if (access === "edit" || access === "admin") {
      canJudge = true;
    }

    let approveGreyed = false;
    let rejectGreyed = false;
    let approveGreyedReason, rejectGreyedReason;
    if (!myUserId) {
      approveGreyed = true;
      rejectGreyed = true;
      approveGreyedReason = "You must be logged in to approve or reject tags.";
      rejectGreyedReason = "You must be logged in to approve or reject tags.";
    } else if (!canJudge) {
      approveGreyed = true;
      rejectGreyed = true;
      approveGreyedReason = "You do not have permission to approve or reject tags for this topic.";
      rejectGreyedReason = "You do not have permission to approve or reject tags for this topic.";
    } else if (ruling === "Rejected") {
      rejectGreyed = true;
      rejectGreyedReason = "You have already rejected this.";
    } else if (ruling === "Approved") {
      approveGreyed = true;
      approveGreyedReason = "You have already approveed this.";
    }

    return m("div.is-flex.panel-block", { style: "justify-content: flex-end; flex-wrap: wrap;" }, [
      m("div.margin-r-1-em", { style: "flex: 1 1 50%; margin-bottom: 4px; margin-top: 4px;" }, [
        m(TaggedTextView, { target_tag: tag, topic, owner }),
      ]),
      m("div.buttons", [
        m("a.button", { href, oncreate: m.route.link }, m("span.icon", m("i.mdi.mdi-link"))),
        m(DecisionButton, {
          onclick: onApprove,
          greyed: approveGreyed,
          greyedReason: approveGreyedReason,
          action: "approve",
        }),
        m(DecisionButton, {
          onclick: onReject,
          greyed: rejectGreyed,
          greyedReason: rejectGreyedReason,
          action: "reject",
        }),
      ]),
    ]);
  }
}

class TagRequestsBox {
  oninit(vnode) {
    vnode.state.expanded = true;
  }
  view(vnode) {
    const { requestor, topicDetails, tagRequests, count, myUserId, ruling } = vnode.attrs;
    const { expanded } = vnode.state;

    const showAllUrl = "";

    const judgeTopicUserTags = async ruling => {
      const jwt = await fetchJwt();
      await judgeUserTopicTagRequests(jwt, requestor.user_id, topicDetails.topic.topic_id, ruling);
      m.route.set(m.route.get());
    };

    const onApproveAll = async evt => judgeTopicUserTags("Approved");

    const onRejectAll = async evt => judgeTopicUserTags("Rejected");

    const onCollapse = () => {
      vnode.state.expanded = false;
      m.redraw();
    };

    const onExpand = () => {
      vnode.state.expanded = true;
      m.redraw();
    };

    const judgeTag = async (tag, ruling) => {
      const jwt = await fetchJwt();
      await judgeTagRequest(jwt, tag.tag_id, ruling);
      // Refresh to get current state of tag approval queue
      m.route.set(m.route.get());
    };

    const buildTagRow = tagRequest => {
      const onApprove = evt => judgeTag(tagRequest.tag, "Approved");
      const onReject = evt => judgeTag(tagRequest.tag, "Rejected");
      return m(TagRequestRow, {
        onApprove,
        onReject,
        tagRequest,
        requestor,
        topicDetails,
        myUserId,
      });
    };

    const tagRequestsViews = expanded ? tagRequests.map(buildTagRow) : [];

    return m(
      "div.panel",
      { style: "padding-bottom: 3em;" },
      [
        m(TagRequestsHeader, {
          topicDetails,
          count,
          onApproveAll,
          onRejectAll,
          onCollapse,
          onExpand,
          expanded,
          requestor,
          ruling,
          myUserId,
        }),
      ].concat(tagRequestsViews.concat([m("div", m("a.href", showAllUrl))]))
    );
  }
}

class TopicTagRequestsPage {
  view(vnode) {
    const { me, topicDetails, requestCounts, userTagRequests, ruling, requestor } = vnode.attrs;

    const judgeAll = async decision => {
      const jwt = await fetchJwt();

      if (topicDetails !== undefined && requestor !== undefined) {
        await judgeUserTopicTagRequests(
          jwt,
          requestor.user_id,
          topicDetails.topic.topic_id,
          decision
        );
      } else if (requestor !== undefined) {
        await judgeUserTagRequests(jwt, requestor.user_id, decision);
      } else if (topicDetails !== undefined) {
        await judgeTopicTagRequests(jwt, topicDetails.topic.topic_id, decision);
      } else {
        throw "No topic or user selected for tag request ruling!";
      }

      m.route.set(m.route.get());
    };

    const onApproveAll = evt => judgeAll("Approved");
    const onRejectAll = evt => judgeAll("Rejected");

    const header = m(TagRequestsPageHeader, {
      me,
      requestor,
      topicDetails,
      requestCounts,
      userTagRequests,
      selected: ruling,
      onApproveAll,
      onRejectAll,
    });

    const buildUserTagRequestsView = tag_requests => {
      const tagRequests = tag_requests.tag_requests;
      const count = tag_requests.count;
      const topicDetails = tag_requests.topic_details;
      const requestor = tag_requests.requestor;
      return m(TagRequestsBox, {
        requestor,
        tagRequests,
        topicDetails,
        count,
        ruling,
        myUserId: (me || {}).userId,
      });
    };

    const bodyContent =
      userTagRequests.length === 0
        ? m("article.message", m("div.message-body", `No ${ruling} tag requests found.`))
        : userTagRequests.map(buildUserTagRequestsView);

    const body = m("div.section.container", bodyContent);

    return m("div", [header, body]);
  }
}

export const topicTagRequestsPageFactory = ruling => {
  const attrs = {
    me: {},
    requestCounts: [
      {
        ruling: "Pending",
        count: 0,
      },
      {
        ruling: "Rejected",
        count: 0,
      },
      {
        ruling: "Approved",
        count: 0,
      },
    ],
    userTagRequests: {},
    topicDetails: {},
    ruling,
  };

  return {
    onmatch: async (args, requestedPath) => {
      const jwt = await fetchJwt();
      const mePromise = loggedInUser();

      let countsPromise, tagRequestsPromise;
      if (args.requestor && args.owner) {
        document.title = `Taggit · Tag Requests by ${args.requestor} for ${args.owner}/${
          args.name
        }`;
        countsPromise = getTopicTagRequestCountsByUser(jwt, args.owner, args.name, args.requestor);
        tagRequestsPromise = getTagRequestsByUserAndTopic(
          jwt,
          ruling,
          args.requestor,
          args.owner,
          args.name
        );
      } else if (args.owner) {
        document.title = `Taggit · Tag Requests for ${args.owner}/${args.name}`;
        countsPromise = getTopicTagRequestCounts(jwt, args.owner, args.name);
        tagRequestsPromise = getTagRequestsByTopic(jwt, ruling, args.owner, args.name);
      } else if (args.requestor) {
        document.title = `Taggit · Tag Requests by ${args.requestor}`;
        countsPromise = getTagRequestCountsByUser(jwt, args.requestor);
        tagRequestsPromise = getTagRequestsByUser(jwt, ruling, args.requestor);
      } else {
        throw "No topic or requestor provided for tag requests page!";
      }

      if (args.requestor) {
        attrs.requestor = (await getUser(jwt, args.requestor)).user;
      } else {
        delete attrs.requestor;
      }

      if (args.owner) {
        attrs.topicDetails = await getTopic(jwt, args.owner, args.name);
      } else {
        delete attrs.topicDetails;
      }

      attrs.me = await mePromise;
      attrs.requestCounts = await countsPromise;
      attrs.userTagRequests = await tagRequestsPromise;
    },
    render: vnode => {
      return m(TopicTagRequestsPage, Object.assign(vnode.attrs, attrs));
    },
  };
};

class TopicTagRequestCountsBox {
  oninit(vnode) {
    vnode.state.expanded = true;
  }
  view(vnode) {
    const { topicTagRequestCounts } = vnode.attrs;

    const buildTopicCountsRow = ({ count, topic_details }) => {
      const { topic, owner } = topic_details;
      const href = `/${owner.display_name}/${topic.name}/tag-requests/pending`;
      return m("div.panel-block.is-flex", { style: "flex-wrap: wrap;" }, [
        TopicButtonView.from(topic_details),
        m("span", { style: "padding: 0 0.25em;" }, " has "),
        m("a.has-text-weight-semibold", { href, oncreate: m.route.link }, `${count} pending tags`),
      ]);
    };
    const toggleExpand = evt => {
      vnode.state.expanded = !vnode.state.expanded;
      m.redraw();
    };

    const expandIcon = vnode.state.expanded
      ? m(
          "span.icon.pad-r-quarter-em",
          { onclick: toggleExpand, style: "cursor: pointer;" },
          m("i.mdi.mdi-unfold-less-horizontal")
        )
      : m(
          "span.icon.pad-r-quarter-em",
          { onclick: toggleExpand, style: "cursor: pointer;" },
          m("i.mdi.mdi-unfold-more-horizontal")
        );

    const rows = vnode.state.expanded ? topicTagRequestCounts.map(buildTopicCountsRow) : [];

    return m(
      "div.panel",
      { style: "padding-bottom: 3em;" },
      [
        m("div.panel-heading.is-flex", { style: "justify-items: center;" }, [
          expandIcon,
          m("span", `${topicTagRequestCounts.length} topics with pending tag requests`),
        ]),
      ].concat(rows)
    );
  }
}

class UserTagRequestCountsBox {
  oninit(vnode) {
    vnode.state.expanded = true;
  }
  view(vnode) {
    const { userTagRequestCounts } = vnode.attrs;

    const buildTopicCountsRow = ({ count, user }) => {
      const href = `/${user.display_name}/tag-requests/pending`;
      return m("div.panel-block.is-flex", { style: "flex-wrap: wrap;" }, [
        m(UserImageAndName, { user }),
        m("span", { style: "padding: 0 0.25em;" }, " has "),
        m("a.has-text-weight-semibold", { href, oncreate: m.route.link }, `${count} pending tags`),
      ]);
    };

    const toggleExpand = evt => {
      vnode.state.expanded = !vnode.state.expanded;
      m.redraw();
    };

    const expandIcon = vnode.state.expanded
      ? m(
          "span.icon.pad-r-quarter-em",
          { onclick: toggleExpand, style: "cursor: pointer;" },
          m("i.mdi.mdi-unfold-less-horizontal")
        )
      : m(
          "span.icon.pad-r-quarter-em",
          { onclick: toggleExpand, style: "cursor: pointer;" },
          m("i.mdi.mdi-unfold-more-horizontal")
        );

    const rows = vnode.state.expanded ? userTagRequestCounts.map(buildTopicCountsRow) : [];

    return m(
      "div.panel",
      { style: "padding-bottom: 3em;" },
      [
        m("div.panel-heading.is-flex", { style: "justify-items: center;" }, [
          expandIcon,
          m("span", `${userTagRequestCounts.length} users with pending tag requests`),
        ]),
      ].concat(rows)
    );
  }
}

class TagRequestsPage {
  view(vnode) {
    const { me, topicUserTagRequests, topicTagRequestCounts, userTagRequestCounts } = vnode.attrs;
    const header = m("header.hero.is-primary", [m("div.hero-title", m(HeaderNav, { me }))]);

    if (topicTagRequestCounts.length === 0) {
      return m("div", [
        header,
        m(
          "div.section.container",
          m(
            "article.message",
            m("div.message-body", `There are no more pending tag requests that you can judge.`)
          )
        ),
      ]);
    }

    const leftColumn = m("div.column", { style: "padding: 1em;" }, [
      m(TopicTagRequestCountsBox, { topicTagRequestCounts }),
      m(UserTagRequestCountsBox, { userTagRequestCounts }),
    ]);

    const buildUserTagRequestsView = tag_requests => {
      const tagRequests = tag_requests.tag_requests;
      const count = tag_requests.count;
      const topicDetails = tag_requests.topic_details;
      const requestor = tag_requests.requestor;
      return m(TagRequestsBox, {
        requestor,
        tagRequests,
        topicDetails,
        count,
        ruling: "Pending",
        myUserId: me.userId,
      });
    };

    const rightColumn = m(
      "div.column",
      { style: "flex: 2 1 0%; padding: 1em;" },
      topicUserTagRequests.map(buildUserTagRequestsView)
    );

    const body = m("div.section.container", m("div.columns", [leftColumn, rightColumn]));

    return m("div", [header, body]);
  }
}

export const tagRequestsPageProver = (() => {
  const attrs = {
    me: null,
    topicTagRequestCounts: [],
    userTagRequestCounts: [],
    topicUserTagRequests: [],
  };
  return {
    onmatch: async (args, requestedPath) => {
      document.title = `Taggit · Tag Requests`;

      const jwt = await fetchJwt();
      const mePromise = loggedInUser();
      const topicCountsPromise = getPendingTagRequestCountsByTopic(jwt, "edit");
      const userCountsPromise = getPendingTagRequestCountsByUser(jwt, "edit");
      const topicUserTagRequestsPromise = getTagRequests(jwt, "Pending");

      attrs.topicTagRequestCounts = await topicCountsPromise;
      attrs.userTagRequestCounts = await userCountsPromise;
      attrs.topicUserTagRequests = await topicUserTagRequestsPromise;
      attrs.me = await mePromise;
    },
    render: vnode => {
      return m(TagRequestsPage, Object.assign(vnode.attrs, attrs));
    },
  };
})();
