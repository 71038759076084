import m from "mithril";
import { HeaderNav, PageHeader } from "./header";
import { Footer } from "./footer";
import { activityViewFor } from "./activity";
import { TopicButtonView } from "./topics";
import { ContentCardView } from "./content_card";
import { fetchJwt, loggedInUser } from "../account";
import { getHome, listHotTopics } from "../api";
import { AutoTagTextResultView } from "./autotag";
import { TagSummaryView, TopicSummaryView } from "./summaries";
import { subHours } from "date-fns";
import { StackdriverErrorReporter } from "stackdriver-errors-js";
import * as StackTrace from "stacktrace-js";
import { addTutorialTaggieToAttrs } from "./tutorials";
import { buildTutorialTaggie } from "./taggie";
import { PlanSelectView } from "./account";

window.StackTrace = StackTrace; // See: https://github.com/GoogleCloudPlatform/stackdriver-errors-js/issues/2

if (location.host !== "www.taggit.test") {
  window.errorReporter = new StackdriverErrorReporter();
  window.errorReporter.start({
    key: "AIzaSyBUaJl3MEoVG6PTavir3P5WX6AB523y9eQ",
    projectId: "taggit-prod",
    service: "web-ui",
    version: "TODO",
    reportUncaughtExceptions: true,
    reportUnhandledPromiseRejections: true,
  });
}

export class HomePageView {
  view(vnode) {
    const { me, homeDetails } = vnode.attrs;
    const { recent_activity, top_topics } = homeDetails;

    return [
      m(PageHeader, { me }),
      m(
        "section.section",
        m(
          "div.container",
          m("div.columns.is-desktop", [
            m(HomeTopicListView, { top_topics }),
            m("div.column", { style: "flex: 2; padding: 1em;" }, [
              m(HotTopicsView, {}),
              m(HomeActivityListView, { recent_activity }),
            ]),
          ])
        )
      ),
      m(Footer),
    ];
  }
}

class MarketingHeaderDiagramView {
  view(vnode) {
    return m.trust(`
<svg viewBox="0 0 480 400" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M306.096 134.202L306.096 179.142L47.6927 179.142C43.8567 179.142 40.0583 178.386 36.5143 176.918C32.9702 175.45 29.75 173.298 27.0376 170.586C24.3251 167.873 22.1734 164.653 20.7055 161.109C19.2375 157.565 18.4819 153.767 18.4819 149.931L18.4819 76.9036L63.4217 76.9036L63.4217 134.202L306.096 134.202Z" fill="#C4C4C4"/>
</g>
<g filter="url(#filter1_d)">
<path d="M161.166 139.19C161.166 141.399 159.375 143.19 157.166 143.19L120.226 143.19C118.017 143.19 116.226 141.399 116.226 139.19L116.226 76.9036L161.166 76.9036L161.166 139.19Z" fill="#C4C4C4"/>
</g>
<g filter="url(#filter2_d)">
<path d="M258.91 139.19C258.91 141.399 257.119 143.19 254.91 143.19L217.97 143.19C215.761 143.19 213.97 141.399 213.97 139.19L213.97 76.9036L258.91 76.9036L258.91 139.19Z" fill="#C4C4C4"/>
</g>
<g filter="url(#filter3_d)">
<rect x="220" y="68" width="33" height="28" rx="4" fill="white"/>
</g>
<rect x="224.4" y="72.48" width="24.2" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="224.4" y="83.68" width="24.2" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="224.4" y="78.08" width="14.3" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="240.9" y="78.08" width="7.7" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="224.4" y="89.28" width="7.7" height="2.24" rx="1.12" fill="#C4C4C4"/>
<g filter="url(#filter4_d)">
<rect x="24" y="89" width="34" height="28" rx="4" fill="white"/>
</g>
<rect x="28.5333" y="93.48" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="28.5333" y="104.68" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="28.5333" y="99.08" width="14.7333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="45.5333" y="99.08" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="28.5333" y="110.28" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<g filter="url(#filter5_d)">
<rect x="53" y="143" width="34" height="28" rx="4" fill="white"/>
</g>
<rect x="57.5333" y="147.48" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="57.5333" y="158.68" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="57.5333" y="153.08" width="14.7333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="74.5333" y="153.08" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="57.5333" y="164.28" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<g filter="url(#filter6_d)">
<rect x="122" y="108" width="34" height="28" rx="4" fill="white"/>
</g>
<rect x="126.533" y="112.48" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="126.533" y="123.68" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="126.533" y="118.08" width="14.7333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="143.533" y="118.08" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="126.533" y="129.28" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<g filter="url(#filter7_d)">
<rect x="278" y="143" width="34" height="28" rx="4" fill="white"/>
</g>
<rect x="282.533" y="147.48" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="282.533" y="158.68" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="282.533" y="153.08" width="14.7333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="299.533" y="153.08" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="282.533" y="164.28" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<g filter="url(#filter8_d)">
<rect x="170" y="143" width="34" height="28" rx="4" fill="white"/>
</g>
<rect x="174.533" y="147.48" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="174.533" y="158.68" width="24.9333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="174.533" y="153.08" width="14.7333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="191.533" y="153.08" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<rect x="174.533" y="164.28" width="7.93333" height="2.24" rx="1.12" fill="#C4C4C4"/>
<g filter="url(#filter9_d)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M369.012 203.858H413.952V336.431C413.952 340.267 413.196 344.065 411.728 347.609C410.26 351.153 408.109 354.373 405.396 357.086C402.684 359.798 399.464 361.95 395.919 363.418C392.375 364.886 388.577 365.642 384.741 365.642H243.181L243.181 320.702L369.012 320.702V203.858Z" fill="#C4C4C4"/>
</g>
<g filter="url(#filter10_d)">
<rect x="275.762" y="328.566" width="33.7048" height="28.0873" rx="4" fill="white"/>
</g>
<rect x="280.256" y="333.06" width="24.7169" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="280.256" y="344.295" width="24.7169" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="280.256" y="338.678" width="14.6054" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="297.108" y="338.678" width="7.86446" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="280.256" y="349.913" width="7.86446" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<g filter="url(#filter11_d)">
<rect x="374.63" y="219.587" width="33.7048" height="28.0873" rx="4" fill="white"/>
</g>
<rect x="379.123" y="224.081" width="24.7169" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<rect x="379.123" y="235.316" width="24.7169" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<rect x="379.123" y="229.699" width="14.6054" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<rect x="395.976" y="229.699" width="7.86446" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<rect x="379.123" y="240.934" width="7.86446" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<g filter="url(#filter12_d)">
<rect x="374.63" y="263.404" width="33.7048" height="28.0873" rx="4" fill="white"/>
</g>
<rect x="379.123" y="267.898" width="24.7169" height="2.24699" rx="1.12349" fill="#BB50F1" fill-opacity="0.6"/>
<rect x="379.123" y="279.133" width="24.7169" height="2.24699" rx="1.12349" fill="#BB50F1" fill-opacity="0.6"/>
<rect x="379.123" y="273.515" width="14.6054" height="2.24699" rx="1.12349" fill="#BB50F1" fill-opacity="0.6"/>
<rect x="395.976" y="273.515" width="7.86446" height="2.24699" rx="1.12349" fill="#BB50F1" fill-opacity="0.6"/>
<rect x="379.123" y="284.75" width="7.86446" height="2.24699" rx="1.12349" fill="#BB50F1" fill-opacity="0.6"/>
<g filter="url(#filter13_d)">
<rect x="374.63" y="307.22" width="33.7048" height="28.0873" rx="4" fill="white"/>
</g>
<rect x="379.123" y="311.714" width="24.7169" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="379.123" y="322.949" width="24.7169" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="379.123" y="317.331" width="14.6054" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="395.976" y="317.331" width="7.86446" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<rect x="379.123" y="328.566" width="7.86446" height="2.24699" rx="1.12349" fill="#FF6C41" fill-opacity="0.6"/>
<g filter="url(#filter14_d)">
<rect x="325.196" y="328.566" width="33.7048" height="28.0873" rx="4" fill="white"/>
</g>
<rect x="329.69" y="333.06" width="24.7169" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<rect x="329.69" y="344.295" width="24.7169" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<rect x="329.69" y="338.678" width="14.6054" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<rect x="346.542" y="338.678" width="7.86446" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<rect x="329.69" y="349.913" width="7.86446" height="2.24699" rx="1.12349" fill="#81CEF9"/>
<g filter="url(#filter15_d)">
<rect x="38.7048" y="219.476" width="224.699" height="168.524" rx="8" fill="#E0E0E0"/>
</g>
<rect x="44.3223" y="225.093" width="213.464" height="157.289" rx="8" fill="white"/>
<path d="M64.5452 337.443L126.337 343.622L185.321 332.949L236.44 325.084" stroke="#DAA1F7" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M64.5452 326.208L126.337 313.849L185.321 321.714L236.44 335.758" stroke="#81CEF9" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M64.5452 348.678L126.337 322.276L185.321 344.184L236.44 260.483" stroke="#FFB099" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="236.44" cy="259.922" r="8.11145" fill="white" stroke="#FFB099" stroke-width="4"/>
<g filter="url(#filter16_d)">
<rect x="306.096" y="71.2861" width="168.524" height="168.524" rx="8" fill="#E0E0E0"/>
</g>
<rect x="311.714" y="76.9036" width="157.289" height="157.289" rx="8" fill="#FF6C41"/>
<path d="M393.905 93.4272L349.801 137.524C346.865 140.46 347.045 142.437 349.801 145.194L355.795 151.186L362.169 144.831C362.039 144.357 361.963 143.87 361.94 143.38C361.801 139.75 364.632 136.69 368.263 136.545C368.933 136.525 369.603 136.596 370.25 136.774L376.144 130.892C376.001 130.393 375.916 129.88 375.893 129.361C375.757 125.731 378.59 122.674 382.221 122.532C385.853 122.39 388.909 125.217 389.046 128.848C389.182 132.478 386.349 135.536 382.718 135.677C382.044 135.698 381.369 135.626 380.719 135.443L376.087 140.068L390.948 140.078C392.054 138.034 394.155 136.724 396.476 136.629C400.108 136.487 403.164 139.315 403.301 142.946C403.44 146.578 400.606 149.639 396.973 149.781C394.549 149.873 392.273 148.624 391.05 146.532L374.139 146.521C372.994 148.415 370.974 149.607 368.765 149.696C368.069 149.727 367.373 149.635 366.703 149.445L360.375 155.766L380.482 175.869C383.406 178.792 385.345 178.676 388.153 175.869L432.257 131.772C434.175 129.854 434.175 129.854 434.175 126.02V106.847C434.175 103.013 434.175 103.013 432.257 101.095C430.339 99.1782 426.514 95.3672 424.587 93.4262C422.669 91.509 422.669 91.509 418.834 91.5091L399.658 91.509C395.823 91.5091 395.823 91.5091 393.905 93.4262V93.4272ZM413.011 105.332C416.658 105.193 419.728 108.036 419.869 111.683C420.012 115.331 417.168 118.405 413.519 118.546C409.869 118.689 406.795 115.846 406.653 112.197C406.511 108.546 409.358 105.471 413.01 105.333L413.011 105.332Z" fill="white"/>
<rect x="338.678" y="191.5" width="105.608" height="31.4578" rx="4" fill="white"/>
<rect x="415.075" y="194.87" width="24.7169" height="24.7169" rx="4" fill="#81CEF9"/>
<path d="M427.434 203.961C428.301 203.961 429.132 204.305 429.745 204.918C430.358 205.531 430.702 206.362 430.702 207.229C430.702 208.096 430.358 208.927 429.745 209.54C429.132 210.153 428.301 210.497 427.434 210.497C426.567 210.497 425.736 210.153 425.123 209.54C424.51 208.927 424.165 208.096 424.165 207.229C424.165 206.362 424.51 205.531 425.123 204.918C425.736 204.305 426.567 203.961 427.434 203.961V203.961ZM420.121 208.046H418.446V206.412H420.121C420.489 203.005 423.209 200.284 426.617 199.916V198.241H428.251V199.916C431.658 200.284 434.379 203.005 434.747 206.412H436.422V208.046H434.747C434.379 211.453 431.658 214.174 428.251 214.542V216.217H426.617V214.542C423.209 214.174 420.489 211.453 420.121 208.046ZM427.434 201.509C425.917 201.509 424.462 202.112 423.389 203.185C422.317 204.257 421.714 205.712 421.714 207.229C421.714 208.746 422.317 210.201 423.389 211.273C424.462 212.346 425.917 212.949 427.434 212.949C428.951 212.949 430.405 212.346 431.478 211.273C432.551 210.201 433.153 208.746 433.153 207.229C433.153 205.712 432.551 204.257 431.478 203.185C430.405 202.112 428.951 201.509 427.434 201.509V201.509Z" fill="white"/>
<rect x="379.123" y="194.87" width="24.7169" height="24.7169" rx="4" fill="#BB50F1" fill-opacity="0.6"/>
<path d="M391.589 211.937C392.043 211.937 392.478 211.757 392.799 211.435C393.121 211.114 393.301 210.679 393.301 210.225C393.301 209.275 392.531 208.513 391.589 208.513C391.135 208.513 390.699 208.693 390.378 209.014C390.057 209.335 389.877 209.771 389.877 210.225C389.877 210.679 390.057 211.114 390.378 211.435C390.699 211.757 391.135 211.937 391.589 211.937ZM396.725 204.233C397.179 204.233 397.614 204.413 397.935 204.734C398.257 205.055 398.437 205.491 398.437 205.945V214.505C398.437 214.959 398.257 215.394 397.935 215.715C397.614 216.036 397.179 216.217 396.725 216.217H386.453C385.999 216.217 385.563 216.036 385.242 215.715C384.921 215.394 384.741 214.959 384.741 214.505V205.945C384.741 204.995 385.511 204.233 386.453 204.233H387.309V202.521C387.309 201.386 387.76 200.297 388.563 199.495C389.365 198.692 390.454 198.241 391.589 198.241C392.151 198.241 392.708 198.352 393.227 198.567C393.746 198.782 394.218 199.097 394.615 199.495C395.013 199.892 395.328 200.364 395.543 200.883C395.758 201.402 395.869 201.959 395.869 202.521V204.233H396.725ZM391.589 199.953C390.908 199.953 390.255 200.224 389.773 200.705C389.291 201.187 389.021 201.84 389.021 202.521V204.233H394.157V202.521C394.157 201.84 393.886 201.187 393.405 200.705C392.923 200.224 392.27 199.953 391.589 199.953Z" fill="white"/>
<rect x="343.172" y="194.87" width="24.7169" height="24.7169" rx="4" fill="#FF6C41" fill-opacity="0.6"/>
<path d="M357.652 207.229H353.658V205.232H357.652V207.229ZM357.652 211.224H353.658V209.226H357.652V211.224ZM363.644 203.234H360.838C360.389 202.455 359.769 201.786 359.02 201.277L360.648 199.649L359.24 198.241L357.073 200.408C356.614 200.298 356.154 200.238 355.655 200.238C355.156 200.238 354.696 200.298 354.247 200.408L352.07 198.241L350.662 199.649L352.279 201.277C351.54 201.786 350.921 202.455 350.472 203.234H347.666V205.232H349.753C349.703 205.561 349.663 205.891 349.663 206.23V207.229H347.666V209.226H349.663V210.225C349.663 210.564 349.703 210.894 349.753 211.224H347.666V213.221H350.472C351.51 215.008 353.438 216.217 355.655 216.217C357.872 216.217 359.799 215.008 360.838 213.221H363.644V211.224H361.557C361.607 210.894 361.647 210.564 361.647 210.225V209.226H363.644V207.229H361.647V206.23C361.647 205.891 361.607 205.561 361.557 205.232H363.644V203.234Z" fill="white"/>
<g filter="url(#filter17_d)">
<rect x="5" y="5" width="71.9036" height="71.9036" rx="8" fill="#E0E0E0"/>
</g>
<rect x="10.6175" y="10.6175" width="60.6687" height="60.6687" rx="8" fill="#FF6C41"/>
<path d="M53.5349 39.329H28.3687V34.4605H53.5349V39.329ZM53.5349 45.8203H42.5247V42.5746H53.5349V45.8203ZM53.5349 52.3116H42.5247V49.0659H53.5349V52.3116ZM39.3789 52.3116H28.3687V42.5746H39.3789V52.3116ZM54.054 29.0565L51.443 26.3464L48.8163 29.0565L46.1895 26.3464L43.5785 29.0565L40.9518 26.3464L38.3251 29.0565L35.7141 26.3464L33.0874 29.0565L30.4606 26.3464L27.8496 29.0565L25.2229 26.3464V52.3116C25.2229 53.1724 25.5543 53.9979 26.1443 54.6066C26.7342 55.2153 27.5344 55.5572 28.3687 55.5572H53.5349C54.3693 55.5572 55.1694 55.2153 55.7594 54.6066C56.3493 53.9979 56.6807 53.1724 56.6807 52.3116V26.3464L54.054 29.0565Z" fill="white"/>
<g filter="url(#filter18_d)">
<rect x="102.744" y="5" width="71.9036" height="71.9036" rx="8" fill="#E0E0E0"/>
</g>
<rect x="108.361" y="10.6175" width="60.6687" height="60.6687" rx="8" fill="#FF6C41"/>
<path d="M132.033 54.4337C144.363 54.4337 151.085 44.0396 151.085 35.0806C151.085 34.7762 151.085 34.4718 151.085 34.2108C152.412 33.2541 153.526 32.0363 154.425 30.6881C153.226 31.21 151.942 31.6014 150.571 31.7754C151.942 30.9491 153.012 29.6009 153.526 27.9918C152.241 28.7746 150.786 29.34 149.287 29.6444C148.045 28.2962 146.333 27.4699 144.406 27.4699C140.681 27.4699 137.685 30.5142 137.685 34.2978C137.685 34.8197 137.727 35.3416 137.856 35.8635C132.29 35.559 127.366 32.8627 124.027 28.7311C123.47 29.7314 123.128 30.9056 123.128 32.1233C123.128 34.4718 124.327 36.5593 126.125 37.777C125.012 37.7335 123.984 37.4291 123.085 36.9072C123.085 36.9507 123.085 36.9507 123.085 36.9942C123.085 40.2995 125.397 43.0393 128.48 43.6482C127.923 43.8222 127.324 43.9091 126.724 43.9091C126.296 43.9091 125.868 43.8656 125.483 43.7787C126.339 46.475 128.822 48.4321 131.733 48.5191C129.421 50.3457 126.553 51.4329 123.427 51.4329C122.871 51.4329 122.357 51.3894 121.843 51.3459C124.755 53.303 128.265 54.4337 132.033 54.4337Z" fill="white"/>
<g filter="url(#filter19_d)">
<rect x="200.488" y="5" width="71.9036" height="71.9036" rx="8" fill="#E0E0E0"/>
</g>
<rect x="206.105" y="10.6175" width="60.6687" height="60.6687" rx="8" fill="#FF6C41"/>
<path d="M249.922 24.0994H222.958C222.064 24.0994 221.207 24.4545 220.575 25.0866C219.942 25.7187 219.587 26.576 219.587 27.4699V57.8042L226.328 51.0633H249.922C250.816 51.0633 251.673 50.7081 252.305 50.0761C252.937 49.444 253.292 48.5867 253.292 47.6928V27.4699C253.292 26.576 252.937 25.7187 252.305 25.0866C251.673 24.4545 250.816 24.0994 249.922 24.0994ZM249.922 47.6928H226.328L222.958 51.0633V27.4699H249.922" fill="white"/>
<path d="M236.44 40.7726L240.606 43.1988L239.5 38.6262L243.181 35.5496L238.334 35.1463L236.44 30.8404L234.545 35.1463L229.699 35.5496L233.373 38.6262L232.274 43.1988L236.44 40.7726Z" fill="white"/>
<defs>
<filter id="filter0_d" x="14.4819" y="76.9036" width="295.614" height="110.238" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter1_d" x="112.226" y="76.9036" width="52.9398" height="74.2861" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter2_d" x="209.97" y="76.9036" width="52.9398" height="74.2861" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter3_d" x="219" y="68" width="35" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter4_d" x="23" y="89" width="36" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter5_d" x="52" y="143" width="36" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter6_d" x="121" y="108" width="36" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter7_d" x="277" y="143" width="36" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter8_d" x="169" y="143" width="36" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter9_d" x="239.181" y="203.858" width="178.771" height="169.783" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter10_d" x="274.762" y="328.566" width="35.7048" height="30.0873" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter11_d" x="373.63" y="219.587" width="35.7048" height="30.0873" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter12_d" x="373.63" y="263.404" width="35.7048" height="30.0873" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter13_d" x="373.63" y="307.22" width="35.7048" height="30.0873" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter14_d" x="324.196" y="328.566" width="35.7048" height="30.0873" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="0.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter15_d" x="34.7048" y="219.476" width="232.699" height="176.524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter16_d" x="302.096" y="71.2861" width="176.524" height="176.524" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter17_d" x="1" y="5" width="79.9036" height="79.9036" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter18_d" x="98.744" y="5" width="79.9036" height="79.9036" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<filter id="filter19_d" x="196.488" y="5" width="79.9036" height="79.9036" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
    `)
  }
}

class HotTopicsView {
  oninit(vnode) {
    vnode.state.hotTopics = [];
    return fetchJwt().then(async jwt => {
      vnode.state.hotTopics = await listHotTopics(jwt);
      m.redraw();
    });
  }

  view(vnode) {
    return [m("h1.title", "Hot Topics")].concat(
      vnode.state.hotTopics.map(({ topic, owner, labels }) =>
        m(TopicSummaryView, { topic, owner, labels })
      )
    );
  }
}

class HomeTopicListView {
  view(vnode) {
    const top_topics = vnode.attrs.top_topics || [];

    const onkeyup = evt => {
      if (evt.keyCode === 13) {
        const search = evt.target.value;
        m.route.set("/search/topics?search_text=" + encodeURIComponent(search));
      }
    };

    const content = m(
      "div",
      [m("input.input.margin-b-1-em", { placeholder: "Search", onkeyup })].concat(
        top_topics.map(({ topic, owner }) =>
          m(
            "div.margin-b-1-em",
            m(TopicButtonView, {
              name: topic.name,
              owner: owner.display_name,
              hasLink: true,
              color: topic.color,
              size: "medium",
            })
          )
        )
      )
    );

    return m(
      "div.column",
      { style: "padding: 1em;" },
      m(ContentCardView, {
        title: m("div.is-flex", { style: "align-items: center; width: 100%;" }, [
          m("h1.is-size-4.has-text-weight-bold", { style: "flex: 1" }, "Topics"),
          m(
            "a.button.is-primary",
            { oncreate: m.route.link, href: "/topics/create" },
            "+ New Topic"
          ),
        ]),
        content,
      })
    );
  }
}

class HomeActivityListView {
  view(vnode) {
    const recent_activity = vnode.attrs.recent_activity || [];

    return m("div", [
      m("h1.title", "Recent Activity"),
      m("div.user-actions", recent_activity.map(activityViewFor)),
    ]);
  }
}

class MarketingScreenshotsView {
  oninit(vnode) {
    vnode.state.selected = 0;
  }

  view(vnode) {
    const imageUrls = [
      "/mktg-img/usage-example-autotag-file.png",
      "/mktg-img/usage-example-document-explorer.png",
      "/mktg-img/usage-example-tagging-topic.png",
    ];

    const texts = [
      "Automatically label topics in your text, without all the reading.",
      "Discover topics present in your data before spending time to tag data.",
      "Identify the best data to tag to make your auto-taggers effective quickly.",
    ];

    return m("section.section", m("div.container", { style: { textAlign: "center" } }, [
      m("h1.title", "One solution for all your text labelling needs."),
      m("div.buttons", { style: { justifyContent: "center" } }, [
        m("button.button" + (vnode.state.selected === 0 ? ".is-primary" : ""), {
          onclick: e => { vnode.state.selected = 0; m.redraw(); }
        }, [
          m("span.icon", m("i.mdi.mdi-memory")),
          m("span", "Automated Tagging"),
        ]),
        m("button.button" + (vnode.state.selected === 1 ? ".is-primary" : ""), {
          onclick: e => { vnode.state.selected = 1; m.redraw(); }
        }, [
          m("span.icon", m("i.mdi.mdi-lightbulb-on-outline")),
          m("span", "Topic Discovery"),
        ]),
        m("button.button" + (vnode.state.selected === 2 ? ".is-primary" : ""), {
          onclick: e => { vnode.state.selected = 2; m.redraw(); }
        }, [
          m("span.icon", m("i.mdi.mdi-tag-plus")),
          m("span", "Smart Tagger"),
        ]),
      ]),
      m("p.margin-b-1-em", texts[vnode.state.selected]),
      m("div", { style: { borderRadius: "8px", border: "10px whitesmoke solid" } },
        m("img", { src: imageUrls[vnode.state.selected], style: { borderRadius: "4px" } })
      ),
    ]));
  }
}

class MarketingHeaderView {
  view(vnode) {
    const { me } = vnode.attrs;

    return m("header.hero.is-primary.is-bold", [
      m("div.hero-title", m(HeaderNav, { me })),
      m(
        "div.hero-body",
        m(
          "section.section",
          m(
            "div.container",
            m("div.columns", [
              m("div.column.is-flex", { style: { flexDirection: "column", justifyContent: "center", alignItems: "baseline" }}, [
                m(
                  "h1.is-size-1.has-text-weight-bold",
                  { style: "margin-bottom: 0;" },
                  "Turn your text data into real decisions."
                ),
                m(
                  "p.is-size-4",
                  { style: "margin-bottom: 1.5em;" },
                  "A full service automated text tagging platform to identify topics in all of " +
                  "your data, be they reviews, tweets, surveys, news, or anything else."
                ),
                m(
                  "a.button.is-primary.is-outlined.is-inverted",
                  { href: "/login", oncreate: m.route.link },
                  "Sign Up Now"
                ),
              ]),
              m("div.column", { style: { textAlign: "center" } }, [
                m(MarketingHeaderDiagramView),
              ]),
            ])
          )
        )
      ),
    ]);
  }
}

class MarketingHomePageView {
  view(vnode) {
    const { me } = vnode.attrs;

    const topics = {
      promoters: {
        topic_meta: { access: "read" },
        topic: { name: "promoters", color: "#23d160" },
        owner: { display_name: "soaxelbrooke" },
      },
      uncertainty: {
        topic_meta: { access: "read" },
        topic: { name: "uncertainty", color: "#dddddd" },
        owner: { display_name: "soaxelbrooke" },
      },
      "app-search": {
        topic_meta: { access: "read" },
        topic: { name: "app-search", color: "#209cee" },
        owner: { display_name: "soaxelbrooke" },
      },
      "app-churn": {
        topic_meta: { access: "read" },
        topic: { name: "app-churn", color: "#7a0d15" },
        owner: { display_name: "soaxelbrooke" },
      },
      "app-ads": {
        topic_meta: { access: "read" },
        topic: { name: "app-ads", color: "#d1a823" },
        owner: { display_name: "soaxelbrooke" },
      },
      "app-annoying": {
        topic_meta: { access: "read" },
        topic: { name: "app-annoying", color: "#ffc357" },
        owner: { display_name: "soaxelbrooke" },
      },
    };

    const jtbdData = [
      {
        title: "Feedback Analysis",
        description:
          "Identify which features users love, and which ones are causing them the most pain.  " +
          "Auto tag a CSV of your product reviews or plug your survey results into the API and " +
          "put data behind your roadmap.",
        classification: {
          auto_tags: [
            {
              end_idx: 70,
              kind: "AutoTag",
              presence_likelihood: 0.6172653436660767,
              present: true,
              start_idx: 25,
              topic: "soaxelbrooke/app-annoying",
              training_request_id: 2293,
            },
            {
              end_idx: 70,
              kind: "AutoTag",
              presence_likelihood: 0.9435598850250244,
              present: true,
              start_idx: 25,
              topic: "soaxelbrooke/app-ads",
              training_request_id: 2255,
            },
            {
              end_idx: 114,
              kind: "AutoTag",
              presence_likelihood: 0.6736981272697449,
              present: true,
              start_idx: 71,
              topic: "soaxelbrooke/app-churn",
              training_request_id: 2412,
            },
          ],
          document_text:
            "Are you kidding me??!!!! I have more ads in my feed than actual feeds. I can\u2019t " +
            "wait to delete this piece of trash.",
          topics: ["soaxelbrooke/app-annoying", "soaxelbrooke/app-churn", "soaxelbrooke/app-ads"],
          topics_present: {
            "soaxelbrooke/app-ads": true,
            "soaxelbrooke/app-annoying": true,
            "soaxelbrooke/app-churn": true,
          },
        },
        topics: [topics["app-ads"], topics["app-churn"], topics["app-annoying"]],
      },
      {
        title: "Social Listening",
        description:
          "Measure public opinion and emotion to identify nuanced user sentiment " +
          "about your products and brands - does your brand inspire confidence? Sadness? " +
          "Uncertainty? Auto tag conversations about your company and find out.",
        classification: {
          auto_tags: [
            {
              end_idx: 44,
              kind: "AutoTag",
              presence_likelihood: 0.0811133161187172,
              present: false,
              start_idx: 0,
              topic: "soaxelbrooke/uncertainty",
              training_request_id: 2136,
            },
            {
              end_idx: 94,
              kind: "AutoTag",
              presence_likelihood: 0.9685860276222229,
              present: true,
              start_idx: 45,
              topic: "soaxelbrooke/uncertainty",
              training_request_id: 2136,
            },
          ],
          document_text:
            "The merger should be interesting for sure... I'm not sure what it means for our" +
            " group, though.",
          topics: ["soaxelbrooke/uncertainty"],
          topics_present: { "soaxelbrooke/uncertainty": true },
        },
        topics: [topics["uncertainty"]],
      },
    ];

    const jtbds = jtbdData.map(job =>
      m(
        "section.section",
        m("div.container", [
          m("div.columns", [
            m("div.column", [m("h1.title", job.title), m("p.is-size-5", job.description)]),
            m(
              "div.column",
              m(
                "div.box",
                m(AutoTagTextResultView, {
                  classification: job.classification,
                  allTopicDetails: job.topics,
                  disallowTagging: true,
                  disallowCorrection: true,
                })
              )
            ),
          ]),
        ])
      )
    );

    const productJtbd = m(
      "section.section",
      m("div.container", [
        m("div.columns", [
          m("div.column", [
            m("h1.title", "Powering Products"),
            m(
              "p.is-size-5",
              "Make automated decisions based on user text input and get your text-powered " +
              "product off the ground in minutes using topic prediction APIs."
            ),
          ]),
          m(
            "div.column",
            m(
              "div.box.is-paddingless",
              m(
                "pre.code",
                {
                  style: {
                    backgroundColor: "rgb(51, 51, 51)",
                    color: "rgb(238, 238, 238)",
                    borderRadius: "4px",
                    paddingTop: "2em",
                    paddingBottom: "2em",
                  }
                },
                `$ curl https://api.taggit.io/api/v1/model/predict \\
    ?topic=soaxelbrooke/Promoter \\
    -d '{"texts": ["One of the best crypto exchanges!"]}'`
              )
            )
          ),
        ]),
      ])
    );

    const featureItems = [
      {
        title: "Text Tagging",
        description: "Easily and quickly label text for multiple topics.",
      },
      {
        title: "Intelligent CSV Tagging",
        description:
          "Improve topics quickly with intelligent CSV tagging, sorting your CSV by what tagged" +
          " text will most improve the topic.",
      },
      {
        title: "Text Auto Tagging",
        description: "Predict topic presence in copied text, a CSV, or via our API - all for free!",
      },
      {
        title: "Auto Tag Performance Statistics",
        description:
          "Get performance statistics on topic auto taggers so you know when your topic is" +
          " accurate enough.",
      },
      {
        title: "Fork Topics to Specialize",
        description:
          "Copy other users's topics and add your own tags to specialize it for your use case.",
      },
      {
        title: "Tag and Topic Auditing",
        description:
          "Audit a topic's tags to make sure you agree with the topic definition before auto " +
          "tagging.",
      },
    ];

    const features = m(
      "section.section",
      m("div.container", [
        m("h1.title", "Features"),
        m(
          "div.columns.is-multiline.is-tablet",
          featureItems.map(fi =>
            m("div.column.is-half", [
              m("h2.is-size-5.has-text-weight-bold", fi.title),
              m("p.is-size-6", fi.description),
            ])
          )
        ),
      ])
    );

    const pricingPlans = [
      {
        title: "Community",
        icon: "account",
        price: 0,
        enabled: true,
        features: [
          "Text tagging",
          "Intelligent CSV tagging",
          "API auto tagging",
          "CSV auto tagging",
          "Unlimited public topics",
          // 'Normal processing priority',
        ],
      },
      {
        title: "Pro",
        icon: "account-multiple",
        enabled: false,
      },
      {
        title: "Enterprise",
        icon: "domain",
        enabled: false,
      },
    ];

    const onPlanSelect = planId => {
      window.localStorage.setItem("selected-plan", planId);
      m.route.set("/login");
    };

    const pricing = m(
      "section.section",
      m("div.container", [
        m("h1.title.pricing", "Pricing"),
        m(PlanSelectView, { onPlanSelect, ctaIsPrimary: true }),
      ])
    );

    const finalCta = m(
      "section.section",
      m("div.container.has-text-centered", [
        m(
          "p.is-size-5",
          { style: "margin-bottom: 0.5em;" },
          "Sign up now and start auto tagging for free."
        ),
        m(
          "a.button.is-primary.is-medium",
          { href: "/login", oncreate: m.route.link },
          "Sign Up Now"
        ),
      ])
    );

    const screenshots = m(MarketingScreenshotsView);

    return [
      m(MarketingHeaderView, { me }),
      screenshots,
      jtbds,
      productJtbd,
      features,
      pricing,
      finalCta,
      m(Footer),
    ];
  }
}

export const homePageProvider = (() => {
  const attrs = {};
  let View = HomePageView;

  return {
    onmatch: async (args, requestedPath) => {
      document.title = "Taggit · Text Topic Tagging";
      const jwt = await fetchJwt();
      const mePromise = loggedInUser(jwt);
      const homePromise = getHome(jwt);

      attrs.me = await mePromise;
      if (attrs.me === null) {
        View = MarketingHomePageView;
      } else {
        attrs.homeDetails = await homePromise;
      }

      return addTutorialTaggieToAttrs(attrs, args, requestedPath, jwt, attrs.me);
    },
    render: vnode => {
      return [
        m(View, Object.assign(attrs, vnode.attrs)),
        window.errorTaggie || attrs.tutorial || null,
      ];
    },
  };
})();
